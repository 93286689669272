import React, { useEffect, useState } from "react";
import RegularButton from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";

import { useLoginContext } from "context/LoginContext.js";

import planilha from "../../assets/img/planilha-green.png";

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: "300px",
    textAlign: "center",
    border: "1px solid #000",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  rootDashed: {
    width: "100%",
    minHeight: "300px",
    textAlign: "center",
    border: "1px dashed #D3D3D3",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  button: {
    bottom: "20px",
    width: "200px",
    alignSelf: "center",
  },
  imageSheet: {
    width: "100px",
    height: "100px",
  },
  nameSheet: {
    maxHeight: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default function CensoSheet({ file, setFile }) {
  const [preview, setPreviews] = useState([]);
  const classes = useStyles();

  const { isReader } = useLoginContext();

  useEffect(() => {
    let blob = file ? URL?.createObjectURL(file) : null;
    let name = file ? file.name : null;
    let pos = 0;
    setPreviews({ blob, pos, name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFile(null);
  }

  return (
    <div style={{ width: "100%" }}>
      {file && preview?.blob && (
        <div className={classes.root} key={preview.pos}>
          <div>
            <img
              className={classes.imageSheet}
              src={planilha}
              alt={"planilha"}
            />
            <div>
              <h4>{preview.name}</h4>
            </div>
          </div>
          <RegularButton
            className={classes.button}
            onClick={(event) => removeFile(event, preview.pos)}
            id="delete-button"
            color="rose"
            type="submit"
            variant="contained"
          >
            Remover planilha
          </RegularButton>
        </div>
      )}
      {!file && (
        <div className={classes.rootDashed}>
          <RegularButton
            className={classes.button}
            disabled={isReader}
            variant="contained"
            color="primary"
            component="label"
          >
            + Adicionar planilha
            <input
              onChange={handleUploadMultipleFiles}
              type="file"
              className="form-control"
              hidden
            />
          </RegularButton>
        </div>
      )}
    </div>
  );
}
