import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createGhe(name, sector_id) {
  const res = await axios.post(
    `${API_URL}/ghe`,
    {
      name,
      sector_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editGhe(name, gheId) {
  const res = await axios.put(
    `${API_URL}/ghe/${gheId}`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllGheBySectorId(sector_id) {
  const res = await axios.get(
    `${API_URL}/ghe?sector_id=${sector_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllGhe() {
  const res = await axios.get(
    `${API_URL}/ghe`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getGhe(id) {
  const res = await axios.get(
    `${API_URL}/ghe/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteGhe(id) {
  const res = await axios.delete(
    `${API_URL}/ghe/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createGhe,
  getAllGheBySectorId,
  getGhe,
  editGhe,
  deleteGhe,
  getAllGhe,
};
