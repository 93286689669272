import React, { useState } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { deleteStep } from "api/stepApi";
import { useLoginContext } from "context/LoginContext";

export default function StepComponentList({
  steps,
  setName,
  setDuration,
  setFrequency,
  setPosture,
  setIsPostureFlexible,
  setIsManualLift,
  setStepId,
  setRefresh,
  setDescription,
  setEdit,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  return (
    <>
      {steps.length > 0 &&
        steps.map(
          ({
            name,
            id,
            duration,
            frequency,
            posture,
            is_posture_flexible,
            is_manual_lift,
            description,
          }) => {
            const handleOpenDialog = () => {
              setIdToDelete(id);
              setOpenDialog(true);
            };

            const handleEdit = () => {
              setName(name);
              setDuration(duration);
              setFrequency(frequency);
              setPosture(posture);
              setIsPostureFlexible(is_posture_flexible);
              setIsManualLift(is_manual_lift);
              setDescription(description);
              setStepId(id);
              setEdit(true);
            };

            return (
              <div key={id} style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={10} md={10}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Nome"
                                variant="outlined"
                                disabled
                                value={name}
                                fullWidth
                              ></TextField>
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                color="primary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleEdit}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                disabled={isReader}
                                color="secondary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleOpenDialog}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                              <DeleteDialogImprovement
                                open={openDialog}
                                setOpen={setOpenDialog}
                                deleteFunction={deleteStep}
                                idToDelete={idToDelete}
                                setRefresh={setRefresh}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            );
          }
        )}
    </>
  );
}
