// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownMenuStatus({ statusField, setStatusField }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setStatusField(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "15px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={statusField}
        onChange={handleChange}
        className={classes2.selectEmpty}
      >
        <MenuItem value={"Concluído"}>{"Concluído"}</MenuItem>
        <MenuItem value={"Em andamento"}>{"Em andamento"}</MenuItem>
        <MenuItem value={"Atrasado"}>{"Atrasado"}</MenuItem>
        <MenuItem value={"Esperar decisão"}>{"Esperar decisão"}</MenuItem>
        <MenuItem value={"Requer avaliação mais profunda"}>
          {"Requer avaliação mais profunda"}
        </MenuItem>
        <MenuItem value={"Inviável"}>{"Inviável"}</MenuItem>
      </Select>
    </FormControl>
  );
}
