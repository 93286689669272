import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import CardBody from "components/Card/CardBody.js";
import RegularButton from "components/CustomButtons/Button";
import { getAllUsers, deleteUser } from "../../api/userApi.js";

export default function DeleteUser({
  setOpen,
  setMessage,
  setSeverity,
  setCheckedDeleteUser,
}) {
  const [userToDelete, setUserToDelete] = useState("");
  const [userToCompare, setUserToCompare] = useState("");
  const [userToDeleteList, setUserToDeleteList] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchDataToDelete() {
      try {
        const data = await getAllUsers();
        return setUserToDeleteList(data);
      } catch (err) {
        setUserToDeleteList([]);
        setOpen(true);
      }
    }
    fetchDataToDelete();
    return () => {
      setRefresh(false);
      setCheckedDeleteUser(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    (userToCompare === userToDelete && userToCompare !== "") ||
    userToCompare === undefined
      ? setButtonDisable(false)
      : setButtonDisable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToDelete, userToCompare]);

  const handleDelete = async function () {
    await deleteUser(userToDelete);
    setMessage("Usuário deletado com sucesso");
    setSeverity("success");
    setOpen(true);
    setRefresh(true);
  };

  return (
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-4px" }}>
            <DropDownMenu
              itemSelected={userToDelete}
              itemSelector={setUserToDelete}
              data={userToDeleteList}
              filterName={"Usuário"}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <div style={{ marginTop: "-4px" }}>
            <DropDownMenu
              itemSelected={userToCompare}
              itemSelector={setUserToCompare}
              data={userToDeleteList}
              filterName={"Confirmar usuário"}
            />
          </div>
        </GridItem>
        <div
          style={{
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RegularButton
            disabled={buttonDisable}
            id="delete-button"
            color="rose"
            type="submit"
            variant="contained"
            onClick={handleDelete}
          >
            Deletar Usuário
          </RegularButton>
        </div>
      </GridContainer>
    </CardBody>
  );
}
