import { getAllActivitiesByWorkstationId } from "api/activityApi";
import { getAllAreasByCompanyId } from "api/areaApi";
import { getAllCompanies } from "api/companyApi";
import { getAllGheBySectorId } from "api/gheApi";
import { getAllJobPositionsByGheId } from "api/jobPositionApi";
import { getAllSectorsByAreaId } from "api/sectorApi";
import { getAllStepsByActivityId } from "api/stepApi";
import { getAllWorkstationsByJobPositionId } from "api/workstationApi";
import React, { createContext, useState, useContext, useEffect } from "react";
const ParamsContext = createContext();

export default function HeaderProvider({ children }) {
  const [areaList, setAreaList] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [gheId, setGheId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const [jobPositionList, setJobPositionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [gheList, setGheList] = useState([]);
  const [activityId, setActivityId] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [workstationId, setWorkstationId] = useState("");
  const [workstationList, setWorkstationList] = useState([]);
  const [stepId, setStepId] = useState("");
  const [stepList, setStepList] = useState([]);
  const [refreshCompanyId, setRefreshCompanyId] = useState(false);
  const [refreshAreaId, setRefreshAreaId] = useState(false);
  const [refreshSectorId, setRefreshSectorId] = useState(false);
  const [refreshGheId, setRefreshGheId] = useState(false);
  const [refreshJobPositionId, setRefreshJobPositionId] = useState(false);
  const [refreshWorkstationId, setRefreshWorkstationId] = useState(false);
  const [refreshActivityId, setRefreshActivityId] = useState(false);
  const [refreshStepId, setRefreshStepId] = useState(false);
  const [treeTag, setTreeTag] = useState(false);

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (jobPositionId !== "" && jobPositionId !== undefined)
        try {
          const data = await getAllWorkstationsByJobPositionId(jobPositionId);
          setWorkstationList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setWorkstationId("");
      setActivityId("");
      setActivityList([]);
      setStepId("");
      setStepList([]);
    }

    fetchData();
    setTreeTag(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionId, refreshWorkstationId]);

  useEffect(() => {
    async function fetchData() {
      if (workstationId !== "" && workstationId !== undefined)
        try {
          const data = await getAllActivitiesByWorkstationId(workstationId);
          setActivityList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setActivityId("");
      setStepId("");
      setStepList([]);
    }
    fetchData();
    setTreeTag(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationId, refreshActivityId]);
  useEffect(() => {
    async function fetchData() {
      if (activityId !== "" && activityId !== undefined)
        try {
          const data = await getAllStepsByActivityId(activityId);
          setStepList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setStepId("");
    }
    fetchData();
    setTreeTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, refreshStepId]);

  useEffect(() => {
    async function fetchData() {
      if (gheId !== "" && gheId !== undefined)
        try {
          const data = await getAllJobPositionsByGheId(gheId);
          setJobPositionList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setJobPositionId("");
      setWorkstationId("");
      setWorkstationList([]);
      setActivityId("");
      setActivityList([]);
      setStepId("");
      setStepList([]);
    }
    fetchData();
    setTreeTag(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gheId, refreshJobPositionId]);
  useEffect(() => {
    async function fetchData() {
      if (areaId !== "" && areaId !== undefined)
        try {
          const data = await getAllSectorsByAreaId(areaId);
          setSectorList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setSectorId("");
      setGheId("");
      setJobPositionId("");
      setJobPositionList([]);
      setWorkstationId("");
      setWorkstationList([]);
      setActivityId("");
      setActivityList([]);
      setStepId("");
      setStepList([]);
      setGheList([]);
    }

    fetchData();
    setTreeTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, refreshSectorId]);
  useEffect(() => {
    async function fetchData() {
      if (sectorId !== "" && sectorId !== undefined)
        try {
          const data = await getAllGheBySectorId(sectorId);
          setGheList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setGheId("");
      setJobPositionId("");
      setJobPositionList([]);
      setWorkstationId("");
      setWorkstationList([]);
      setActivityId("");
      setActivityList([]);
      setStepId("");
      setStepList([]);
    }

    fetchData();
    setTreeTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId, refreshGheId]);
  useEffect(() => {
    async function fetchData() {
      if (companyId !== "" && companyId !== undefined)
        try {
          const data = await getAllAreasByCompanyId(companyId);
          setAreaList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    if (treeTag === false) {
      setGheList([]);
      setGheId("");
      setSectorId("");
      setSectorList([]);
      setAreaId("");
      setJobPositionId("");
      setJobPositionList([]);
      setWorkstationId("");
      setWorkstationList([]);
      setActivityId("");
      setActivityList([]);
      setStepId("");
      setStepList([]);
    }

    fetchData();
    setTreeTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, refreshAreaId]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (sessionStorage.getItem("TOKEN_KEY")) {
          const data = await getAllCompanies();
          setCompanyList(data);
          setRefreshCompanyId(false);
          setOpenBackDrop(false);
        }
      } catch (err) {
        setOpen(true);
        setOpenBackDrop(false);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCompanyId]);

  return (
    <ParamsContext.Provider
      value={{
        areaList,
        setAreaList,
        areaId,
        setAreaId,
        sectorId,
        setSectorId,
        gheId,
        setGheId,
        companyId,
        setCompanyId,
        jobPositionId,
        setJobPositionId,
        jobPositionList,
        setJobPositionList,
        companyList,
        setCompanyList,
        sectorList,
        setSectorList,
        gheList,
        setGheList,
        activityId,
        setActivityId,
        activityList,
        setActivityList,
        workstationId,
        setWorkstationId,
        workstationList,
        setWorkstationList,
        stepId,
        setStepId,
        stepList,
        setStepList,
        openBackDrop,
        setOpenBackDrop,
        open,
        setOpen,
        refreshCompanyId,
        setRefreshCompanyId,
        refreshAreaId,
        setRefreshAreaId,
        refreshSectorId,
        setRefreshSectorId,
        refreshGheId,
        setRefreshGheId,
        refreshJobPositionId,
        setRefreshJobPositionId,
        refreshWorkstationId,
        setRefreshWorkstationId,
        refreshActivityId,
        setRefreshActivityId,
        refreshStepId,
        setRefreshStepId,
        treeTag,
        setTreeTag,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
}

export function useHeaderContext() {
  const context = useContext(ParamsContext);
  const {
    areaList,
    setAreaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    gheId,
    setGheId,
    companyId,
    setCompanyId,
    jobPositionId,
    setJobPositionId,
    jobPositionList,
    setJobPositionList,
    companyList,
    setCompanyList,
    sectorList,
    setSectorList,
    gheList,
    setGheList,
    activityId,
    setActivityId,
    activityList,
    setActivityList,
    workstationId,
    setWorkstationId,
    workstationList,
    setWorkstationList,
    stepId,
    setStepId,
    stepList,
    setStepList,
    openBackDrop,
    setOpenBackDrop,
    open,
    setOpen,
    refreshAreaId,
    setRefreshCompanyId,
    setRefreshAreaId,
    refreshSectorId,
    setRefreshSectorId,
    refreshGheId,
    setRefreshGheId,
    refreshJobPositionId,
    setRefreshJobPositionId,
    refreshWorkstationId,
    setRefreshWorkstationId,
    refreshActivityId,
    setRefreshActivityId,
    refreshStepId,
    setRefreshStepId,
    treeTag,
    setTreeTag,
  } = context;
  return {
    areaList,
    setAreaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    gheId,
    setGheId,
    companyId,
    setCompanyId,
    jobPositionId,
    setJobPositionId,
    jobPositionList,
    setJobPositionList,
    companyList,
    setCompanyList,
    sectorList,
    setSectorList,
    gheList,
    setGheList,
    activityId,
    setActivityId,
    activityList,
    setActivityList,
    workstationId,
    setWorkstationId,
    workstationList,
    setWorkstationList,
    stepId,
    setStepId,
    stepList,
    setStepList,
    openBackDrop,
    setOpenBackDrop,
    open,
    setOpen,
    refreshAreaId,
    setRefreshCompanyId,
    setRefreshAreaId,
    refreshSectorId,
    setRefreshSectorId,
    refreshGheId,
    setRefreshGheId,
    refreshJobPositionId,
    setRefreshJobPositionId,
    refreshWorkstationId,
    setRefreshWorkstationId,
    refreshActivityId,
    setRefreshActivityId,
    refreshStepId,
    setRefreshStepId,
    treeTag,
    setTreeTag,
  };
}
