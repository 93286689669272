import ImageCardEvaluation from "components/ImageCardEvaluation/ImageCardEvaluation.js";
import React, { useEffect, useState } from "react";
import RegularButton from "components/CustomButtons/Button";
import ImageCard from "components/ImageCard/ImageCard";

import GridItem from "components/Grid/GridItem.js";

import {
  uploadObjectImage,
  getAllObjectImages,
  deleteObjectImage,
} from "api/objectApi";

export default function ObjectImagesRegister({
  objectId,
  setEdit,
  setOpen,
  setMessage,
  setSeverity,
  handleSubmitObject,
  setChecked,
}) {
  const [imageArray, setImageArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  useEffect(() => {
    const fetch = async () => {
      if (objectId !== "") {
        const data = await getAllObjectImages(objectId);
        setImageArray(data);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (objectId !== "") {
        const data = await getAllObjectImages(objectId);
        setImageArray(data);
        setRefresh(false);
      }
    };
    fetch();
  }, [objectId, refresh]);

  const handleSubmit = async () => {
    try {
      for (const file of files) {
        await uploadObjectImage(file, objectId);
      }
      setMessage("Imagens editadas com sucesso");
      setSeverity("success");
      handleSubmitObject();
      setOpen(true);
      setEdit(false);
      setChecked(false);
    } catch (err) {
      setMessage("Erro ao editar imagens");
      setSeverity("error");
      setOpen(true);
      setEdit(false);
    }
  };

  return (
    <>
      {imageArray.length > 0 &&
        imageArray.map(({ id, name, url }) => {
          const handleDelete = (id) => {
            deleteObjectImage(id);
            setRefresh(true);
          };

          return (
            <ImageCardEvaluation
              key={id}
              id={id}
              name={name}
              url={url}
              deleteFunction={handleDelete}
            />
          );
        })}
      {preview.length > 0 &&
        preview
          .filter((preview) => preview.blob !== null)
          .map((preview) => {
            return (
              <ImageCard
                key={preview.pos}
                preview={preview}
                removeFile={removeFile}
              />
            );
          })}
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <RegularButton variant="contained" color="primary" component="label">
            + Adicionar imagens
            <input
              onChange={handleUploadMultipleFiles}
              type="file"
              className="form-control"
              hidden
            />
          </RegularButton>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          <RegularButton
            variant="contained"
            color="warning"
            component="label"
            onClick={handleSubmit}
          >
            Editar
          </RegularButton>
        </div>
      </GridItem>
    </>
  );
}
