import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components

import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownIsTrue from "components/DropDownMenu/DropDownIsTrue";
import {
  createStep,
  editStep,
  getAllStepsByActivityId,
} from "../../api/stepApi.js";

import CardBody from "components/Card/CardBody.js";
import StepComponentList from "./StepComponentList";
import DropDownPosition from "components/DropDownPosition/DropdownPosition.js";
import { useLoginContext } from "context/LoginContext.js";

export default function StepEditorRegister({
  activityId,
  setChecked,
  setMessage,
  setOpen,
  setSeverity,
  companyId,
  gheId,
  jobPositionId,
  sectorId,
  areaId,
  workstationId,
  setRefreshStepId,
}) {
  const [isManualLift, setIsManualLift] = useState("");
  const [stepArray, setStepArray] = useState([]);
  const [stepId, setStepId] = useState("");
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("-");
  const [frequency, setFrequency] = useState("");
  const [description, setDescription] = useState("");
  const [posture, setPosture] = useState("");
  const [isPostureFlexible, setIsPostureFlexible] = useState("");
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { isReader } = useLoginContext();

  const handleSubmit = async () => {
    if (
      name === "" ||
      duration === "" ||
      frequency === "" ||
      posture === "" ||
      isPostureFlexible === "" ||
      description === "" ||
      isManualLift === ""
    ) {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await createStep(
          name,
          duration,
          frequency,
          posture,
          isPostureFlexible,
          isManualLift,
          description,
          activityId,
          companyId,
          gheId,
          jobPositionId,
          sectorId,
          areaId,
          workstationId
        );
        setRefreshStepId(true);

        setMessage("Passo criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setIsManualLift("");
        setChecked(false);
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (
      name === "" ||
      duration === "" ||
      frequency === "" ||
      posture === "" ||
      isPostureFlexible === "" ||
      description === "" ||
      isManualLift === ""
    ) {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await editStep(
          name,
          duration,
          frequency,
          posture,
          isPostureFlexible,
          isManualLift,
          description,
          stepId
        );
        setMessage("Passo editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setIsManualLift("");
        setChecked(false);
        setRefreshStepId(true);
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllStepsByActivityId(activityId);
      setStepArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    refresh ? setRefreshStepId(true) : setRefreshStepId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <CardBody>
        <StepComponentList
          steps={stepArray}
          setName={setName}
          setDuration={setDuration}
          setFrequency={setFrequency}
          setPosture={setPosture}
          setIsPostureFlexible={setIsPostureFlexible}
          setIsManualLift={setIsManualLift}
          setDescription={setDescription}
          setStepId={setStepId}
          setRefresh={setRefresh}
          setEdit={setEdit}
        />
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              multiline
              value={name}
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              id="name"
              label="Nome do Passo"
              name="name"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              multiline
              value={frequency}
              onChange={(event) => setFrequency(event.target.value)}
              variant="outlined"
              id="frequency"
              label="Frequência"
              name="frequency"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              multiline
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              variant="outlined"
              id="description"
              label="Descrição"
              name="description"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <div style={{ marginTop: "-4px" }}>
              <DropDownPosition
                stepPosition={posture}
                setStepPosition={setPosture}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              multiline
              value={isPostureFlexible}
              onChange={(event) => setIsPostureFlexible(event.target.value)}
              variant="outlined"
              id="is_posture_flexible"
              label="Pode flexibilizar?"
              name="is_posture_flexible"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <div style={{ marginTop: "-4px" }}>
              <DropDownIsTrue
                itemSelected={isManualLift}
                itemSelector={setIsManualLift}
                filterName={"Levantamento acima de 3kg?"}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <RegularButton
                disabled={isReader}
                color={edit ? "warning" : "success"}
                onClick={edit ? handleEdit : handleSubmit}
                variant="contained"
              >
                {edit ? "Editar" : "Criar Passo"}
              </RegularButton>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </>
  );
}
