import GridContainer from "components/Grid/GridContainer.js";
import ImageCardEvaluation from "components/ImageCardEvaluation/ImageCardEvaluation.js";
import React, { useEffect, useState } from "react";
import RegularButton from "components/CustomButtons/Button";
import ImageCard from "components/ImageCard/ImageCard";

import GridItem from "components/Grid/GridItem.js";

import {
  getAllEvaluationsImages,
  deleteEvaluationImage,
  uploadEvaluationImage,
} from "../../api/evaluationApi.js";
import { useLoginContext } from "context/LoginContext.js";

export default function StepEvaluationImage({
  step_evaluation_id,
  setEditImage,
  setOpen,
  setMessage,
  setSeverity,
  setOpenBackDrop,
  setOutRefresh,
}) {
  const [imageArray, setImageArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  useEffect(() => {
    const fetch = async () => {
      if (step_evaluation_id !== "") {
        const data = await getAllEvaluationsImages(step_evaluation_id);
        setImageArray(data);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (step_evaluation_id !== "") {
        const data = await getAllEvaluationsImages(step_evaluation_id);
        setImageArray(data);
      }
    };
    fetch();
    return () => {
      setRefresh(false);
    };
  }, [step_evaluation_id, refresh]);

  const handleSubmit = async () => {
    try {
      setOpenBackDrop(true);
      for (const file of files) {
        await uploadEvaluationImage(file, step_evaluation_id);
      }
      setMessage("Imagens editadas com sucesso");
      setSeverity("success");
      setOpen(true);
      setEditImage(false);
      setOutRefresh(true);
      setOpenBackDrop(false);
    } catch (err) {
      setMessage("Erro ao editar imagens");
      setSeverity("error");
      setOpen(true);
      setEditImage(false);
      setOpenBackDrop(false);
    }
  };

  return (
    <>
      <GridContainer>
        {imageArray.length > 0 &&
          imageArray.map(({ id, name, url }) => {
            const handleDelete = async (id) => {
              setOpenBackDrop(true);
              await deleteEvaluationImage(id);
              setRefresh(true);
              setOutRefresh(true);
              setOpenBackDrop(false);
            };

            return (
              <ImageCardEvaluation
                key={id}
                id={id}
                name={name}
                url={url}
                deleteFunction={handleDelete}
              />
            );
          })}
        {preview.length > 0 &&
          preview
            .filter((preview) => preview.blob !== null)
            .map((preview) => {
              return (
                <ImageCard
                  key={preview.pos}
                  preview={preview}
                  removeFile={removeFile}
                />
              );
            })}
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              variant="contained"
              color="primary"
              component="label"
            >
              + Adicionar imagens
              <input
                onChange={handleUploadMultipleFiles}
                type="file"
                className="form-control"
                hidden
              />
            </RegularButton>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              variant="contained"
              color="success"
              component="label"
              onClick={handleSubmit}
            >
              Editar
            </RegularButton>
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
}
