import axios from "axios";
import { API_URL } from "./urlApi.js";

async function getAllQuestionaires() {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/all/by-user-id`,

      {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function getQuestionnaireById(id) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/get-by-id`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function deleteQuestionnaire({ questionnaire_id }) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/delete-by-id`,
      {
        id: questionnaire_id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}

async function getQuestionnaireByPersonalNumberAndId(
  questionnaire_id,
  personal_number
) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/get-questionnaire-response`,
      {
        questionnaire_id,
        personal_number,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function submitQuestionnaire({
  id,
  absence_due_to_discomfort,
  activity_rotation,
  adequate_staff,
  bathroom_freedom,
  break_count,
  constant_attention,
  discomfort_ankle_and_foot,
  discomfort_ankle_and_foot_duration,
  discomfort_ankle_and_foot_improvement,
  discomfort_ankle_and_foot_level,
  discomfort_arms,
  discomfort_arms_duration,
  discomfort_arms_improvement,
  discomfort_arms_level,
  discomfort_elbows,
  discomfort_elbows_duration,
  discomfort_elbows_improvement,
  discomfort_elbows_level,
  discomfort_forearms,
  discomfort_forearms_duration,
  discomfort_forearms_improvement,
  discomfort_forearms_level,
  discomfort_hands_and_fingers,
  discomfort_hands_and_fingers_duration,
  discomfort_hands_and_fingers_improvement,
  discomfort_hands_and_fingers_level,
  discomfort_hip,
  discomfort_hip_duration,
  discomfort_hip_improvement,
  discomfort_hip_level,
  discomfort_knee,
  discomfort_knee_duration,
  discomfort_knee_improvement,
  discomfort_knee_level,
  discomfort_leg,
  discomfort_leg_duration,
  discomfort_leg_improvement,
  discomfort_leg_level,
  discomfort_lumbar_spine,
  discomfort_lumbar_spine_duration,
  discomfort_lumbar_spine_improvement,
  discomfort_lumbar_spine_level,
  discomfort_neck,
  discomfort_neck_duration,
  discomfort_neck_improvement,
  discomfort_neck_level,
  discomfort_shoulders,
  discomfort_shoulders_duration,
  discomfort_shoulders_improvement,
  discomfort_shoulders_level,
  discomfort_thigh,
  discomfort_thigh_duration,
  discomfort_thigh_improvement,
  discomfort_thigh_level,
  discomfort_thoracic_spine,
  discomfort_thoracic_spine_duration,
  discomfort_thoracic_spine_improvement,
  discomfort_thoracic_spine_level,
  discomfort_wrists,
  discomfort_wrists_duration,
  discomfort_wrists_improvement,
  discomfort_wrists_level,
  equipment_force,
  extra_breaks,
  feel_discomfort,
  gender,
  height,
  issue_adequate_work_post,
  issue_compromised_task_vision,
  issue_constant_displacements,
  issue_difficult_valve_activation,
  issue_environmental_noise_issues,
  issue_environmental_temperature_issues,
  issue_equipment_issues,
  issue_excessive_cabinet_or_support_height,
  issue_forced_body_position,
  issue_furniture_issues,
  issue_hard_to_understand_activities,
  issue_improper_tool,
  issue_inadequate_access_ramp_or_stairs,
  issue_inadequate_flooring,
  issue_inadequate_it_systems,
  issue_inadequate_platform,
  issue_inadequate_work_post,
  issue_inadequate_workspace_lighting,
  issue_intense_effort_due_to_machine_maintenance,
  issue_intense_physical_effort_using_manual_tools,
  issue_manual_heavy_load_movements,
  issue_misaligned_operational_procedure,
  issue_no_opportunity_for_improvement,
  issue_overtime_hours,
  issue_pulling_and_pushing_heavy_loads,
  job_satisfaction,
  mental_fatigue,
  pain_medication,
  participate_in_changes,
  personal_authorization,
  personal_name,
  personal_number,
  physical_fatigue,
  physiotherapy_last_year,
  productivity_demand,
  sufficient_time,
  suggestion,
  weight,
  work_posture,
}) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/subbimit-questionnaire-response`,
      {
        id,
        absence_due_to_discomfort,
        activity_rotation,
        adequate_staff,
        bathroom_freedom,
        break_count,
        constant_attention,
        discomfort_ankle_and_foot,
        discomfort_ankle_and_foot_duration,
        discomfort_ankle_and_foot_improvement,
        discomfort_ankle_and_foot_level,
        discomfort_arms,
        discomfort_arms_duration,
        discomfort_arms_improvement,
        discomfort_arms_level,
        discomfort_elbows,
        discomfort_elbows_duration,
        discomfort_elbows_improvement,
        discomfort_elbows_level,
        discomfort_forearms,
        discomfort_forearms_duration,
        discomfort_forearms_improvement,
        discomfort_forearms_level,
        discomfort_hands_and_fingers,
        discomfort_hands_and_fingers_duration,
        discomfort_hands_and_fingers_improvement,
        discomfort_hands_and_fingers_level,
        discomfort_hip,
        discomfort_hip_duration,
        discomfort_hip_improvement,
        discomfort_hip_level,
        discomfort_knee,
        discomfort_knee_duration,
        discomfort_knee_improvement,
        discomfort_knee_level,
        discomfort_leg,
        discomfort_leg_duration,
        discomfort_leg_improvement,
        discomfort_leg_level,
        discomfort_lumbar_spine,
        discomfort_lumbar_spine_duration,
        discomfort_lumbar_spine_improvement,
        discomfort_lumbar_spine_level,
        discomfort_neck,
        discomfort_neck_duration,
        discomfort_neck_improvement,
        discomfort_neck_level,
        discomfort_shoulders,
        discomfort_shoulders_duration,
        discomfort_shoulders_improvement,
        discomfort_shoulders_level,
        discomfort_thigh,
        discomfort_thigh_duration,
        discomfort_thigh_improvement,
        discomfort_thigh_level,
        discomfort_thoracic_spine,
        discomfort_thoracic_spine_duration,
        discomfort_thoracic_spine_improvement,
        discomfort_thoracic_spine_level,
        discomfort_wrists,
        discomfort_wrists_duration,
        discomfort_wrists_improvement,
        discomfort_wrists_level,
        equipment_force,
        extra_breaks,
        feel_discomfort,
        gender,
        height,
        issue_adequate_work_post,
        issue_compromised_task_vision,
        issue_constant_displacements,
        issue_difficult_valve_activation,
        issue_environmental_noise_issues,
        issue_environmental_temperature_issues,
        issue_equipment_issues,
        issue_excessive_cabinet_or_support_height,
        issue_forced_body_position,
        issue_furniture_issues,
        issue_hard_to_understand_activities,
        issue_improper_tool,
        issue_inadequate_access_ramp_or_stairs,
        issue_inadequate_flooring,
        issue_inadequate_it_systems,
        issue_inadequate_platform,
        issue_inadequate_work_post,
        issue_inadequate_workspace_lighting,
        issue_intense_effort_due_to_machine_maintenance,
        issue_intense_physical_effort_using_manual_tools,
        issue_manual_heavy_load_movements,
        issue_misaligned_operational_procedure,
        issue_no_opportunity_for_improvement,
        issue_overtime_hours,
        issue_pulling_and_pushing_heavy_loads,
        job_satisfaction,
        mental_fatigue,
        pain_medication,
        participate_in_changes,
        personal_authorization,
        personal_name,
        personal_number,
        physical_fatigue,
        physiotherapy_last_year,
        productivity_demand,
        sufficient_time,
        suggestion,
        weight,
        work_posture,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}

async function createQuestionnaire({ name, reference_year, company_id }) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/create`,
      {
        name,
        reference_year,
        company_id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function uploadSheet(file, questionnaire_id) {
  let formData = new FormData();
  formData.append("file", file);

  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/upload-sheet/${questionnaire_id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function generateCharts(ids, areaId, sectorId, gheId, restrict) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/generate-charts`,
      {
        ids,
        area: areaId,
        sector: sectorId,
        ghe: gheId,
        is_restrict: restrict === "" ? undefined : restrict,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}

async function isUserQuestionnaire(user_id) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/is-user-questionnaire`,
      {
        user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}

async function setUserQuestionnaire({ user_id, is_questionnaire }) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/set-user-questionnaire`,
      {
        user_id,
        is_questionnaire,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function downloadSheetById({ questionnaire_id }) {
  try {
    const res = await axios.post(
      `${API_URL}/questionnaire/download-sheet`,
      {
        questionnaire_id,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.warn(err);
  }
}
export {
  getAllQuestionaires,
  createQuestionnaire,
  getQuestionnaireById,
  uploadSheet,
  getQuestionnaireByPersonalNumberAndId,
  submitQuestionnaire,
  generateCharts,
  isUserQuestionnaire,
  setUserQuestionnaire,
  downloadSheetById,
  deleteQuestionnaire,
};
