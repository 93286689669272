import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
// import { styled } from "@material-ui/styles";
// import { Tooltip } from "@material-ui/core";

export default function StyledTreeItem(props) {
  const { nodeId, label, tooltipTitle, ...other } = props;

  return (
    // <Tooltip title={tooltipTitle} placement="top">
    <TreeItem nodeId={nodeId} label={label} {...other} />
    //  </Tooltip>
  );
}
