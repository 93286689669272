import axios from "axios";
import { API_URL } from "./urlApi.js";
async function isAuth() {
  const res = await axios.post(
    `${API_URL}/isauth`,
    {},
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );

  return res.data;
}

export { isAuth };
