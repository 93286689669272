import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import legStrength from "../../../assets/img/legStrength.png";
import leg1 from "../../../assets/img/leg1.png";
import leg2 from "../../../assets/img/leg2.png";
import leg3 from "../../../assets/img/leg3.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestLegs() {
  const {
    legsPostureCounter,
    setLegsPostureCounter,
    legsStrengthCounter,
    setLegsStrengthCounter,

    legsDurationCounter,
    setLegsDurationCounter,

    legsFrequencyCounter,
    setLegsFrequencyCounter,

    setLegsCrouching,
    setLegsKneeling,
    setLegsNoSupport,
    setLegsStrength,
    setLegsDuration,
    setLegsFrequency,
    legsCrouching,
    legsKneeling,
    legsNoSupport,
    legsStrength,
    legsDuration,
    legsFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <div style={{ padding: "15%", paddingTop: "0" }}>
          <GridContainer>
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setLegsCrouching}
              check={legsCrouching}
              functionPosture={setLegsPostureCounter}
              counterPosture={legsPostureCounter}
              name={"Pernas"}
              title={"Pernas"}
              picture={leg1}
              subtitle={"agachado"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setLegsKneeling}
              check={legsKneeling}
              functionPosture={setLegsPostureCounter}
              counterPosture={legsPostureCounter}
              name={"Pernas"}
              title={"Pernas"}
              picture={leg2}
              subtitle={"ajoelhado"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setLegsNoSupport}
              check={legsNoSupport}
              functionPosture={setLegsPostureCounter}
              counterPosture={legsPostureCounter}
              name={"Pernas"}
              title={"Pernas"}
              picture={leg3}
              subtitle={"sem apoio"}
            />
            <GridItem xs={12} sm={12} md={6} />

            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setLegsStrength}
              checkStrength={legsStrength}
              functionCheckDuration={setLegsDuration}
              checkDuration={legsDuration}
              functionCheckFrequency={setLegsFrequency}
              checkFrequency={legsFrequency}
              functionStrength={setLegsStrengthCounter}
              counterStrength={legsStrengthCounter}
              functionDuration={setLegsDurationCounter}
              counterDuration={legsDurationCounter}
              functionFrequency={setLegsFrequencyCounter}
              counterFrequency={legsFrequencyCounter}
              frequency={">=2/min"}
              duration={"> que 30% dia"}
              name={"Pernas"}
              subtitle={"Força igual ou maior que 4,5kg"}
              title={"Pernas"}
              picture={legStrength}
            />
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  );
}
