import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import { createJobPosition, editJobPosition } from "api/jobPositionApi.js";
import { getAllJobPositionsByGheId } from "api/jobPositionApi.js";
import JobPositionComponentList from "./JobPositionComponentList.js";
import { useLoginContext } from "context/LoginContext.js";

export default function JobPositionRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  gheId,
  companyId,
  sectorId,
  workAreaId,
  setRefreshJobPositionId,
}) {
  const [jobPositionArray, setJobPositionArray] = useState([]);
  const [jobPositionId, setJobPositionId] = useState("");
  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllJobPositionsByGheId(gheId);
      setJobPositionArray(data);
      setRefresh(false);
    };
    fetch();
  }, [gheId, refresh]);

  const handleSubmit = async () => {
    if (name === "") {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await createJobPosition(name, gheId, companyId, sectorId, workAreaId);
        setRefreshJobPositionId(true);
        setMessage("Cargo criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (name === "") {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await editJobPosition(name, jobPositionId);
        setMessage("Cargo editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshJobPositionId(true);
      } catch (err) {
        setMessage("Cargo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    refresh ? setRefreshJobPositionId(true) : setRefreshJobPositionId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <CardBody>
      <JobPositionComponentList
        jobPositions={jobPositionArray}
        setName={setName}
        setRefresh={setRefresh}
        setEdit={setEdit}
        setJobPositionId={setJobPositionId}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome do Cargo"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              color={edit ? "warning" : "success"}
              onClick={edit ? handleEdit : handleSubmit}
              variant="contained"
            >
              {edit ? "Editar" : "Criar Cargo"}
            </RegularButton>
          </div>
        </GridItem>
      </GridContainer>
    </CardBody>
  );
}
