import React, { useState, useEffect } from "react";

import Card from "components/Card/Card.js";

import AlertCustom from "components/Alerts/AlertCustom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getAllCompanies } from "../../api/companyApi.js";

import CardBody from "components/Card/CardBody.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";
import { saveAs } from "file-saver";

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ImageCard from "components/ImageTable/ImageCard.js";
import PGRTextField from "components/PGRTableView/PGRTextField.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";
import { Button, LinearProgress, makeStyles } from "@material-ui/core";
import { getAllPGR } from "api/PGRApi.js";
import notFound from "../../assets/img/not-found.jpg";
import PGRModal from "components/PGRModal/BarChartPGR.js";
import { useHeaderContext } from "context/HeaderContext.js";
import { useHistory } from "react-router-dom";
import WorkstationCheckbox from "components/EditorView/WorkstationCheckBox.js";
import { downloadXlsPGR } from "api/PGRApi.js";

// Exemplo de uso no componente React
function ExportButton({ handleDownload, disabled }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        maxWidth: "250px",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Button
        disabled={disabled}
        fullWidth
        variant="contained"
        color="primary"
        type="button"
        onClick={handleDownload}
      >
        Gerar como excel
      </Button>
    </div>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const styles = {
  columnBox: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
  columnText: {
    fontSize: "14px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  columnTextConsequence: {
    fontSize: "13px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  partText: {
    fontSize: "18px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  dataGrid: {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      display: "flex",
      justifyContent: "center",
    },
  },
};

function fillBodyPart(part) {
  if (part === "0") {
    return "#57c9d5";
  } else if (part === "1") {
    return "#57b05cff";
  } else if (part === "2") {
    return "#fc930aff";
  } else if (part === "3") {
    return "#eb4945ff";
  } else if (part === "4") {
    return "#9c27b0";
  } else {
    return "#57c9d5";
  }
}

function fillCriticity(part) {
  if (part === "Trivial") {
    return "#57c9d5";
  } else if (part === "Tolerável") {
    return "#57b05cff";
  } else if (part === "Moderado") {
    return "#fc930aff";
  } else if (part === "Alto") {
    return "#eb4945ff";
  } else if (part === "Intolerável") {
    return "#9c27b0";
  } else {
    return "#57c9d5";
  }
}

function fillNPRBackground(part) {
  if (part < 0.1) {
    return "#57c9d5";
  } else if (part < 10) {
    return "#57b05cff";
  } else if (part < 30) {
    return "#fc930aff";
  } else if (part < 50) {
    return "#eb4945ff";
  } else if (part > 50) {
    return "#9c27b0";
  }
}

const useStyles = makeStyles(styles);

export default function PGRTable() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [array, setArray] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openMatrix, setOpenMatrix] = useState(false);
  const [nprFilter, setNprFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [noImprovement, setNoImprovement] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [dangerCount, setDangerCount] = useState(0);

  const classes = useStyles();

  const history = useHistory();

  const handleOpenMatrix = () => {
    setOpenMatrix(!openMatrix);
  };

  const {
    setActivityId,
    setStepId,
    setTreeTag,
    areaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    setGheId,
    setCompanyId,
    companyId,
    setJobPositionId,
    sectorList,
    setWorkstationId,
  } = useHeaderContext();

  const handleClick = async (item) => {
    console.log(item);
    setTreeTag(true);
    await setCompanyId(item.row.company_id);
    setTreeTag(true);
    await setAreaId(item.row.work_area_id);
    setTreeTag(true);
    await setSectorId(item.row.sector_id);
    setTreeTag(true);
    await setGheId(item.row.ghe_id);
    setTreeTag(true);
    await setJobPositionId(item.row.job_position_id);
    setTreeTag(true);
    await setWorkstationId(item.row.workstation_id);
    setTreeTag(true);
    await setActivityId(item.row.activity_id);
    setTreeTag(true);
    await setStepId(item.row.step_id);
    history.push("/editor/step");
  };

  useEffect(() => {
    setColumns([
      {
        field: "id",
        headerName: "Id",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "improvement_id",
        headerName: "Id melhoria",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "company",
        headerName: "Empresa",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "area",
        headerName: "Área",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "sector",
        headerName: "Setor",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "ghe",
        headerName: "GHE",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "workstation",
        headerName: "Posto de trabalho",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "activity",
        headerName: "Atividade",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "step",
        headerName: "Passo da atividade",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "cause_danger",
        headerName: "Perigo ou fator de risco",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "consequence",
        headerName: "Consequências (lesões ou agravos à saúde)",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnTextConsequence}>
                {params.value}
              </span>
            </div>
          );
        },
      },
      {
        field: "cause_description",
        headerName: "Diagnóstico",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnTextConsequence}>
                {params.value}
              </span>
            </div>
          );
        },
      },
      {
        field: "workers_quantity",
        headerName: "Nº de pessoas expostas",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "npr_initial",
        headerName: "NPR inicial",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillNPRBackground(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "npr_current",
        headerName: "NPR pós melhoria",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillNPRBackground(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "right_hand",
        headerName: "Mão D",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "left_hand",
        headerName: "Mão E",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "right_elbow",
        headerName: "Cotovelo D",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "left_elbow",
        headerName: "Cotovelo E",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "right_shoulder",
        headerName: "Ombro D",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "left_shoulder",
        headerName: "Ombro E",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "neck",
        headerName: "Cervical",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "back",
        headerName: "Lombar",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "legs",
        headerName: "Pernas",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillBodyPart(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.partText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "severity",
        headerName: "Severidade",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillCriticity(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "probability",
        headerName: "Probabilidade",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillCriticity(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "criticity",
        headerName: "Classificação",
        width: 180,
        renderCell: (params) => {
          return (
            <div
              style={{ backgroundColor: fillCriticity(params.value) }}
              className={classes.columnBox}
            >
              <span className={classes.columnText}>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "cause_recomendation",
        headerName: "Recomendação",
        width: 180,
        renderCell: (params) => {
          return (
            <div className={classes.columnBox}>
              <span className={classes.columnTextConsequence}>
                {params.value}
              </span>
            </div>
          );
        },
      },
      {
        field: "improvement_action",
        headerName: "Ação",
        width: 200,
        renderCell: (params) => {
          return (
            <PGRTextField
              setOpenBackDrop={setOpenBackDrop}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              id={params?.row?.improvement_id}
              type="text"
              value={params.value ? params.value : ""}
              field="improvement_action"
              array={array}
              setArray={setArray}
              item={params?.row}
            />
          );
        },
      },
      {
        field: "improvement_responsible_name",
        headerName: "Responsável",
        width: 200,
        renderCell: (params) => {
          return (
            <PGRTextField
              setOpenBackDrop={setOpenBackDrop}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              id={params?.row?.improvement_id}
              type="text"
              value={params?.value ? params?.value : ""}
              field="improvement_responsible_name"
              array={array}
              setArray={setArray}
              item={params?.row}
            />
          );
        },
      },
      {
        field: "improvement_target_date",
        headerName: "Data Projetada",
        width: 200,
        renderCell: (params) => {
          return (
            <PGRTextField
              setOpenBackDrop={setOpenBackDrop}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              id={params?.row?.improvement_id}
              value={params.value ? params.value : ""}
              field="improvement_target_date"
              type="date"
              array={array}
              setArray={setArray}
              item={params?.row}
            />
          );
        },
      },
      {
        field: "improvement_final_date",
        headerName: "Data Conclusão",
        width: 200,
        renderCell: (params) => {
          return (
            <PGRTextField
              setOpenBackDrop={setOpenBackDrop}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              id={params?.row?.improvement_id}
              value={params.value ? params.value : " "}
              field="improvement_final_date"
              type="date"
              array={array}
              setArray={setArray}
              item={params?.row}
            />
          );
        },
      },
      {
        field: "improvement_status",
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          return (
            <PGRTextField
              setOpenBackDrop={setOpenBackDrop}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              id={params?.row?.improvement_id}
              type="text"
              value={params.value ? params.value : ""}
              field="improvement_status"
              array={array}
              setArray={setArray}
              item={params?.row}
            />
          );
        },
      },
      {
        field: "image_name",
        headerName: "Foto",
        width: 200,
        renderCell: (params) => {
          return (
            <div
              style={{ cursor: "pointer " }}
              onClick={() => handleClick(params)}
            >
              <ImageCard
                url={
                  params?.row?.image?.url === ""
                    ? notFound
                    : params?.row?.image?.url
                }
                name={params?.row?.image?.name}
              />
            </div>
          );
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array]);

  useEffect(() => {
    setDangerCount(array.length);

    const uniqueActivityIds = new Set();
    array.forEach((item) => {
      if (item.step_id) {
        uniqueActivityIds.add(item.step_id);
      }
    });
    setStepCount(uniqueActivityIds.size);
  }, [array]);

  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      try {
        const data = await getAllCompanies();
        setCompanyList(data);
      } catch (err) {
        setOpen(true);
      }
    }
    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGerarTabelaPGR = async () => {
    setOpenBackDrop(true);
    const newArray = await getAllPGR({
      ids: companyId,
      work_area_id: areaId,
      sector_id: sectorId,
      isNoImprovement: noImprovement,
      npr: nprFilter,
      status: statusFilter,
    });
    setArray(newArray);
    setOpenBackDrop(false);
  };

  const handleDownload = async () => {
    setOpenBackDrop(true);

    const response = await downloadXlsPGR({
      ids: companyId,
      work_area_id: areaId,
      sector_id: sectorId,
      isNoImprovement: noImprovement,
      npr: nprFilter,
      status: statusFilter,
    });

    const downloadFile = (response) => {
      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const name = `pgr-${new Date().getTime()}.xlsx`;

      saveAs(blob, `${name}.xlsx`);
    };

    downloadFile(response);

    setOpenBackDrop(false);
  };

  return (
    <>
      <PGRModal open={openMatrix} setOpen={setOpenMatrix} />
      <Card>
        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <AlertCustom
            setOpen={setOpen}
            open={open}
            message={message}
            severity={severity}
          />

          <GridContainer>
            <AlertCustom
              setOpen={setOpen}
              open={open}
              message={message}
              severity={severity}
            />

            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Empresa"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={areaId}
                itemSelector={setAreaId}
                data={areaList}
                filterName={"Área"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={sectorId}
                itemSelector={setSectorId}
                data={sectorList}
                filterName={"Setor"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={nprFilter}
                itemSelector={setNprFilter}
                data={[
                  { id: 0, name: "0 Sem Risco" },
                  { id: 1, name: "0 a 9.99 Baixo" },
                  { id: 2, name: "10 a 29.99 Médio" },
                  { id: 3, name: "30 a 49.99 Alto" },
                  { id: 4, name: "> 50 Muito Alto" },
                ]}
                filterName={"Npr"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={statusFilter}
                itemSelector={setStatusFilter}
                data={[
                  { id: "Concluído", name: "Concluído" },
                  { id: "Em andamento", name: "Em andamento" },
                  { id: "Atrasado", name: "Atrasado" },
                  { id: "Esperar decisão", name: "Esperar decisão" },
                  {
                    id: "Requer avaliação mais profunda",
                    name: "Requer avaliação mais profunda",
                  },
                  { id: "Inviável", name: "Inviável" },
                  { id: "Sem status", name: "Sem status" },
                ]}
                filterName={"Status"}
              />
            </GridItem>

            <GridItem xs={12} sm={8} md={9}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "25px",
                }}
              >
                <WorkstationCheckbox
                  title={"Somente sem pós melhoria"}
                  setCheck={setNoImprovement}
                  checked={noImprovement}
                />
              </div>
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  maxWidth: "250px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  disabled={companyId.length === 0}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleGerarTabelaPGR}
                >
                  Gerar Tabela PGR
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  maxWidth: "250px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "auto",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleOpenMatrix}
                >
                  Mostrar Matriz 5x5
                </Button>
              </div>
            </GridItem>
            {dangerCount > 0 && stepCount > 0 && (
              <>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <h5 style={{ justifyContent: "flex-start" }}>
                      Total de Perigos ou Fatores de Risco:{" "}
                      <strong>{dangerCount}</strong>
                    </h5>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      marginTop: "-20px",
                      marginBottom: "10px",
                    }}
                  >
                    <h5 style={{ justifyContent: "flex-start" }}>
                      Total de Passos: <strong>{stepCount}</strong>
                    </h5>
                  </div>
                </GridItem>
              </>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <ExportButton
                disabled={companyId.length === 0}
                handleDownload={handleDownload}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card style={{ maxHeight: "calc(100vh * 0.9)" }}>
        <DataGrid
          className={classes.dataGrid}
          rows={array}
          columns={columns}
          componentsProps={{
            pagination: {
              rowsPerPageText: "Linhas por página:",
              labelRowsPerPage: "Linhas por página:",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                improvement_id: false,
                right_hand: false,
                left_hand: false,
                right_elbow: false,
                left_elbow: false,
                right_shoulder: false,
                left_shoulder: false,
                neck: false,
                back: false,
                legs: false,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          rowHeight={200}
          stickyHeader
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
          }}
          density="compact"
          localeText={{
            labelRowsPerPage: "Linhas por página:",
            noRowsLabel: "Nenhum registro encontrado",
            noResultsOverlayLabel: "Sem resultados",
            toolbarDensity: "Altura da linha",
            toolbarDensityLabel: "Altura",
            toolbarDensityCompact: "Compacto",
            toolbarDensityStandard: "Padrão",
            toolbarDensityComfortable: "Confortável",
            toolbarColumns: "Colunas",
            toolbarColumnsLabel: "Colunas",
            toolbarFilters: "Filtros",
            toolbarFiltersLabel: "Filtros",
            toolbarFiltersTooltipHide: "Esconder filtros",
            toolbarFiltersTooltipShow: "Mostrar filtros",
            toolbarFiltersTooltipActive: (count) =>
              `${count} filtro(s) ativo(s)`,
            toolbarQuickFilterPlaceholder: "Filtro rápido",
            toolbarQuickFilterLabel: "Filtro rápido",
            toolbarQuickFilterDeleteIconLabel: "Remover filtro rápido",
            toolbarExport: "Exportar",
            toolbarExportLabel: "Exportar",
            toolbarExportCSV: "Exportar como CSV",
            toolbarExportPrint: "Imprimir",
            toolbarExportExcel: "Exportar como Excel",
            columnsPanelTextFieldLabel: "Procurar coluna",
            columnsPanelTextFieldPlaceholder: "Título da coluna",
            columnsPanelDragIconLabel: "Reordenar coluna",
            columnsPanelShowAllButton: "Mostrar todas",
            columnsPanelHideAllButton: "Esconder todas",
            filterPanelAddFilter: "Adicionar filtro",
            filterPanelDeleteIconLabel: "Excluir",
            filterPanelOperators: "Operadores",
            filterPanelOperatorAnd: "E",
            filterPanelOperatorOr: "Ou",
            filterPanelColumns: "Colunas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Valor do filtro",
            footerPaginationRowsPerPageLabel: "Linhas por página:",
            footerRowSelected: (count) => `${count} linha(s) selecionada(s)`,
            footerTotalRows: "Total de linhas:",
          }}
        />
      </Card>
    </>
  );
}
