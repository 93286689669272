import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllAreasByCompanyId } from "api/areaApi";
import AreaTreeComponent from "./AreaTreeComponent";
import { getAllEvaluationsByCompanyId } from "api/evaluationApi";
import StyledTreeItem from "./StyledTreeItem";

const useStyles = makeStyles({
  root: {
    height: "100%",
    flexGrow: 1,
    maxWidth: "100%",
    collapse: true,
  },
});

export default function TreeViewComponent({
  companyName,
  companyId,
  setOpenBackDrop,
}) {
  const [areaList, setAreaList] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setOpenBackDrop(true);
      if (companyId !== "" && companyId !== undefined)
        try {
          const data = await getAllAreasByCompanyId(companyId);
          setAreaList(data);
          setOpenBackDrop(false);
        } catch (err) {
          setOpenBackDrop(false);
        }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setOpenBackDrop(true);

    const fetch = async () => {
      if (companyId !== "" || companyId !== undefined) {
        const data = await getAllEvaluationsByCompanyId(companyId);

        setEvaluationList(data);
      }
    };
    fetch();
    setOpenBackDrop(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const classes = useStyles();

  return (
    <div style={{ marginBottom: "30px" }}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        <StyledTreeItem
          tooltipTitle={"Empresa"}
          selected={"1"}
          nodeId="1"
          label={companyName}
        >
          {areaList &&
            areaList.map(({ name, id }) => {
              return (
                <AreaTreeComponent
                  setOpenBackDrop={setOpenBackDrop}
                  companyId={companyId}
                  key={id}
                  areaName={name}
                  evaluationList={evaluationList}
                  areaId={id}
                />
              );
            })}
        </StyledTreeItem>
      </TreeView>
    </div>
  );
}
