import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCompany } from "../../api/companyApi.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import KanbanView from "components/KanbanView/KanbanView.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";
import { useHeaderContext } from "context/HeaderContext.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

export default function Kanban() {
  const [companyData, setCompanyData] = useState([]);

  const {
    companyId,
    setCompanyId,
    companyList,
    openBackDrop,
    setOpenBackDrop,
  } = useHeaderContext();

  const classes = useStyles();

  useEffect(() => {
    setOpenBackDrop(true);
    async function fetchData() {
      try {
        const data = await getCompany(companyId);
        return setCompanyData(data);
      } catch (err) {
        console.warn(err);
      }
    }
    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <Card className={classes.root}>
      <OpenBackdrop open={openBackDrop} />
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={4} md={3}>
            <DropDownMenuEditor
              itemSelected={companyId}
              itemSelector={setCompanyId}
              data={companyList}
              filterName={"Empresa"}
            />
          </GridItem>

          {companyData?.name && (
            <KanbanView
              setOpenBackDrop={setOpenBackDrop}
              companyId={companyData.id}
              companyName={companyData.name}
              openBackDrop={openBackDrop}
            />
          )}
        </GridContainer>
      </CardBody>
    </Card>
  );
}
