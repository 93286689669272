import React, { useState } from "react";
// @material-ui/core components
// core components
import Card from "components/Card/Card.js";

import AlertCustom from "components/Alerts/AlertCustom";

import WorkstationEditorRegister from "components/EditorView/WorkstationEditorRegister";
import EquipmentEditorRegister from "components/EditorView/EquipmentEditorRegister";
import CustomCardHeader from "components/ViewCardHeader/CustomCardHeader";
import ActivityEditorRegister from "components/EditorView/ActivityEditorRegister";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";
import CardBody from "components/Card/CardBody.js";
import StepEditorRegister from "components/EditorView/StepEditorRegister.js";
import ObjectEditorRegister from "components/EditorView/ObjectEditorRegister.js";
import CompanyRegister from "components/EditorView/CompanyRegister.js";
import AreaRegister from "components/EditorView/AreaRegister.js";
import SectorRegister from "components/EditorView/SectorRegister.js";
import GheRegister from "components/EditorView/GheRegister.js";
import JobPositionRegister from "components/EditorView/JobPositionRegister.js";
import { useHeaderContext } from "context/HeaderContext.js";

export default function TableList() {
  const [checkedActivityRegister, setCheckedActivityRegister] = useState(false);
  const [checkedSectorRegister, setCheckedSectorRegister] = useState(false);
  const [checkedJobPositionRegister, setCheckedJobPositionRegister] = useState(
    false
  );
  const [checkedGheRegister, setCheckedGheRegister] = useState(false);
  const [checkedAreaRegister, setCheckedAreaRegister] = useState(false);
  const [checkedObjectRegister, setCheckedObjectRegister] = useState(false);
  const [checkedCompanyRegister, setCheckedCompanyRegister] = useState(false);
  const [checkedStepRegister, setCheckedStepRegister] = useState(false);
  const [checkedEquipmentRegister, setCheckedEquipmentRegister] = useState(
    false
  );
  const [checkedWorkstationRegister, setCheckedWorkstationRegister] = useState(
    false
  );
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");

  const {
    areaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    gheId,
    setGheId,
    companyId,
    setCompanyId,
    jobPositionId,
    setJobPositionId,
    jobPositionList,
    companyList,
    sectorList,
    gheList,
    activityId,
    setActivityId,
    activityList,
    workstationId,
    setWorkstationId,
    workstationList,
    stepId,
    setStepId,
    stepList,
    open,
    setOpen,
    setRefreshCompanyId,
    setRefreshAreaId,
    setRefreshSectorId,
    setRefreshGheId,
    setRefreshJobPositionId,
    setRefreshWorkstationId,
    setRefreshActivityId,
    setRefreshStepId,
  } = useHeaderContext();

  const handleCheckedWorkstationRegister = () => {
    !checkedWorkstationRegister
      ? setCheckedWorkstationRegister(true)
      : setCheckedWorkstationRegister(false);
  };
  const handleCheckedEquipmentRegister = () => {
    !checkedEquipmentRegister
      ? setCheckedEquipmentRegister(true)
      : setCheckedEquipmentRegister(false);
  };

  const handleCheckedStepRegister = () => {
    !checkedStepRegister
      ? setCheckedStepRegister(true)
      : setCheckedStepRegister(false);
  };
  const handleCheckedActivityRegister = () => {
    !checkedActivityRegister
      ? setCheckedActivityRegister(true)
      : setCheckedActivityRegister(false);
  };
  const handleCheckedObjectRegister = () => {
    !checkedObjectRegister
      ? setCheckedObjectRegister(true)
      : setCheckedObjectRegister(false);
  };
  const handleCheckedCompanyRegister = () => {
    !checkedCompanyRegister
      ? setCheckedCompanyRegister(true)
      : setCheckedCompanyRegister(false);
  };
  const handleCheckedAreaRegister = () => {
    !checkedAreaRegister
      ? setCheckedAreaRegister(true)
      : setCheckedAreaRegister(false);
  };
  const handleCheckedSectorRegister = () => {
    !checkedSectorRegister
      ? setCheckedSectorRegister(true)
      : setCheckedSectorRegister(false);
  };
  const handleCheckedGheRegister = () => {
    !checkedGheRegister
      ? setCheckedGheRegister(true)
      : setCheckedGheRegister(false);
  };
  const handleCheckedJobPositionRegister = () => {
    !checkedJobPositionRegister
      ? setCheckedJobPositionRegister(true)
      : setCheckedJobPositionRegister(false);
  };

  return (
    <>
      <Card>
        <CardBody>
          <AlertCustom
            setOpen={setOpen}
            open={open}
            message={message}
            severity={severity}
          />

          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Empresa"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={areaId}
                itemSelector={setAreaId}
                data={areaList}
                filterName={"Área"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={sectorId}
                itemSelector={setSectorId}
                data={sectorList}
                filterName={"Setor"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={gheId}
                itemSelector={setGheId}
                data={gheList}
                filterName={"Ghe"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={jobPositionId}
                itemSelector={setJobPositionId}
                data={jobPositionList}
                filterName={"Cargo"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={workstationId}
                itemSelector={setWorkstationId}
                data={workstationList}
                filterName={"Posto de trabalho"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={activityId}
                itemSelector={setActivityId}
                data={activityList}
                filterName={"Atividade"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={stepId}
                itemSelector={setStepId}
                data={stepList}
                filterName={"Passo"}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CustomCardHeader
          title={"Empresa"}
          checked={checkedCompanyRegister}
          handleChecked={handleCheckedCompanyRegister}
        />
        {checkedCompanyRegister && (
          <>
            <CompanyRegister
              setRefreshCompanyId={setRefreshCompanyId}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              setChecked={setCheckedCompanyRegister}
            />
          </>
        )}
      </Card>
      {companyId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Área"}
              checked={checkedAreaRegister}
              handleChecked={handleCheckedAreaRegister}
            />

            {checkedAreaRegister && (
              <AreaRegister
                setRefreshAreaId={setRefreshAreaId}
                companyId={companyId}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={handleCheckedAreaRegister}
                sectorId={sectorId}
                gheId={gheId}
                jobPositionId={jobPositionId}
                areaId={areaId}
              />
            )}
          </Card>
        </>
      )}{" "}
      {areaId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Setor"}
              checked={checkedSectorRegister}
              handleChecked={handleCheckedSectorRegister}
            />

            {checkedSectorRegister && (
              <SectorRegister
                setRefreshSectorId={setRefreshSectorId}
                areaId={areaId}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={handleCheckedSectorRegister}
              />
            )}
          </Card>
        </>
      )}{" "}
      {sectorId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Ghe"}
              checked={checkedGheRegister}
              handleChecked={handleCheckedGheRegister}
            />

            {checkedGheRegister && (
              <GheRegister
                setRefreshGheId={setRefreshGheId}
                sectorId={sectorId}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={handleCheckedGheRegister}
              />
            )}
          </Card>
        </>
      )}{" "}
      {gheId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Cargo"}
              checked={checkedJobPositionRegister}
              handleChecked={handleCheckedJobPositionRegister}
            />

            {checkedJobPositionRegister && (
              <JobPositionRegister
                setRefreshJobPositionId={setRefreshJobPositionId}
                gheId={gheId}
                companyId={companyId}
                sectorId={sectorId}
                workAreaId={areaId}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={handleCheckedJobPositionRegister}
              />
            )}
          </Card>
        </>
      )}{" "}
      {jobPositionId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Posto de trabalho"}
              checked={checkedWorkstationRegister}
              handleChecked={handleCheckedWorkstationRegister}
            />

            {checkedWorkstationRegister && (
              <WorkstationEditorRegister
                setRefreshWorkstationId={setRefreshWorkstationId}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={setCheckedWorkstationRegister}
                companyId={companyId}
                sectorId={sectorId}
                gheId={gheId}
                workAreaId={areaId}
                jobPositionId={jobPositionId}
                areaId={areaId}
              />
            )}
          </Card>
        </>
      )}{" "}
      {workstationId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Mobiliário/Equipamento"}
              checked={checkedEquipmentRegister}
              handleChecked={handleCheckedEquipmentRegister}
            />

            {checkedEquipmentRegister && (
              <EquipmentEditorRegister
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                setChecked={setCheckedEquipmentRegister}
                workstationId={workstationId}
              />
            )}
          </Card>
          <Card>
            <CustomCardHeader
              title={"Atividades"}
              checked={checkedActivityRegister}
              handleChecked={handleCheckedActivityRegister}
            />

            {checkedActivityRegister && (
              <ActivityEditorRegister
                setRefreshActivityId={setRefreshActivityId}
                setChecked={setCheckedActivityRegister}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                workstationId={workstationId}
                gheId={gheId}
                companyId={companyId}
                sectorId={sectorId}
                workAreaId={areaId}
              />
            )}
          </Card>
        </>
      )}
      {activityId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Passos"}
              checked={checkedStepRegister}
              handleChecked={handleCheckedStepRegister}
            />

            {checkedStepRegister && (
              <StepEditorRegister
                setRefreshStepId={setRefreshStepId}
                setChecked={setCheckedStepRegister}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                activityId={activityId}
                areaId={areaId}
                sectorId={sectorId}
                gheId={gheId}
                companyId={companyId}
                jobPositionId={jobPositionId}
                workstationId={workstationId}
              />
            )}
          </Card>
        </>
      )}
      {stepId !== "" && (
        <>
          <Card>
            <CustomCardHeader
              title={"Objeto"}
              checked={checkedObjectRegister}
              handleChecked={handleCheckedObjectRegister}
            />

            {checkedObjectRegister && (
              <ObjectEditorRegister
                setChecked={setCheckedObjectRegister}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
                stepId={stepId}
              />
            )}
          </Card>
        </>
      )}
    </>
  );
}
