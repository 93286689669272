import React from "react";
import Card from "components/Card/Card.js";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      minHeight: "350px",
    },
    cardDiv: {
      display: "flex",
      justifyContent: "center",
      height: "350px",
      alignItems: "center",
      color: "#00acc1",
    },
  })
);
export default function ChartLoader() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.cardDiv}>
        <CircularProgress style={{ color: "#00acc1" }} size={50} />
      </div>
    </Card>
  );
}
