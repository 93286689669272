import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import handFlexed from "../../../assets/img/handFlexed.png";
import handFlexedRight from "../../../assets/img/handFlexedRight.png";
import handFlexed2 from "../../../assets/img/handFlexed2.png";
import handFlexed2Right from "../../../assets/img/handFlexed2Right.png";
import fistFlexed from "../../../assets/img/fistFlexed.png";
import fistFlexedRight from "../../../assets/img/fistFlexedRight.png";
import fistFlexed2 from "../../../assets/img/fistFlexed2.png";
import fistFlexed22Right from "../../../assets/img/fistFlexed22Right.png";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import handStrength1 from "../../../assets/img/handStrength1.png";
import handStrength1Right from "../../../assets/img/handStrength1Right.png";
import handStrength2 from "../../../assets/img/handStrength2.png";
import handStrength2Right from "../../../assets/img/handStrength2Right.png";
import handStrength3 from "../../../assets/img/handStrength3.png";
import handStrength3Right from "../../../assets/img/handStrength3Right.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestHands() {
  const {
    rightHandPostureCounter,
    setRightHandPostureCounter,
    leftHandPostureCounter,
    setLeftHandPostureCounter,
    rightHandStrengthCounter,
    setRightHandStrengthCounter,
    leftHandStrengthCounter,
    setLeftHandStrengthCounter,

    rightHandDurationCounter,
    setRightHandDurationCounter,
    leftHandDurationCounter,
    setLeftHandDurationCounter,

    rightHandFrequencyCounter,
    setRightHandFrequencyCounter,
    leftHandFrequencyCounter,
    setLeftHandFrequencyCounter,

    leftHandExtension,
    leftHandFlexion,
    leftHandUlnar,
    leftHandRadial,
    rightHandExtension,
    rightHandFlexion,
    rightHandUlnar,
    rightHandRadial,
    leftHandGripStrength,
    leftHandGripDuration,
    leftHandGripFrequency,
    leftHandHoldStrength,
    leftHandHoldDuration,
    leftHandHoldFrequency,
    leftHandClampStrength,
    leftHandClampDuration,
    leftHandClampFrequency,
    rightHandGripStrength,
    rightHandGripDuration,
    rightHandGripFrequency,
    rightHandHoldStrength,
    rightHandHoldDuration,
    rightHandHoldFrequency,
    rightHandClampStrength,
    rightHandClampDuration,
    rightHandClampFrequency,
    setLeftHandExtension,
    setLeftHandFlexion,
    setLeftHandUlnar,
    setLeftHandRadial,
    setRightHandExtension,
    setRightHandFlexion,
    setRightHandUlnar,
    setRightHandRadial,
    setLeftHandGripStrength,
    setLeftHandGripDuration,
    setLeftHandGripFrequency,
    setLeftHandHoldStrength,
    setLeftHandHoldDuration,
    setLeftHandHoldFrequency,
    setLeftHandClampStrength,
    setLeftHandClampDuration,
    setLeftHandClampFrequency,
    setRightHandGripStrength,
    setRightHandGripDuration,
    setRightHandGripFrequency,
    setRightHandHoldDuration,
    setRightHandHoldStrength,
    setRightHandHoldFrequency,
    setRightHandClampStrength,
    setRightHandClampDuration,
    setRightHandClampFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={6} md={6}>
          <div style={{ padding: "5%", paddingTop: "0" }}>
            <GridContainer>
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftHandExtension}
                check={leftHandExtension}
                functionPosture={setLeftHandPostureCounter}
                counterPosture={leftHandPostureCounter}
                name={"Mão esquerda"}
                title={"Mão esquerda"}
                subtitle={"extensão >= 45º"}
                picture={handFlexed}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftHandFlexion}
                check={leftHandFlexion}
                functionPosture={setLeftHandPostureCounter}
                counterPosture={leftHandPostureCounter}
                name={"Mão esquerda"}
                title={"Mão esquerda"}
                picture={handFlexed2}
                subtitle={"flexão >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftHandUlnar}
                check={leftHandUlnar}
                functionPosture={setLeftHandPostureCounter}
                counterPosture={leftHandPostureCounter}
                name={"Mão esquerda"}
                title={"Mão esquerda"}
                picture={fistFlexed}
                subtitle={"desvio ulnar"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setLeftHandRadial}
                check={leftHandRadial}
                functionPosture={setLeftHandPostureCounter}
                counterPosture={leftHandPostureCounter}
                name={"Mão esquerda"}
                title={"Mão esquerda"}
                picture={fistFlexed2}
                subtitle={"desvio radial"}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setLeftHandGripStrength}
                checkStrength={leftHandGripStrength}
                functionCheckDuration={setLeftHandGripDuration}
                checkDuration={leftHandGripDuration}
                functionCheckFrequency={setLeftHandGripFrequency}
                checkFrequency={leftHandGripFrequency}
                functionStrength={setLeftHandStrengthCounter}
                counterStrength={leftHandStrengthCounter}
                functionDuration={setLeftHandDurationCounter}
                counterDuration={leftHandDurationCounter}
                functionFrequency={setLeftHandFrequencyCounter}
                counterFrequency={leftHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão esquerda"}
                subtitle={"Força de aperto igual ou maior que 4,5kg"}
                title={"Mão esquerda"}
                picture={handStrength1}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setLeftHandHoldStrength}
                checkStrength={leftHandHoldStrength}
                functionCheckDuration={setLeftHandHoldDuration}
                checkDuration={leftHandHoldDuration}
                functionCheckFrequency={setLeftHandHoldFrequency}
                checkFrequency={leftHandHoldFrequency}
                functionStrength={setLeftHandStrengthCounter}
                counterStrength={leftHandStrengthCounter}
                functionDuration={setLeftHandDurationCounter}
                counterDuration={leftHandDurationCounter}
                functionFrequency={setLeftHandFrequencyCounter}
                counterFrequency={leftHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão esquerda"}
                subtitle={"Preenssão com o dedo igual ou maior que 0,9kg"}
                title={"Mão esquerda"}
                picture={handStrength2}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setLeftHandClampStrength}
                checkStrength={leftHandClampStrength}
                functionCheckDuration={setLeftHandClampDuration}
                checkDuration={leftHandClampDuration}
                functionCheckFrequency={setLeftHandClampFrequency}
                checkFrequency={leftHandClampFrequency}
                functionStrength={setLeftHandStrengthCounter}
                counterStrength={leftHandStrengthCounter}
                functionDuration={setLeftHandDurationCounter}
                counterDuration={leftHandDurationCounter}
                functionFrequency={setLeftHandFrequencyCounter}
                counterFrequency={leftHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão esquerda"}
                subtitle={"Posição de pinça igual ou maior que 0,9kg"}
                title={"Mão esquerda"}
                picture={handStrength3}
              />
            </GridContainer>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <div style={{ padding: "5%", paddingTop: "0" }}>
            <GridContainer>
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightHandExtension}
                check={rightHandExtension}
                functionPosture={setRightHandPostureCounter}
                counterPosture={rightHandPostureCounter}
                name={"Mão direita"}
                title={"Mão direita"}
                subtitle={"extensão >= 45º"}
                picture={handFlexedRight}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightHandFlexion}
                check={rightHandFlexion}
                functionPosture={setRightHandPostureCounter}
                counterPosture={rightHandPostureCounter}
                name={"Mão direita"}
                title={"Mão direita"}
                picture={handFlexed2Right}
                subtitle={"flexão >= 45º"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightHandUlnar}
                check={rightHandUlnar}
                functionPosture={setRightHandPostureCounter}
                counterPosture={rightHandPostureCounter}
                name={"Mão direita"}
                title={"Mão direita"}
                picture={fistFlexedRight}
                subtitle={"desvio ulnar"}
              />
              <BriefAndBestCard
                line={3}
                lineMid={12}
                functionCheck={setRightHandRadial}
                check={rightHandRadial}
                functionPosture={setRightHandPostureCounter}
                counterPosture={rightHandPostureCounter}
                name={"Mão direita"}
                title={"Mão direita"}
                picture={fistFlexed22Right}
                subtitle={"desvio radial"}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setRightHandGripStrength}
                checkStrength={rightHandGripStrength}
                functionCheckDuration={setRightHandGripDuration}
                checkDuration={rightHandGripDuration}
                functionCheckFrequency={setRightHandGripFrequency}
                checkFrequency={rightHandGripFrequency}
                functionStrength={setRightHandStrengthCounter}
                counterStrength={rightHandStrengthCounter}
                functionDuration={setRightHandDurationCounter}
                counterDuration={rightHandDurationCounter}
                functionFrequency={setRightHandFrequencyCounter}
                counterFrequency={rightHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão direita"}
                subtitle={"Força de aperto igual ou maior que 4,5kg"}
                title={"Mão direita"}
                picture={handStrength1Right}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setRightHandHoldStrength}
                checkStrength={rightHandHoldStrength}
                functionCheckDuration={setRightHandHoldDuration}
                checkDuration={rightHandHoldDuration}
                functionCheckFrequency={setRightHandHoldFrequency}
                checkFrequency={rightHandHoldFrequency}
                functionStrength={setRightHandStrengthCounter}
                counterStrength={rightHandStrengthCounter}
                functionDuration={setRightHandDurationCounter}
                counterDuration={rightHandDurationCounter}
                functionFrequency={setRightHandFrequencyCounter}
                counterFrequency={rightHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão direita"}
                subtitle={"Preenssão com o dedo igual ou maior que 0,9kg"}
                title={"Mão direita"}
                picture={handStrength2Right}
              />
              <BriefAndBestStrengthCard
                line={3}
                lineMid={12}
                functionCheckStrength={setRightHandClampStrength}
                checkStrength={rightHandClampStrength}
                functionCheckDuration={setRightHandClampDuration}
                checkDuration={rightHandClampDuration}
                functionCheckFrequency={setRightHandClampFrequency}
                checkFrequency={rightHandClampFrequency}
                functionStrength={setRightHandStrengthCounter}
                counterStrength={rightHandStrengthCounter}
                functionDuration={setRightHandDurationCounter}
                counterDuration={rightHandDurationCounter}
                functionFrequency={setRightHandFrequencyCounter}
                counterFrequency={rightHandFrequencyCounter}
                frequency={">=30/min"}
                duration={">=10s"}
                name={"Mão direita"}
                subtitle={"Posição de pinça igual ou maior que 0,9kg"}
                title={"Mão direita"}
                picture={handStrength3Right}
              />
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
