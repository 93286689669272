import React from "react";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PieCharStatus from "components/PieChart/PieChartStatus";
import PieChartControl from "components/PieChart/PieChartControl";
import PieChartPriority from "components/PieChart/PieChartPriority";
import CausesDashboard from "./CausesDashboard";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ChartLoader from "./ChartLoader";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row", // ensure the children are in a row
      alignItems: "stretch", // ensure the children stretch to the full height of the container
    },
    item: {
      display: "flex",
      flexDirection: "column",
      height: "100%", // take up all available height
    },
    card: {
      flex: "1", // This will make the card fill up the entire height of the GridItem
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);
export default function ImprovementsDashboard({
  improvements,
  setOpenBackDrop,
  improvementArray,
  causes,
  loading,
}) {
  const classes = useStyles();
  const settingChart = (improvements, filterName, selection) => {
    if (improvements.length > 0) {
      var newArray = improvements.filter(
        (item) => item[filterName] === selection
      );
      return newArray.length;
    }
  };

  if (loading) {
    return (
      <GridContainer className={classes.container}>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <ChartLoader />
        </GridItem>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <ChartLoader />
        </GridItem>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <ChartLoader />
        </GridItem>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <ChartLoader />
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <>
      <GridContainer className={classes.container}>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <Card className={classes.card}>
            <PieCharStatus
              finished={settingChart(improvements, "status", "Concluído")}
              current={settingChart(improvements, "status", "Em andamento")}
              delayed={settingChart(improvements, "status", "Atrasado")}
              waitingDecision={settingChart(
                improvements,
                "status",
                "Esperar decisão"
              )}
              requiresEvaluation={settingChart(
                improvements,
                "status",
                "Requer avaliação mais profunda"
              )}
              impracticable={settingChart(improvements, "status", "Inviável")}
            />
          </Card>
        </GridItem>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <Card className={classes.card}>
            <PieChartControl
              riskEliminate={settingChart(
                improvements,
                "control",
                "Eliminar risco"
              )}
              localChanges={settingChart(
                improvements,
                "control",
                "Mudança no local para reduzir risco"
              )}
              timeReduce={settingChart(
                improvements,
                "control",
                "Reduzir o tempo de esposição ao risco"
              )}
              practiceImplementation={settingChart(
                improvements,
                "control",
                "Implementar práticas para reduzir o risco"
              )}
              implementProgram={settingChart(
                improvements,
                "control",
                "Providenciar programas de EPI, ginástica ou outros"
              )}
            />
          </Card>
        </GridItem>
        <GridItem className={classes.card} xs={12} sm={3} md={3}>
          <Card className={classes.card}>
            <PieChartPriority
              a={settingChart(
                improvements,
                "priority",
                "A (Alto impacto, fácil de implementar)"
              )}
              b={settingChart(
                improvements,
                "priority",
                "B (Baixo impacto, fácil de implementar)"
              )}
              c={settingChart(
                improvements,
                "priority",
                "C (Alto impacto, difícil de implementar)"
              )}
              d={settingChart(
                improvements,
                "priority",
                "D (Baixo impacto, difícil de implementar)"
              )}
            />
          </Card>
        </GridItem>
        <CausesDashboard
          classes={classes}
          setOpenBackDrop={setOpenBackDrop}
          improvements={improvementArray}
          causes={causes}
        />
      </GridContainer>
    </>
  );
}
