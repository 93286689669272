import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  uploadAreaImage,
  createArea,
  editArea,
  getAllAreasByCompanyId,
} from "../../api/areaApi.js";
import ImageCard from "components/ImageCard/ImageCard";
import AreaComponentList from "./AreaComponentList.js";
import AreaImageRegister from "./AreaImageRegister.js";
import { useLoginContext } from "context/LoginContext.js";

export default function CompanyRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  companyId,
  setRefreshAreaId,
}) {
  const [areaList, setAreaList] = useState([]);
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [areaId, setAreaId] = useState("");
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllAreasByCompanyId(companyId);
        setAreaList(data);
        setRefresh(false);
      } catch (err) {
        setOpen(true);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllAreasByCompanyId(companyId);
        return setAreaList(data);
      } catch (err) {
        setOpen(true);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh ? setRefreshAreaId(true) : setRefreshAreaId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleSubmit = async () => {
    if (name === "" || description === "") {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await createArea(name, description, companyId);
        setRefreshAreaId(true);
        if (res) {
          for (const file of files) {
            await uploadAreaImage(file, res.id);
          }
        }
        setMessage("Área criada com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (companyId === "") {
      setMessage("Favor Inserir  a empresa ");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await editArea(name, description, areaId);
        if (res) {
          for (const file of files) {
            await uploadAreaImage(file, res.id);
          }
        }
        setMessage("Área editada com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshAreaId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  return (
    <CardBody>
      <AreaComponentList
        areas={areaList}
        setName={setName}
        setDescription={setDescription}
        setAreaId={setAreaId}
        setRefresh={setRefresh}
        setEdit={setEdit}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome da área"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="outlined"
            id="area_description"
            label="Descrição"
            name="area_description"
            fullWidth
            multiline
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        {preview.length > 0 &&
          preview
            .filter((preview) => preview.blob !== null)
            .map((preview) => {
              return (
                <ImageCard
                  key={preview.pos}
                  preview={preview}
                  removeFile={removeFile}
                />
              );
            })}
        {!edit && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  + Adicionar imagens
                  <input
                    onChange={handleUploadMultipleFiles}
                    type="file"
                    className="form-control"
                    hidden
                  />
                </RegularButton>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  color={edit ? "warning" : "success"}
                  onClick={edit ? handleEdit : handleSubmit}
                  variant="contained"
                >
                  {edit ? "Editar" : "Criar Área"}
                </RegularButton>
              </div>
            </GridItem>
          </>
        )}
        {edit && (
          <AreaImageRegister
            areaId={areaId}
            setEdit={setEdit}
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            handleSubmitArea={handleEdit}
            setChecked={setChecked}
          />
        )}
      </GridContainer>
    </CardBody>
  );
}
