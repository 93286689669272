import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import RegularButton from "components/CustomButtons/Button";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: "100%",
  },
});

export default function ImageCard({ id, url, name, deleteFunction }) {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    setOpen(true);
  };

  const classes = useStyles();
  return (
    <GridItem className={classes.root} key={id} xs={12} sm={4} md={3}>
      <img style={{ width: "100%", height: "200px" }} src={url} alt={name} />
      <div
        style={{
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <RegularButton
          onClick={handleDelete}
          id="delete-button"
          color="rose"
          type="submit"
          variant="contained"
        >
          Excluir Imagem
        </RegularButton>
        <DeleteDialog
          open={open}
          setOpen={setOpen}
          deleteFunction={deleteFunction}
          id={id}
        />
      </div>
    </GridItem>
  );
}
