import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  createSector,
  getAllSectorsByAreaId,
  editSector,
} from "../../api/sectorApi.js";
import SectorComponentList from "./SectorComponentList.js";
import { useLoginContext } from "context/LoginContext.js";

export default function SectorRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  areaId,
  setRefreshSectorId,
}) {
  const [name, setName] = useState("");
  const [sectorActivity, setSectorActivity] = useState("");
  const [sectorArray, setSectorArray] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sectorId, setSectorId] = useState("");

  const { isReader } = useLoginContext();

  const handleSubmit = async () => {
    if (name === "" || sectorActivity === "") {
      setMessage("Favor preencher todos os dados");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await createSector(name, sectorActivity, areaId);
        setRefreshSectorId(true);
        setMessage("Setor criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (name === "" || sectorActivity === "") {
      setMessage("Favor preencher todos os dados");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await editSector(name, sectorActivity, sectorId);
        setMessage("Setor editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshSectorId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllSectorsByAreaId(areaId);
      setSectorArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    refresh ? setRefreshSectorId(true) : setRefreshSectorId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <CardBody>
      <SectorComponentList
        sectors={sectorArray}
        setName={setName}
        setSectorActivity={setSectorActivity}
        setRefresh={setRefresh}
        setEdit={setEdit}
        setSectorId={setSectorId}
      />
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome do setor"
            name="name"
            fullWidth
            margin="normal"
            multiline
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <TextField
            value={sectorActivity}
            onChange={(event) => setSectorActivity(event.target.value)}
            variant="outlined"
            id="sector_activity"
            label="Atividade fim do setor"
            name="sector_activity"
            fullWidth
            margin="normal"
            required
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              color={edit ? "warning" : "success"}
              onClick={edit ? handleEdit : handleSubmit}
              variant="contained"
            >
              {edit ? "Editar" : "Criar setor"}
            </RegularButton>
          </div>
        </GridItem>
      </GridContainer>
    </CardBody>
  );
}
