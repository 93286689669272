import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components
import Card from "components/Card/Card.js";

import AlertCustom from "components/Alerts/AlertCustom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";
import { getAllCompanies } from "../../api/companyApi.js";
import { getAllSectorsByAreaId } from "../../api/sectorApi.js";
import { getAllAreasByCompanyId } from "../../api/areaApi.js";
import { getAllGheBySectorId } from "../../api/gheApi.js";

import CardBody from "components/Card/CardBody.js";
import Dashboard from "components/DashboardView/Dashboard.js";
import { TextField } from "@material-ui/core";

const formatDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
};

export default function StepEvaluation() {
  const [open, setOpen] = useState(false);
  const [severity] = useState("error");
  const [message] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [gheId, setGheId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [jobPositionId, setJobPositionId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [gheList, setGheList] = useState([]);
  const [activityId, setActivityId] = useState("");
  const [workstationId, setWorkstationId] = useState("");
  const [stepId, setStepId] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formatDate(new Date()));

  useEffect(() => {
    setRefresh(true);
  }, [gheId]);
  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      if (areaId !== "" && areaId !== undefined)
        try {
          const data = await getAllSectorsByAreaId(areaId);
          setSectorList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    setSectorId("");
    setGheId("");
    setJobPositionId("");
    setWorkstationId("");
    setActivityId("");
    setStepId("");
    setRefresh(true);

    setSectorList([]);
    setGheList([]);
    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);
  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      if (sectorId !== "" && sectorId !== undefined)
        try {
          const data = await getAllGheBySectorId(sectorId);
          setGheList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    setGheId("");
    setJobPositionId("");
    setWorkstationId("");
    setActivityId("");
    setStepId("");
    setRefresh(true);

    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId]);
  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      if (companyId !== "" && companyId !== undefined)
        try {
          const data = await getAllAreasByCompanyId(companyId);
          setAreaList(data);
        } catch (err) {
          setOpen(true);
        }
    }
    setGheList([]);
    setGheId("");
    setSectorId("");
    setSectorList([]);
    setAreaId("");
    setJobPositionId("");
    setWorkstationId("");
    setActivityId("");
    setStepId("");
    setRefresh(true);

    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      try {
        const data = await getAllCompanies();
        setCompanyList(data);
        setRefresh(true);
      } catch (err) {
        setOpen(true);
      }
    }
    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <AlertCustom
            setOpen={setOpen}
            open={open}
            message={message}
            severity={severity}
          />

          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Empresa"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={areaId}
                itemSelector={setAreaId}
                data={areaList}
                filterName={"Área"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={sectorId}
                itemSelector={setSectorId}
                data={sectorList}
                filterName={"Setor"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={gheId}
                itemSelector={setGheId}
                data={gheList}
                filterName={"Ghe"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <TextField
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                variant="outlined"
                id="target_date"
                label="Data Inicial"
                name="inicial_date"
                type="date"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <TextField
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                variant="outlined"
                id="end_date"
                label="Data Final"
                name="end_date"
                type="date"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Dashboard
        setOpenBackDrop={setOpenBackDrop}
        areaId={areaId}
        sectorId={sectorId}
        gheId={gheId}
        companyId={companyId}
        jobPositionId={jobPositionId}
        activityId={activityId}
        workstationId={workstationId}
        stepId={stepId}
        refresh={refresh}
        setRefresh={setRefresh}
        startDate={startDate}
        endDate={endDate}
        openBackDrop={openBackDrop}
      />
    </>
  );
}
