// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownMenuCategory({ category, setCategory }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "20px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">Categoria</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={category}
        onChange={handleChange}
        className={classes2.selectEmpty}
      >
        <MenuItem value={"Postura"}>{"Postura"}</MenuItem>
        <MenuItem value={"Força"}>{"Força"}</MenuItem>
        <MenuItem value={"Repetição"}>{"Repetição"}</MenuItem>
        <MenuItem value={"Layout"}>{"Layout"}</MenuItem>
        <MenuItem value={"Ferramenta"}>{"Ferramenta"}</MenuItem>
      </Select>
    </FormControl>
  );
}
