import GridContainer from "components/Grid/GridContainer.js";
import React from "react";
import ImageCardSide from "components/ImageCard/ImageCardSide.js";

export default function StepEvaluationImageSide({ image }) {
  return (
    <>
      <GridContainer>
        <ImageCardSide
          url={image ? image.url : ""}
          name={image ? image.name : ""}
        />
      </GridContainer>
    </>
  );
}
