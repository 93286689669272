import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createUserCompany(user_id, company_id) {
  const resCompany = await axios.post(
    `${API_URL}/companyuserpermission`,
    {
      user_id,
      company_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return resCompany.data;
}

async function createCompany(
  name,
  cnpj,
  address,
  risk_rate,
  cnae,
  technic_name,
  paragraph_1,
  paragraph_2,
  technic_specialty,
  technic_class
) {
  const resCompany = await axios.post(
    `${API_URL}/company`,
    {
      name,
      cnpj,
      address,
      risk_rate,
      cnae,
      technic_name: technic_name ? technic_name : " ",
      paragraph_1: paragraph_1 ? paragraph_1 : " ",
      paragraph_2: paragraph_2 ? paragraph_2 : " ",
      technic_specialty: technic_specialty ? technic_specialty : " ",
      technic_class: technic_class ? technic_class : "",
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return resCompany.data;
}
async function editCompany(
  name,
  cnpj,
  address,
  risk_rate,
  cnae,
  companyId,
  technic_name,
  paragraph_1,
  paragraph_2,
  technic_specialty,
  technic_class
) {
  const resCompany = await axios.put(
    `${API_URL}/company/${companyId}`,
    {
      name,
      cnpj,
      address,
      risk_rate,
      cnae,
      technic_name: technic_name ? technic_name : " ",
      paragraph_1: paragraph_1 ? paragraph_1 : " ",
      paragraph_2: paragraph_2 ? paragraph_2 : " ",
      technic_specialty: technic_specialty ? technic_specialty : " ",
      technic_class: technic_class ? technic_class : "",
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return resCompany.data;
}
async function deletePermissionByUserCompanyId(user_id, company_id) {
  const resCompany = await axios.delete(
    `${API_URL}/companyuserpermission/${user_id}/${company_id}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return resCompany.data;
}

async function getAllCompanies() {
  const res = await axios.get(
    `${API_URL}/company/all`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getCompany(id) {
  const res = await axios.get(
    `${API_URL}/company/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteCompany(id) {
  const res = await axios.delete(
    `${API_URL}/company/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getCompaniesByUser(userId) {
  const res = await axios.get(
    `${API_URL}/company/user/${userId}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadCompanyImage(file, company_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/companyimage/${company_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllCompanyImages(company_id) {
  const res = await axios.get(
    `${API_URL}/companyimage?company_id=${company_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteCompanyImage(imageId) {
  const res = await axios.delete(
    `${API_URL}/companyimage/${imageId}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createUserCompany,
  getAllCompanies,
  getCompaniesByUser,
  deletePermissionByUserCompanyId,
  createCompany,
  getCompany,
  editCompany,
  deleteCompany,
  uploadCompanyImage,
  getAllCompanyImages,
  deleteCompanyImage,
};
