import React, { useState, useEffect } from "react";
import StepToolEvaluation from "./StepToolEvaluation";
import StepTooComponentList from "./StepToolComponentList";
import { getAllStepToolByStepId } from "api/stepToolApi";

export default function StepToolViewn({
  stepId,
  setOpen,
  setMessage,
  setSeverity,
  setOpenBackDrop,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [stepTools, setStepTools] = useState([]);

  useEffect(() => {
    setOpenBackDrop(true);
    const fetch = async () => {
      const data = await getAllStepToolByStepId(stepId);
      setStepTools(data);
    };
    fetch();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  useEffect(() => {
    setOpenBackDrop(true);

    const fetch = async () => {
      const data = await getAllStepToolByStepId(stepId);
      setStepTools(data);
    };
    fetch();
    setOpenBackDrop(false);
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <StepTooComponentList stepTools={stepTools} setRefresh={setRefresh} />
      <StepToolEvaluation
        stepId={stepId}
        setOpen={setOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
        setOpenBackDrop={setOpenBackDrop}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        setRefresh={setRefresh}
      />
    </>
  );
}
