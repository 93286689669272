import axios from "axios";
import { API_URL } from "./urlApi.js";
async function editOrCreatePGR({
  improvement_action,
  improvement_responsible_name,
  improvement_target_date,
  improvement_final_date,
  improvement_status,
  id,
  cause_id,
}) {
  try {
    const res = await axios.post(
      `${API_URL}/pgr/edit-or-create`,
      {
        title: improvement_action,
        responsible_name: improvement_responsible_name,
        target_date: improvement_target_date,
        final_date: improvement_final_date,
        status: improvement_status,
        id,
        cause_id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function getAllPGR({
  ids,
  work_area_id,
  sector_id,
  isNoImprovement,
  npr,
  status,
}) {
  try {
    const res = await axios.post(
      `${API_URL}/pgr`,
      {
        company_id: ids,
        work_area_id: work_area_id,
        sector_id: sector_id,
        isNoImprovement: isNoImprovement,
        npr: npr,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function downloadXlsPGR({
  ids,
  work_area_id,
  sector_id,
  isNoImprovement,
  npr,
  status,
}) {
  try {
    const res = await axios.post(
      `${API_URL}/pgr/download-xls`,
      {
        company_id: ids,
        work_area_id: work_area_id,
        sector_id: sector_id,
        isNoImprovement: isNoImprovement,
        npr: npr,
        status,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.warn(err);
  }
}

async function getAllChars(ids) {
  try {
    const res = await axios.post(
      `${API_URL}/pgr/chars`,
      {
        company_ids: ids,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
export { editOrCreatePGR, getAllPGR, getAllChars, downloadXlsPGR };
