import React, { useState, useEffect } from "react";

import Card from "components/Card/Card.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getAllCompanies } from "../../api/companyApi.js";

import CardBody from "components/Card/CardBody.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";

import DropDownMenuMultiplies from "components/DropDownMenu/DropDownMenuMultiplies.js";
import { Button } from "@material-ui/core";
import { getAllChars } from "api/PGRApi.js";
import BarCharPGR from "components/PieChart/BarChartPGR.js";
import BarCharStatusPGR from "components/PieChart/BarChartStatusPGR.js";
import BarResponsiblePGR from "components/PieChart/BarResponsiblePGR.js";

import { v4 as uuidv4 } from "uuid";

export default function PGRDashboard() {
  const [companyId, setCompanyId] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [chars, setChars] = useState([]);
  const [filteredChars, setFilteredChars] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [areaName, setAreaName] = useState("");

  useEffect(() => {
    setOpenBackDrop(true);

    async function fetchData() {
      try {
        const data = await getAllCompanies();
        setCompanyList(data);
      } catch (err) {
        console.warn(err);
      }
    }
    fetchData();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredChars(chars);
  }, [chars]);

  useEffect(() => {
    setChars([]);
    setFilteredChars([]);
    setResponsibles([]);
  }, [companyId]);

  const handleGerarGráficos = async () => {
    setOpenBackDrop(true);
    const newArray = await getAllChars(companyId);
    setChars(newArray);
    setOpenBackDrop(false);
  };

  return (
    <>
      <Card>
        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <DropDownMenuMultiplies
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Selecionar empresa(s)"}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  maxWidth: "250px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  disabled={companyId.length === 0}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleGerarGráficos}
                  fullWidth
                >
                  Gerar Gráficos
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {filteredChars.length > 0 &&
          filteredChars.map((item, index) => {
            return (
              <div
                key={item.responsible_name + index}
                style={{ marginRight: "10px" }}
              >
                <Card>
                  <BarCharPGR
                    company={item.company_name}
                    responsible={item.area_name}
                    tolerable={
                      item.criticity.find(
                        (item) => item.criticity === "Tolerável"
                      )?.count ?? 0
                    }
                    trivial={
                      item.criticity.find(
                        (item) => item.criticity === "Trivial"
                      )?.count ?? 0
                    }
                    moderate={
                      item.criticity.find(
                        (item) => item.criticity === "Moderado"
                      )?.count ?? 0
                    }
                    high={
                      item.criticity.find((item) => item.criticity === "Alto")
                        ?.count ?? 0
                    }
                    intolerable={
                      item.criticity.find(
                        (item) => item.criticity === "Intolerável"
                      )?.count ?? 0
                    }
                  />
                </Card>
              </div>
            );
          })}
      </div>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {filteredChars.length > 0 &&
          filteredChars.map((item, index) => {
            return (
              <div
                key={item.responsible_name + index}
                style={{ marginRight: "10px" }}
              >
                <Card>
                  <BarCharStatusPGR
                    setResponsibles={setResponsibles}
                    setAreaName={setAreaName}
                    responsibles={item.responsible}
                    company={item.company_name}
                    responsible={item.area_name}
                    finished={
                      item.status.find((item) => item.status === "Concluído")
                        ?.count ?? 0
                    }
                    current={
                      item.status.find((item) => item.status === "Em andamento")
                        ?.count ?? 0
                    }
                    delayed={
                      item.status.find((item) => item.status === "Atrasado")
                        ?.count ?? 0
                    }
                    waitingDecision={
                      item.status.find(
                        (item) => item.status === "Esperar decisão"
                      )?.count ?? 0
                    }
                    requiresEvaluation={
                      item.status.find(
                        (item) => item.status === "Requer avaliação"
                      )?.count ?? 0
                    }
                    impracticable={
                      item.status.find((item) => item.status === "Inviável")
                        ?.count ?? 0
                    }
                    notCreated={
                      item.status.find((item) => item.status === "Inexistente")
                        ?.count ?? 0
                    }
                  />
                </Card>
              </div>
            );
          })}
      </div>

      <div
        style={{
          display: "flex",
          overflowX: "auto",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {responsibles.length > 0 &&
          responsibles
            .filter((item) => item.name !== "Sem responsável")
            .map((item, index) => {
              return (
                <div key={uuidv4()} style={{ marginRight: "10px" }}>
                  <Card>
                    <BarResponsiblePGR
                      responsible={
                        item.name === "" ? "Sem responsável" : item.name
                      }
                      company={areaName}
                      finished={
                        item.status.find((item) => item.status === "Concluído")
                          ?.count ?? 0
                      }
                      current={
                        item.status.find(
                          (item) => item.status === "Em andamento"
                        )?.count ?? 0
                      }
                      delayed={
                        item.status.find((item) => item.status === "Atrasado")
                          ?.count ?? 0
                      }
                      waitingDecision={
                        item.status.find(
                          (item) => item.status === "Esperar decisão"
                        )?.count ?? 0
                      }
                      requiresEvaluation={
                        item.status.find(
                          (item) => item.status === "Requer avaliação"
                        )?.count ?? 0
                      }
                      impracticable={
                        item.status.find((item) => item.status === "Inviável")
                          ?.count ?? 0
                      }
                      notCreated={
                        item.status.find(
                          (item) => item.status === "Inexistente"
                        )?.count ?? 0
                      }
                    />
                  </Card>
                </div>
              );
            })}
      </div>
    </>
  );
}
