import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createStepTool(description, name, stepId) {
  const res = await axios.post(
    `${API_URL}/steptool`,
    {
      name,
      description,
      step_id: stepId,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteStepTool(id) {
  const res = await axios.delete(
    `${API_URL}/steptool/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllStepToolFiles(id) {
  const res = await axios.get(
    `${API_URL}/steptoolfile?step_tool_id=${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllStepToolFile() {
  const res = await axios.get(
    `${API_URL}/steptoolfile`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadStepToolFile(file, step_tool_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/steptoolfile/${step_tool_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllStepToolByStepId(step_id) {
  const res = await axios.get(
    `${API_URL}/steptool?step_id=${step_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );

  return res.data;
}
async function getAllStepTool() {
  const res = await axios.get(
    `${API_URL}/steptool`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createStepTool,
  uploadStepToolFile,
  getAllStepToolByStepId,
  deleteStepTool,
  getAllStepToolFiles,
  getAllStepToolFile,
  getAllStepTool,
};
