import React, { useState } from "react";
// @material-ui/core components
import CreateUser from "components/UserView/CreateUser.js";
import EditUserCompany from "components/UserView/EditUserCompany.js";
import DeleteUser from "components/UserView/DeleteUser.js";
import AlertCustom from "components/Alerts/AlertCustom.js";
import Card from "components/Card/Card.js";
import CustomCardHeader from "components/ViewCardHeader/CustomCardHeader.js";
import UserQuestionnaire from "components/UserView/UserQuestionnaire";
export default function UserManagement() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [checkedCreateUser, setCheckedCreateUser] = useState(false);
  const [checkedEditUserCompany, setCheckedEditUserCompany] = useState(false);
  const [checkedDeleteUser, setCheckedDeleteUser] = useState(false);
  const [checkedQuestionnaire, setCheckedQuestionnaire] = useState(false);

  const handleCheckedCreateUser = () => {
    !checkedCreateUser
      ? setCheckedCreateUser(true)
      : setCheckedCreateUser(false);
  };
  const handleCheckedEditUserCompany = () => {
    !checkedEditUserCompany
      ? setCheckedEditUserCompany(true)
      : setCheckedEditUserCompany(false);
  };
  const handleCheckedDeleteUser = () => {
    !checkedDeleteUser
      ? setCheckedDeleteUser(true)
      : setCheckedDeleteUser(false);
  };
  const handleCheckedQuestionnaire = () => {
    !checkedQuestionnaire
      ? setCheckedQuestionnaire(true)
      : setCheckedQuestionnaire(false);
  };

  return (
    <>
      <Card>
        <AlertCustom
          setOpen={setOpen}
          open={open}
          message={message}
          severity={severity}
        />
        <CustomCardHeader
          title={"Criar Novo Usuário"}
          checked={checkedCreateUser}
          handleChecked={handleCheckedCreateUser}
        />
        {checkedCreateUser && (
          <CreateUser
            setMessage={setMessage}
            setOpen={setOpen}
            setSeverity={setSeverity}
            setCheckedCreateUser={setCheckedCreateUser}
          />
        )}
      </Card>

      <Card>
        <CustomCardHeader
          title={"Usuário/Empresas"}
          checked={checkedEditUserCompany}
          handleChecked={handleCheckedEditUserCompany}
        />

        {checkedEditUserCompany && (
          <EditUserCompany
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
          />
        )}
      </Card>
      <Card>
        <CustomCardHeader
          title={"Deletar Usuário"}
          checked={checkedDeleteUser}
          handleChecked={handleCheckedDeleteUser}
        />
        {checkedDeleteUser && (
          <DeleteUser
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            setCheckedDeleteUser={setCheckedDeleteUser}
          />
        )}
      </Card>
      <Card>
        <CustomCardHeader
          title={"Adicionar/Remover Questionário"}
          checked={checkedQuestionnaire}
          handleChecked={handleCheckedQuestionnaire}
        />
        {checkedQuestionnaire && (
          <UserQuestionnaire
            setMessage={setMessage}
            setSeverity={setSeverity}
            setOpen={setOpen}
            setCheckedQuestionnaire={setCheckedQuestionnaire}
          />
        )}
      </Card>
    </>
  );
}
