import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import BriefAndBestDropDownFrequency from "./BriefAndBestDropdownFrequency";

export default function BriefAndBestSpecifications() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <BriefAndBestDropDownFrequency />
        </GridItem>
      </GridContainer>
    </div>
  );
}
