import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  createEquipment,
  editEquipment,
  getAllEquipmentByWorkstationId,
  uploadEquipmentImage,
} from "../../api/equipmentApi.js";

import ImageCard from "components/ImageCard/ImageCard.js";
import EquipmentComponentList from "./EquipmentComponentList.js";
import EquipmentImageRegister from "./EquipmentImageRegister.js";
import { useLoginContext } from "context/LoginContext.js";

export default function WorkstationRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  workstationId,
}) {
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [observation, setObservation] = useState("");
  const [isStandard, setIsStandard] = useState("");
  const [equipmentsArray, setEquipmentsArray] = useState([]);
  const [equipmentId, setEquipmentId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  const handleSubmit = async (data) => {
    if (workstationId === "") {
      setMessage("Favor selecione todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await createEquipment(
          name,
          description,
          observation,
          isStandard,
          workstationId
        );
        if (res) {
          for (const file of files) {
            await uploadEquipmentImage(file, res.id);
          }
        }

        setMessage("Mobiliário criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async (data) => {
    try {
      const res = await editEquipment(
        name,
        description,
        observation,
        isStandard,
        equipmentId
      );
      if (res) {
        for (const file of files) {
          await uploadEquipmentImage(file, res.id);
        }
      }

      setMessage("Mobiliário editado com sucesso");
      setSeverity("success");
      setOpen(true);
      setChecked(false);
    } catch (err) {
      setMessage("Objeto já cadastrado com esse nome");
      setSeverity("error");
      setOpen(true);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllEquipmentByWorkstationId(workstationId);
      setEquipmentsArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <CardBody>
      <EquipmentComponentList
        equipments={equipmentsArray}
        setEquipmentId={setEquipmentId}
        setRefresh={setRefresh}
        setEdit={setEdit}
        setName={setName}
        setDescription={setDescription}
        setObservation={setObservation}
        setIsStandard={setIsStandard}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome do equipamento"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="outlined"
            id="description"
            label="Descrição"
            name="description"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={observation}
            onChange={(event) => setObservation(event.target.value)}
            variant="outlined"
            id="observation"
            label="Observação"
            name="observation"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            multiline
            value={isStandard}
            onChange={(event) => setIsStandard(event.target.value)}
            variant="outlined"
            id="is_standard"
            label="O mobiliário é padronizado?"
            name="is_standard"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {preview.length > 0 &&
          preview
            .filter((preview) => preview.blob !== null)
            .map((preview) => {
              return (
                <ImageCard
                  key={preview.pos}
                  preview={preview}
                  removeFile={removeFile}
                />
              );
            })}
        {!edit && (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  + Adicionar imagens
                  <input
                    onChange={handleUploadMultipleFiles}
                    type="file"
                    className="form-control"
                    multiple
                    hidden
                  />
                </RegularButton>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  color="success"
                  onClick={handleSubmit}
                  variant="contained"
                >
                  Criar Equipamento
                </RegularButton>
              </div>
            </GridItem>
          </>
        )}
        {edit && (
          <EquipmentImageRegister
            equipmentId={equipmentId}
            setEdit={setEdit}
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            handleSubmitEquipment={handleEdit}
            setChecked={setChecked}
          />
        )}
      </GridContainer>
    </CardBody>
  );
}
