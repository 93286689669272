import React, { useState } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { deleteStepTool } from "../../api/stepToolApi";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { useLoginContext } from "context/LoginContext";

export default function StepTooComponentList({ stepTools, setRefresh }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  return (
    <>
      {stepTools.length > 0 &&
        stepTools.map(({ description, id, name, step_tool_file }) => {
          const file = step_tool_file[0]?.url;

          const handleOpenDialog = () => {
            setIdToDelete(id);
            setOpenDialog(true);
          };

          return (
            <div key={id} style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={5} md={5}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Nome"
                              variant="outlined"
                              disabled
                              value={name}
                              fullWidth
                              multiline
                            ></TextField>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Descrição"
                              variant="outlined"
                              disabled
                              value={description}
                              fullWidth
                              multiline
                            ></TextField>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                          <div style={{ padding: "10px", textAlign: "center" }}>
                            <IconButton
                              disabled={isReader}
                              color="secondary"
                              edge="end"
                              aria-label="delete"
                              onClick={handleOpenDialog}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                            <DeleteDialogImprovement
                              open={openDialog}
                              setOpen={setOpenDialog}
                              deleteFunction={deleteStepTool}
                              idToDelete={idToDelete}
                              setRefresh={setRefresh}
                            />
                          </div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <h2 style={{ textAlign: "center" }}>
                            Pré visualização
                          </h2>{" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              marginBottom: "20px",
                            }}
                          >
                            {" "}
                            <img
                              src={file}
                              alt={file}
                              title="Pré visualização"
                              style={{
                                width: "247.5px",
                                height: "421px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                  transform: "scale(1.02)",
                                },
                              }}
                            />
                            <a
                              target="_blank"
                              href={file}
                              style={{ marginTop: "10px" }}
                              rel="noopener noreferrer"
                            >
                              Link para o arquivo
                            </a>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          );
        })}
    </>
  );
}
