import React from "react";
import GridContainer from "components/Grid/GridContainer";
import BriefAndBestPhysicalStressorCard from "./BriefAndBestPhysicalStressorCard";

import { useBriefAndBestContext } from "../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestPosture() {
  const {
    vibrationCounter,
    setVibrationCounter,
    lowTemperatureCounter,
    setLowTemperatureCounter,
    softTissueCompressionCounter,
    setSoftTissueCompressionCounter,
    impactStressCounter,
    setImpactStressCounter,
    gloveIssuesCounter,
    setGloveIssuesCounter,

    setVibration,
    setLowTemperature,
    setSoftTissueCompression,
    setImpactStress,
    setGloveIssue,
    vibration,
    lowTemperature,
    softTissueCompression,
    impactStress,
    gloveIssue,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <div
          style={{
            padding: "5%",
            paddingTop: "0",
            width: "100%",
            minHeight: "500px",
          }}
        >
          <GridContainer>
            <BriefAndBestPhysicalStressorCard
              functionCheck={setVibration}
              check={vibration}
              functionPhysicalStressor={setVibrationCounter}
              counter={vibrationCounter}
              title={"Vibração"}
            />
            <BriefAndBestPhysicalStressorCard
              functionCheck={setLowTemperature}
              check={lowTemperature}
              functionPhysicalStressor={setLowTemperatureCounter}
              counter={lowTemperatureCounter}
              title={"Baixas temperaturas"}
            />
            <BriefAndBestPhysicalStressorCard
              functionCheck={setSoftTissueCompression}
              check={softTissueCompression}
              functionPhysicalStressor={setSoftTissueCompressionCounter}
              counter={softTissueCompressionCounter}
              title={"Compressão de tecidos moles"}
            />
            <BriefAndBestPhysicalStressorCard
              functionCheck={setImpactStress}
              check={impactStress}
              functionPhysicalStressor={setImpactStressCounter}
              counter={impactStressCounter}
              title={"Estresse de impacto"}
            />
            <BriefAndBestPhysicalStressorCard
              functionCheck={setGloveIssue}
              check={gloveIssue}
              functionPhysicalStressor={setGloveIssuesCounter}
              counter={gloveIssuesCounter}
              title={"Problemas de luvas"}
            />
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  );
}
