import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routesEditor.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo.png";
import { useLoginContext } from "context/LoginContext";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/editor") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/editor" to="/editor/register" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [filteredRoutes, setFilteredRoutes] = React.useState(routes);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/editor/maps";
  };

  const { setIsReader } = useLoginContext();

  React.useEffect(() => {
    const role = sessionStorage.getItem("ROLE");
    const isQuestionnaire = sessionStorage.getItem("QUESTIONNAIRE");

    let finalRoutes = filteredRoutes;

    if (role === "reader") {
      setIsReader(true);
      finalRoutes = finalRoutes.filter(
        (el) => el.name !== "Usuários" && el.name !== "Exportar Dados"
      );
    }

    if (role === "editor") {
      finalRoutes = finalRoutes.filter((el) => el.name !== "Usuários");
    }

    if (
      isQuestionnaire === "false" ||
      (isQuestionnaire === "null" && role !== "administer")
    ) {
      finalRoutes = finalRoutes.filter(
        (el) => el.name !== "Censo Painel" && el.name !== "Censo Dashboard"
      );
    }
    setFilteredRoutes(finalRoutes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={filteredRoutes}
        logoText={"Ergo Vale"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={filteredRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div style={{ paddingTop: "0px" }} className={classes.content}>
            <div style={{ paddingTop: "0px" }} className={classes.container}>
              {switchRoutes}
            </div>
          </div>
        ) : (
          <div style={{ paddingTop: "0px" }} className={classes.map}>
            {switchRoutes}
          </div>
        )}
      </div>
    </div>
  );
}
