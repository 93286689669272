import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createActivity(
  name,
  workstationId,
  gheId,
  companyId,
  sectorId,
  workAreaId
) {
  const res = await axios.post(
    `${API_URL}/activity`,
    {
      name,
      workstation_id: workstationId,
      ghe_id: gheId,
      company_id: companyId,
      sector_id: sectorId,
      work_area_id: workAreaId,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editActivity(name, id) {
  const res = await axios.put(
    `${API_URL}/activity/${id}`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getActivity(id) {
  const res = await axios.get(
    `${API_URL}/activity/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteActivity(id) {
  const res = await axios.delete(
    `${API_URL}/activity/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllActivitiesByWorkstationId(workstation_id) {
  const res = await axios.get(
    `${API_URL}/activity?workstation_id=${workstation_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllActivities() {
  const res = await axios.get(
    `${API_URL}/activity`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllActivitiesByFilter(
  ghe_id,
  company_id,
  work_area_id,
  sector_id
) {
  if (company_id) {
    const res = await axios.get(
      // prettier-ignore
      `${API_URL}/activity?${ghe_id && `ghe_id=${ghe_id}`}${company_id && `&company_id=${company_id}`}${work_area_id && `&work_area_id=${work_area_id}`}${sector_id && `&sector_id=${sector_id}`}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  }
  return [];
}

export {
  createActivity,
  getAllActivitiesByWorkstationId,
  getActivity,
  deleteActivity,
  editActivity,
  getAllActivitiesByFilter,
  getAllActivities,
};
