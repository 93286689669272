import React from "react";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem";
import PieChartCategory from "components/PieChart/PieChartCategory";

export default function CausesDashboard({ causes, classes }) {
  const settingChart = (arrayToFilter, filterName, selection) => {
    if (arrayToFilter.length > 0) {
      var newArray = arrayToFilter.filter(
        (item) => item[filterName] === selection
      );
      return newArray.length;
    }
  };

  return (
    <GridItem className={classes.item} xs={12} sm={3} md={3}>
      <Card className={classes.card}>
        <PieChartCategory
          posture={settingChart(causes, "category", "Postura")}
          strength={settingChart(causes, "category", "Força")}
          repetition={settingChart(causes, "category", "Repetição")}
          layout={settingChart(causes, "category", "Layout")}
          tool={settingChart(causes, "category", "Ferramenta")}
        />
      </Card>
    </GridItem>
  );
}
