import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import { getAllStepsByActivityId } from "api/stepApi";
import StepTreeComponent from "./StepTreeComponent";

export default function ActivityTreeComponent({
  activityName,
  activityId,
  workstationColor,
  setWorkstationColor,
  evaluationList,
  setOpenBackDrop,
}) {
  const [activityList, setActivityList] = useState([]);
  const [activityColor, setActivityColor] = useState("#57c9d5");

  const useStyles = makeStyles({
    root: {
      color: activityColor,
    },
  });

  useEffect(() => {
    if (activityColor) {
      if (workstationColor === "#57c9d5" && activityColor === "#57c9d5")
        setWorkstationColor("#57c9d5");

      if (workstationColor === "#57c9d5" && activityColor === "#5cb860")
        setWorkstationColor("#5cb860");

      if (workstationColor === "#57c9d5" && activityColor === "#ffa21a")
        setWorkstationColor("#ffa21a");

      if (workstationColor === "#5cb860" && activityColor === "#ffa21a")
        setWorkstationColor("#ffa21a");

      if (workstationColor === "#57c9d5" && activityColor === "#ef5350")
        setWorkstationColor("#ef5350");

      if (workstationColor === "#5cb860" && activityColor === "#ef5350")
        setWorkstationColor("#ef5350");

      if (workstationColor === "#ffa21a" && activityColor === "#ef5350")
        setWorkstationColor("#ef5350");

      if (activityColor === "#af2cc5") setWorkstationColor("#af2cc5");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityColor]);

  useEffect(() => {
    setOpenBackDrop(true);
    async function fetchData() {
      if (activityId !== "" && activityId !== undefined)
        try {
          const data = await getAllStepsByActivityId(activityId);
          setActivityList(data);
          setOpenBackDrop(false);
        } catch (err) {
          setOpenBackDrop(false);
        }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  const classes = useStyles();

  return (
    <>
      <TreeItem
        className={classes.root}
        nodeId={activityId}
        label={activityName}
      >
        {activityList &&
          activityList.map(
            ({
              name,
              id,
              activity_id,
              company_id,
              ghe_id,
              job_position_id,
              sector_id,
              work_area_id,
              workstation_id,
            }) => {
              return (
                <StepTreeComponent
                  setOpenBackDrop={setOpenBackDrop}
                  evaluationList={evaluationList}
                  setActivityColor={setActivityColor}
                  activityColor={activityColor}
                  key={id}
                  stepName={name}
                  activity_id={activity_id}
                  stepId={id}
                  company_id={company_id}
                  ghe_id={ghe_id}
                  job_position_id={job_position_id}
                  sector_id={sector_id}
                  work_area_id={work_area_id}
                  workstation_id={workstation_id}
                />
              );
            }
          )}
      </TreeItem>
    </>
  );
}
