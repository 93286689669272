import React, { useState, useEffect } from "react";
import TreeViewComponent from "components/TreeView/TreeViewComponent";
import { getAllCompanies } from "../../api/companyApi.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Backdrop from "components/BackDrop/BackDrop.js";

export default function MultiSelectTreeView() {
  const [companyList, setCompanyList] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setOpenBackDrop(true);
      try {
        const data = await getAllCompanies();
        return setCompanyList(data);
      } catch (err) {
        console.warn(err);
      }
    }
    fetchData();
    setOpenBackDrop(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Backdrop open={openBackDrop} />
      <CardBody>
        {companyList &&
          companyList.map(({ name, id }) => {
            return (
              <TreeViewComponent
                setOpenBackDrop={setOpenBackDrop}
                companyName={name}
                companyId={id}
                key={id}
              />
            );
          })}
      </CardBody>
    </Card>
  );
}
