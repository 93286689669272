import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createArea(name, area_description, company_id) {
  const res = await axios.post(
    `${API_URL}/workarea`,
    {
      name,
      area_description,
      company_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editArea(name, area_description, areaId) {
  const res = await axios.put(
    `${API_URL}/workarea/${areaId}`,
    {
      name,
      area_description,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getArea(id) {
  const res = await axios.get(
    `${API_URL}/workarea/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteArea(id) {
  const res = await axios.delete(
    `${API_URL}/workarea/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadAreaImage(file, area_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/workareaimage/${area_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllAreasByCompanyId(company_id) {
  const res = await axios.get(
    `${API_URL}/workarea?company_id=${company_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllAreas() {
  const res = await axios.get(
    `${API_URL}/workarea`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllAreaImages(area_id) {
  const res = await axios.get(
    `${API_URL}/workareaimage?work_area_id=${area_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteAreaImage(imageId) {
  const res = await axios.delete(
    `${API_URL}/workareaimage/${imageId}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createArea,
  getAllAreas,
  uploadAreaImage,
  getAllAreasByCompanyId,
  getArea,
  deleteArea,
  editArea,
  getAllAreaImages,
  deleteAreaImage,
};
