import React, { useState } from "react";
// @material-ui/core components
// core components
import Card from "components/Card/Card.js";
import BriefAndBestPhysicalStressor from "components/BriefAndBest/BriefAndBestPhysicalStressor.js";
import BriefAndBestSpecifications from "components/BriefAndBest/BriefAndBestSpecifications";
import BriefAndBestHands from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestHand";
import BriefAndBestElbow from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestElbow";
import BriefAndBestShoulder from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestShoulder";
import BriefAndBestNeck from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestNeck";
import BriefAndBestBack from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestBack";
import BriefAndBestLegs from "components/BriefAndBest/BodyPartsBriefAndBest/BriefAndBestLegs";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TableList() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card style={{ marginTop: "-20px" }}>
        <div
          style={{
            padding: "15px",
            paddingTop: "10px",
            paddingBottom: "0px",
          }}
        >
          <BriefAndBestSpecifications />
        </div>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{
            backgroundColor: "#f5f5f5", // Um cinza claro
            color: "#333", // Texto em cinza escuro para contraste
            indicatorColor: "primary",
            textColor: "primary",
            marginBottom: "10px",
          }}
        >
          <Tab label="Mãos" {...a11yProps(0)} />
          <Tab label="Cotovelos" {...a11yProps(1)} />
          <Tab label="Ombros" {...a11yProps(2)} />
          <Tab label="Pescoço" {...a11yProps(3)} />
          <Tab label="Costas" {...a11yProps(4)} />
          <Tab label="Pernas" {...a11yProps(5)} />
          <Tab label="Estressor Físico" {...a11yProps(6)} />
        </Tabs>

        <div style={{ display: `${value === 0 ? "" : "none"}` }}>
          <BriefAndBestHands />
        </div>
        <div style={{ display: `${value === 1 ? "" : "none"}` }}>
          <BriefAndBestElbow />
        </div>
        <div style={{ display: `${value === 2 ? "" : "none"}` }}>
          <BriefAndBestShoulder />
        </div>
        <div style={{ display: `${value === 3 ? "" : "none"}` }}>
          <BriefAndBestNeck />
        </div>
        <div style={{ display: `${value === 4 ? "" : "none"}` }}>
          <BriefAndBestBack />
        </div>
        <div style={{ display: `${value === 5 ? "" : "none"}` }}>
          <BriefAndBestLegs />
        </div>
        <div style={{ display: `${value === 6 ? "" : "none"}` }}>
          <BriefAndBestPhysicalStressor />
        </div>
      </Card>
    </>
  );
}
