import ImageCardEvaluation from "components/ImageCardEvaluation/ImageCardEvaluation.js";
import React, { useEffect, useState } from "react";
import RegularButton from "components/CustomButtons/Button";
import ImageCard from "components/ImageCard/ImageCard";

import GridItem from "components/Grid/GridItem.js";

import {
  uploadCompanyImage,
  deleteCompanyImage,
  getAllCompanyImages,
} from "api/companyApi.js";

export default function CompanyImageRegister({
  companyId,
  setEdit,
  setOpen,
  setMessage,
  setSeverity,
  handleSubmitCompany,
  setChecked,
}) {
  const [imageArray, setImageArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [file, setFile] = useState();
  const [preview, setPreviews] = useState([]);

  useEffect(() => {
    let blob = file ? URL.createObjectURL(file) : null;
    let pos = 0;
    setPreviews({ blob, pos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFile();
    setPreviews({ blob: null, pos: 0 });
  }

  useEffect(() => {
    const fetch = async () => {
      if (companyId !== "") {
        const data = await getAllCompanyImages(companyId);
        setImageArray(data);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (companyId !== "") {
        const data = await getAllCompanyImages(companyId);
        setImageArray(data);
        setRefresh(false);
      }
    };
    fetch();
  }, [companyId, refresh]);

  const handleSubmit = async () => {
    try {
      if (file) {
        await uploadCompanyImage(file, companyId);
      }
      await handleSubmitCompany();
      setMessage("Imagens editadas com sucesso");
      setSeverity("success");
      setOpen(true);
      setEdit(false);
      setChecked(false);
    } catch (err) {
      setMessage("Erro ao editar imagens");
      setSeverity("error");
      setOpen(true);
      setEdit(false);
    }
  };

  return (
    <>
      {imageArray.length > 0 &&
        imageArray.map(({ id, name, url }) => {
          const handleDelete = (id) => {
            deleteCompanyImage(id);
            setRefresh(true);
          };

          return (
            <ImageCardEvaluation
              key={id}
              id={id}
              name={name}
              url={url}
              deleteFunction={handleDelete}
            />
          );
        })}
      {preview?.blob && (
        <ImageCard
          key={preview.pos}
          preview={preview}
          removeFile={removeFile}
        />
      )}
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <RegularButton variant="contained" color="primary" component="label">
            + Adicionar logo
            <input
              onChange={handleUploadMultipleFiles}
              type="file"
              className="form-control"
              hidden
            />
          </RegularButton>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          <RegularButton
            variant="contained"
            color="warning"
            component="label"
            onClick={handleSubmit}
          >
            Editar
          </RegularButton>
        </div>
      </GridItem>
    </>
  );
}
