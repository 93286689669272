import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createJobPosition(name, gheId, companyId, sectorId, workAreaId) {
  const res = await axios.post(
    `${API_URL}/jobposition`,
    {
      name,
      ghe_id: gheId,
      company_id: companyId,
      sector_id: sectorId,
      work_area_id: workAreaId,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editJobPosition(name, jobPositionId) {
  const res = await axios.put(
    `${API_URL}/jobposition/${jobPositionId}`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllJobPositionsByGheId(ghe_id) {
  const res = await axios.get(
    `${API_URL}/jobposition?ghe_id=${ghe_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllJobPositions() {
  const res = await axios.get(
    `${API_URL}/jobposition`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllJobPositionsByFilter(
  ghe_id,
  company_id,
  work_area_id,
  sector_id
) {
  if (company_id) {
    const res = await axios.get(
      // prettier-ignore
      `${API_URL}/jobposition?${ghe_id && `ghe_id=${ghe_id}`}${company_id && `&company_id=${company_id}`}${work_area_id && `&work_area_id=${work_area_id}`}${sector_id && `&sector_id=${sector_id}`}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  }
  return [];
}

async function getJobPosition(id) {
  const res = await axios.get(
    `${API_URL}/jobposition/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteJobPosition(id) {
  const res = await axios.delete(
    `${API_URL}/jobposition/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createJobPosition,
  getAllJobPositionsByGheId,
  getJobPosition,
  editJobPosition,
  deleteJobPosition,
  getAllJobPositionsByFilter,
  getAllJobPositions,
};
