import React, { useEffect, useState } from "react";

import BriefAndBestNPRPriorityCardEvaluation from "./BriefAndBestNPRPriorityCardEvaluation";

export default function BriefAndBestNPREvaluation({ totalResults }) {
  const [color, setColor] = useState("");
  const [priority, setPriority] = useState("");

  useEffect(() => {
    if (parseFloat(totalResults) === 0) {
      setColor("#00bcd4");
      setPriority("Trivial");
    } else if (parseFloat(totalResults) < 10) {
      setColor("#57b05cff");
      setPriority("Baixa Prioridade");
    } else if (parseFloat(totalResults) < 30) {
      setColor("#fc930aff");
      setPriority("Média Prioridade");
    } else if (parseFloat(totalResults) < 50) {
      setColor("#eb4945ff");
      setPriority("Alta Prioridade");
    } else if (parseFloat(totalResults) > 50) {
      setColor("#9c27b0");
      setPriority("Altíssima Prioridade");
    }
  }, [totalResults]);

  return (
    <div>
      <BriefAndBestNPRPriorityCardEvaluation
        color={color}
        value={parseFloat(totalResults).toFixed(2)}
        priority={priority}
      />
    </div>
  );
}
