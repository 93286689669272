import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components

import { getAllCausesByStepId } from "../../api/causeApi";
import CauseComponentList from "./CauseComponentList";
import CauseEvaluation from "./CauseEvaluation";

export default function CausesView({
  stepId,
  setOpen,
  setMessage,
  setSeverity,
  setOpenBackDrop,
}) {
  const [causes, setCauses] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [danger, setDanger] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [causeId, setCauseId] = useState("");

  useEffect(() => {
    setOpenBackDrop(true);
    const fetch = async () => {
      const data = await getAllCausesByStepId(stepId);
      setCauses(data);
    };
    fetch();
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  useEffect(() => {
    setOpenBackDrop(true);

    const fetch = async () => {
      const data = await getAllCausesByStepId(stepId);
      setCauses(data);
    };
    fetch();
    setOpenBackDrop(false);
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    setOpenBackDrop(true);

    const fetch = async () => {
      const data = await getAllCausesByStepId(stepId);
      setCauses(data);
    };
    fetch();
    setOpenBackDrop(false);
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <CauseComponentList
        setCauseId={setCauseId}
        causes={causes}
        setRefresh={setRefresh}
        setCategory={setCategory}
        setDescription={setDescription}
        setRecommendation={setRecommendation}
        setEdit={setEdit}
        setDanger={setDanger}
      />

      <CauseEvaluation
        stepId={stepId}
        setOpen={setOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
        setRefresh={setRefresh}
        setCategory={setCategory}
        setDescription={setDescription}
        recommendation={recommendation}
        setRecommendation={setRecommendation}
        category={category}
        description={description}
        causeId={causeId}
        edit={edit}
        setEdit={setEdit}
        danger={danger}
        setDanger={setDanger}
      />
    </>
  );
}
