import React, { useEffect, useState } from "react";

import { Chart } from "react-google-charts";

export default function BarResponsiblePGR({
  finished,
  current,
  delayed,
  waitingDecision,
  requiresEvaluation,
  impracticable,
  responsible,
  notCreated,
  company,
}) {
  const [data, setData] = useState([
    [
      "",
      "Concluído",
      "Em andamento",
      "Atrasado",
      "Esperar decisão",
      "Requer avaliação",
      "Inviável",
      "Sem ação",
    ],
    [
      responsible,
      finished,
      current,
      delayed,
      waitingDecision,
      requiresEvaluation,
      impracticable,
      notCreated,
    ],
  ]);

  useEffect(() => {
    const newData = [
      [
        "",
        "Concluído",
        "Em andamento",
        "Atrasado",
        "Esperar decisão",
        "Requer avaliação",
        "Inviável",
        "Sem ação",
      ],
      [
        responsible,
        finished,
        current,
        delayed,
        waitingDecision,
        requiresEvaluation,
        impracticable,
        notCreated,
      ],
    ];

    setData(newData);
  }, [
    responsible,
    finished,
    current,
    delayed,
    waitingDecision,
    requiresEvaluation,
    impracticable,
    notCreated,
  ]);

  const colors = [
    "#57b05c",
    "#57c9d5",
    "#eb4945",
    "#fc930a",
    "#00695c",
    "#795548",
    "#777",
  ];
  const labels = [
    "Concluído",
    "Em andamento",
    "Atrasado",
    "Esperar decisão",
    "Requer avaliação",
    "Inviável",
    "Sem ação",
  ];

  return (
    <div
      style={{
        marginBottom: "30px",
        padding: "30px",
        cursor: "pointer",
        minWidth: "400px",
      }}
    >
      <h4>{responsible}</h4>
      <Chart
        chartType="Bar"
        width="400px"
        height="400px"
        data={data}
        options={{
          legend: { position: "none" },
          chart: {
            title: "Status de melhoria",
            subtitle: company,
          },
          colors: colors,
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {colors.slice(0, 3).map((color, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: color,
                marginRight: "10px",
              }}
            ></div>
            {labels[index]}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {colors.slice(3, 6).map((color, index) => (
          <div
            key={index + 3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: color,
                marginRight: "10px",
              }}
            ></div>
            {labels[index + 3]}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {colors.slice(6).map((color, index) => (
          <div
            key={index + 6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: color,
                marginRight: "10px",
              }}
            ></div>
            {labels[index + 6]}
          </div>
        ))}
      </div>
    </div>
  );
}
