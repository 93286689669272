import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createStep(
  name,
  duration,
  frequency,
  posture,
  is_posture_flexible,
  is_manual_lift,
  description,
  activity_id,
  company_id,
  ghe_id,
  job_position_id,
  sector_id,
  work_area_id,
  workstation_id
) {
  try {
    const res = await axios.post(
      `${API_URL}/step`,
      {
        name,
        posture,
        duration,
        frequency,
        is_posture_flexible,
        is_manual_lift,
        description,
        activity_id,
        company_id,
        ghe_id,
        job_position_id,
        sector_id,
        work_area_id,
        workstation_id,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function editStep(
  name,
  duration,
  frequency,
  posture,
  is_posture_flexible,
  is_manual_lift,
  description,
  id
) {
  try {
    const res = await axios.put(
      `${API_URL}/step/${id}`,
      {
        name,
        posture,
        duration,
        frequency,
        is_posture_flexible,
        is_manual_lift,
        description,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.warn(err);
  }
}
async function getAllStepsByActivityId(activity_id) {
  const res = await axios.get(
    `${API_URL}/step?activity_id=${activity_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllSteps() {
  const res = await axios.get(
    `${API_URL}/step`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllStepsByCompanyId(company_id) {
  const res = await axios.get(
    `${API_URL}/step?company_id=${company_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllStepsByFilter(
  ghe_id,
  company_id,
  work_area_id,
  sector_id
) {
  if (company_id) {
    const res = await axios.get(
      // prettier-ignore
      `${API_URL}/step?${ghe_id && `ghe_id=${ghe_id}`}${company_id && `&company_id=${company_id}`}${work_area_id && `&work_area_id=${work_area_id}`}${sector_id && `&sector_id=${sector_id}`}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  }
  return [];
}

async function getStep(id) {
  const res = await axios.get(
    `${API_URL}/step/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteStep(id) {
  const res = await axios.delete(
    `${API_URL}/step/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createStep,
  getAllStepsByActivityId,
  getStep,
  deleteStep,
  getAllStepsByCompanyId,
  editStep,
  getAllStepsByFilter,
  getAllSteps,
};
