// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "../../assets/jss/material-dashboard-react/components/dropDownButtonStyle";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownOverTimeShift({ overTime, setOverTime }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setOverTime(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "30px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">
        Qual o período?
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        onChange={handleChange}
        value={overTime}
        className={classes2.selectEmpty}
      >
        <MenuItem value={"Não aplica"}>{"Não aplica"}</MenuItem>
        <MenuItem value={"Antes do início do turno de trabalho"}>
          {"Antes do início do turno de trabalho"}
        </MenuItem>
        <MenuItem value={"Após o turno de trabalho"}>
          {"Após o turno de trabalho"}
        </MenuItem>
        <MenuItem value={"Aos sábados"}>{"Aos sábados"}</MenuItem>
        <MenuItem value={"Aos finais de semana"}>
          {"Aos finais de semana"}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
