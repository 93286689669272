import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createCause(
  description,
  category,
  recommendation,
  step_id,
  danger
) {
  const res = await axios.post(
    `${API_URL}/cause`,
    {
      description,
      category,
      recommendation,
      step_id,
      danger: danger === "" ? " " : danger,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editCause(
  description,
  category,
  recommendation,
  cause_id,
  danger
) {
  const res = await axios.put(
    `${API_URL}/cause/${cause_id}`,
    {
      description,
      category,
      recommendation,
      danger: danger === "" ? " " : danger,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getCause(id) {
  const res = await axios.get(
    `${API_URL}/cause/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function deleteCause(cause_id) {
  const res = await axios.delete(
    `${API_URL}/cause/${cause_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllCausesByStepId(step_id) {
  const res = await axios.get(
    `${API_URL}/cause?step_id=${step_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllCauses() {
  const res = await axios.get(`${API_URL}/cause`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
    },
  });
  return res.data;
}
async function getAllCausesByImprovementId(improvement_id) {
  const res = await axios.get(
    `${API_URL}/cause/improvement/${improvement_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createCause,
  getAllCausesByStepId,
  deleteCause,
  getAllCausesByImprovementId,
  editCause,
  getCause,
  getAllCauses,
};
