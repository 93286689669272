import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import neckStrengthPick from "../../../assets/img/neckStrength.png";

import neck1 from "../../../assets/img/neck1.png";
import neck2 from "../../../assets/img/neck2.png";
import neck3 from "../../../assets/img/neck3.png";
import neck4 from "../../../assets/img/neck4.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestNeck() {
  const {
    neckPostureCounter,
    setNeckPostureCounter,
    neckStrengthCounter,
    setNeckStrengthCounter,
    neckDurationCounter,
    setNeckDurationCounter,
    neckFrequencyCounter,
    setNeckFrequencyCounter,

    setNeckFlexion,
    setNeckSideFlexion,
    setNeckExtension,
    setNeckRotation,
    setNeckStrength,
    setNeckDuration,
    setNeckFrequency,
    neckFlexion,
    neckSideFlexion,
    neckExtension,
    neckRotation,
    neckStrength,
    neckDuration,
    neckFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <div style={{ padding: "15%", paddingTop: "0" }}>
          <GridContainer>
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setNeckFlexion}
              check={neckFlexion}
              functionPosture={setNeckPostureCounter}
              counterPosture={neckPostureCounter}
              name={"Pescoço"}
              title={"Pescoço"}
              picture={neck1}
              subtitle={"flexão >= 30º"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setNeckSideFlexion}
              check={neckSideFlexion}
              functionPosture={setNeckPostureCounter}
              counterPosture={neckPostureCounter}
              name={"Pescoço"}
              title={"Pescoço"}
              picture={neck2}
              subtitle={"flexão lateral"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setNeckExtension}
              check={neckExtension}
              functionPosture={setNeckPostureCounter}
              counterPosture={neckPostureCounter}
              name={"Pescoço"}
              title={"Pescoço"}
              picture={neck3}
              subtitle={"extensão"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setNeckRotation}
              check={neckRotation}
              functionPosture={setNeckPostureCounter}
              counterPosture={neckPostureCounter}
              name={"Pescoço"}
              title={"Pescoço"}
              picture={neck4}
              subtitle={"rotação >= 20º"}
            />

            <GridItem xs={12} sm={12} md={4} />

            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setNeckStrength}
              checkStrength={neckStrength}
              functionCheckDuration={setNeckDuration}
              checkDuration={neckDuration}
              functionCheckFrequency={setNeckFrequency}
              checkFrequency={neckFrequency}
              functionStrength={setNeckStrengthCounter}
              counterStrength={neckStrengthCounter}
              functionDuration={setNeckDurationCounter}
              counterDuration={neckDurationCounter}
              functionFrequency={setNeckFrequencyCounter}
              counterFrequency={neckFrequencyCounter}
              frequency={">=2/min"}
              duration={">=10s"}
              name={"Pescoço"}
              subtitle={"Força igual ou maior que 0,9kg"}
              title={"Pescoço"}
              picture={neckStrengthPick}
            />
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  );
}
