import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import elbow1 from "../../../assets/img/elbow1.png";
import elbow1Right from "../../../assets/img/elbow1Right.png";
import elbow2 from "../../../assets/img/elbow2.png";
import elbow2Right from "../../../assets/img/elbow2Right.png";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import elbowStrength1 from "../../../assets/img/elbowStrength1.png";
import elbowStrength2 from "../../../assets/img/elbowStrength2.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestElbow() {
  const {
    rightElbowPostureCounter,
    setRightElbowPostureCounter,
    leftElbowPostureCounter,
    setLeftElbowPostureCounter,
    rightElbowStrengthCounter,
    setRightElbowStrengthCounter,
    bothElbowStrengthCounter,
    setBothElbowStrengthCounter,
    leftElbowStrengthCounter,
    setLeftElbowStrengthCounter,

    rightElbowDurationCounter,
    setRightElbowDurationCounter,
    bothElbowDurationCounter,
    setBothElbowDurationCounter,
    leftElbowDurationCounter,
    setLeftElbowDurationCounter,
    rightElbowFrequencyCounter,
    setRightElbowFrequencyCounter,
    bothElbowFrequencyCounter,
    setBothElbowFrequencyCounter,
    leftElbowFrequencyCounter,
    setLeftElbowFrequencyCounter,

    setLeftElbowRotation,
    setRightElbowRotation,
    setLeftElbowExtension,
    setRightElbowExtension,
    setLeftElbowStrength,
    setLeftElbowDuration,
    setLeftElbowFrequency,
    setRightElbowStrength,
    setRightElbowDuration,
    setRightElbowFrequency,
    setBothElbowStrength,
    setBothElbowDuration,
    setBothElbowFrequency,
    leftElbowRotation,
    rightElbowRotation,
    leftElbowExtension,
    rightElbowExtension,
    leftElbowStrength,
    leftElbowDuration,
    leftElbowFrequency,
    rightElbowStrength,
    rightElbowDuration,
    rightElbowFrequency,
    bothElbowStrength,
    bothElbowDuration,
    bothElbowFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ padding: "15%", paddingTop: "0" }}>
            <GridContainer>
              <BriefAndBestCard
                line={2}
                lineMid={12}
                functionCheck={setLeftElbowRotation}
                check={leftElbowRotation}
                functionPosture={setLeftElbowPostureCounter}
                counterPosture={leftElbowPostureCounter}
                name={"Cotovelo esquerdo"}
                title={"Cotovelo esquerdo"}
                picture={elbow1Right}
                subtitle={"antebraço em rotação"}
              />
              <BriefAndBestCard
                line={2}
                lineMid={12}
                functionCheck={setLeftElbowExtension}
                check={leftElbowExtension}
                functionPosture={setLeftElbowPostureCounter}
                counterPosture={leftElbowPostureCounter}
                name={"Cotovelo esquerdo"}
                title={"Cotovelo esquerdo"}
                picture={elbow2Right}
                subtitle={"extensão total"}
              />
              <GridItem xs={12} sm={12} md={2} />

              <BriefAndBestCard
                line={2}
                lineMid={12}
                functionCheck={setRightElbowRotation}
                check={rightElbowRotation}
                functionPosture={setRightElbowPostureCounter}
                counterPosture={rightElbowPostureCounter}
                name={"Cotovelo direito"}
                title={"Cotovelo direito"}
                picture={elbow1}
                subtitle={"antebraço em rotação"}
              />
              <BriefAndBestCard
                line={2}
                lineMid={12}
                functionCheck={setRightElbowExtension}
                check={rightElbowExtension}
                functionPosture={setRightElbowPostureCounter}
                counterPosture={rightElbowPostureCounter}
                name={"Cotovelo direito"}
                title={"Cotovelo direito"}
                picture={elbow2}
                subtitle={"extensão total"}
              />

              <GridItem xs={12} sm={12} md={2} />

              <BriefAndBestStrengthCard
                line={2}
                lineMid={12}
                functionCheckStrength={setLeftElbowStrength}
                checkStrength={leftElbowStrength}
                functionCheckDuration={setLeftElbowDuration}
                checkDuration={leftElbowDuration}
                functionCheckFrequency={setLeftElbowFrequency}
                checkFrequency={leftElbowFrequency}
                functionStrength={setLeftElbowStrengthCounter}
                counterStrength={leftElbowStrengthCounter}
                functionDuration={setLeftElbowDurationCounter}
                counterDuration={leftElbowDurationCounter}
                functionFrequency={setLeftElbowFrequencyCounter}
                counterFrequency={leftElbowFrequencyCounter}
                frequency={">=2/min"}
                duration={">=10s"}
                name={"Cotovelo esquerdo"}
                subtitle={"Força igual ou maior que 4,5kg"}
                title={"Cotovelo esquerdo"}
                picture={elbowStrength1}
              />
              <GridItem xs={12} sm={12} md={2} />
              <BriefAndBestStrengthCard
                line={2}
                lineMid={12}
                functionCheckStrength={setBothElbowStrength}
                checkStrength={bothElbowStrength}
                functionCheckDuration={setBothElbowDuration}
                checkDuration={bothElbowDuration}
                functionCheckFrequency={setBothElbowFrequency}
                checkFrequency={bothElbowFrequency}
                functionStrength={setBothElbowStrengthCounter}
                counterStrength={bothElbowStrengthCounter}
                functionDuration={setBothElbowDurationCounter}
                counterDuration={bothElbowDurationCounter}
                functionFrequency={setBothElbowFrequencyCounter}
                counterFrequency={bothElbowFrequencyCounter}
                frequency={">=2/min"}
                duration={">=10s"}
                name={"Ambos cotovelos"}
                subtitle={"Força igual ou maior que 6,8kg"}
                title={"Ambos cotovelos"}
                picture={elbowStrength2}
              />
              <GridItem xs={12} sm={12} md={2} />

              <BriefAndBestStrengthCard
                line={2}
                lineMid={12}
                functionCheckStrength={setRightElbowStrength}
                checkStrength={rightElbowStrength}
                functionCheckDuration={setRightElbowDuration}
                checkDuration={rightElbowDuration}
                functionCheckFrequency={setRightElbowFrequency}
                checkFrequency={rightElbowFrequency}
                functionStrength={setRightElbowStrengthCounter}
                counterStrength={rightElbowStrengthCounter}
                functionDuration={setRightElbowDurationCounter}
                counterDuration={rightElbowDurationCounter}
                functionFrequency={setRightElbowFrequencyCounter}
                counterFrequency={rightElbowFrequencyCounter}
                frequency={">=2/min"}
                duration={">=10s"}
                name={"Cotovelo direito"}
                subtitle={"Força igual ou maior que 4,5kg"}
                title={"Cotovelo direito"}
                picture={elbowStrength1}
              />
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
