import GridContainer from "components/Grid/GridContainer";
import React from "react";
import BriefAndBestCard from "../BriefAndBestCard";
import back1 from "../../../assets/img/back1.png";
import back2 from "../../../assets/img/back2.png";
import back3 from "../../../assets/img/back3.png";
import back4 from "../../../assets/img/back4.png";
import back5 from "../../../assets/img/back5.png";
import backStrengthPick from "../../../assets/img/backStrength.png";

import { useBriefAndBestContext } from "../../../context/BriefAndBestContext.js";
import { makeStyles } from "@material-ui/core";
import BriefAndBestStrengthCard from "../BriefAndBestStrengthCard";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  grid: {
    flex: "1",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestBack() {
  const {
    backPostureCounter,
    setBackPostureCounter,
    backStrengthCounter,
    setBackStrengthCounter,
    backDurationCounter,
    setBackDurationCounter,

    backFrequencyCounter,
    setBackFrequencyCounter,

    setBackFlexion,
    setBackRotation,
    setBackSideFlexion,
    setBackExtension,
    setBackNoSupport,
    setBackStrength,
    setBackDuration,
    setBackFrequency,
    backFlexion,
    backRotation,
    backSideFlexion,
    backExtension,
    backNoSupport,
    backStrength,
    backDuration,
    backFrequency,
  } = useBriefAndBestContext();

  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <div style={{ padding: "15%", paddingTop: "0" }}>
          <GridContainer>
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setBackFlexion}
              check={backFlexion}
              functionPosture={setBackPostureCounter}
              counterPosture={backPostureCounter}
              name={"Costas"}
              title={"Costas"}
              picture={back1}
              subtitle={"flexão >= 20º"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setBackRotation}
              check={backRotation}
              functionPosture={setBackPostureCounter}
              counterPosture={backPostureCounter}
              name={"Costas"}
              title={"Costas"}
              picture={back2}
              subtitle={"rotação"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setBackSideFlexion}
              check={backSideFlexion}
              functionPosture={setBackPostureCounter}
              counterPosture={backPostureCounter}
              name={"Costas"}
              title={"Costas"}
              picture={back3}
              subtitle={"flexão lateral"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setBackNoSupport}
              check={backNoSupport}
              functionPosture={setBackPostureCounter}
              counterPosture={backPostureCounter}
              name={"Costas"}
              title={"Costas"}
              picture={back4}
              subtitle={"sem apoio"}
            />
            <BriefAndBestCard
              line={2}
              lineMid={12}
              functionCheck={setBackExtension}
              check={backExtension}
              functionPosture={setBackPostureCounter}
              counterPosture={backPostureCounter}
              name={"Costas"}
              title={"Costas"}
              picture={back5}
              subtitle={"extensão"}
            />
            <GridItem xs={12} sm={12} md={2} />
            <BriefAndBestStrengthCard
              line={2}
              lineMid={12}
              functionCheckStrength={setBackStrength}
              checkStrength={backStrength}
              functionCheckDuration={setBackDuration}
              checkDuration={backDuration}
              functionCheckFrequency={setBackFrequency}
              checkFrequency={backFrequency}
              functionStrength={setBackStrengthCounter}
              counterStrength={backStrengthCounter}
              functionDuration={setBackDurationCounter}
              counterDuration={backDurationCounter}
              functionFrequency={setBackFrequencyCounter}
              counterFrequency={backFrequencyCounter}
              frequency={">=2/min"}
              duration={">=10s"}
              name={"Coluna"}
              subtitle={"Força igual ou maior que 11,3kg"}
              title={"Coluna"}
              picture={backStrengthPick}
            />{" "}
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  );
}
