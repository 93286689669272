import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import RegularButton from "components/CustomButtons/Button";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: "100%",
  },
});

export default function ImageCard({ preview, removeFile }) {
  const classes = useStyles();
  return (
    <GridItem className={classes.root} key={preview.pos} xs={12} sm={4} md={3}>
      <img
        style={{ width: "100%", height: "200px" }}
        src={preview.blob}
        alt={preview.blob}
      />
      <div
        style={{
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <RegularButton
          onClick={(event) => removeFile(event, preview.pos)}
          id="delete-button"
          color="rose"
          type="submit"
          variant="contained"
        >
          Excluir Imagem
        </RegularButton>
      </div>
    </GridItem>
  );
}
