import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllWorkstationsByJobPositionId } from "api/workstationApi";
import WorkstationTreeComponent from "./WorkstationTreeComponent";
import StyledTreeItem from "./StyledTreeItem";

export default function JobPositionTreeComponent({
  jobPositionName,
  jobPositionId,
  evaluationList,
  setOpenBackDrop,
}) {
  const [jobPositionList, setJobPositionList] = useState([]);
  const [jobPositionColor, setJobPositionColor] = useState("#57c9d5");

  const useStyles = makeStyles({
    root: {
      color: jobPositionColor,
    },
  });

  useEffect(() => {
    async function fetchData() {
      if (jobPositionId !== "" && jobPositionId !== undefined)
        try {
          const data = await getAllWorkstationsByJobPositionId(jobPositionId);
          setJobPositionList(data);
        } catch (err) {}
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionId]);

  const classes = useStyles();

  return (
    <StyledTreeItem
      tooltipTitle="Cargo"
      selected="5"
      className={classes.root}
      nodeId={jobPositionId}
      label={jobPositionName}
    >
      {jobPositionList &&
        jobPositionList.map(({ name, id }) => {
          return (
            <TreeView
              key={id}
              defaultExpanded={[`${id}`]}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              multiSelect
            >
              <WorkstationTreeComponent
                setOpenBackDrop={setOpenBackDrop}
                evaluationList={evaluationList}
                workstationName={name}
                workstationId={id}
                jobPositionColor={jobPositionColor}
                setJobPositionColor={setJobPositionColor}
              />
            </TreeView>
          );
        })}
    </StyledTreeItem>
  );
}
