/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
// import Build from "@material-ui/icons/Build";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
import Assignment from "@material-ui/icons/Assignment";
// import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/DashboardSelection.js";
// import UserProfile from "views/UserProfile/UserProfile.js";
import EditorRegister from "views/EditorRegister/EditorRegister.js";
import Reports from "views/Reports/Reports";
import CompleteReports from "views/Reports/CompleteReports";
import StepEvaluation from "views/StepEvaluation/StepEvaluation.js";
// import FirstEvaluation from "views/Evaluations/FirstEvaluation.js";
// import NewEvaluation from "views/Evaluations/NewEvaluation.js";
// import BriefAndBest from "views/BriefAndBest/BriefAndBest.js";
import TreeView from "views/TreeView/TreeView.js";
// import User from "views/UserManagement/User";
// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// core components/views for RTL layout
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import Kanban from "views/Kanban/Kanban";
import ExportDataSelection from "views/ExportData/ExportDataSelection";
import { InsertDriveFile } from "@material-ui/icons";
import User from "views/UserManagement/User";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PGRTable from "views/PGRTable/ExportDataSelection";
import PGRDashboard from "views/PGRDashboard/ExportDataSelection";
import CensoCarga from "views/Censo/CensoCarga";
import CensoGrafico from "views/Censo/CensoGrafico";

const dashboardRoutes = [
  {
    path: "/tree",
    name: "Lista em árvore",
    icon: ListRoundedIcon,
    component: TreeView,
    layout: "/editor",
  },
  // {
  //   path: "/users/create",
  //   name: "Usuários",
  //   icon: AssignmentIndIcon,
  //   component: User,
  //   layout: "/editor",
  // },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/editor",
  },
  {
    path: "/register",
    name: "Cadastro",
    icon: "content_paste",
    component: EditorRegister,
    layout: "/editor",
  },

  {
    path: "/step",
    name: "Avaliação",
    icon: CheckBoxRoundedIcon,
    component: StepEvaluation,
    layout: "/editor",
  },
  {
    path: "/relatorio",
    name: "Impressão Passo",
    icon: PrintRoundedIcon,
    component: Reports,
    layout: "/editor",
  },
  {
    path: "/completo",
    name: "Impressão Relatório",
    icon: PrintRoundedIcon,
    component: CompleteReports,
    layout: "/editor",
  },
  {
    path: "/exportar-dados",
    name: "Exportar Dados",
    icon: InsertDriveFile,
    component: ExportDataSelection,
    layout: "/editor",
  },
  {
    path: "/kanban",
    name: "Kanban",
    icon: GridOnRoundedIcon,
    component: Kanban,
    layout: "/editor",
  },

  {
    path: "/users/create",
    name: "Usuários",
    icon: AssignmentIndIcon,
    component: User,
    layout: "/editor",
  },
  {
    path: "/pgr/table",
    name: "Tabela PGR",
    icon: GridOnRoundedIcon,
    component: PGRTable,
    layout: "/editor",
  },
  {
    path: "/pgr/dashboard",
    name: "Dashboard PGR",
    icon: Dashboard,
    component: PGRDashboard,
    layout: "/editor",
  },
  {
    path: "/censo-ergonomico/painel",
    name: "Censo Painel",
    icon: Assignment,
    component: CensoCarga,
    layout: "/editor",
  },
  {
    path: "/censo-ergonomico/dashboard",
    name: "Censo Dashboard",
    icon: Assignment,
    component: CensoGrafico,
    layout: "/editor",
  },

  // {
  //   path: "/briefandbest",
  //   name: "Brief & Best",
  //   icon: Build,
  //   component: BriefAndBest,
  //   layout: "/editor",
  // },
  // {
  //   path: "/kanban",
  //   name: "Kanban",
  //   icon: Build,
  //   component: Typography,
  //   layout: "/editor",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: Build,
  //   component: Typography,
  //   layout: "/editor",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/editor",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/editor",
  // },
];

export default dashboardRoutes;
