import React from "react";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      cursor: "pointer",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function CustomCardHeader({ handleChecked, title }) {
  const classes = useStyles();

  return (
    <div style={{ cursor: "pointer" }} onClick={handleChecked}>
      <CardHeader className={classes.cardCategory} color="info">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.cardTitleWhite}>{title}</h4>
          </GridItem>
        </GridContainer>
      </CardHeader>
    </div>
  );
}
