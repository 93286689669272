import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { createCauseImprovement } from "../../api/causeImprovementApi.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
}));

export default function CauseList({
  data,
  improvementId,
  setRefresh,
  setOpen,
  setMessage,
  setSeverity,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.title}>
            Todas as causas
          </Typography>
          <div className={classes.demo}>
            <List>
              {data &&
                data.map(({ id, description }) => {
                  const handleCreateUserCompany = async () => {
                    createCauseImprovement(id, improvementId);
                    setMessage("Causa associada a melhoria");
                    setSeverity("success");
                    setOpen(true);
                    setRefresh(true);
                  };

                  return (
                    <ListItem key={id}>
                      <ListItemText primary={description} />
                      <ListItemSecondaryAction>
                        <IconButton
                          color="primary"
                          edge="end"
                          aria-label="delete"
                          onClick={handleCreateUserCompany}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
