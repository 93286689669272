import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import CardBody from "components/Card/CardBody.js";
import { getAllUsers } from "../../api/userApi.js";
import { isUserQuestionnaire } from "api/questionnaireApi.js";
import { Button } from "@material-ui/core";
import { setUserQuestionnaire } from "api/questionnaireApi.js";

export default function UserQuestionnaire({
  setOpen,
  setMessage,
  setSeverity,
  setCheckedQuestionnaire,
}) {
  const [createUser, setCreateUser] = useState("");
  const [userList, setUserList] = useState("");
  const [isQuestionnaire, setIsQuestionnaire] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllUsers();
        return setUserList(data);
      } catch (err) {
        setUserList([]);
        setOpen(true);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (createUser !== "") {
        const questionaire = await isUserQuestionnaire(createUser);
        setIsQuestionnaire(questionaire.is_questionnaire);
      }
    }

    fetchData();
  }, [createUser]);

  const handleChangeQuestionnaire = async () => {
    if (createUser !== "") {
      await setUserQuestionnaire({
        user_id: createUser,
        is_questionnaire: !isQuestionnaire,
      });
    }
    setMessage("Permissão alterada com sucesso!");
    setSeverity("success");
    setOpen(true);
    setCheckedQuestionnaire(false);
  };

  return (
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <DropDownMenu
            itemSelected={createUser}
            itemSelector={setCreateUser}
            data={userList}
            filterName={"Usuário"}
          />
        </GridItem>
        {createUser !== "" && (
          <GridItem xs={12} sm={4} md={4}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleChangeQuestionnaire}
              >
                {isQuestionnaire ? "Remover" : "Adicionar"}
              </Button>
            </div>
          </GridItem>
        )}
      </GridContainer>
    </CardBody>
  );
}
