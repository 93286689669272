import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useHeaderContext } from "../../context/HeaderContext";
import { useHistory } from "react-router-dom";

const dateHelper = (dateToFormat) => {
  if (dateToFormat === "--") return "N/C";
  const date = new Date(dateToFormat);
  return `${
    date.getDate().toString().length === 2
      ? date.getDate().toString()
      : "0" + date.getDate().toString()
  }/${
    (date.getMonth() + 1).toString().length === 2
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString()
  }/${date.getFullYear()}`;
};

export default function StepTreeComponent({
  stepName,
  setActivityColor,
  activityColor,
  evaluationList,
  setOpenBackDrop,
  stepId,
  activity_id,
  company_id,
  ghe_id,
  job_position_id,
  sector_id,
  work_area_id,
  workstation_id,
}) {
  const [nprInitial, setNprInitial] = useState("");
  const [currentArray, setCurrentArray] = useState("");
  const [nprCurrent, setNprCurrent] = useState("");
  const [nprProjected, setNprProjected] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [colorToSet, setColorToSet] = useState("");

  const history = useHistory();

  const {
    setAreaId,
    setSectorId,
    setGheId,
    setCompanyId,
    setJobPositionId,
    setActivityId,
    setWorkstationId,
    setStepId,
    setTreeTag,
  } = useHeaderContext();

  const handleClick = async () => {
    setTreeTag(true);
    await setCompanyId(company_id);
    setTreeTag(true);
    await setAreaId(work_area_id);
    setTreeTag(true);
    await setSectorId(sector_id);
    setTreeTag(true);
    await setGheId(ghe_id);
    setTreeTag(true);
    await setJobPositionId(job_position_id);
    setTreeTag(true);
    await setWorkstationId(workstation_id);
    setTreeTag(true);
    await setActivityId(activity_id);
    setTreeTag(true);
    await setStepId(stepId);
    history.push("/editor/step");
  };

  const getColor = (npr) => {
    if (npr === "--") return "#57c9d5";
    var nprFloat = parseFloat(npr);
    if (nprFloat >= 0 && nprFloat < 10) return "#5cb860";
    if (nprFloat >= 10 && nprFloat < 30) return "#ffa21a";
    if (nprFloat >= 30 && nprFloat < 50) return "#ef5350";
    if (nprFloat >= 50) return "#af2cc5";
  };

  useEffect(() => {
    setOpenBackDrop(true);
    setTimeout(() => {
      if (nprCurrent !== "--") {
        setColorToSet(getColor(nprCurrent));
      } else {
        setColorToSet(getColor(nprInitial));
      }
      setOpenBackDrop(false);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nprCurrent, nprInitial, currentArray]);

  useEffect(() => {
    if (colorToSet) {
      if (activityColor === "#57c9d5" && colorToSet === "#5cb860")
        setActivityColor("#5cb860");
      if (activityColor === "#57c9d5" && colorToSet === "#ffa21a")
        setActivityColor("#ffa21a");
      if (activityColor === "#57c9d5" && colorToSet === "#af2cc5")
        setActivityColor("#af2cc5");
      if (activityColor === "#57c9d5" && colorToSet === "#ef5350")
        setActivityColor("#ef5350");
      if (activityColor === "#5cb860" && colorToSet === "#ffa21a")
        setActivityColor("#ffa21a");
      if (activityColor === "#5cb860" && colorToSet === "#ef5350")
        setActivityColor("#ef5350");
      if (activityColor === "#5cb860" && colorToSet === "#af2cc5")
        setActivityColor("#af2cc5");
      if (activityColor === "#ffa21a" && colorToSet === "#ef5350")
        setActivityColor("#ef5350");
      if (activityColor === "#ffa21a" && colorToSet === "#af2cc5")
        setActivityColor("#af2cc5");
      if (colorToSet === "#af2cc5") setActivityColor("#af2cc5");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorToSet]);

  useEffect(() => {
    const initial = evaluationList.filter(
      ({ type, step_id }) => step_id === stepId && type === "Inicial"
    );
    const projected = evaluationList.filter(
      ({ type, step_id }) => step_id === stepId && type === "Projetado"
    );
    const current = evaluationList.filter(
      ({ type, step_id }) => step_id === stepId && type === "Acompanhamento"
    );

    setCurrentArray(current);

    initial !== undefined && initial.length !== 0
      ? setNprInitial(initial[0].npr)
      : setNprInitial("--");
    initial !== undefined && initial.length !== 0
      ? setInitialDate(initial[0].created_at)
      : setInitialDate("--");
    current !== undefined && current.length !== 0
      ? setNprCurrent(current[0].npr)
      : setNprCurrent("--");
    projected !== undefined && projected.length !== 0
      ? setNprProjected(projected[0].npr)
      : setNprProjected("--");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={3} md={3}>
        <p
          onClick={handleClick}
          style={{
            fontWeight: "bold",
            textAlign: "center",
            cursor: "pointer",
            margin: 0,
            color: `${
              currentArray !== undefined && currentArray.length !== 0
                ? getColor(nprCurrent)
                : getColor(nprInitial)
            }`,
          }}
        >
          {stepName}
        </p>
      </GridItem>
      <GridItem xs={12} sm={2} md={2}>
        <p
          style={{
            color: "black",
            textAlign: "center",
            margin: 0,
          }}
        >
          Avaliação:
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            margin: 0,
            color: `${getColor(nprInitial)}`,
          }}
        >
          <strong>
            {nprInitial !== "--"
              ? parseFloat(nprInitial).toFixed(2)
              : nprInitial}
          </strong>
        </p>
      </GridItem>
      <GridItem xs={12} sm={2} md={2}>
        <p style={{ color: "black", textAlign: "center", margin: 0 }}>
          Concepção:
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            margin: 0,
            color: `${getColor(nprProjected)}`,
          }}
        >
          <strong>
            {nprProjected !== "--"
              ? parseFloat(nprProjected).toFixed(2)
              : nprProjected}
          </strong>
        </p>
      </GridItem>
      <GridItem xs={12} sm={2} md={2}>
        <p style={{ color: "black", textAlign: "center", margin: 0 }}>
          Avaliação pós melhoria:
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            margin: 0,
            color: `${getColor(nprCurrent)}`,
          }}
        >
          <strong>
            {nprCurrent !== "--"
              ? parseFloat(nprCurrent).toFixed(2)
              : nprCurrent}
          </strong>
        </p>
      </GridItem>
      <GridItem xs={12} sm={2} md={2}>
        <p style={{ color: "black", textAlign: "center", margin: 0 }}>
          Data da criação:
        </p>
        <p
          style={{
            color: "black",
            textAlign: "center",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          <strong>{dateHelper(initialDate)}</strong>
        </p>
      </GridItem>
      <GridItem xs={1} sm={1} md={1}></GridItem>
    </GridContainer>
  );
}
