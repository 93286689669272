import "./kanban.css";
import React, { useEffect, useState } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import {
  getAllImprovementsByCompanyId,
  editImprovementStatus,
} from "api/improvementApi";
import { getAllStepsByCompanyId } from "api/stepApi";
import { getAllAreasByCompanyId } from "api/areaApi";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuKanban";
import GridItem from "components/Grid/GridItem";
import OpenBackdrop from "components/BackDrop/BackDrop";
import { useLoginContext } from "context/LoginContext";

const board = {
  columns: [
    {
      id: 1,
      title: "Em andamento",
      cards: [],
    },
    {
      id: 2,
      title: "Atrasado",
      cards: [],
    },
    {
      id: 3,
      title: "Esperar decisão",
      cards: [],
    },
    {
      id: 4,
      title: "Requer avaliação",
      cards: [],
    },
    {
      id: 5,
      title: "Inviável",
      cards: [],
    },
    {
      id: 6,
      title: "Concluído",
      cards: [],
    },
  ],
};

const dateHelper = (dateToFormat) => {
  const date = new Date(dateToFormat);
  return `${
    date.getDate() < 9 ? `0${date.getDate() + 1}` : date.getDate() + 1
  }/${
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()}`;
};

export default function KanbanView({
  companyId,

  setOpenBackDrop,
  openBackDrop,
}) {
  const [controlledBoard, setBoard] = useState(board);

  const [improvementsList, setImprovementsList] = useState([]);
  const [finishedArray, setFinishedArray] = useState([]);
  const [currentArray, setCurrentArray] = useState([]);
  const [delayedArray, setDelayedArray] = useState([]);
  const [waitingDecisionArray, setWaitingDecisionArray] = useState([]);
  const [requiresEvaluationArray, setRequiresEvaluationArray] = useState([]);
  const [impracticableArray, setImpracticableArray] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [areaId, setAreaId] = useState("Todas");

  const { isReader } = useLoginContext();

  useEffect(() => {
    if (companyId !== undefined && companyId !== "") {
      const fetch = async () => {
        setOpenBackDrop(true);
        setAllAreas(await getAllAreasByCompanyId(companyId));
        setOpenBackDrop(false);
        setAreaId("Todas");
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);
  useEffect(() => {
    if (companyId !== undefined && companyId !== "") {
      const fetch = async () => {
        setOpenBackDrop(true);
        setAllSteps(await getAllStepsByCompanyId(companyId));
        setOpenBackDrop(false);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setBoard({
      columns: [
        {
          id: 1,
          title: "Em andamento",
          cards:
            areaId === "Todas"
              ? [...currentArray]
              : [
                  ...currentArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },
        {
          id: 2,
          title: "Atrasado",
          cards:
            areaId === "Todas"
              ? [...delayedArray]
              : [
                  ...delayedArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },
        {
          id: 3,
          title: "Concluído",
          cards:
            areaId === "Todas"
              ? [...finishedArray]
              : [
                  ...finishedArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },

        {
          id: 4,
          title: "Requer avaliação",
          cards:
            areaId === "Todas"
              ? [...requiresEvaluationArray]
              : [
                  ...requiresEvaluationArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },
        {
          id: 5,
          title: "Inviável",
          cards:
            areaId === "Todas"
              ? [...impracticableArray]
              : [
                  ...impracticableArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },
        {
          id: 6,
          title: "Esperar decisão",
          cards:
            areaId === "Todas"
              ? [...waitingDecisionArray]
              : [
                  ...waitingDecisionArray.filter(
                    ({ workArea }) => workArea === `${areaId}`
                  ),
                ],
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areaId,
    currentArray,
    delayedArray,
    waitingDecisionArray,
    requiresEvaluationArray,
    impracticableArray,
  ]);

  useEffect(() => {
    setOpenBackDrop(true);
    if (allSteps.length > 0 && allAreas.length > 0) {
      var current = improvementsList
        .filter(({ status }) => status === "Em andamento")
        .map(
          ({ priority, target_date, id, title, responsible_name, step_id }) => {
            return {
              workArea: allAreas.filter(
                ({ id }) =>
                  id ===
                  allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
              )[0]?.id,
              id,
              title,
              description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                target_date
              )}\n Prioridade: ${priority} \n Área: ${
                allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.name
              } `,
            };
          }
        );
      if (current !== undefined) setCurrentArray(current);
      var delayed = improvementsList
        .filter(({ status }) => status === "Atrasado")
        .map(
          ({ priority, target_date, id, title, responsible_name, step_id }) => {
            return {
              workArea: allAreas.filter(
                ({ id }) =>
                  id ===
                  allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
              )[0]?.id,
              id,
              title,
              description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                target_date
              )}\n Prioridade: ${priority} \n Área: ${
                allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.name
              } `,
            };
          }
        );
      if (delayed !== undefined) setDelayedArray(delayed);
      var waitingDecision = improvementsList
        .filter(({ status }) => status === "Esperar decisão")
        .map(
          ({ priority, target_date, id, title, responsible_name, step_id }) => {
            return {
              workArea: allAreas.filter(
                ({ id }) =>
                  id ===
                  allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
              )[0]?.id,
              id,
              title,
              description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                target_date
              )}\n Prioridade: ${priority} \n Área: ${
                allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.name
              } `,
            };
          }
        );
      if (waitingDecision !== undefined)
        setWaitingDecisionArray(waitingDecision);
      var requiresEvaluation = improvementsList
        .filter(({ status }) => status === "Requer avaliação mais profunda")
        .map(
          ({ priority, target_date, id, title, responsible_name, step_id }) => {
            return {
              workArea: allAreas.filter(
                ({ id }) =>
                  id ===
                  allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
              )[0]?.id,
              id,
              title,
              description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                target_date
              )}\n Prioridade: ${priority} \n Área: ${
                allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.name
              } `,
            };
          }
        );

      if (requiresEvaluation !== undefined)
        setRequiresEvaluationArray(requiresEvaluation);
      var impracticable = improvementsList
        .filter(({ status }) => status === "Inviável")
        .map(
          ({ priority, target_date, id, title, responsible_name, step_id }) => {
            return {
              workArea: allAreas.filter(
                ({ id }) =>
                  id ===
                  allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
              )[0]?.id,
              id,
              title,
              description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                target_date
              )}\n Prioridade: ${priority} \n Área: ${
                allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.name
              } `,
            };
          }
        );
      if (impracticable !== undefined) setImpracticableArray(impracticable);
      if (allSteps.length > 0 && allAreas.length > 0) {
        var finished = improvementsList
          .filter(({ status }) => status === "Concluído")
          .map(
            ({
              priority,
              target_date,
              id,
              title,
              responsible_name,
              step_id,
            }) => {
              return {
                workArea: allAreas.filter(
                  ({ id }) =>
                    id ===
                    allSteps.filter(({ id }) => id === step_id)[0]?.work_area_id
                )[0]?.id,
                id,
                title,
                description: `Responsável: ${responsible_name} \n Data objetivo: ${dateHelper(
                  target_date
                )}\n Prioridade: ${priority} \n Área: ${
                  allAreas.filter(
                    ({ id }) =>
                      id ===
                      allSteps.filter(({ id }) => id === step_id)[0]
                        ?.work_area_id
                  )[0]?.name
                } `,
              };
            }
          );
      }
    }
    if (finished !== undefined) setFinishedArray(finished);
    setOpenBackDrop(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [improvementsList, allSteps, allAreas]);

  useEffect(() => {
    setOpenBackDrop(true);
    const fetch = async () => {
      if (companyId !== undefined && companyId !== "")
        try {
          const data = await getAllImprovementsByCompanyId(companyId);
          return setImprovementsList(data);
        } catch (err) {
          console.warn(err);
        }
    };
    fetch();
    setOpenBackDrop(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, areaId]);

  async function handleCardMove(_card, source, destination) {
    if (isReader) return;
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
    if (destination.toColumnId === 1)
      await editImprovementStatus(_card.id, "Em andamento");
    if (destination.toColumnId === 2)
      await editImprovementStatus(_card.id, "Atrasado");
    if (destination.toColumnId === 3)
      await editImprovementStatus(_card.id, "Concluído");
    if (destination.toColumnId === 4)
      await editImprovementStatus(_card.id, "Requer avaliação mais profunda");
    if (destination.toColumnId === 5)
      await editImprovementStatus(_card.id, "Inviável");
    if (destination.toColumnId === 6)
      await editImprovementStatus(_card.id, "Esperar decisão");
  }

  return (
    <>
      <OpenBackdrop open={openBackDrop} />
      <GridItem sx={{ marginBottom: " + 50px" }} xs={12} sm={4} md={3}>
        <DropDownMenuEditor
          itemSelected={areaId}
          itemSelector={setAreaId}
          data={allAreas}
          filterName={"Área"}
        />
      </GridItem>
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>
    </>
  );
}
