import { Button } from "@material-ui/core";
import { getReport } from "api/report";
import Card from "components/Card/Card";
import React from "react";
export default function PrintButtonComponent({
  setOpenBackDrop,
  areaId,
  sectorId,
  gheId,
  companyId,
  jobPositionId,
  activityId,
  workstationId,
  isCover,
  isSummary,
  disabled,
  setMessage,
  setOpen,
  setSeverity,
  isBB,
}) {
  const handlePrint = async () => {
    setOpenBackDrop(true);
    setSeverity("info");
    setMessage(
      "Dependendo do tamanho do arquivo, o relatório poderá demorar vários minutos para ser gerado."
    );
    setOpen(true);
    try {
      await getReport({
        areaId,
        sectorId,
        gheId,
        companyId,
        jobPositionId,
        activityId,
        workstationId,
        isCover,
        isSummary,
        isBB,
      });
    } catch (e) {
      setSeverity("error");
      setMessage(
        "O relatório não pode ser gerado inteiro, tente gerá-lo por partes."
      );
      setOpen(true);
    }
    setOpenBackDrop(false);
    setSeverity("success");
    setMessage("Relatório gerado com sucesso.");
    setOpen(true);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          disabled={!disabled}
          color="primary"
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4"
          onClick={handlePrint}
        >
          {" "}
          Imprimir Relatório{" "}
        </Button>
      </div>
      <Card>
        <div style={{ marginTop: "20px", textAlign: "left", padding: "20px" }}>
          <p style={{ fontSize: "16px", marginBottom: "5px;" }}>
            <strong>Atenção: </strong>Para imprimir este relatório, siga as
            instruções abaixo:
          </p>
          <ol>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Clique no botão "Imprimir Relatório".
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Uma nova aba se abrirá com a visualização do relatório.
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Verifique se o bloqueador de pop-ups do seu navegador não está
              impedindo a abertura da nova aba.
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Na nova aba, selecione a opção "Imagens em segundo plano" ou
              "Gráficos em segundo plano" e desmarque a opção "Cabeçalhos e
              rodapés".
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Selecione a opção "Guardar como PDF" ou "Mircosoft Print to PDF".
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Certifique-se de esperar a visualização completa do relatório
              (Esse processo pode demorar alguns minutos, dependendo do tamanho
              do relatório.)
            </li>
            <li style={{ fontSize: "16px", marginBottom: "5px;" }}>
              Clique em "Imprimir" ou "Guardar" e escolha o local e nome para
              salvar o seu PDF.
            </li>
          </ol>
          <p style={{ fontSize: "16px", marginBottom: "5px;" }}>
            <strong>Nota: </strong> Se o relatório não abrir, verifique as
            configurações do seu navegador e permita pop-ups para este site.
          </p>
        </div>
      </Card>
    </div>
  );
}
