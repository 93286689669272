import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import { createGhe, getAllGheBySectorId, editGhe } from "../../api/gheApi.js";
import GheComponentList from "./GheComponentList.js";
import { useLoginContext } from "context/LoginContext.js";

export default function GheRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  sectorId,
  setRefreshGheId,
}) {
  const [gheArray, setGheArray] = useState([]);
  const [gheId, setGheId] = useState("");
  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllGheBySectorId(sectorId);
      setGheArray(data);
      setRefresh(false);
    };
    fetch();
  }, [sectorId, refresh]);

  const handleSubmit = async () => {
    if (name === "") {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await createGhe(name, sectorId);
        setRefreshGheId(true);
        setMessage("Ghe criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (name === "") {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await editGhe(name, gheId);
        setMessage("Ghe editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshGheId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    refresh ? setRefreshGheId(true) : setRefreshGheId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <CardBody>
      <GheComponentList
        ghes={gheArray}
        setName={setName}
        setRefresh={setRefresh}
        setEdit={setEdit}
        setGheId={setGheId}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome do Ghe"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              color={edit ? "warning" : "success"}
              onClick={edit ? handleEdit : handleSubmit}
              variant="contained"
            >
              {edit ? "Editar" : "Criar Ghe"}
            </RegularButton>
          </div>
        </GridItem>
      </GridContainer>
    </CardBody>
  );
}
