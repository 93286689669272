import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { deletePermissionByUserCompanyId } from "api/companyApi.js";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
}));

export default function UserCompanyList({
  data,
  user,
  setRefresh,
  setOpen,
  setMessage,
  setSeverity,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.title}>
            Empresas do Usuário
          </Typography>
          <div className={classes.demo}>
            <List>
              {data &&
                data.map(({ id, name }) => {
                  const handleDelete = async () => {
                    await deletePermissionByUserCompanyId(user, id);
                    setMessage("Empresa desassociada ao usuário");
                    setSeverity("success");
                    setOpen(true);
                    setRefresh(true);
                  };

                  return (
                    <ListItem key={id + "user"}>
                      <ListItemText primary={name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          color="secondary"
                          edge="end"
                          aria-label="delete"
                          onClick={handleDelete}
                        >
                          <HighlightOff />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
