import React, { useState } from "react";
// @material-ui/core components
// core components
import Card from "components/Card/Card.js";

import AlertCustom from "components/Alerts/AlertCustom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";

import CardBody from "components/Card/CardBody.js";
import TabsView from "components/StepEvaluationView/TabsView.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";
import { useHeaderContext } from "context/HeaderContext.js";

export default function StepEvaluation() {
  const [severity] = useState("error");
  const [message] = useState("");

  const {
    areaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    gheId,
    setGheId,
    companyId,
    setCompanyId,
    jobPositionId,
    setJobPositionId,
    jobPositionList,
    companyList,
    sectorList,
    gheList,
    activityId,
    setActivityId,
    activityList,
    workstationId,
    setWorkstationId,
    workstationList,
    stepId,
    setStepId,
    stepList,
    openBackDrop,
    setOpenBackDrop,
    open,
    setOpen,
  } = useHeaderContext();

  return (
    <>
      <Card>
        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <AlertCustom
            setOpen={setOpen}
            open={open}
            message={message}
            severity={severity}
          />

          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Empresa"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={areaId}
                itemSelector={setAreaId}
                data={areaList}
                filterName={"Área"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={sectorId}
                itemSelector={setSectorId}
                data={sectorList}
                filterName={"Setor"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={gheId}
                itemSelector={setGheId}
                data={gheList}
                filterName={"Ghe"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={jobPositionId}
                itemSelector={setJobPositionId}
                data={jobPositionList}
                filterName={"Cargo"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={workstationId}
                itemSelector={setWorkstationId}
                data={workstationList}
                filterName={"Posto de trabalho"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={activityId}
                itemSelector={setActivityId}
                data={activityList}
                filterName={"Atividade"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={stepId}
                itemSelector={setStepId}
                data={stepList}
                filterName={"Passo"}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {stepId !== "" && (
        <TabsView
          setOpenBackDrop={setOpenBackDrop}
          activityId={activityId}
          areaId={areaId}
          sectorId={sectorId}
          gheId={gheId}
          companyId={companyId}
          jobPositionId={jobPositionId}
          workstationId={workstationId}
          stepId={stepId}
        />
      )}
    </>
  );
}
