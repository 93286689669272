import React from "react";
import { Modal, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Matriz from "../../assets/img/Matriz.jpg";

export default function PGRModal({ open, setOpen }) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "grid",
          }}
        >
          <img
            src={Matriz}
            alt="Matriz 5x5"
            style={{ width: "50vw", height: "auto" }}
          />

          <IconButton
            style={{ position: "absolute", top: "0", left: "0", zIndex: 2 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}
