import React, { useState } from "react";
// @material-ui/core components
// core components
import Card from "components/Card/Card.js";

import AlertCustom from "components/Alerts/AlertCustom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";

import CardBody from "components/Card/CardBody.js";
import PrintButtonComponent from "components/CompleteReportPrintScreen/PrintButtonComponent.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";
import { useHeaderContext } from "context/HeaderContext.js";
import WorkstationCheckbox from "components/EditorView/WorkstationCheckBox";

export default function CompleteReports() {
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [isCover, setIsCover] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [isBB, setIsBB] = useState(false);

  const {
    areaList,
    areaId,
    setAreaId,
    sectorId,
    setSectorId,
    gheId,
    setGheId,
    companyId,
    setCompanyId,
    jobPositionId,
    setJobPositionId,
    jobPositionList,
    companyList,
    sectorList,
    gheList,
    activityId,
    workstationId,
    setWorkstationId,
    workstationList,
    openBackDrop,
    setOpenBackDrop,
    open,
    setOpen,
  } = useHeaderContext();

  return (
    <>
      <Card>
        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <AlertCustom
            setOpen={setOpen}
            open={open}
            message={message}
            severity={severity}
          />

          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companyList}
                filterName={"Empresa"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={areaId}
                itemSelector={setAreaId}
                data={areaList}
                filterName={"Área"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={sectorId}
                itemSelector={setSectorId}
                data={sectorList}
                filterName={"Setor"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={gheId}
                itemSelector={setGheId}
                data={gheList}
                filterName={"Ghe"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={jobPositionId}
                itemSelector={setJobPositionId}
                data={jobPositionList}
                filterName={"Cargo"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <DropDownMenuEditor
                itemSelected={workstationId}
                itemSelector={setWorkstationId}
                data={workstationList}
                filterName={"Posto de trabalho"}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "25px",
                }}
              >
                <WorkstationCheckbox
                  title={"Adicionar Capa"}
                  setCheck={setIsCover}
                  checked={isCover}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "25px",
                }}
              >
                <WorkstationCheckbox
                  title={"Adicionar Sumário e Introdução"}
                  setCheck={setIsSummary}
                  checked={isSummary}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "25px",
                }}
              >
                <WorkstationCheckbox
                  title={"Adicionar ferramenta Brief & Best"}
                  setCheck={setIsBB}
                  checked={isBB}
                />
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <PrintButtonComponent
        setOpenBackDrop={setOpenBackDrop}
        areaId={areaId}
        sectorId={sectorId}
        gheId={gheId}
        companyId={companyId}
        jobPositionId={jobPositionId}
        activityId={activityId}
        workstationId={workstationId}
        isCover={isCover}
        disabled={!!companyId}
        isSummary={isSummary}
        setOpen={setOpen}
        isBB={isBB}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
    </>
  );
}
