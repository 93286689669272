import React, { useEffect, useState } from "react";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";

const useStyles2 = makeStyles({
  grid: {
    flex: "1 !important",
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBestStrengthCard({
  name,
  subtitle,
  picture,
  title,
  duration,
  frequency,
  functionStrength,
  counterStrength,
  functionDuration,
  counterDuration,
  functionFrequency,
  counterFrequency,
  functionCheckStrength,
  checkStrength,
  functionCheckDuration,
  checkDuration,
  functionCheckFrequency,
  checkFrequency,
  line = 3,
}) {
  const [checkedStrength, setCheckedStrength] = useState(false);
  const [checkedDuration, setCheckedDuration] = useState(false);
  const [checkedFrequency, setCheckedFrequency] = useState(false);

  useEffect(() => {
    if (checkStrength) {
      setCheckedStrength(checkStrength);
      functionStrength(counterStrength + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkStrength]);

  useEffect(() => {
    if (checkDuration) {
      setCheckedDuration(checkDuration);
      functionDuration(counterDuration + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDuration]);

  useEffect(() => {
    if (checkFrequency) {
      setCheckedFrequency(checkFrequency);
      functionFrequency(counterFrequency + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFrequency]);

  const handleChangeStrength = () => {
    if (!checkedStrength) {
      setCheckedStrength(true);
      functionCheckStrength(true);
    } else {
      setCheckedStrength(false);
      functionCheckStrength(false);
      functionStrength(counterStrength - 1);
    }
  };
  const handleChangeDuration = () => {
    if (!checkedDuration) {
      setCheckedDuration(true);
      functionCheckDuration(true);
    } else {
      setCheckedDuration(false);
      functionCheckDuration(false);
      functionDuration(counterDuration - 1);
    }
  };

  const handleChangeFrequency = () => {
    if (!checkedFrequency) {
      setCheckedFrequency(true);
      functionCheckFrequency(true);
    } else {
      setCheckedFrequency(false);
      functionCheckFrequency(false);
      functionFrequency(counterFrequency - 1);
    }
  };

  const classes2 = useStyles2();
  return (
    <GridItem className={classes2.container} xs={12} sm={line} md={line}>
      <Card
        style={{
          backgroundColor: checkedStrength ? "rgba(0, 211, 238, 0.1)" : "white",
          boxShadow: checkedStrength
            ? "0px 0px 10px #00acc1"
            : "0px 0px 10px rgba(0, 0, 0, 0.3)",
        }}
        className={classes2.grid}
        onClick={handleChangeStrength}
      >
        <CardHeader
          style={{
            marginBottom: "-30px",
            marginTop: "-20px",
          }}
        >
          <h6
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: "9px",
              fontWeight: "bold",
            }}
          >
            {name}
          </h6>
          <h6
            style={{
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              fontSize: "9px",
              fontWeight: "bold",
              minHeight: "40px",
              marginTop: "-10px",
            }}
          >
            {subtitle}
          </h6>
        </CardHeader>
        <CardBody
          style={{
            padding: "10%",
            maxHeight: "60px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "60px",
                display: "block",
              }}
              src={picture}
              alt={title}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "60px",
                backgroundColor: checkedStrength
                  ? "rgba(0, 211, 238, 0.1)"
                  : "transparent",
              }}
            />
          </div>
        </CardBody>
        <h6
          style={{
            marginTop: "10px",
            justifyContent: "center",
            display: "flex",
            fontSize: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          Força
        </h6>
      </Card>
      <Card
        style={{
          backgroundColor: checkedDuration ? "rgba(0, 211, 238, 0.1)" : "white",
          boxShadow: checkedDuration
            ? "0px 0px 10px #00acc1"
            : "0px 0px 10px rgba(0, 0, 0, 0.3)",
        }}
        className={classes2.grid}
        onClick={handleChangeDuration}
      >
        <h6
          style={{
            marginTop: "10px",
            justifyContent: "center",
            display: "flex",
            fontSize: "10px",
            fontWeight: "bold",
            marginBottom: "-10px",
          }}
        >
          Duração
        </h6>
        <h5
          style={{
            fontWeight: "bold",
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          {duration}
        </h5>
      </Card>
      <Card
        style={{
          backgroundColor: checkedFrequency
            ? "rgba(0, 211, 238, 0.1)"
            : "white",
          boxShadow: checkedFrequency
            ? "0px 0px 10px #00acc1"
            : "0px 0px 10px rgba(0, 0, 0, 0.3)",
        }}
        className={classes2.grid}
        onClick={handleChangeFrequency}
      >
        <h5
          style={{
            marginTop: "10px",
            justifyContent: "center",
            display: "flex",
            fontSize: "10px",
            fontWeight: "bold",
            marginBottom: "-10px",
          }}
        >
          FREQUÊNCIA
        </h5>
        <h5
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          {frequency}
        </h5>
      </Card>
    </GridItem>
  );
}
