import React, { createContext, useState, useContext, useEffect } from "react";
const ParamsContext = createContext();

export default function BriefAndBestProvider({ children }) {
  const [rightHandPostureCounter, setRightHandPostureCounter] = useState(0);
  const [leftHandPostureCounter, setLeftHandPostureCounter] = useState(0);
  const [rightElbowPostureCounter, setRightElbowPostureCounter] = useState(0);
  const [leftElbowPostureCounter, setLeftElbowPostureCounter] = useState(0);
  const [
    rightShoulderPostureCounter,
    setRightShoulderPostureCounter,
  ] = useState(0);
  const [leftShoulderPostureCounter, setLeftShoulderPostureCounter] = useState(
    0
  );
  const [neckPostureCounter, setNeckPostureCounter] = useState(0);
  const [backPostureCounter, setBackPostureCounter] = useState(0);
  const [legsPostureCounter, setLegsPostureCounter] = useState(0);

  const [rightHandStrengthCounter, setRightHandStrengthCounter] = useState(0);
  const [leftHandStrengthCounter, setLeftHandStrengthCounter] = useState(0);
  const [rightElbowStrengthCounter, setRightElbowStrengthCounter] = useState(0);
  const [leftElbowStrengthCounter, setLeftElbowStrengthCounter] = useState(0);
  const [bothElbowStrengthCounter, setBothElbowStrengthCounter] = useState(0);
  const [
    bothShouldersStrengthCounter,
    setBothShouldersStrengthCounter,
  ] = useState(0);
  const [
    rightShoulderStrengthCounter,
    setRightShoulderStrengthCounter,
  ] = useState(0);
  const [
    leftShoulderStrengthCounter,
    setLeftShoulderStrengthCounter,
  ] = useState(0);
  const [neckStrengthCounter, setNeckStrengthCounter] = useState(0);
  const [backStrengthCounter, setBackStrengthCounter] = useState(0);
  const [legsStrengthCounter, setLegsStrengthCounter] = useState(0);

  const [rightHandDurationCounter, setRightHandDurationCounter] = useState(0);
  const [leftHandDurationCounter, setLeftHandDurationCounter] = useState(0);
  const [rightElbowDurationCounter, setRightElbowDurationCounter] = useState(0);
  const [leftElbowDurationCounter, setLeftElbowDurationCounter] = useState(0);
  const [bothElbowDurationCounter, setBothElbowDurationCounter] = useState(0);
  const [
    bothShouldersDurationCounter,
    setBothShouldersDurationCounter,
  ] = useState(0);
  const [
    rightShoulderDurationCounter,
    setRightShoulderDurationCounter,
  ] = useState(0);
  const [
    leftShoulderDurationCounter,
    setLeftShoulderDurationCounter,
  ] = useState(0);
  const [neckDurationCounter, setNeckDurationCounter] = useState(0);
  const [backDurationCounter, setBackDurationCounter] = useState(0);
  const [legsDurationCounter, setLegsDurationCounter] = useState(0);

  const [rightHandFrequencyCounter, setRightHandFrequencyCounter] = useState(0);
  const [leftHandFrequencyCounter, setLeftHandFrequencyCounter] = useState(0);
  const [rightElbowFrequencyCounter, setRightElbowFrequencyCounter] = useState(
    0
  );
  const [leftElbowFrequencyCounter, setLeftElbowFrequencyCounter] = useState(0);
  const [bothElbowFrequencyCounter, setBothElbowFrequencyCounter] = useState(0);
  const [
    bothShouldersFrequencyCounter,
    setBothShouldersFrequencyCounter,
  ] = useState(0);
  const [
    rightShoulderFrequencyCounter,
    setRightShoulderFrequencyCounter,
  ] = useState(0);
  const [
    leftShoulderFrequencyCounter,
    setLeftShoulderFrequencyCounter,
  ] = useState(0);
  const [neckFrequencyCounter, setNeckFrequencyCounter] = useState(0);
  const [backFrequencyCounter, setBackFrequencyCounter] = useState(0);
  const [legsFrequencyCounter, setLegsFrequencyCounter] = useState(0);
  const [timeActivity, setTimeActivity] = useState(0.4);

  const [vibrationCounter, setVibrationCounter] = useState(0);
  const [lowTemperatureCounter, setLowTemperatureCounter] = useState(0);
  const [
    softTissueCompressionCounter,
    setSoftTissueCompressionCounter,
  ] = useState(0);
  const [impactStressCounter, setImpactStressCounter] = useState(0);
  const [gloveIssuesCounter, setGloveIssuesCounter] = useState(0);

  const [rightHandResults, setRightHandResults] = useState(0);
  const [leftHandResults, setLeftHandResults] = useState(0);
  const [rightElbowResults, setRightElbowResults] = useState(0);
  const [leftElbowResults, setLeftElbowResults] = useState(0);
  const [rightShoulderResults, setRightShoulderResults] = useState(0);
  const [leftShoulderResults, setLeftShoulderResults] = useState(0);
  const [neckResults, setNeckResults] = useState(0);
  const [backResults, setBackResults] = useState(0);
  const [legsResults, setLegsResults] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [stepPosition, setStepPosition] = useState(1);
  const [stepFrequency, setStepFrequency] = useState("");

  const [leftHandExtension, setLeftHandExtension] = useState(false);
  const [leftHandFlexion, setLeftHandFlexion] = useState(false);
  const [leftHandUlnar, setLeftHandUlnar] = useState(false);
  const [leftHandRadial, setLeftHandRadial] = useState(false);
  const [rightHandExtension, setRightHandExtension] = useState(false);
  const [rightHandFlexion, setRightHandFlexion] = useState(false);
  const [rightHandUlnar, setRightHandUlnar] = useState(false);
  const [rightHandRadial, setRightHandRadial] = useState(false);
  const [leftHandGripStrength, setLeftHandGripStrength] = useState(false);
  const [leftHandGripDuration, setLeftHandGripDuration] = useState(false);
  const [leftHandGripFrequency, setLeftHandGripFrequency] = useState(false);
  const [leftHandHoldStrength, setLeftHandHoldStrength] = useState(false);
  const [leftHandHoldDuration, setLeftHandHoldDuration] = useState(false);
  const [leftHandHoldFrequency, setLeftHandHoldFrequency] = useState(false);
  const [leftHandClampStrength, setLeftHandClampStrength] = useState(false);
  const [leftHandClampDuration, setLeftHandClampDuration] = useState(false);
  const [leftHandClampFrequency, setLeftHandClampFrequency] = useState(false);
  const [rightHandGripStrength, setRightHandGripStrength] = useState(false);
  const [rightHandGripDuration, setRightHandGripDuration] = useState(false);
  const [rightHandGripFrequency, setRightHandGripFrequency] = useState(false);
  const [rightHandHoldStrength, setRightHandHoldStrength] = useState(false);
  const [rightHandHoldDuration, setRightHandHoldDuration] = useState(false);
  const [rightHandHoldFrequency, setRightHandHoldFrequency] = useState(false);
  const [rightHandClampStrength, setRightHandClampStrength] = useState(false);
  const [rightHandClampDuration, setRightHandClampDuration] = useState(false);
  const [rightHandClampFrequency, setRightHandClampFrequency] = useState(false);
  const [leftElbowRotation, setLeftElbowRotation] = useState(false);
  const [rightElbowRotation, setRightElbowRotation] = useState(false);
  const [leftElbowExtension, setLeftElbowExtension] = useState(false);
  const [rightElbowExtension, setRightElbowExtension] = useState(false);
  const [leftElbowStrength, setLeftElbowStrength] = useState(false);
  const [leftElbowDuration, setLeftElbowDuration] = useState(false);
  const [leftElbowFrequency, setLeftElbowFrequency] = useState(false);
  const [rightElbowStrength, setRightElbowStrength] = useState(false);
  const [rightElbowDuration, setRightElbowDuration] = useState(false);
  const [rightElbowFrequency, setRightElbowFrequency] = useState(false);
  const [bothElbowStrength, setBothElbowStrength] = useState(false);
  const [bothElbowDuration, setBothElbowDuration] = useState(false);
  const [bothElbowFrequency, setBothElbowFrequency] = useState(false);
  const [leftShoulderFlexion, setLeftShoulderFlexion] = useState(false);
  const [rightShoulderFlexion, setRightShoulderFlexion] = useState(false);
  const [leftShoulderAbduction, setLeftShoulderAbduction] = useState(false);
  const [rightShoulderAbduction, setRightShoulderAbduction] = useState(false);
  const [leftShoulderElevation, setLeftShoulderElevation] = useState(false);
  const [rightShoulderElevation, setRightShoulderElevation] = useState(false);
  const [leftShoulderStrength, setLeftShoulderStrength] = useState(false);
  const [leftShoulderDuration, setLeftShoulderDuration] = useState(false);
  const [leftShoulderFrequency, setLeftShoulderFrequency] = useState(false);
  const [rightShoulderStrength, setRightShoulderStrength] = useState(false);
  const [rightShoulderDuration, setRightShoulderDuration] = useState(false);
  const [rightShoulderFrequency, setRightShoulderFrequency] = useState(false);
  const [bothShoulderStrength, setBothShoulderStrength] = useState(false);
  const [bothShoulderDuration, setBothShoulderDuration] = useState(false);
  const [bothShoulderFrequency, setBothShoulderFrequency] = useState(false);
  const [neckFlexion, setNeckFlexion] = useState(false);
  const [neckSideFlexion, setNeckSideFlexion] = useState(false);
  const [neckExtension, setNeckExtension] = useState(false);
  const [neckRotation, setNeckRotation] = useState(false);
  const [neckStrength, setNeckStrength] = useState(false);
  const [neckDuration, setNeckDuration] = useState(false);
  const [neckFrequency, setNeckFrequency] = useState(false);
  const [backFlexion, setBackFlexion] = useState(false);
  const [backRotation, setBackRotation] = useState(false);
  const [backSideFlexion, setBackSideFlexion] = useState(false);
  const [backExtension, setBackExtension] = useState(false);
  const [backNoSupport, setBackNoSupport] = useState(false);
  const [backStrength, setBackStrength] = useState(false);
  const [backDuration, setBackDuration] = useState(false);
  const [backFrequency, setBackFrequency] = useState(false);
  const [legsCrouching, setLegsCrouching] = useState(false);
  const [legsKneeling, setLegsKneeling] = useState(false);
  const [legsNoSupport, setLegsNoSupport] = useState(false);
  const [legsStrength, setLegsStrength] = useState(false);
  const [legsDuration, setLegsDuration] = useState(false);
  const [legsFrequency, setLegsFrequency] = useState(false);
  const [vibration, setVibration] = useState(false);
  const [lowTemperature, setLowTemperature] = useState(false);
  const [softTissueCompression, setSoftTissueCompression] = useState(false);
  const [impactStress, setImpactStress] = useState(false);
  const [gloveIssue, setGloveIssue] = useState(false);
  const [lift, setLift] = useState(0);
  const [push, setPush] = useState(0);
  const [pull, setPull] = useState(0);
  const [load, setLoad] = useState(0);

  useEffect(() => {}, [stepPosition, stepFrequency]);

  useEffect(() => {
    const a = leftHandUlnar ? 1 : 0;
    const b = leftHandRadial ? 1 : 0;
    const c = leftHandExtension ? 1 : 0;
    const d = leftHandFlexion ? 1 : 0;
    setLeftHandPostureCounter(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftHandExtension, leftHandFlexion, leftHandUlnar, leftHandRadial]);

  useEffect(() => {
    const a = rightHandUlnar ? 1 : 0;
    const b = rightHandRadial ? 1 : 0;
    const c = rightHandExtension ? 1 : 0;
    const d = rightHandFlexion ? 1 : 0;
    setRightHandPostureCounter(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightHandExtension, rightHandFlexion, rightHandUlnar, rightHandRadial]);

  useEffect(() => {
    const a = leftShoulderFlexion ? 1 : 0;
    const b = leftShoulderAbduction ? 1 : 0;
    const c = leftShoulderElevation ? 1 : 0;
    setLeftShoulderPostureCounter(a + b + c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftShoulderFlexion, leftShoulderAbduction, leftShoulderElevation]);

  useEffect(() => {
    const a = rightShoulderFlexion ? 1 : 0;
    const b = rightShoulderAbduction ? 1 : 0;
    const c = rightShoulderElevation ? 1 : 0;
    setRightShoulderPostureCounter(a + b + c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightShoulderFlexion, rightShoulderAbduction, rightShoulderElevation]);

  useEffect(() => {
    const a = neckFlexion ? 1 : 0;
    const b = neckSideFlexion ? 1 : 0;
    const c = neckExtension ? 1 : 0;
    const d = neckRotation ? 1 : 0;
    setNeckPostureCounter(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neckFlexion, neckSideFlexion, neckExtension, neckRotation]);

  useEffect(() => {
    const a = backFlexion ? 1 : 0;
    const b = backRotation ? 1 : 0;
    const c = backSideFlexion ? 1 : 0;
    const d = backExtension ? 1 : 0;
    const e = backNoSupport ? 1 : 0;
    setBackPostureCounter(a + b + c + d + e);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    backFlexion,
    backRotation,
    backSideFlexion,
    backExtension,
    backNoSupport,
  ]);

  useEffect(() => {
    const a = leftElbowRotation ? 1 : 0;
    const b = leftElbowExtension ? 1 : 0;
    setLeftElbowPostureCounter(a + b);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftElbowRotation, leftElbowExtension]);

  useEffect(() => {
    const a = rightElbowRotation ? 1 : 0;
    const b = rightElbowExtension ? 1 : 0;
    setRightElbowPostureCounter(a + b);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightElbowRotation, rightElbowExtension]);

  useEffect(() => {
    const a = legsCrouching ? 1 : 0;
    const b = legsKneeling ? 1 : 0;
    const c = legsNoSupport ? 1 : 0;
    setLegsPostureCounter(a + b + c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legsCrouching, legsKneeling, legsNoSupport]);

  useEffect(() => {
    const a = rightHandPostureCounter === 0 ? 0 : 1;
    const b = rightHandStrengthCounter === 0 ? 0 : 1;
    const c = rightHandDurationCounter === 0 ? 0 : 1;
    const d = rightHandFrequencyCounter === 0 ? 0 : 1;
    setRightHandResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rightHandPostureCounter,
    rightHandStrengthCounter,
    rightHandDurationCounter,
    rightHandFrequencyCounter,
  ]);
  useEffect(() => {
    const a = leftHandPostureCounter === 0 ? 0 : 1;
    const b = leftHandStrengthCounter === 0 ? 0 : 1;
    const c = leftHandDurationCounter === 0 ? 0 : 1;
    const d = leftHandFrequencyCounter === 0 ? 0 : 1;
    setLeftHandResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftHandPostureCounter,
    leftHandStrengthCounter,
    leftHandDurationCounter,
    leftHandFrequencyCounter,
  ]);
  useEffect(() => {
    const a = neckPostureCounter === 0 ? 0 : 1;
    const b = neckStrengthCounter === 0 ? 0 : 1;
    const c = neckDurationCounter === 0 ? 0 : 1;
    const d = neckFrequencyCounter === 0 ? 0 : 1;
    setNeckResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    neckPostureCounter,
    neckStrengthCounter,
    neckDurationCounter,
    neckFrequencyCounter,
  ]);
  useEffect(() => {
    const a = backPostureCounter === 0 ? 0 : 1;
    const b = backStrengthCounter === 0 ? 0 : 1;
    const c = backDurationCounter === 0 ? 0 : 1;
    const d = backFrequencyCounter === 0 ? 0 : 1;
    setBackResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    backPostureCounter,
    backStrengthCounter,
    backDurationCounter,
    backFrequencyCounter,
  ]);
  useEffect(() => {
    const a = legsPostureCounter === 0 ? 0 : 1;
    const b = legsStrengthCounter === 0 ? 0 : 1;
    const c = legsDurationCounter === 0 ? 0 : 1;
    const d = legsFrequencyCounter === 0 ? 0 : 1;
    setLegsResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    legsPostureCounter,
    legsStrengthCounter,
    legsDurationCounter,
    legsFrequencyCounter,
  ]);
  useEffect(() => {
    const a = rightElbowPostureCounter === 0 ? 0 : 1;
    const b = rightElbowStrengthCounter || bothElbowStrengthCounter > 0 ? 1 : 0;
    const c = rightElbowDurationCounter || bothElbowDurationCounter > 0 ? 1 : 0;
    const d =
      rightElbowFrequencyCounter || bothElbowFrequencyCounter > 0 ? 1 : 0;
    setRightElbowResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rightElbowPostureCounter,
    rightElbowStrengthCounter,
    rightElbowDurationCounter,
    rightElbowFrequencyCounter,
    bothElbowFrequencyCounter,
    bothElbowDurationCounter,
    bothElbowStrengthCounter,
  ]);
  useEffect(() => {
    const a = leftElbowPostureCounter === 0 ? 0 : 1;
    const b = leftElbowStrengthCounter || bothElbowStrengthCounter > 0 ? 1 : 0;
    const c = leftElbowDurationCounter || bothElbowDurationCounter > 0 ? 1 : 0;
    const d =
      leftElbowFrequencyCounter || bothElbowFrequencyCounter > 0 ? 1 : 0;
    setLeftElbowResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftElbowPostureCounter,
    leftElbowStrengthCounter,
    leftElbowDurationCounter,
    leftElbowFrequencyCounter,
    bothElbowFrequencyCounter,
    bothElbowDurationCounter,
    bothElbowStrengthCounter,
  ]);
  useEffect(() => {
    const a = rightShoulderPostureCounter === 0 ? 0 : 1;
    const b =
      rightShoulderStrengthCounter || bothShouldersStrengthCounter > 0 ? 1 : 0;
    const c =
      rightShoulderDurationCounter || bothShouldersDurationCounter > 0 ? 1 : 0;
    const d =
      rightShoulderFrequencyCounter || bothShouldersFrequencyCounter > 0
        ? 1
        : 0;
    setRightShoulderResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rightShoulderPostureCounter,
    rightShoulderStrengthCounter,
    rightShoulderDurationCounter,
    rightShoulderFrequencyCounter,
    bothShouldersFrequencyCounter,
    bothShouldersDurationCounter,
    bothShouldersStrengthCounter,
  ]);
  useEffect(() => {
    const a = leftShoulderPostureCounter < 1 ? 0 : 1;
    const b =
      leftShoulderStrengthCounter || bothShouldersStrengthCounter > 0 ? 1 : 0;
    const c =
      leftShoulderDurationCounter || bothShouldersDurationCounter > 0 ? 1 : 0;
    const d =
      leftShoulderFrequencyCounter || bothShouldersFrequencyCounter > 0 ? 1 : 0;
    setLeftShoulderResults(a + b + c + d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftShoulderPostureCounter,
    leftShoulderStrengthCounter,
    leftShoulderDurationCounter,
    leftShoulderFrequencyCounter,
    bothShouldersFrequencyCounter,
    bothShouldersDurationCounter,
    bothShouldersStrengthCounter,
  ]);

  function resultsWeight(result) {
    if (result === 0) return 0;
    if (result === 1) return 1;
    if (result === 2) return 3;
    if (result === 3) return 5;
    if (result === 4) return 10;
  }

  useEffect(() => {
    let counter =
      resultsWeight(rightHandResults) +
      resultsWeight(leftHandResults) +
      resultsWeight(rightElbowResults) +
      resultsWeight(leftElbowResults) +
      resultsWeight(rightShoulderResults) +
      resultsWeight(leftShoulderResults) +
      resultsWeight(neckResults) +
      resultsWeight(backResults) +
      resultsWeight(legsResults) +
      vibrationCounter +
      lowTemperatureCounter +
      softTissueCompressionCounter +
      impactStressCounter +
      gloveIssuesCounter;

    setTotalResults(counter * stepFrequency);
    counter = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vibrationCounter,
    lowTemperatureCounter,
    softTissueCompressionCounter,
    impactStressCounter,
    gloveIssuesCounter,
    rightHandResults,
    leftHandResults,
    rightElbowResults,
    leftElbowResults,
    rightShoulderResults,
    leftShoulderResults,
    neckResults,
    backResults,
    legsResults,
    stepPosition,

    stepFrequency,
  ]);

  return (
    <ParamsContext.Provider
      value={{
        lift,
        setLift,
        push,
        setPush,
        pull,
        setPull,
        load,
        setLoad,

        rightHandPostureCounter,
        setRightHandPostureCounter,
        leftHandPostureCounter,
        setLeftHandPostureCounter,
        rightElbowPostureCounter,
        setRightElbowPostureCounter,
        rightShoulderPostureCounter,
        setRightShoulderPostureCounter,
        leftElbowPostureCounter,
        setLeftElbowPostureCounter,
        leftShoulderPostureCounter,
        setLeftShoulderPostureCounter,
        neckPostureCounter,
        setNeckPostureCounter,
        backPostureCounter,
        setBackPostureCounter,
        legsPostureCounter,
        setLegsPostureCounter,
        timeActivity,
        setTimeActivity,

        rightHandStrengthCounter,
        setRightHandStrengthCounter,
        leftHandStrengthCounter,
        setLeftHandStrengthCounter,
        rightElbowStrengthCounter,
        setRightElbowStrengthCounter,
        bothElbowStrengthCounter,
        setBothElbowStrengthCounter,
        bothShouldersStrengthCounter,
        setBothShouldersStrengthCounter,
        rightShoulderStrengthCounter,
        setRightShoulderStrengthCounter,
        leftElbowStrengthCounter,
        setLeftElbowStrengthCounter,
        leftShoulderStrengthCounter,
        setLeftShoulderStrengthCounter,
        neckStrengthCounter,
        setNeckStrengthCounter,
        backStrengthCounter,
        setBackStrengthCounter,
        legsStrengthCounter,
        setLegsStrengthCounter,

        rightHandDurationCounter,
        setRightHandDurationCounter,
        leftHandDurationCounter,
        setLeftHandDurationCounter,
        rightElbowDurationCounter,
        setRightElbowDurationCounter,
        bothElbowDurationCounter,
        setBothElbowDurationCounter,
        bothShouldersDurationCounter,
        setBothShouldersDurationCounter,
        rightShoulderDurationCounter,
        setRightShoulderDurationCounter,
        leftElbowDurationCounter,
        setLeftElbowDurationCounter,
        leftShoulderDurationCounter,
        setLeftShoulderDurationCounter,
        neckDurationCounter,
        setNeckDurationCounter,
        backDurationCounter,
        setBackDurationCounter,
        legsDurationCounter,
        setLegsDurationCounter,

        rightHandFrequencyCounter,
        setRightHandFrequencyCounter,
        leftHandFrequencyCounter,
        setLeftHandFrequencyCounter,
        rightElbowFrequencyCounter,
        setRightElbowFrequencyCounter,
        bothElbowFrequencyCounter,
        setBothElbowFrequencyCounter,
        bothShouldersFrequencyCounter,
        setBothShouldersFrequencyCounter,
        rightShoulderFrequencyCounter,
        setRightShoulderFrequencyCounter,
        leftElbowFrequencyCounter,
        setLeftElbowFrequencyCounter,
        leftShoulderFrequencyCounter,
        setLeftShoulderFrequencyCounter,
        neckFrequencyCounter,
        setNeckFrequencyCounter,
        backFrequencyCounter,
        setBackFrequencyCounter,
        legsFrequencyCounter,
        setLegsFrequencyCounter,

        vibrationCounter,
        setVibrationCounter,
        lowTemperatureCounter,
        setLowTemperatureCounter,
        softTissueCompressionCounter,
        setSoftTissueCompressionCounter,
        impactStressCounter,
        setImpactStressCounter,
        gloveIssuesCounter,
        setGloveIssuesCounter,

        rightHandResults,
        setRightHandResults,
        leftHandResults,
        setLeftHandResults,
        rightElbowResults,
        setRightElbowResults,
        leftElbowResults,
        setLeftElbowResults,
        rightShoulderResults,
        setRightShoulderResults,
        leftShoulderResults,
        setLeftShoulderResults,
        neckResults,
        setNeckResults,
        backResults,
        setBackResults,
        legsResults,
        setLegsResults,
        totalResults,
        setTotalResults,
        stepPosition,
        setStepPosition,
        stepFrequency,
        setStepFrequency,

        leftHandExtension,
        setLeftHandExtension,
        leftHandFlexion,
        setLeftHandFlexion,
        leftHandUlnar,
        setLeftHandUlnar,
        leftHandRadial,
        setLeftHandRadial,
        rightHandExtension,
        setRightHandExtension,
        rightHandFlexion,
        setRightHandFlexion,
        rightHandUlnar,
        setRightHandUlnar,
        rightHandRadial,
        setRightHandRadial,
        leftHandGripStrength,
        setLeftHandGripStrength,
        leftHandGripDuration,
        setLeftHandGripDuration,
        leftHandGripFrequency,
        setLeftHandGripFrequency,
        leftHandHoldStrength,
        setLeftHandHoldStrength,
        leftHandHoldDuration,
        setLeftHandHoldDuration,
        leftHandHoldFrequency,
        setLeftHandHoldFrequency,
        leftHandClampStrength,
        setLeftHandClampStrength,
        leftHandClampDuration,
        setLeftHandClampDuration,
        leftHandClampFrequency,
        setLeftHandClampFrequency,
        rightHandGripStrength,
        setRightHandGripStrength,
        rightHandGripDuration,
        setRightHandGripDuration,
        rightHandGripFrequency,
        setRightHandGripFrequency,
        rightHandHoldStrength,
        setRightHandHoldStrength,
        rightHandHoldDuration,
        setRightHandHoldDuration,
        rightHandHoldFrequency,
        setRightHandHoldFrequency,
        rightHandClampStrength,
        setRightHandClampStrength,
        rightHandClampDuration,
        setRightHandClampDuration,
        rightHandClampFrequency,
        setRightHandClampFrequency,
        leftElbowRotation,
        setLeftElbowRotation,
        rightElbowRotation,
        setRightElbowRotation,
        leftElbowExtension,
        setLeftElbowExtension,
        rightElbowExtension,
        setRightElbowExtension,
        leftElbowStrength,
        setLeftElbowStrength,
        leftElbowDuration,
        setLeftElbowDuration,
        leftElbowFrequency,
        setLeftElbowFrequency,
        rightElbowStrength,
        setRightElbowStrength,
        rightElbowDuration,
        setRightElbowDuration,
        rightElbowFrequency,
        setRightElbowFrequency,
        bothElbowStrength,
        setBothElbowStrength,
        bothElbowDuration,
        setBothElbowDuration,
        bothElbowFrequency,
        setBothElbowFrequency,
        leftShoulderFlexion,
        setLeftShoulderFlexion,
        rightShoulderFlexion,
        setRightShoulderFlexion,
        leftShoulderAbduction,
        setLeftShoulderAbduction,
        rightShoulderAbduction,
        setRightShoulderAbduction,
        leftShoulderElevation,
        setLeftShoulderElevation,
        rightShoulderElevation,
        setRightShoulderElevation,
        leftShoulderStrength,
        setLeftShoulderStrength,
        leftShoulderDuration,
        setLeftShoulderDuration,
        leftShoulderFrequency,
        setLeftShoulderFrequency,
        rightShoulderStrength,
        setRightShoulderStrength,
        rightShoulderDuration,
        setRightShoulderDuration,
        rightShoulderFrequency,
        setRightShoulderFrequency,
        bothShoulderStrength,
        setBothShoulderStrength,
        bothShoulderDuration,
        setBothShoulderDuration,
        bothShoulderFrequency,
        setBothShoulderFrequency,
        neckFlexion,
        setNeckFlexion,
        neckSideFlexion,
        setNeckSideFlexion,
        neckExtension,
        setNeckExtension,
        neckRotation,
        setNeckRotation,
        neckStrength,
        setNeckStrength,
        neckDuration,
        setNeckDuration,
        neckFrequency,
        setNeckFrequency,
        backFlexion,
        setBackFlexion,
        backRotation,
        setBackRotation,
        backSideFlexion,
        setBackSideFlexion,
        backExtension,
        setBackExtension,
        backNoSupport,
        setBackNoSupport,
        backStrength,
        setBackStrength,
        backDuration,
        setBackDuration,
        backFrequency,
        setBackFrequency,
        legsCrouching,
        setLegsCrouching,
        legsKneeling,
        setLegsKneeling,
        legsNoSupport,
        setLegsNoSupport,
        legsStrength,
        setLegsStrength,
        legsDuration,
        setLegsDuration,
        legsFrequency,
        setLegsFrequency,
        vibration,
        setVibration,
        lowTemperature,
        setLowTemperature,
        softTissueCompression,
        setSoftTissueCompression,
        impactStress,
        setImpactStress,
        gloveIssue,
        setGloveIssue,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
}

export function useBriefAndBestContext() {
  const context = useContext(ParamsContext);
  const {
    lift,
    setLift,
    push,
    setPush,
    pull,
    setPull,
    load,
    setLoad,

    rightHandPostureCounter,
    setRightHandPostureCounter,
    leftHandPostureCounter,
    setLeftHandPostureCounter,
    rightElbowPostureCounter,
    setRightElbowPostureCounter,
    rightShoulderPostureCounter,
    setRightShoulderPostureCounter,
    leftElbowPostureCounter,
    setLeftElbowPostureCounter,
    leftShoulderPostureCounter,
    setLeftShoulderPostureCounter,
    neckPostureCounter,
    setNeckPostureCounter,
    backPostureCounter,
    setBackPostureCounter,
    legsPostureCounter,
    setLegsPostureCounter,

    rightHandStrengthCounter,
    setRightHandStrengthCounter,
    leftHandStrengthCounter,
    setLeftHandStrengthCounter,
    rightElbowStrengthCounter,
    setRightElbowStrengthCounter,
    bothElbowStrengthCounter,
    setBothElbowStrengthCounter,
    bothShouldersStrengthCounter,
    setBothShouldersStrengthCounter,
    rightShoulderStrengthCounter,
    setRightShoulderStrengthCounter,
    leftElbowStrengthCounter,
    setLeftElbowStrengthCounter,
    leftShoulderStrengthCounter,
    setLeftShoulderStrengthCounter,
    neckStrengthCounter,
    setNeckStrengthCounter,
    backStrengthCounter,
    setBackStrengthCounter,
    legsStrengthCounter,
    setLegsStrengthCounter,
    timeActivity,
    setTimeActivity,

    rightHandDurationCounter,
    setRightHandDurationCounter,
    leftHandDurationCounter,
    setLeftHandDurationCounter,
    rightElbowDurationCounter,
    setRightElbowDurationCounter,
    bothElbowDurationCounter,
    setBothElbowDurationCounter,
    bothShouldersDurationCounter,
    setBothShouldersDurationCounter,
    rightShoulderDurationCounter,
    setRightShoulderDurationCounter,
    leftElbowDurationCounter,
    setLeftElbowDurationCounter,
    leftShoulderDurationCounter,
    setLeftShoulderDurationCounter,
    neckDurationCounter,
    setNeckDurationCounter,
    backDurationCounter,
    setBackDurationCounter,
    legsDurationCounter,
    setLegsDurationCounter,

    rightHandFrequencyCounter,
    setRightHandFrequencyCounter,
    leftHandFrequencyCounter,
    setLeftHandFrequencyCounter,
    rightElbowFrequencyCounter,
    setRightElbowFrequencyCounter,
    bothElbowFrequencyCounter,
    setBothElbowFrequencyCounter,
    bothShouldersFrequencyCounter,
    setBothShouldersFrequencyCounter,
    rightShoulderFrequencyCounter,
    setRightShoulderFrequencyCounter,
    leftElbowFrequencyCounter,
    setLeftElbowFrequencyCounter,
    leftShoulderFrequencyCounter,
    setLeftShoulderFrequencyCounter,
    neckFrequencyCounter,
    setNeckFrequencyCounter,
    backFrequencyCounter,
    setBackFrequencyCounter,
    legsFrequencyCounter,
    setLegsFrequencyCounter,

    vibrationCounter,
    setVibrationCounter,
    lowTemperatureCounter,
    setLowTemperatureCounter,
    softTissueCompressionCounter,
    setSoftTissueCompressionCounter,
    impactStressCounter,
    setImpactStressCounter,
    gloveIssuesCounter,
    setGloveIssuesCounter,

    rightHandResults,
    setRightHandResults,
    leftHandResults,
    setLeftHandResults,
    rightElbowResults,
    setRightElbowResults,
    leftElbowResults,
    setLeftElbowResults,
    rightShoulderResults,
    setRightShoulderResults,
    leftShoulderResults,
    setLeftShoulderResults,
    neckResults,
    setNeckResults,
    backResults,
    setBackResults,
    legsResults,
    setLegsResults,
    totalResults,
    setTotalResults,
    stepPosition,
    setStepPosition,
    stepFrequency,
    setStepFrequency,
    leftHandExtension,
    setLeftHandExtension,
    leftHandFlexion,
    setLeftHandFlexion,
    leftHandUlnar,
    setLeftHandUlnar,
    leftHandRadial,
    setLeftHandRadial,
    rightHandExtension,
    setRightHandExtension,
    rightHandFlexion,
    setRightHandFlexion,
    rightHandUlnar,
    setRightHandUlnar,
    rightHandRadial,
    setRightHandRadial,
    leftHandGripStrength,
    setLeftHandGripStrength,
    leftHandGripDuration,
    setLeftHandGripDuration,
    leftHandGripFrequency,
    setLeftHandGripFrequency,
    leftHandHoldStrength,
    setLeftHandHoldStrength,
    leftHandHoldDuration,
    setLeftHandHoldDuration,
    leftHandHoldFrequency,
    setLeftHandHoldFrequency,
    leftHandClampStrength,
    setLeftHandClampStrength,
    leftHandClampDuration,
    setLeftHandClampDuration,
    leftHandClampFrequency,
    setLeftHandClampFrequency,
    rightHandGripStrength,
    setRightHandGripStrength,
    rightHandGripDuration,
    setRightHandGripDuration,
    rightHandGripFrequency,
    setRightHandGripFrequency,
    rightHandHoldStrength,
    setRightHandHoldStrength,
    rightHandHoldDuration,
    setRightHandHoldDuration,
    rightHandHoldFrequency,
    setRightHandHoldFrequency,
    rightHandClampStrength,
    setRightHandClampStrength,
    rightHandClampDuration,
    setRightHandClampDuration,
    rightHandClampFrequency,
    setRightHandClampFrequency,
    leftElbowRotation,
    setLeftElbowRotation,
    rightElbowRotation,
    setRightElbowRotation,
    leftElbowExtension,
    setLeftElbowExtension,
    rightElbowExtension,
    setRightElbowExtension,
    leftElbowStrength,
    setLeftElbowStrength,
    leftElbowDuration,
    setLeftElbowDuration,
    leftElbowFrequency,
    setLeftElbowFrequency,
    rightElbowStrength,
    setRightElbowStrength,
    rightElbowDuration,
    setRightElbowDuration,
    rightElbowFrequency,
    setRightElbowFrequency,
    bothElbowStrength,
    setBothElbowStrength,
    bothElbowDuration,
    setBothElbowDuration,
    bothElbowFrequency,
    setBothElbowFrequency,
    leftShoulderFlexion,
    setLeftShoulderFlexion,
    rightShoulderFlexion,
    setRightShoulderFlexion,
    leftShoulderAbduction,
    setLeftShoulderAbduction,
    rightShoulderAbduction,
    setRightShoulderAbduction,
    leftShoulderElevation,
    setLeftShoulderElevation,
    rightShoulderElevation,
    setRightShoulderElevation,
    leftShoulderStrength,
    setLeftShoulderStrength,
    leftShoulderDuration,
    setLeftShoulderDuration,
    leftShoulderFrequency,
    setLeftShoulderFrequency,
    rightShoulderStrength,
    setRightShoulderStrength,
    rightShoulderDuration,
    setRightShoulderDuration,
    rightShoulderFrequency,
    setRightShoulderFrequency,
    bothShoulderStrength,
    setBothShoulderStrength,
    bothShoulderDuration,
    setBothShoulderDuration,
    bothShoulderFrequency,
    setBothShoulderFrequency,
    neckFlexion,
    setNeckFlexion,
    neckSideFlexion,
    setNeckSideFlexion,
    neckExtension,
    setNeckExtension,
    neckRotation,
    setNeckRotation,
    neckStrength,
    setNeckStrength,
    neckDuration,
    setNeckDuration,
    neckFrequency,
    setNeckFrequency,
    backFlexion,
    setBackFlexion,
    backRotation,
    setBackRotation,
    backSideFlexion,
    setBackSideFlexion,
    backExtension,
    setBackExtension,
    backNoSupport,
    setBackNoSupport,
    backStrength,
    setBackStrength,
    backDuration,
    setBackDuration,
    backFrequency,
    setBackFrequency,
    legsCrouching,
    setLegsCrouching,
    legsKneeling,
    setLegsKneeling,
    legsNoSupport,
    setLegsNoSupport,
    legsStrength,
    setLegsStrength,
    legsDuration,
    setLegsDuration,
    legsFrequency,
    setLegsFrequency,
    vibration,
    setVibration,
    lowTemperature,
    setLowTemperature,
    softTissueCompression,
    setSoftTissueCompression,
    impactStress,
    setImpactStress,
    gloveIssue,
    setGloveIssue,
  } = context;
  return {
    lift,
    setLift,
    push,
    setPush,
    pull,
    setPull,
    load,
    setLoad,
    rightHandPostureCounter,
    setRightHandPostureCounter,
    leftHandPostureCounter,
    setLeftHandPostureCounter,
    rightElbowPostureCounter,
    setRightElbowPostureCounter,
    rightShoulderPostureCounter,
    setRightShoulderPostureCounter,
    leftElbowPostureCounter,
    setLeftElbowPostureCounter,
    leftShoulderPostureCounter,
    setLeftShoulderPostureCounter,
    neckPostureCounter,
    setNeckPostureCounter,
    backPostureCounter,
    setBackPostureCounter,
    legsPostureCounter,
    setLegsPostureCounter,
    timeActivity,
    setTimeActivity,

    rightHandStrengthCounter,
    setRightHandStrengthCounter,
    leftHandStrengthCounter,
    setLeftHandStrengthCounter,
    rightElbowStrengthCounter,
    setRightElbowStrengthCounter,
    bothElbowStrengthCounter,
    setBothElbowStrengthCounter,
    bothShouldersStrengthCounter,
    setBothShouldersStrengthCounter,
    rightShoulderStrengthCounter,
    setRightShoulderStrengthCounter,
    leftElbowStrengthCounter,
    setLeftElbowStrengthCounter,
    leftShoulderStrengthCounter,
    setLeftShoulderStrengthCounter,
    neckStrengthCounter,
    setNeckStrengthCounter,
    backStrengthCounter,
    setBackStrengthCounter,
    legsStrengthCounter,
    setLegsStrengthCounter,

    rightHandDurationCounter,
    setRightHandDurationCounter,
    leftHandDurationCounter,
    setLeftHandDurationCounter,
    rightElbowDurationCounter,
    setRightElbowDurationCounter,
    bothElbowDurationCounter,
    setBothElbowDurationCounter,
    bothShouldersDurationCounter,
    setBothShouldersDurationCounter,
    rightShoulderDurationCounter,
    setRightShoulderDurationCounter,
    leftElbowDurationCounter,
    setLeftElbowDurationCounter,
    leftShoulderDurationCounter,
    setLeftShoulderDurationCounter,
    neckDurationCounter,
    setNeckDurationCounter,
    backDurationCounter,
    setBackDurationCounter,
    legsDurationCounter,
    setLegsDurationCounter,

    rightHandFrequencyCounter,
    setRightHandFrequencyCounter,
    leftHandFrequencyCounter,
    setLeftHandFrequencyCounter,
    rightElbowFrequencyCounter,
    setRightElbowFrequencyCounter,
    bothElbowFrequencyCounter,
    setBothElbowFrequencyCounter,
    bothShouldersFrequencyCounter,
    setBothShouldersFrequencyCounter,
    rightShoulderFrequencyCounter,
    setRightShoulderFrequencyCounter,
    leftElbowFrequencyCounter,
    setLeftElbowFrequencyCounter,
    leftShoulderFrequencyCounter,
    setLeftShoulderFrequencyCounter,
    neckFrequencyCounter,
    setNeckFrequencyCounter,
    backFrequencyCounter,
    setBackFrequencyCounter,
    legsFrequencyCounter,
    setLegsFrequencyCounter,

    vibrationCounter,
    setVibrationCounter,
    lowTemperatureCounter,
    setLowTemperatureCounter,
    softTissueCompressionCounter,
    setSoftTissueCompressionCounter,
    impactStressCounter,
    setImpactStressCounter,
    gloveIssuesCounter,
    setGloveIssuesCounter,

    rightHandResults,
    setRightHandResults,
    leftHandResults,
    setLeftHandResults,
    rightElbowResults,
    setRightElbowResults,
    leftElbowResults,
    setLeftElbowResults,
    rightShoulderResults,
    setRightShoulderResults,
    leftShoulderResults,
    setLeftShoulderResults,
    neckResults,
    setNeckResults,
    backResults,
    setBackResults,
    legsResults,
    setLegsResults,
    totalResults,
    setTotalResults,
    stepPosition,
    setStepPosition,
    stepFrequency,
    setStepFrequency,
    leftHandExtension,
    setLeftHandExtension,
    leftHandFlexion,
    setLeftHandFlexion,
    leftHandUlnar,
    setLeftHandUlnar,
    leftHandRadial,
    setLeftHandRadial,
    rightHandExtension,
    setRightHandExtension,
    rightHandFlexion,
    setRightHandFlexion,
    rightHandUlnar,
    setRightHandUlnar,
    rightHandRadial,
    setRightHandRadial,
    leftHandGripStrength,
    setLeftHandGripStrength,
    leftHandGripDuration,
    setLeftHandGripDuration,
    leftHandGripFrequency,
    setLeftHandGripFrequency,
    leftHandHoldStrength,
    setLeftHandHoldStrength,
    leftHandHoldDuration,
    setLeftHandHoldDuration,
    leftHandHoldFrequency,
    setLeftHandHoldFrequency,
    leftHandClampStrength,
    setLeftHandClampStrength,
    leftHandClampDuration,
    setLeftHandClampDuration,
    leftHandClampFrequency,
    setLeftHandClampFrequency,
    rightHandGripStrength,
    setRightHandGripStrength,
    rightHandGripDuration,
    setRightHandGripDuration,
    rightHandGripFrequency,
    setRightHandGripFrequency,
    rightHandHoldStrength,
    setRightHandHoldStrength,
    rightHandHoldDuration,
    setRightHandHoldDuration,
    rightHandHoldFrequency,
    setRightHandHoldFrequency,
    rightHandClampStrength,
    setRightHandClampStrength,
    rightHandClampDuration,
    setRightHandClampDuration,
    rightHandClampFrequency,
    setRightHandClampFrequency,
    leftElbowRotation,
    setLeftElbowRotation,
    rightElbowRotation,
    setRightElbowRotation,
    leftElbowExtension,
    setLeftElbowExtension,
    rightElbowExtension,
    setRightElbowExtension,
    leftElbowStrength,
    setLeftElbowStrength,
    leftElbowDuration,
    setLeftElbowDuration,
    leftElbowFrequency,
    setLeftElbowFrequency,
    rightElbowStrength,
    setRightElbowStrength,
    rightElbowDuration,
    setRightElbowDuration,
    rightElbowFrequency,
    setRightElbowFrequency,
    bothElbowStrength,
    setBothElbowStrength,
    bothElbowDuration,
    setBothElbowDuration,
    bothElbowFrequency,
    setBothElbowFrequency,
    leftShoulderFlexion,
    setLeftShoulderFlexion,
    rightShoulderFlexion,
    setRightShoulderFlexion,
    leftShoulderAbduction,
    setLeftShoulderAbduction,
    rightShoulderAbduction,
    setRightShoulderAbduction,
    leftShoulderElevation,
    setLeftShoulderElevation,
    rightShoulderElevation,
    setRightShoulderElevation,
    leftShoulderStrength,
    setLeftShoulderStrength,
    leftShoulderDuration,
    setLeftShoulderDuration,
    leftShoulderFrequency,
    setLeftShoulderFrequency,
    rightShoulderStrength,
    setRightShoulderStrength,
    rightShoulderDuration,
    setRightShoulderDuration,
    rightShoulderFrequency,
    setRightShoulderFrequency,
    bothShoulderStrength,
    setBothShoulderStrength,
    bothShoulderDuration,
    setBothShoulderDuration,
    bothShoulderFrequency,
    setBothShoulderFrequency,
    neckFlexion,
    setNeckFlexion,
    neckSideFlexion,
    setNeckSideFlexion,
    neckExtension,
    setNeckExtension,
    neckRotation,
    setNeckRotation,
    neckStrength,
    setNeckStrength,
    neckDuration,
    setNeckDuration,
    neckFrequency,
    setNeckFrequency,
    backFlexion,
    setBackFlexion,
    backRotation,
    setBackRotation,
    backSideFlexion,
    setBackSideFlexion,
    backExtension,
    setBackExtension,
    backNoSupport,
    setBackNoSupport,
    backStrength,
    setBackStrength,
    backDuration,
    setBackDuration,
    backFrequency,
    setBackFrequency,
    legsCrouching,
    setLegsCrouching,
    legsKneeling,
    setLegsKneeling,
    legsNoSupport,
    setLegsNoSupport,
    legsStrength,
    setLegsStrength,
    legsDuration,
    setLegsDuration,
    legsFrequency,
    setLegsFrequency,
    vibration,
    setVibration,
    lowTemperature,
    setLowTemperature,
    softTissueCompression,
    setSoftTissueCompression,
    impactStress,
    setImpactStress,
    gloveIssue,
    setGloveIssue,
  };
}
