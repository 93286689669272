import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createStepEvaluation({
  evaluationType,
  rightHandResults,
  leftHandResults,
  rightElbowResults,
  leftElbowResults,
  rightShoulderResults,
  leftShoulderResults,
  legsResults,
  neckResults,
  backResults,
  totalResults,
  lift,
  pull,
  load,
  push,
  activityId,
  companyId,
  gheId,
  jobPositionId,
  sectorId,
  areaId,
  workstationId,
  stepId,
  leftHandExtension,
  leftHandFlexion,
  leftHandUlnar,
  leftHandRadial,
  rightHandExtension,
  rightHandFlexion,
  rightHandUlnar,
  rightHandRadial,
  leftHandGripStrength,
  leftHandGripDuration,
  leftHandGripFrequency,
  leftHandHoldStrength,
  leftHandHoldDuration,
  leftHandHoldFrequency,
  leftHandClampStrength,
  leftHandClampDuration,
  leftHandClampFrequency,
  rightHandGripStrength,
  rightHandGripDuration,
  rightHandGripFrequency,
  rightHandHoldStrength,
  rightHandHoldDuration,
  rightHandHoldFrequency,
  rightHandClampStrength,
  rightHandClampDuration,
  rightHandClampFrequency,
  leftElbowRotation,
  rightElbowRotation,
  leftElbowExtension,
  rightElbowExtension,
  leftElbowStrength,
  leftElbowDuration,
  leftElbowFrequency,
  rightElbowStrength,
  rightElbowDuration,
  rightElbowFrequency,
  bothElbowStrength,
  bothElbowDuration,
  bothElbowFrequency,
  leftShoulderFlexion,
  rightShoulderFlexion,
  leftShoulderAbduction,
  rightShoulderAbduction,
  leftShoulderElevation,
  rightShoulderElevation,
  leftShoulderStrength,
  leftShoulderDuration,
  leftShoulderFrequency,
  rightShoulderStrength,
  rightShoulderDuration,
  rightShoulderFrequency,
  bothShoulderStrength,
  bothShoulderDuration,
  bothShoulderFrequency,
  neckFlexion,
  neckSideFlexion,
  neckExtension,
  neckRotation,
  neckStrength,
  neckDuration,
  neckFrequency,
  backFlexion,
  backRotation,
  backSideFlexion,
  backExtension,
  backNoSupport,
  backStrength,
  backDuration,
  backFrequency,
  legsCrouching,
  legsKneeling,
  legsNoSupport,
  legsStrength,
  legsDuration,
  legsFrequency,
  vibration,
  lowTemperature,
  softTissueCompression,
  impactStress,
  gloveIssue,

  timeActivity,
}) {
  const res = await axios.post(
    `${API_URL}/stepevaluation`,
    {
      type: evaluationType,
      right_hand: rightHandResults,
      left_hand: leftHandResults,
      right_elbow: rightElbowResults,
      left_elbow: leftElbowResults,
      right_shoulder: rightShoulderResults,
      left_shoulder: leftShoulderResults,
      legs: legsResults,
      neck: neckResults,
      back: backResults,
      npr: totalResults,
      lift,
      pull,
      load,
      push,
      step_id: stepId,
      activity_id: activityId,
      company_id: companyId,
      ghe_id: gheId,
      job_position_id: jobPositionId,
      sector_id: sectorId,
      work_area_id: areaId,
      workstation_id: workstationId,
      left_hand_extension: leftHandExtension,
      left_hand_flexion: leftHandFlexion,
      left_hand_ulnar: leftHandUlnar,
      left_hand_radial: leftHandRadial,
      right_hand_extension: rightHandExtension,
      right_hand_flexion: rightHandFlexion,
      right_hand_ulnar: rightHandUlnar,
      right_hand_radial: rightHandRadial,
      left_hand_grip_strength: leftHandGripStrength,
      left_hand_grip_duration: leftHandGripDuration,
      left_hand_grip_frequency: leftHandGripFrequency,
      left_hand_hold_strength: leftHandHoldStrength,
      left_hand_hold_duration: leftHandHoldDuration,
      left_hand_hold_frequency: leftHandHoldFrequency,
      left_hand_clamp_strength: leftHandClampStrength,
      left_hand_clamp_duration: leftHandClampDuration,
      left_hand_clamp_frequency: leftHandClampFrequency,
      right_hand_grip_strength: rightHandGripStrength,
      right_hand_grip_duration: rightHandGripDuration,
      right_hand_grip_frequency: rightHandGripFrequency,
      right_hand_hold_strength: rightHandHoldStrength,
      right_hand_hold_duration: rightHandHoldDuration,
      right_hand_hold_frequency: rightHandHoldFrequency,
      right_hand_clamp_strength: rightHandClampStrength,
      right_hand_clamp_duration: rightHandClampDuration,
      right_hand_clamp_frequency: rightHandClampFrequency,
      left_elbow_rotation: leftElbowRotation,
      right_elbow_rotation: rightElbowRotation,
      left_elbow_extension: leftElbowExtension,
      right_elbow_extension: rightElbowExtension,
      left_elbow_strength: leftElbowStrength,
      left_elbow_duration: leftElbowDuration,
      left_elbow_frequency: leftElbowFrequency,
      right_elbow_strength: rightElbowStrength,
      right_elbow_duration: rightElbowDuration,
      right_elbow_frequency: rightElbowFrequency,
      both_elbow_strength: bothElbowStrength,
      both_elbow_duration: bothElbowDuration,
      both_elbow_frequency: bothElbowFrequency,
      left_shoulder_flexion: leftShoulderFlexion,
      right_shoulder_flexion: rightShoulderFlexion,
      left_shoulder_abduction: leftShoulderAbduction,
      right_shoulder_abduction: rightShoulderAbduction,
      left_shoulder_elevation: leftShoulderElevation,
      right_shoulder_elevation: rightShoulderElevation,
      left_shoulder_strength: leftShoulderStrength,
      left_shoulder_duration: leftShoulderDuration,
      left_shoulder_frequency: leftShoulderFrequency,
      right_shoulder_strength: rightShoulderStrength,
      right_shoulder_duration: rightShoulderDuration,
      right_shoulder_frequency: rightShoulderFrequency,
      both_shoulder_strength: bothShoulderStrength,
      both_shoulder_duration: bothShoulderDuration,
      both_shoulder_frequency: bothShoulderFrequency,
      neck_flexion: neckFlexion,
      neck_side_flexion: neckSideFlexion,
      neck_extension: neckExtension,
      neck_rotation: neckRotation,
      neck_strength: neckStrength,
      neck_duration: neckDuration,
      neck_frequency: neckFrequency,
      back_flexion: backFlexion,
      back_rotation: backRotation,
      back_side_flexion: backSideFlexion,
      back_extension: backExtension,
      back_no_support: backNoSupport,
      back_strength: backStrength,
      back_duration: backDuration,
      back_frequency: backFrequency,
      legs_crouching: legsCrouching,
      legs_kneeling: legsKneeling,
      legs_no_support: legsNoSupport,
      legs_strength: legsStrength,
      legs_duration: legsDuration,
      legs_frequency: legsFrequency,
      vibration,
      low_temperature: lowTemperature,
      soft_tissue_compression: softTissueCompression,
      impact_stress: impactStress,
      glove_issue: gloveIssue,
      time_activity: timeActivity,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editStepEvaluation({
  evaluationId,
  evaluationType,
  rightHandResults,
  leftHandResults,
  rightElbowResults,
  leftElbowResults,
  rightShoulderResults,
  leftShoulderResults,
  legsResults,
  neckResults,
  backResults,
  totalResults,
  lift,
  pull,
  load,
  push,
  activityId,
  companyId,
  gheId,
  jobPositionId,
  sectorId,
  areaId,
  workstationId,
  stepId,
  leftHandExtension,
  leftHandFlexion,
  leftHandUlnar,
  leftHandRadial,
  rightHandExtension,
  rightHandFlexion,
  rightHandUlnar,
  rightHandRadial,
  leftHandGripStrength,
  leftHandGripDuration,
  leftHandGripFrequency,
  leftHandHoldStrength,
  leftHandHoldDuration,
  leftHandHoldFrequency,
  leftHandClampStrength,
  leftHandClampDuration,
  leftHandClampFrequency,
  rightHandGripStrength,
  rightHandGripDuration,
  rightHandGripFrequency,
  rightHandHoldStrength,
  rightHandHoldDuration,
  rightHandHoldFrequency,
  rightHandClampStrength,
  rightHandClampDuration,
  rightHandClampFrequency,
  leftElbowRotation,
  rightElbowRotation,
  leftElbowExtension,
  rightElbowExtension,
  leftElbowStrength,
  leftElbowDuration,
  leftElbowFrequency,
  rightElbowStrength,
  rightElbowDuration,
  rightElbowFrequency,
  bothElbowStrength,
  bothElbowDuration,
  bothElbowFrequency,
  leftShoulderFlexion,
  rightShoulderFlexion,
  leftShoulderAbduction,
  rightShoulderAbduction,
  leftShoulderElevation,
  rightShoulderElevation,
  leftShoulderStrength,
  leftShoulderDuration,
  leftShoulderFrequency,
  rightShoulderStrength,
  rightShoulderDuration,
  rightShoulderFrequency,
  bothShoulderStrength,
  bothShoulderDuration,
  bothShoulderFrequency,
  neckFlexion,
  neckSideFlexion,
  neckExtension,
  neckRotation,
  neckStrength,
  neckDuration,
  neckFrequency,
  backFlexion,
  backRotation,
  backSideFlexion,
  backExtension,
  backNoSupport,
  backStrength,
  backDuration,
  backFrequency,
  legsCrouching,
  legsKneeling,
  legsNoSupport,
  legsStrength,
  legsDuration,
  legsFrequency,
  vibration,
  lowTemperature,
  softTissueCompression,
  impactStress,
  gloveIssue,

  timeActivity,
}) {
  const res = await axios.put(
    `${API_URL}/stepevaluation/${evaluationId}`,
    {
      id: evaluationId,
      type: evaluationType,
      right_hand: rightHandResults,
      left_hand: leftHandResults,
      right_elbow: rightElbowResults,
      left_elbow: leftElbowResults,
      right_shoulder: rightShoulderResults,
      left_shoulder: leftShoulderResults,
      legs: legsResults,
      neck: neckResults,
      back: backResults,
      npr: totalResults,
      lift,
      pull,
      load,
      push,
      step_id: stepId,
      activity_id: activityId,
      company_id: companyId,
      ghe_id: gheId,
      job_position_id: jobPositionId,
      sector_id: sectorId,
      work_area_id: areaId,
      workstation_id: workstationId,
      left_hand_extension: leftHandExtension,
      left_hand_flexion: leftHandFlexion,
      left_hand_ulnar: leftHandUlnar,
      left_hand_radial: leftHandRadial,
      right_hand_extension: rightHandExtension,
      right_hand_flexion: rightHandFlexion,
      right_hand_ulnar: rightHandUlnar,
      right_hand_radial: rightHandRadial,
      left_hand_grip_strength: leftHandGripStrength,
      left_hand_grip_duration: leftHandGripDuration,
      left_hand_grip_frequency: leftHandGripFrequency,
      left_hand_hold_strength: leftHandHoldStrength,
      left_hand_hold_duration: leftHandHoldDuration,
      left_hand_hold_frequency: leftHandHoldFrequency,
      left_hand_clamp_strength: leftHandClampStrength,
      left_hand_clamp_duration: leftHandClampDuration,
      left_hand_clamp_frequency: leftHandClampFrequency,
      right_hand_grip_strength: rightHandGripStrength,
      right_hand_grip_duration: rightHandGripDuration,
      right_hand_grip_frequency: rightHandGripFrequency,
      right_hand_hold_strength: rightHandHoldStrength,
      right_hand_hold_duration: rightHandHoldDuration,
      right_hand_hold_frequency: rightHandHoldFrequency,
      right_hand_clamp_strength: rightHandClampStrength,
      right_hand_clamp_duration: rightHandClampDuration,
      right_hand_clamp_frequency: rightHandClampFrequency,
      left_elbow_rotation: leftElbowRotation,
      right_elbow_rotation: rightElbowRotation,
      left_elbow_extension: leftElbowExtension,
      right_elbow_extension: rightElbowExtension,
      left_elbow_strength: leftElbowStrength,
      left_elbow_duration: leftElbowDuration,
      left_elbow_frequency: leftElbowFrequency,
      right_elbow_strength: rightElbowStrength,
      right_elbow_duration: rightElbowDuration,
      right_elbow_frequency: rightElbowFrequency,
      both_elbow_strength: bothElbowStrength,
      both_elbow_duration: bothElbowDuration,
      both_elbow_frequency: bothElbowFrequency,
      left_shoulder_flexion: leftShoulderFlexion,
      right_shoulder_flexion: rightShoulderFlexion,
      left_shoulder_abduction: leftShoulderAbduction,
      right_shoulder_abduction: rightShoulderAbduction,
      left_shoulder_elevation: leftShoulderElevation,
      right_shoulder_elevation: rightShoulderElevation,
      left_shoulder_strength: leftShoulderStrength,
      left_shoulder_duration: leftShoulderDuration,
      left_shoulder_frequency: leftShoulderFrequency,
      right_shoulder_strength: rightShoulderStrength,
      right_shoulder_duration: rightShoulderDuration,
      right_shoulder_frequency: rightShoulderFrequency,
      both_shoulder_strength: bothShoulderStrength,
      both_shoulder_duration: bothShoulderDuration,
      both_shoulder_frequency: bothShoulderFrequency,
      neck_flexion: neckFlexion,
      neck_side_flexion: neckSideFlexion,
      neck_extension: neckExtension,
      neck_rotation: neckRotation,
      neck_strength: neckStrength,
      neck_duration: neckDuration,
      neck_frequency: neckFrequency,
      back_flexion: backFlexion,
      back_rotation: backRotation,
      back_side_flexion: backSideFlexion,
      back_extension: backExtension,
      back_no_support: backNoSupport,
      back_strength: backStrength,
      back_duration: backDuration,
      back_frequency: backFrequency,
      legs_crouching: legsCrouching,
      legs_kneeling: legsKneeling,
      legs_no_support: legsNoSupport,
      legs_strength: legsStrength,
      legs_duration: legsDuration,
      legs_frequency: legsFrequency,
      vibration,
      low_temperature: lowTemperature,
      soft_tissue_compression: softTissueCompression,
      impact_stress: impactStress,
      glove_issue: gloveIssue,
      time_activity: timeActivity,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEvaluations() {
  const res = await axios.get(
    `${API_URL}/stepevaluation`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllEvaluationsByStepId(step_id) {
  const res = await axios.get(
    `${API_URL}/stepevaluation?step_id=${step_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEvaluationsByCompanyId(company_id) {
  const res = await axios.get(
    `${API_URL}/stepevaluation?company_id=${company_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEvaluationsByStepIdAndType(type, step_id) {
  const res = await axios.get(
    `${API_URL}/stepevaluation?step_id=${step_id}&type=${type}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllEvaluationsByFilter(
  ghe_id,
  company_id,
  work_area_id,
  sector_id
) {
  if (company_id) {
    const res = await axios.get(
      // prettier-ignore
      `${API_URL}/stepevaluation?${ghe_id && `ghe_id=${ghe_id}`}${company_id && `&company_id=${company_id}`}${work_area_id && `&work_area_id=${work_area_id}`}${sector_id && `&sector_id=${sector_id}`}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  }
  return [];
}

async function uploadEvaluationImage(file, step_evaluation_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/stepevaluationimage/${step_evaluation_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllEvaluationsImages(step_evaluation_id) {
  const res = await axios.get(
    `${API_URL}/stepevaluationimage?step_evaluation_id=${step_evaluation_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllEvaluationsImage() {
  const res = await axios.get(
    `${API_URL}/stepevaluationimage`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteEvaluationImage(step_evaluation_image_id) {
  const res = await axios.delete(
    `${API_URL}/stepevaluationimage/${step_evaluation_image_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function deleteEvaluation(id) {
  const res = await axios.delete(
    `${API_URL}/stepevaluation/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createStepEvaluation,
  getAllEvaluationsByStepId,
  uploadEvaluationImage,
  getAllEvaluationsByStepIdAndType,
  editStepEvaluation,
  getAllEvaluationsImages,
  deleteEvaluationImage,
  getAllEvaluations,
  getAllEvaluationsByCompanyId,
  getAllEvaluationsByFilter,
  getAllEvaluationsImage,
  deleteEvaluation,
};
