import React from "react";
import GenericChart from "./Charts/GenericChart";
import PeopleChart from "./Charts/PeopleChart";

export default function CensoCharts({ chartData }) {
  if (!chartData?.total) {
    return <></>;
  }

  return (
    <div
      style={{
        height: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <div style={{ padding: "20px" }}>
        <h5>
          Total de pessoas: <strong>{chartData.total}</strong>
        </h5>
      </div>
      <PeopleChart
        title="Total por área"
        data={chartData.totalPorArea}
        chartType="Bar"
        colors={["#3498DB", "#E74C3C"]}
        fullWidth={true}
        title1={"Responderam"}
        title2={"Não responderam"}
      />
      <PeopleChart
        title="Total de autorização por área"
        data={chartData.autorizacaoPorArea}
        chartType="Bar"
        colors={["#3498DB", "#E74C3C"]}
        fullWidth={true}
        title1={"Autorizaram"}
        title2={"Não autorizaram"}
      />
      <GenericChart
        title="Tempo de empresa"
        data={chartData.tempoEmpresa}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Idade"
        data={chartData.idade}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Gênero"
        data={chartData.genero}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="IMC"
        data={chartData.imc}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Pescoço"
        data={chartData.desconfortoNeck}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />
      <GenericChart
        title="Tempo de Desconforto Pescoço"
        data={chartData.desconfortoNeckDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Pescoço"
        data={chartData.desconfortoNeckImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Ombros"
        data={chartData.desconfortoShoulders}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Ombros"
        data={chartData.desconfortoShouldersDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Ombros"
        data={chartData.desconfortoShouldersImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Braços"
        data={chartData.desconfortoArms}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Braços"
        data={chartData.desconfortoArmsDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Braços"
        data={chartData.desconfortoArmsImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Cotovelos"
        data={chartData.desconfortoElbows}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Cotovelos"
        data={chartData.desconfortoElbowsDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Cotovelos"
        data={chartData.desconfortoElbowsImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Antebraços"
        data={chartData.desconfortoForearms}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Antebraços"
        data={chartData.desconfortoForearmsDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Antebraços"
        data={chartData.desconfortoForearmsImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Punhos"
        data={chartData.desconfortoWrists}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Punhos"
        data={chartData.desconfortoWristsDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Punhos"
        data={chartData.desconfortoWristsImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Mãos e Dedos"
        data={chartData.desconfortoHandsAndFingers}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Mãos e Dedos"
        data={chartData.desconfortoHandsAndFingersDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Mãos e Dedos"
        data={chartData.desconfortoHandsAndFingersImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Coluna Torácica"
        data={chartData.desconfortoThoracicSpine}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Coluna Torácica"
        data={chartData.desconfortoThoracicSpineDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Coluna Torácica"
        data={chartData.desconfortoThoracicSpineImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Coluna Lombar"
        data={chartData.desconfortoLumbarSpine}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Coluna Lombar"
        data={chartData.desconfortoLumbarSpineDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Coluna Lombar"
        data={chartData.desconfortoLumbarSpineImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Desconforto Quadril"
        data={chartData.desconfortoHip}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Quadril"
        data={chartData.desconfortoHipDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Quadril"
        data={chartData.desconfortoHipImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Coxa"
        data={chartData.desconfortoThigh}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Coxa"
        data={chartData.desconfortoThighDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Coxa"
        data={chartData.desconfortoThighImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Perna"
        data={chartData.desconfortoLeg}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Perna"
        data={chartData.desconfortoLegDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Perna"
        data={chartData.desconfortoLegImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Joelho"
        data={chartData.desconfortoKnee}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Joelho"
        data={chartData.desconfortoKneeDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Joelho"
        data={chartData.desconfortoKneeImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Desconforto Tornozelo e Pé"
        data={chartData.desconfortoAnkleAndFoot}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Tempo de Desconforto Tornozelo e Pé"
        data={chartData.desconfortoAnkleAndFootDuration}
        chartType="Bar"
        colors={["#3498DB"]}
      />
      <GenericChart
        title="Melhora de Desconforto Tornozelo e Pé"
        data={chartData.desconfortoAnkleAndFootImprovement}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Uso de Medicamentos para Dor"
        data={chartData.medicamento}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Acompanhamento Fisioterapêutico"
        data={chartData.acompanhamentoFisioterapeutico}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Ausência do Trabalho por Desconforto"
        data={chartData.ausentarDesconforto}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Causas de Desconforto I"
        data={chartData.problemas.slice(0, 8)}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />
      <GenericChart
        title="Causas de Desconforto II"
        data={chartData.problemas.slice(8, 16)}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />
      <GenericChart
        title="Causas de Desconforto III"
        data={chartData.problemas.slice(16, 25)}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Força em Equipamentos e Ferramentas"
        data={chartData.forcaEquipamento}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Postura no Trabalho"
        data={chartData.postura}
        chartType="Bar"
        colors={["#3498DB"]}
        fullWidth={true}
      />

      <GenericChart
        title="Pessoal Suficiente"
        data={chartData.pessoalSuficiente}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Liberdade para Pausas"
        data={chartData.liberdadeBanheiro}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Exigência de Produtividade/Meta"
        data={chartData.demandaProdutividade}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Participação na Elaboração/Modificação do Trabalho"
        data={chartData.participaMudancas}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Pausas extras no Trabalho"
        data={chartData.outrasPausas}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Quantas pausas"
        data={chartData.quantasPausas}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Rodízio de Atividades"
        data={chartData.rodizio}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Cansaço Mental Relacionado ao Trabalho"
        data={chartData.cansacoMental}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Cansaço Físico Relacionado ao Trabalho"
        data={chartData.cansacoFisico}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Tempo Suficiente para Tarefas"
        data={chartData.tempoSuficiente}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Atenção Constante no Trabalho"
        data={chartData.atencaoConstante}
        chartType="Bar"
        colors={["#3498DB"]}
      />

      <GenericChart
        title="Satisfação com a Situação de Trabalho"
        data={chartData.satisfacaoTrabalho}
        chartType="Bar"
        colors={["#3498DB"]}
      />
    </div>
  );
}
