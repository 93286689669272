import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { editOrCreatePGR } from "api/PGRApi";
import { useLoginContext } from "context/LoginContext";

export default function PGRTextField({
  value,
  id,
  type,
  field,
  array,
  setArray,
  item,
  setOpenBackDrop,
  setMessage,
  setOpen,
  setSeverity,
}) {
  const [textValue, setTextValue] = useState();

  const { isReader } = useLoginContext();

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const handleBlur = async (e) => {
    if (e.target.value === "") {
      return;
    }
    array.map((arrayItem) => {
      if (arrayItem.improvement_id === id && id !== undefined) {
        arrayItem[field] = e.target.value;
      }
      return arrayItem;
    });

    setArray(array);

    if (!id) {
      const newItem = {
        improvement_action: item.improvement_action,
        improvement_responsible_name: item.improvement_responsible_name,
        improvement_target_date: item.improvement_target_date,
        improvement_final_date: item.improvement_final_date,
        improvement_status: item.improvement_status,
        id: undefined,
        cause_id: item.cause_id,
      };

      newItem[field] = e.target.value;

      setOpenBackDrop(true);
      const res = await editOrCreatePGR({ ...newItem });
      setOpenBackDrop(false);
      setMessage("Melhoria criada com sucesso");
      setSeverity("success");
      setOpen(true);

      array.map((arrayItem) => {
        if (arrayItem.no_improvement && arrayItem.cause_id === item.cause_id) {
          arrayItem.improvement_id = res.id;
          arrayItem.improvement_status = res.status;
          arrayItem.no_improvement = false;
        }
        return arrayItem;
      });

      setArray(array);
    } else {
      const newItem = {
        improvement_action: item.improvement_action,
        improvement_responsible_name: item.improvement_responsible_name,
        improvement_target_date: item.improvement_target_date,
        improvement_final_date: item.improvement_final_date,
        improvement_status: item.improvement_status,
        id,
        cause_id: item.cause_id,
      };

      newItem[field] = e.target.value;

      setOpenBackDrop(true);

      await editOrCreatePGR({ ...newItem });

      setOpenBackDrop(false);
      setMessage("Melhoria editada com sucesso");
      setSeverity("success");
      setOpen(true);
    }

    if (e.target.value !== value) {
      // await editOrCreatePGR();

      console.log("Call The Function");
      console.log(e.target.value);
      console.log(field);
      console.log(id);
    }
  };

  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  if (isReader) {
    return <TextField disabled={true} value={textValue} multiline />;
  }

  if (field === "improvement_status" && textValue !== undefined) {
    return (
      <TextField
        disabled={item.no_improvement && !item.improvement_status}
        fullWidth
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        type={type}
        value={textValue}
        select
      >
        <option value={"Concluído"}>{"Concluído"}</option>
        <option value={"Em andamento"}>{"Em andamento"}</option>
        <option value={"Atrasado"}>{"Atrasado"}</option>
        <option value={"Esperar decisão"}>{"Esperar decisão"}</option>
        <option value={"Requer avaliação mais profunda"}>
          {"Requer avaliação mais profunda"}
        </option>
        <option value={"Inviável"}>{"Inviável"}</option>
      </TextField>
    );
  }

  return (
    <>
      {type !== "date" ? (
        <TextField
          disabled={
            item.no_improvement &&
            !item.improvement_action &&
            field === "improvement_responsible_name"
          }
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          type={type}
          value={textValue}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
        />
      ) : (
        <TextField
          disabled={item.no_improvement && !item.improvement_action}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          type={"date"}
          value={textValue}
        />
      )}
    </>
  );
}
