import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createImprovement(
  number,
  title,
  cost,
  control,
  priority,
  final_date,
  target_date,
  status,
  responsible_name,
  company_id,
  step_id
) {
  const res = await axios.post(
    `${API_URL}/improvement`,
    {
      number,
      title,
      cost,
      control,
      priority,
      final_date,
      target_date,
      status,
      responsible_name,
      company_id,
      step_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editImprovement(
  number,
  title,
  cost,
  control,
  priority,
  final_date,
  target_date,
  status,
  responsible_name,
  improvement_id
) {
  const res = await axios.put(
    `${API_URL}/improvement/${improvement_id}`,
    {
      number,
      title,
      cost,
      control,
      priority,
      final_date,
      target_date,
      status,
      responsible_name,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editImprovementStatus(improvement_id, status) {
  const res = await axios.put(
    `${API_URL}/improvement/status/${improvement_id}`,
    {
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllImprovementsByStepId(step_id) {
  const res = await axios.get(
    `${API_URL}/improvement?step_id=${step_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllImprovements() {
  const res = await axios.get(
    `${API_URL}/improvement`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllImprovementsByCompanyId(company_id) {
  const res = await axios.get(
    `${API_URL}/improvement?company_id=${company_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllImprovementsByResponsibleName(responsible_name) {
  const res = await axios.get(
    `${API_URL}/improvement?responsible_name=${responsible_name}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function deleteImprovement(improvement_id) {
  const res = await axios.delete(
    `${API_URL}/improvement/${improvement_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createImprovement,
  getAllImprovementsByStepId,
  deleteImprovement,
  editImprovement,
  getAllImprovementsByResponsibleName,
  getAllImprovementsByCompanyId,
  editImprovementStatus,
  getAllImprovements,
};
