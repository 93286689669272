import React, { useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllActivitiesByWorkstationId } from "api/activityApi";
import ActivityTreeComponent from "./ActivityTreeComponent";
import { makeStyles } from "@material-ui/core/styles";
import StyledTreeItem from "./StyledTreeItem";

export default function WorkstationTreeComponent({
  workstationName,
  workstationId,
  jobPositionColor,
  setJobPositionColor,
  evaluationList,
  setOpenBackDrop,
}) {
  const [workstationList, setWorkstationList] = useState([]);
  const [workstationColor, setWorkstationColor] = useState("#57c9d5");

  const useStyles = makeStyles({
    root: {
      color: workstationColor,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (workstationColor) {
      if (jobPositionColor === "#57c9d5" && workstationColor === "#57c9d5")
        setJobPositionColor("#57c9d5");
      if (jobPositionColor === "#57c9d5" && workstationColor === "#5cb860")
        setJobPositionColor("#5cb860");
      if (jobPositionColor === "#57c9d5" && workstationColor === "#ffa21a")
        setJobPositionColor("#ffa21a");
      if (jobPositionColor === "#57c9d5" && workstationColor === "#af2cc5")
        setJobPositionColor("#af2cc5");
      if (jobPositionColor === "#57c9d5" && workstationColor === "#ef5350")
        setJobPositionColor("#ef5350");
      if (jobPositionColor === "#5cb860" && workstationColor === "#ffa21a")
        setJobPositionColor("#ffa21a");
      if (jobPositionColor === "#5cb860" && workstationColor === "#ef5350")
        setJobPositionColor("#ef5350");
      if (jobPositionColor === "#ffa21a" && workstationColor === "#ef5350")
        setJobPositionColor("#ef5350");
      if (workstationColor === "#af2cc5") setJobPositionColor("#af2cc5");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationColor]);

  useEffect(() => {
    setOpenBackDrop(true);
    async function fetchData() {
      if (workstationId !== "" && workstationId !== undefined)
        try {
          const data = await getAllActivitiesByWorkstationId(workstationId);

          setWorkstationList(data);
          setOpenBackDrop(false);
        } catch (err) {
          setOpenBackDrop(false);
        }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationId]);

  return (
    <StyledTreeItem
      tooltipTitle="Posto de trabalho"
      nodeId={workstationId}
      label={workstationName}
      className={classes.root}
    >
      {workstationList &&
        workstationList.map(({ name, id }) => {
          return (
            <TreeView
              key={id}
              defaultExpanded={[`${id}`]}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              multiSelect
            >
              <ActivityTreeComponent
                setOpenBackDrop={setOpenBackDrop}
                evaluationList={evaluationList}
                key={id}
                activityName={name}
                activityId={id}
                workstationColor={workstationColor}
                setWorkstationColor={setWorkstationColor}
              />
            </TreeView>
          );
        })}
    </StyledTreeItem>
  );
}
