import React, { useEffect, useState } from "react";
import { getAllGheBySectorId } from "api/gheApi";
import GheTreeComponent from "./GheTreeComponent";
import StyledTreeItem from "./StyledTreeItem";

export default function SectorTreeComponent({
  sectorName,
  sectorId,
  companyId,
  evaluationList,
  setOpenBackDrop,
}) {
  const [gheList, setGheList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (sectorId !== "" && sectorId !== undefined)
        try {
          const data = await getAllGheBySectorId(sectorId);
          setGheList(data);
        } catch (err) {}
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId]);

  return (
    <StyledTreeItem
      selected="3"
      tooltipTitle={"Setor"}
      nodeId={sectorId}
      label={sectorName}
    >
      {gheList &&
        gheList.map(({ name, id }) => {
          return (
            <GheTreeComponent
              setOpenBackDrop={setOpenBackDrop}
              evaluationList={evaluationList}
              companyId={companyId}
              key={id}
              gheName={name}
              gheId={id}
            />
          );
        })}
    </StyledTreeItem>
  );
}
