// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownMenuControl({ control, setControl }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setControl(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "15px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">Controle</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={control}
        onChange={handleChange}
        className={classes2.selectEmpty}
      >
        <MenuItem value={"Eliminar risco"}>{"Eliminar risco"}</MenuItem>
        <MenuItem value={"Mudança no local para reduzir risco"}>
          {"Mudança no local para reduzir risco"}
        </MenuItem>
        <MenuItem value={"Reduzir o tempo de esposição ao risco"}>
          {"Reduzir o tempo de esposição ao risco"}
        </MenuItem>
        <MenuItem value={"Implementar práticas para reduzir o risco"}>
          {"Implementar práticas para reduzir o risco"}
        </MenuItem>
        <MenuItem value={"Providenciar programas de EPI, ginástica ou outros"}>
          {"Providenciar programas de EPI, ginástica ou outros"}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
