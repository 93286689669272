import React, { useEffect, useState } from "react";
// @material-ui/core components// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  createActivity,
  editActivity,
  getAllActivitiesByWorkstationId,
} from "../../api/activityApi.js";
import ActivityComponentList from "./ActivityComponentList.js";
import { useLoginContext } from "context/LoginContext.js";

export default function WorkstationRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  workstationId,
  setRefreshActivityId,
  gheId,
  companyId,
  sectorId,
  workAreaId,
}) {
  const [activityArray, setActivityArray] = useState([]);
  const [activityId, setActivityId] = useState("");
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { isReader } = useLoginContext();

  const handleSubmit = async () => {
    if (name === "") {
      setMessage("Favor preencher o nome");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await createActivity(
          name,
          workstationId,
          gheId,
          companyId,
          sectorId,
          workAreaId
        );
        setRefreshActivityId(true);
        setMessage("Atividade criada com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };
  const handleEdit = async () => {
    if (name === "") {
      setMessage("Favor preencher o nome");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        await editActivity(name, activityId);
        setMessage("Atividade editada com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshActivityId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllActivitiesByWorkstationId(workstationId);
      setActivityArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  useEffect(() => {
    refresh ? setRefreshActivityId(true) : setRefreshActivityId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <CardBody>
      <ActivityComponentList
        activities={activityArray}
        setName={setName}
        setRefresh={setRefresh}
        setEdit={setEdit}
        setActivityId={setActivityId}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            id="name"
            label="Nome da Atividade"
            name="name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <RegularButton
              disabled={isReader}
              color={edit ? "warning" : "success"}
              onClick={edit ? handleEdit : handleSubmit}
              variant="contained"
            >
              {edit ? "Editar" : "Criar Atividade"}
            </RegularButton>
          </div>
        </GridItem>
      </GridContainer>
    </CardBody>
  );
}
