// material-ui components
import React from "react";

export default function BriefAndBestNPRPriorityCardEvaluation({
  value,
  priority,
  color,
}) {
  return (
    <div
      style={{
        marginTop: "25px",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "block",
      }}
    >
      <h3>NPR:</h3>

      <div
        style={{
          backgroundColor: `${color}`,
          width: "90px",
          margin: "auto",
          borderRadius: "4px",
          color: `white`,
          textAlign: "center",
          alignItems: "center",

          fontWeight: "bold",
        }}
      >
        <h3>{value}</h3>
      </div>
      <div
        style={{
          color: `${color}`,

          fontWeight: "bold",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>{priority}</h3>
      </div>
    </div>
  );
}
