import React, { createContext, useState, useContext } from "react";
const ParamsContext = createContext();

export default function LoginProvider({ children }) {
  const [isReader, setIsReader] = useState(false);

  return (
    <ParamsContext.Provider
      value={{
        isReader,
        setIsReader,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
}

export function useLoginContext() {
  const context = useContext(ParamsContext);
  const { isReader, setIsReader } = context;
  return {
    isReader,
    setIsReader,
  };
}
