import React, { useState } from "react";
// @material-ui/core components
// core components
import { TextField } from "@material-ui/core";

import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { createStepTool, uploadStepToolFile } from "../../api/stepToolApi.js";
import { useForm } from "react-hook-form";
import { useLoginContext } from "context/LoginContext.js";
import StepToolFile from "./StepToolFile.js";

export default function StepToolEvaluation({
  stepId,
  setOpen,
  setMessage,
  setSeverity,
  setRefresh,
  setDescription,
  description,
  name,
  setName,
  setOpenBackDrop,
  setEditFile,
}) {
  const { handleSubmit, register, reset } = useForm();
  const [file, setFile] = useState(null);

  const { isReader } = useLoginContext();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await createStepTool(data.description, data.name, stepId);
      if (res) {
        await uploadStepToolFile(file, res.id);
      }
      setMessage("Ferramenta criada com sucesso");
      setDescription("");
      setName("");
      setSeverity("success");
      setOpen(true);
      setRefresh(true);
      reset();
      setFile();
    } catch (err) {
      setMessage("Ferramenta já cadastrada com esse nome");
      setSeverity("error");
      setOpen(true);
    }
  });

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={4} sm={12} md={4}>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  id="name"
                  label="Nome"
                  inputRef={register}
                  name="name"
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={8} sm={12} md={8}>
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  id="description"
                  label="Descrição"
                  inputRef={register}
                  name="description"
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                />
              </GridItem>
              <StepToolFile
                setOpenBackDrop={setOpenBackDrop}
                setEditFile={setEditFile}
                step_id={stepId}
                setOpen={setOpen}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setOutRefresh={setRefresh}
                file={file}
                setFile={setFile}
              />
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  <RegularButton
                    disabled={isReader || !file}
                    color={"success"}
                    type="submit"
                    variant="contained"
                  >
                    Criar Ferramenta
                  </RegularButton>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </>
  );
}
