import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function PieChartPriority({ a, b, c, d }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(-1);

  const [data, setData] = useState([
    { title: "A", value: a ? a : 0, color: "#57c9d5" },
    { title: "B", value: b ? b : 0, color: "#5cb860" },
    { title: "C", value: c ? c : 0, color: "#ffa21a" },
    { title: "D", value: d ? d : 0, color: "#ef5350" },
  ]);

  useEffect(() => {
    setData([
      { title: "A", value: a ? a : 0, color: "#57c9d5" },
      { title: "B", value: b ? b : 0, color: "#5cb860" },
      { title: "C", value: c ? c : 0, color: "#ffa21a" },
      { title: "D", value: d ? d : 0, color: "#ef5350" },
    ]);
  }, [a, b, c, d]);

  return (
    <div style={{ marginBottom: "30px", padding: "10px" }}>
      <h5
        className={classes.pieTitle}
        style={{ justifyContent: "center", display: "flex" }}
      >
        Prioridade
      </h5>
      <PieChart
        radius={PieChart.defaultProps.radius - 6}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        segmentsShift={(index) => (index === selected ? 6 : 1)}
        onClick={(event, index) => {
          setSelected(index === selected ? undefined : index);
        }}
        labelStyle={{
          fontSize: "6px",
          fontWeight: "bold",
          fontColor: "white",
        }}
        label={({ dataEntry }) =>
          dataEntry.value !== 0 ? dataEntry.value : ""
        }
        data={data}
      />
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#57c9d5" }}>
        A (Alto impacto, fácil de implementar)
      </p>
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#5cb860" }}>
        B (Baixo impacto, fácil de implementar)
      </p>{" "}
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ffa21a" }}>
        C (Alto impacto, difícil de implementar){" "}
      </p>{" "}
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ef5350" }}>
        {" "}
        D (Baixo impacto, difícil de implementar){" "}
      </p>
    </div>
  );
}
