import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownMenuDanger({ control, setControl }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setControl(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "15px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">
        Perigo ou fator de risco
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={control}
        onChange={handleChange}
        className={classes2.selectEmpty}
      >
        <MenuItem value={""}>{""}</MenuItem>
        <MenuItem
          value={
            "Ambiental - Condições ambientais de trabalho quanto a temperatura, velocidade do ar e umidade que causam desconforto aos trabalhadores"
          }
        >
          {
            "Ambiental - Condições ambientais de trabalho quanto a temperatura, velocidade do ar e umidade que causam desconforto aos trabalhadores"
          }
        </MenuItem>
        <MenuItem
          value={"Ambiental - Condições de trabalho com Iluminação inadequada"}
        >
          {"Ambiental - Condições de trabalho com Iluminação inadequada"}
        </MenuItem>
        <MenuItem
          value={
            "Cognitivo - Possíveis situações de sobrecarga de trabalho mental"
          }
        >
          {"Cognitivo - Possíveis situações de sobrecarga de trabalho mental"}
        </MenuItem>
        <MenuItem
          value={
            "Estressores Físicos - Compressão de partes do corpo por superfícies rígidas ou com quinas"
          }
        >
          {
            "Estressores Físicos - Compressão de partes do corpo por superfícies rígidas ou com quinas"
          }
        </MenuItem>
        <MenuItem
          value={
            "Estressores Físicos - Movimentos de impacto com membro superior"
          }
        >
          {"Estressores Físicos - Movimentos de impacto com membro superior"}
        </MenuItem>
        <MenuItem value={"Estressores Físicos - Vibração de corpo inteiro"}>
          {"Estressores Físicos - Vibração de corpo inteiro"}
        </MenuItem>
        <MenuItem value={"Estressores Físicos - Vibração em mãos e braços"}>
          {"Estressores Físicos - Vibração em mãos e braços"}
        </MenuItem>
        <MenuItem
          value={
            "Força -  Exigência de esforço físico intenso coluna vertebral"
          }
        >
          {"Força -  Exigência de esforço físico intenso coluna vertebral"}
        </MenuItem>
        <MenuItem
          value={
            "Força -  Exigência de esforço físico intenso membros inferiores"
          }
        >
          {"Força -  Exigência de esforço físico intenso membros inferiores"}
        </MenuItem>
        <MenuItem
          value={
            "Força - Exigência de esforço físico intenso membros superiores"
          }
        >
          {"Força - Exigência de esforço físico intenso membros superiores"}
        </MenuItem>
        <MenuItem value={"Força -  Manuseio de ferramentas pesadas"}>
          {"Força -  Manuseio de ferramentas pesadas"}
        </MenuItem>
        <MenuItem
          value={
            'Força -  Manuseio ou movimentação de cargas e volumes sem pega ou com pega "pobre"'
          }
        >
          {
            'Força -  Manuseio ou movimentação de cargas e volumes sem pega ou com pega "pobre"'
          }
        </MenuItem>
        <MenuItem value={"Força - Ação de puxar e empurrar cargas ou volumes"}>
          {"Força - Ação de puxar e empurrar cargas ou volumes"}
        </MenuItem>
        <MenuItem
          value={
            "Mobiliário - Posto de trabalho e/ou mobiliário inadequados e/ou sem meio de regulagens e ajustes"
          }
        >
          {
            "Mobiliário - Posto de trabalho e/ou mobiliário inadequados e/ou sem meio de regulagens e ajustes"
          }
        </MenuItem>
        <MenuItem
          value={
            "Mobiliário - Presença de reflexos em superfície que cause desconforto ou prejudique a visualização"
          }
        >
          {
            "Mobiliário - Presença de reflexos em superfície que cause desconforto ou prejudique a visualização"
          }
        </MenuItem>
        <MenuItem
          value={
            "Organizacional - Necessidade de manter ritmos intensos de trabalho"
          }
        >
          {"Organizacional - Necessidade de manter ritmos intensos de trabalho"}
        </MenuItem>
        <MenuItem
          value={
            "Organizacional - Possíveis situações de estresse organizacional"
          }
        >
          {"Organizacional - Possíveis situações de estresse organizacional"}
        </MenuItem>
        <MenuItem
          value={
            "Organizacional - Trabalho com utilização rigorosa de metas de produção, ou remunerado por produção"
          }
        >
          {
            "Organizacional - Trabalho com utilização rigorosa de metas de produção, ou remunerado por produção"
          }
        </MenuItem>
        <MenuItem
          value={
            "Organizacional - Trabalho noturno ou com necessidade de variação de turnos"
          }
        >
          {
            "Organizacional - Trabalho noturno ou com necessidade de variação de turnos"
          }
        </MenuItem>
        <MenuItem
          value={"Organizacional - Trabalho realizado sem pausas para descanso"}
        >
          {"Organizacional - Trabalho realizado sem pausas para descanso"}
        </MenuItem>
        <MenuItem
          value={
            "Postura - Exigência de condições inadequadas para a coluna cervical"
          }
        >
          {
            "Postura - Exigência de condições inadequadas para a coluna cervical"
          }
        </MenuItem>
        <MenuItem
          value={
            "Postura - Exigência de condições inadequadas para a coluna lombar"
          }
        >
          {"Postura - Exigência de condições inadequadas para a coluna lombar"}
        </MenuItem>
        <MenuItem
          value={
            "Postura - Exigência de condições inadequadas para os membros inferiores"
          }
        >
          {
            "Postura - Exigência de condições inadequadas para os membros inferiores"
          }
        </MenuItem>
        <MenuItem
          value={
            "Postura - Exigência de condições inadequadas para os membros superiores"
          }
        >
          {
            "Postura - Exigência de condições inadequadas para os membros superiores"
          }
        </MenuItem>
        <MenuItem value={"Postura - Postura em pé por longos períodos"}>
          {"Postura - Postura em pé por longos períodos"}
        </MenuItem>
        <MenuItem value={"Postura - Postura sentada por longos períodos"}>
          {"Postura - Postura sentada por longos períodos"}
        </MenuItem>

        <MenuItem
          value={
            "Repetição - Execução de movimentos repetitivos coluna vertebral"
          }
        >
          {"Repetição - Execução de movimentos repetitivos coluna vertebral"}
        </MenuItem>
        <MenuItem
          value={
            "Repetição - Execução de movimentos repetitivos membro inferior"
          }
        >
          {"Repetição - Execução de movimentos repetitivos membro inferior"}
        </MenuItem>
        <MenuItem
          value={
            "Repetição - Execução de movimentos repetitivos membro superior"
          }
        >
          {"Repetição - Execução de movimentos repetitivos membro superior"}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
