import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Card from "components/Card/Card";
import ChartLoader from "components/DashboardView/ChartLoader";

const useStyles = makeStyles(styles);

export default function PieChar({
  title,
  empty,
  low,
  high,
  veryHigh,
  moderate,
  refresh,
  setRefresh,
  loading,
}) {
  const classes = useStyles();

  const [data, setData] = useState([
    { title: "Nenhum", value: empty ? empty : 0, color: "#57c9d5" },
    { title: "Baixo", value: low ? low : 0, color: "#5cb860" },
    { title: "Moderado", value: moderate ? moderate : 0, color: "#ffa21a" },
    { title: "Alto", value: high ? high : 0, color: "#ef5350" },
    { title: "Altíssimo", value: veryHigh ? veryHigh : 0, color: "#af2cc5" },
  ]);
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    setData([
      { title: "Nenhum", value: empty ? empty : 0, color: "#57c9d5" },
      { title: "Baixo", value: low ? low : 0, color: "#5cb860" },
      { title: "Moderado", value: moderate ? moderate : 0, color: "#ffa21a" },
      { title: "Alto", value: high ? high : 0, color: "#ef5350" },
      { title: "Altíssimo", value: veryHigh ? veryHigh : 0, color: "#af2cc5" },
    ]);
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, empty, low, high, veryHigh, moderate]);

  if (loading) {
    return <ChartLoader />;
  }

  return (
    <Card>
      <div style={{ marginBottom: "10px", padding: "10px" }}>
        <h5
          className={classes.pieTitle}
          style={{ justifyContent: "center", display: "flex" }}
        >
          {title}
        </h5>
        <PieChart
          radius={PieChart.defaultProps.radius - 6}
          segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
          segmentsShift={(index) => (index === selected ? 6 : 1)}
          onClick={(event, index) => {
            setSelected(index === selected ? undefined : index);
          }}
          labelStyle={{
            fontSize: "6px",
            fontWeight: "bold",
            fontColor: "white",
          }}
          label={({ dataEntry }) =>
            dataEntry.value !== 0 ? dataEntry.value : ""
          }
          data={data}
        />
      </div>
    </Card>
  );
}
