import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createCauseImprovement(cause_id, improvement_id) {
  const res = await axios.post(
    `${API_URL}/causeimprovement`,
    {
      cause_id,
      improvement_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteCauseImprovement(cause_improvement_id) {
  const res = await axios.delete(
    `${API_URL}/causeimprovement/${cause_improvement_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteByCauseImprovementId(cause_id, improvement_id) {
  const res = await axios.delete(
    `${API_URL}/causeimprovement/${cause_id}/${improvement_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllCauseImprovementsByImprovementId(improvement_id) {
  const res = await axios.get(
    `${API_URL}/causeimprovement?improvement_id=${improvement_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllCauseImprovementsByCauseId(cause_id) {
  const res = await axios.get(
    `${API_URL}/causeimprovement?cause_id=${cause_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createCauseImprovement,
  deleteCauseImprovement,
  getAllCauseImprovementsByImprovementId,
  deleteByCauseImprovementId,
  getAllCauseImprovementsByCauseId,
};
