import React from "react";
import { Table, TableBody, TableCell, TableRow, Grid } from "@mui/material";

const TabelaRisco = () => {
  return (
    <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
      <Grid item xs={12}>
        <div style={{ overflowX: "auto", marginBottom: "16px" }}>
          <Table
            style={{
              tableLayout: "fixed",
              width: "100%",
              minWidth: "600px",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#57c9d5",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  0
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#57c9d5",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  Trivial
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#57b05c",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  0,1 - 9,9
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#57b05c",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  Baixo
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#fc930a",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  10 - 29,9
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#fc930a",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  Moderado
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#eb4945",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  30 - 49,9
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#eb4945",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  Alto
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#9c27b0",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  &gt; 50
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#9c27b0",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  Altíssimo
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
  );
};

export default TabelaRisco;
