import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Button,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AlertCustom from "components/Alerts/AlertCustom";
import Logo from "../../assets/img/logoergo.jpg";
import { getQuestionnaireById } from "api/questionnaireApi";
import { getQuestionnaireByPersonalNumberAndId } from "api/questionnaireApi";
import { submitQuestionnaire } from "api/questionnaireApi";
import Neck from "../../assets/img/neck-quizz.jpg";
import Shoulders from "../../assets/img/shoulders-quizz.jpg";
import Arms from "../../assets/img/arms-quizz.jpg";
import ForeArms from "../../assets/img/forearms-quizz.jpg";
import LumbarSpine from "../../assets/img/lumbarSpine-quizz.jpg";
import Hip from "../../assets/img/hip-quizz.jpg";
import Legs from "../../assets/img/legs-quizz.jpg";
import Fist from "../../assets/img/fist-quizz.jpg";
import Knee from "../../assets/img/knee-quizz.jpg";
import Thigh from "../../assets/img/thigh-quizz.jpg";
import Hands from "../../assets/img/hands-quizz.jpg";
import ThoracicSpine from "../../assets/img/thoracicSpine-quizz.jpg";
import AnkleAndFoot from "../../assets/img/ankleAndFoot-quizz.jpg";
import Elbows from "../../assets/img/elbows-quizz.jpg";

const getImage = (discomfort) => {
  switch (discomfort) {
    case "neck":
      return Neck;
    case "shoulders":
      return Shoulders;
    case "arms":
      return Arms;
    case "elbows":
      return Elbows;
    case "forearms":
      return ForeArms;
    case "wrists":
      return Fist;
    case "hands_and_fingers":
      return Hands;
    case "thoracic_spine":
      return ThoracicSpine;
    case "lumbar_spine":
      return LumbarSpine;
    case "hip":
      return Hip;
    case "thigh":
      return Thigh;
    case "leg":
      return Legs;
    case "knee":
      return Knee;
    case "ankle_and_foot":
      return AnkleAndFoot;
    default:
      return discomfort;
  }
};

const Divider = () => {
  return (
    <div style={{ width: "100%" }}>
      <hr
        style={{
          border: "0.5px solid #999",
          margin: "10px",
          marginBottom: "20px",
        }}
      />
    </div>
  );
};
const DividerNoLine = () => {
  return (
    <div style={{ width: "100%" }}>
      <span
        style={{
          margin: "10px",
          marginBottom: "20px",
        }}
      />
    </div>
  );
};
const DividerNoLineTextField = () => {
  return (
    <div style={{ width: "100%" }}>
      <span
        style={{
          margin: "10px",
          marginBottom: "10px",
        }}
      />
    </div>
  );
};

function translate(discomfort) {
  switch (discomfort) {
    case "neck":
      return "Pescoço";
    case "shoulders":
      return "Ombros";
    case "arms":
      return "Braços";
    case "elbows":
      return "Cotovelos";
    case "forearms":
      return "Antebraços";
    case "wrists":
      return "Punhos";
    case "hands_and_fingers":
      return "Mãos e dedos";
    case "thoracic_spine":
      return "Coluna torácica";
    case "lumbar_spine":
      return "Coluna lombar";
    case "hip":
      return "Quadril";
    case "thigh":
      return "Coxa";
    case "leg":
      return "Perna";
    case "knee":
      return "Joelho";
    case "ankle_and_foot":
      return "Tornozelo e pé";
    default:
      return discomfort;
  }
}

function getQuestionNumber(questionNumber) {
  switch (questionNumber) {
    case "gender":
      return "1";
    case "height":
      return "2";
    case "weight":
      return "3";
    case "feel_discomfort":
      return "4";
    case "pain_medication":
      return "8";
    case "physiotherapy_last_year":
      return "9";
    case "absence_due_to_discomfort":
      return "10";
    case "equipment_force":
      return "12";
    case "work_posture":
      return "13";
    case "adequate_staff":
      return "14";
    case "bathroom_freedom":
      return "15";
    case "productivity_demand":
      return "16 ";
    case "participate_in_changes":
      return "17";
    case "extra_breaks":
      return "18";
    case "break_count":
      return "19";
    case "activity_rotation":
      return "20";
    case "mental_fatigue":
      return "21";
    case "physical_fatigue":
      return "22";
    case "sufficient_time":
      return "23";
    case "constant_attention":
      return "24";
    case "job_satisfaction":
      return "25";
    default:
      return questionNumber;
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      width: "-webkit-fill-available",
      "& > span": {
        width: "-webkit-fill-available !important",
      },
    },
    radio: {
      alignSelf: "flex-start",
    },
  })
);

export default function Questionnaire() {
  const [personal_number, setPersonalNumber] = useState();
  const [companyName, setCompanyName] = useState("");
  const [company, setCompany] = useState({});
  const [questionnaireResponseID, setQuestionnaireResponseID] = useState({});
  const [companyImage, setCompanyImage] = useState({});
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [isAnswered, setIsAnswered] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      const data = await getQuestionnaireById(id);

      if (data) {
        setCompanyName(data.company.name);
        setCompanyImage(data.companyImage);
        setCompany(data.company);
      } else {
        setCompany(false);
      }
    };
    fetchQuestionnaire();
  }, [id]);

  const [suggestion, setSuggestion] = useState([]);

  const [answers, setAnswers] = useState({
    personal_name: "",
    born_date: "",
    company_name: "",
    area_name: "",
    sector_name: "",
    job_position_name: "",
    ghe_name: "",
    ghe_description: "",
    gender: "",
    height: "",
    weight: "",
    feel_discomfort: "",
    pain_medication: "",
    physiotherapy_last_year: "",
    absence_due_to_discomfort: "",
    equipment_force: "",
    work_posture: "",
    adequate_staff: "",
    bathroom_freedom: "",
    productivity_demand: "",
    participate_in_changes: "",
    extra_breaks: "",
    break_count: "",
    activity_rotation: "",
    mental_fatigue: "",
    physical_fatigue: "",
    sufficient_time: "",
    constant_attention: "",
    job_satisfaction: "",
    personal_authorization: true,
    personal_number: "",
    workEnvironmentIssues: {
      issue_adequate_work_post: false,
      issue_inadequate_work_post: false,
      issue_improper_tool: false,
      issue_manual_heavy_load_movements: false,
      issue_forced_body_position: false,
      issue_inadequate_platform: false,
      issue_inadequate_access_ramp_or_stairs: false,
      issue_inadequate_flooring: false,
      issue_difficult_valve_activation: false,
      issue_intense_effort_due_to_machine_maintenance: false,
      issue_excessive_cabinet_or_support_height: false,
      issue_compromised_task_vision: false,
      issue_hard_to_understand_activities: false,
      issue_misaligned_operational_procedure: false,
      issue_overtime_hours: false,
      issue_intense_physical_effort_using_manual_tools: false,
      issue_furniture_issues: false,
      issue_equipment_issues: false,
      issue_environmental_noise_issues: false,
      issue_environmental_temperature_issues: false,
      issue_inadequate_workspace_lighting: false,
      issue_no_opportunity_for_improvement: false,
      issue_constant_displacements: false,
      issue_inadequate_it_systems: false,
      issue_pulling_and_pushing_heavy_loads: false,
    },
    discomfort: {
      neck: { checked: false, level: "0", duration: "", improvement: "" },
      shoulders: { checked: false, level: "0", duration: "", improvement: "" },
      arms: { checked: false, level: "0", duration: "", improvement: "" },
      elbows: { checked: false, level: "0", duration: "", improvement: "" },
      forearms: { checked: false, level: "0", duration: "", improvement: "" },
      wrists: { checked: false, level: "0", duration: "", improvement: "" },
      hands_and_fingers: {
        checked: false,
        level: "0",
        duration: "",
        improvement: "",
      },
      thoracic_spine: {
        checked: false,
        level: "0",
        duration: "",
        improvement: "",
      },
      lumbar_spine: {
        checked: false,
        level: "0",
        duration: "",
        improvement: "",
      },
      hip: { checked: false, level: "0", duration: "", improvement: "" },
      thigh: { checked: false, level: "0", duration: "", improvement: "" },
      leg: { checked: false, level: "0", duration: "", improvement: "" },
      knee: { checked: false, level: "0", duration: "", improvement: "" },
      ankle_and_foot: {
        checked: false,
        level: "0",
        duration: "",
        improvement: "",
      },
    },
  });

  const handleChange = (name, value) => {
    if (name === "discomfort_level") {
      setAnswers((prev) => ({ ...prev, discomfort_level: value }));
    } else {
      setAnswers((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCheckboxChangeWorkEnvironmentIssues = (e) => {
    setAnswers({
      ...answers,
      workEnvironmentIssues: {
        ...answers.workEnvironmentIssues,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleGetQuestionnaireResponse = async (personal_number) => {
    const questionaire = await getQuestionnaireByPersonalNumberAndId(
      id,
      personal_number
    );

    if (questionaire?.id) {
      setIsAnswered(questionaire.is_answered);
      setPersonalNumber(questionaire.personal_number);
      setQuestionnaireResponseID(questionaire.id);
      setAnswers({
        ...answers,
        personal_number: questionaire.personal_number,
        company_id: questionaire.company_id,
        personal_name: questionaire.personal_name,
        company_name: questionaire.company_name,
        area_name: questionaire.area_name,
        sector_name: questionaire.sector_name,
        job_position_name: questionaire.job_position_name,
        ghe_name: questionaire.ghe_name,
        ghe_description: questionaire.ghe_description,
        born_date: new Date(questionaire.born_date).toLocaleDateString(
          "pt-BR",
          { day: "2-digit", month: "2-digit", year: "numeric" }
        ),
      });
    } else {
      setMessage("NP não encontrado");
      setSeverity("error");
      setOpen(true);
    }
  };

  const handleSubmmit = async () => {
    let isFormValid = true;

    Object.entries(answers).forEach(([key, value]) => {
      if (value === "" && getQuestionNumber(key) !== key) {
        setMessage(`A pergunta ${getQuestionNumber(key)} é obrigatória!`);
        isFormValid = false;
      }
    });

    if (answers.feel_discomfort === "Sim") {
      let isChecked = false;
      Object.entries(answers.discomfort).forEach(([segment, values]) => {
        if (values.level && values.level !== "0") {
          isChecked = true;
        }

        if (values.level !== "0" && values.improvement === "") {
          setMessage(
            `A pergunta 7 relativa ao segmento ${translate(
              segment
            )} é obrigatória!`
          );
          isFormValid = false;
        }
        if (values.level !== "0" && values.duration === "") {
          setMessage(
            `A pergunta 6 relativa ao segmento ${translate(
              segment
            )} é obrigatória!`
          );
          isFormValid = false;
        }
      });
      if (!isChecked) {
        isFormValid = false;
        setMessage(
          "Na pergunta 5, você precisa avaliar ao menos um segmento com algum desconforto"
        );
      }
    }

    let isIssue = false;
    Object.entries(answers.workEnvironmentIssues).forEach(([key, value]) => {
      if (value === true) {
        isIssue = true;
      }
    });

    if (!isIssue) {
      isFormValid = false;
      setMessage("A questão número 11 é obrigatória");
    }

    if (isFormValid) {
      await submitQuestionnaire({
        id: questionnaireResponseID,
        ...answers,
        ...transformDiscomfort(answers.discomfort),
        ...answers.workEnvironmentIssues,
        suggestion,
      });
      setMessage("Respondido com sucesso!");
      setSeverity("success");
      setOpen(true);
      window.location.reload();
    } else {
      setSeverity("error");
      setOpen(true);
    }
  };

  if (isAnswered) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GridContainer style={{ maxWidth: "600px", padding: "20px" }}>
          <Card
            style={{ width: "100%", padding: "20px", marginBottom: "-10px" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "40%", height: "auto" }}
                src={companyImage ? companyImage.url : Logo}
                alt={companyName}
              />
            </div>

            <FormLabel component="legend">
              Você já respondeu a esse questionário
            </FormLabel>
          </Card>
        </GridContainer>
      </div>
    );
  }

  if (!company) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GridContainer style={{ maxWidth: "600px", padding: "20px" }}>
          <Card
            style={{ width: "100%", padding: "20px", marginBottom: "-10px" }}
          >
            <Typography variant="h5">
              Erro 404: O questionário foi apagado ou não existe
            </Typography>
          </Card>
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {company.id && (
          <GridContainer style={{ maxWidth: "600px", padding: "20px" }}>
            <Card
              style={{ width: "100%", padding: "20px", marginBottom: "-10px" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "40%", height: "auto" }}
                  src={companyImage ? companyImage.url : Logo}
                  alt={companyName}
                />
              </div>
              <AlertCustom
                setOpen={setOpen}
                open={open}
                message={message}
                severity={severity}
              />
              {!personal_number && (
                <GridItem xs={12}>
                  <FormLabel component="legend">
                    Insira seu Número Pessoal - NP
                  </FormLabel>
                  <TextField
                    name="personal_number"
                    autoComplete="off"
                    placeholder="NP"
                    value={answers.personal_number}
                    onChange={(e) =>
                      handleChange("personal_number", e.target.value)
                    }
                    fullWidth
                  />

                  <DividerNoLineTextField />
                  <GridItem xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleGetQuestionnaireResponse(answers.personal_number)
                      }
                    >
                      Iniciar Questionário
                    </Button>
                  </GridItem>
                </GridItem>
              )}
              {personal_number && (
                <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5">
                      Sua opinião sobre seu trabalho é importante para o Serviço
                      de Saúde e a Ergonomia.
                    </Typography>
                    <DividerNoLine />
                    <Typography variant="body1">
                      Solicitamos que você responda com sinceridade as perguntas
                      abaixo escolhendo a resposta que melhor representa a
                      relação entre você e seu posto de trabalho e sua equipe. É
                      essencial, pela natureza da pesquisa que você se
                      identifique e nos informe a área a qual você pertence.
                      Ressaltando que as informações contidas nesse formulário
                      são SIGILOSAS.
                      <br />A melhora da qualidade dos postos de trabalho é um
                      desafio de todos.
                    </Typography>
                    <DividerNoLine />

                    <Typography variant="body2">
                      Eu autorizo a {companyName} a tratar meus dados pessoais,
                      em especial para compartilhar meus dados pessoais com a
                      finalidade de Promoção da Saúde, pelo prazo de 12 meses.
                    </Typography>
                    <DividerNoLine />

                    <Typography as="span" variant="body2">
                      Dados pessoais que serão tratados:
                    </Typography>
                    <li>Peso</li>
                    <li>Idade</li>
                    <li>Dados de saúde</li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={answers.personal_authorization}
                          onChange={(e) =>
                            handleChange(
                              "personal_authorization",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Eu autorizo"
                    />
                    <Typography variant="body2">
                      A autorização fornecida no presente instrumento não
                      implica em alteração do contrato de trabalho havido entre
                      as partes, tratando-se de medida voluntária do
                      colaborador, e pode ser revogada a qualquer momento,
                      restando ratificadas as atividades desenvolvidas antes da
                      manifestação contrária do Titular, mediante manifestação
                      em um dos seguintes canais
                    </Typography>
                  </GridItem>
                  <DividerNoLine />
                  <GridItem xs={12}>
                    <Typography variant="h5">Dados pessoais</Typography>

                    <DividerNoLine />
                    <Typography variant="body2">
                      NP: {personal_number}
                      <br />
                      <br />
                      Nome: {answers.personal_name}
                      <br />
                      <br />
                      Data de nascimento: {answers.born_date || ""}
                      <br />
                      <br />
                      Empresa: {answers.company_name}
                      <br />
                      <br />
                      Área: {answers.area_name}
                      <br />
                      <br />
                      Setor: {answers.sector_name}
                      <br />
                      <br />
                      Cargo: {answers.job_position_name}
                      <br />
                      <br />
                      GHE: {answers.ghe_name}
                      <br />
                      <br />
                      Descrição do GHE: {answers.ghe_description}
                    </Typography>
                  </GridItem>
                  <DividerNoLine />
                </GridContainer>
              )}
            </Card>

            {personal_number && (
              <>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        1 - Qual o seu gênero
                      </FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        value={answers.gender}
                        onChange={(e) => handleChange("gender", e.target.value)}
                        required
                      >
                        <FormControlLabel
                          value="Feminino"
                          control={<Radio />}
                          label="Feminino"
                        />
                        <FormControlLabel
                          value="Masculino"
                          control={<Radio />}
                          label="Masculino"
                        />
                        <FormControlLabel
                          value="Prefiro não dizer"
                          control={<Radio />}
                          label="Prefiro não dizer"
                        />
                        <FormControlLabel
                          value="Outro"
                          control={<Radio />}
                          label="Outro"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>

                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormLabel component="legend">
                      2 - Qual sua altura? Formato Ex: 1,65
                    </FormLabel>
                    <InputMask
                      mask="9,99"
                      value={answers.height || ""}
                      onChange={(e) => handleChange("height", e.target.value)}
                    >
                      {() => (
                        <TextField
                          placeholder="1,65"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    </InputMask>
                    <DividerNoLineTextField />
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormLabel component="legend">
                      3 - Qual o seu peso? Escrever número inteiro. Ex:75
                    </FormLabel>
                    <InputMask
                      mask="999"
                      value={answers.weight || ""}
                      onChange={(e) => handleChange("weight", e.target.value)}
                    >
                      {() => (
                        <TextField
                          placeholder="75"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    </InputMask>

                    <DividerNoLineTextField />
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl style={{ width: "100%" }} component="fieldset">
                      <FormLabel component="legend">
                        4 - Você sente atualmente algum desconforto?
                      </FormLabel>
                      <RadioGroup
                        aria-label="feel-discomfort"
                        name="feel_discomfort"
                        value={answers.feel_discomfort}
                        onChange={(e) =>
                          setAnswers({
                            ...answers,
                            feel_discomfort: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                    <DividerNoLine />
                    {answers.feel_discomfort === "Sim" && (
                      <FormControl
                        style={{ width: "100%" }}
                        component="fieldset"
                      >
                        <FormLabel component="legend">
                          5 - Como você classifica o que sente?
                        </FormLabel>
                        <FormLabel component="legend">
                          *As perguntas 6 e 7 são obrigatórias para cada
                          desconforto avaliado com algum nível de desconforto
                        </FormLabel>
                        {Object.keys(answers.discomfort).map(
                          (discomfortKey) => (
                            <div key={discomfortKey}>
                              <Accordion
                                style={{ width: "100%" }}
                                expanded={
                                  answers.discomfort[discomfortKey].checked
                                }
                                onChange={() =>
                                  setAnswers({
                                    ...answers,
                                    discomfort: {
                                      ...answers.discomfort,
                                      [discomfortKey]: {
                                        ...answers.discomfort[discomfortKey],
                                        checked: !answers.discomfort[
                                          discomfortKey
                                        ].checked,
                                      },
                                    },
                                  })
                                }
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls={`${discomfortKey}-content`}
                                  id={`${discomfortKey}-header`}
                                >
                                  <div
                                    style={{
                                      marginBottom: "20px",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography>
                                      {translate(discomfortKey)}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                  >
                                    <img
                                      style={{
                                        marginTop: "20px",
                                        height: "250px",
                                      }}
                                      src={getImage(discomfortKey)}
                                      alt={translate(discomfortKey)}
                                    />
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <>
                                    <RadioGroup
                                      aria-label={`${discomfortKey}-level`}
                                      name={`${discomfortKey}-level`}
                                      value={
                                        answers.discomfort[discomfortKey].level
                                      }
                                      onChange={(e) =>
                                        setAnswers({
                                          ...answers,
                                          discomfort: {
                                            ...answers.discomfort,
                                            [discomfortKey]: {
                                              ...answers.discomfort[
                                                discomfortKey
                                              ],
                                              level: e.target.value,
                                            },
                                          },
                                        })
                                      }
                                    >
                                      {Object.keys(options).map(
                                        (key, index) => (
                                          <FormControlLabel
                                            key={key}
                                            value={key}
                                            control={<Radio />}
                                            label={
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    options[key].color,
                                                  padding: "5px",
                                                  borderRadius: "5px",
                                                  color: "black",
                                                }}
                                              >
                                                {options[key].label}
                                              </div>
                                            }
                                            classes={{
                                              label: classes.label,
                                            }}
                                          />
                                        )
                                      )}
                                    </RadioGroup>
                                    <Divider />

                                    <FormControl component="fieldset">
                                      <FormLabel component="legend">
                                        6 - Há quanto tempo sente esse
                                        desconforto?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label={`${discomfortKey}-duration`}
                                        name={`${discomfortKey}-duration`}
                                        value={
                                          answers.discomfort[discomfortKey]
                                            .duration
                                        }
                                        onChange={(e) =>
                                          setAnswers({
                                            ...answers,
                                            discomfort: {
                                              ...answers.discomfort,
                                              [discomfortKey]: {
                                                ...answers.discomfort[
                                                  discomfortKey
                                                ],
                                                duration: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                      >
                                        <FormControlLabel
                                          value="Até 1 mês"
                                          control={<Radio />}
                                          label="Até 1 mês"
                                        />
                                        <FormControlLabel
                                          value="De 1 a 3 meses"
                                          control={<Radio />}
                                          label="De 1 a 3 meses"
                                        />
                                        <FormControlLabel
                                          value="De 3 a 6 meses"
                                          control={<Radio />}
                                          label="De 3 a 6 meses"
                                        />
                                        <FormControlLabel
                                          value="Acima de 6 meses"
                                          control={<Radio />}
                                          label="Acima de 6 meses"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    <Divider />

                                    <FormControl component="fieldset">
                                      <FormLabel component="legend">
                                        7 - Em qual momento você percebe melhora
                                        nesse desconforto?
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label={`${discomfortKey}-improvement`}
                                        name={`${discomfortKey}-improvement`}
                                        value={
                                          answers.discomfort[discomfortKey]
                                            .improvement
                                        }
                                        onChange={(e) =>
                                          setAnswers({
                                            ...answers,
                                            discomfort: {
                                              ...answers.discomfort,
                                              [discomfortKey]: {
                                                ...answers.discomfort[
                                                  discomfortKey
                                                ],
                                                improvement: e.target.value,
                                              },
                                            },
                                          })
                                        }
                                      >
                                        <FormControlLabel
                                          value="A noite"
                                          control={<Radio />}
                                          label="A noite"
                                        />
                                        <FormControlLabel
                                          value="Nos dias de folga"
                                          control={<Radio />}
                                          label="Nos dias de folga"
                                        />
                                        <FormControlLabel
                                          value="Durante o rodízio de atividades"
                                          control={<Radio />}
                                          label="Durante o rodízio de atividades"
                                        />
                                        <FormControlLabel
                                          value="Não melhora"
                                          control={<Radio />}
                                          label="Não melhora"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </>
                                </AccordionDetails>
                              </Accordion>
                              <DividerNoLineTextField />
                            </div>
                          )
                        )}
                      </FormControl>
                    )}
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        8 - Você tem tomado remédio para dor para poder
                        trabalhar?
                      </FormLabel>
                      <RadioGroup
                        aria-label="pain-medication"
                        name="pain-medication"
                        value={answers.pain_medication}
                        onChange={(e) =>
                          handleChange("pain_medication", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Ás vezes"
                          control={<Radio />}
                          label="Ás vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        9 - Você já esteve ou está em acompanhamento
                        fisioterapêutico nos últimos 12 meses? Se sim, há quanto
                        tempo?
                      </FormLabel>
                      <RadioGroup
                        aria-label="physiotherapy-last-year"
                        name="physiotherapy-last-year"
                        value={answers.physiotherapy_last_year}
                        onChange={(e) =>
                          handleChange(
                            "physiotherapy_last_year",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Até 1 mês"
                          control={<Radio />}
                          label="Até 1 mês"
                        />
                        <FormControlLabel
                          value="De 1 a 3 meses"
                          control={<Radio />}
                          label="De 1 a 3 meses"
                        />
                        <FormControlLabel
                          value="De 3 a 6 meses"
                          control={<Radio />}
                          label="De 3 a 6 meses"
                        />
                        <FormControlLabel
                          value="Acima de 6 meses"
                          control={<Radio />}
                          label="Acima de 6 meses"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        10 - Nos últimos 12 meses você precisou se ausentar do
                        trabalho pelos desconfortos apontados?
                      </FormLabel>
                      <RadioGroup
                        aria-label="absence-due-to-discomfort"
                        name="absence-due-to-discomfort"
                        value={answers.absence_due_to_discomfort}
                        onChange={(e) =>
                          handleChange(
                            "absence_due_to_discomfort",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        11 - Considerando o seu ambiente de trabalho, tarefas ou
                        atividades, marque as situações descritas abaixo, que na
                        sua opinião ocasionam desconforto físico e/ou mental:
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_adequate_work_post
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_adequate_work_post"
                          />
                        }
                        label="Considero meu posto de trabalho adequado"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_work_post
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_work_post"
                          />
                        }
                        label="Considero meu posto de trabalho inadequado"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues.issue_improper_tool
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_improper_tool"
                          />
                        }
                        label="Ferramenta de trabalho imprópria ou não existe"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_manual_heavy_load_movements
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_manual_heavy_load_movements"
                          />
                        }
                        label="Movimentos manuais individuais de cargas pesadas"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_forced_body_position
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_forced_body_position"
                          />
                        }
                        label="Posição forçada do corpo para fazer o trabalho"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_platform
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_platform"
                          />
                        }
                        label="Plataforma inadequada ou não existe"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_access_ramp_or_stairs
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_access_ramp_or_stairs"
                          />
                        }
                        label="Escada / rampa ou condições de acesso inadequado"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_flooring
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_flooring"
                          />
                        }
                        label="Piso inadequado"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_difficult_valve_activation
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_difficult_valve_activation"
                          />
                        }
                        label="Válvulas de acionamento difícil"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_intense_effort_due_to_machine_maintenance
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_intense_effort_due_to_machine_maintenance"
                          />
                        }
                        label="Esforços intensos decorrentes de problemas com manutenção de máquinas"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_excessive_cabinet_or_support_height
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_excessive_cabinet_or_support_height"
                          />
                        }
                        label="Altura excessivas de armários ou suportes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_compromised_task_vision
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_compromised_task_vision"
                          />
                        }
                        label="Visão de tarefa comprometida"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_hard_to_understand_activities
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_hard_to_understand_activities"
                          />
                        }
                        label="Atividades são de difícil entendimento"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_misaligned_operational_procedure
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_misaligned_operational_procedure"
                          />
                        }
                        label="Procedimento operacional não condiz com a atividade real"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues.issue_overtime_hours
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_overtime_hours"
                          />
                        }
                        label="Horas extras"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_intense_physical_effort_using_manual_tools
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_intense_physical_effort_using_manual_tools"
                          />
                        }
                        label="Esforços físicos intensos decorrentes da utilização de ferramentas manuais"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_furniture_issues
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_furniture_issues"
                          />
                        }
                        label="Problemas com os mobiliários ( mesas / cadeiras / bancadas)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_equipment_issues
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_equipment_issues"
                          />
                        }
                        label="Problemas com os equipamentos (suporte para computador / notebook / pés)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_environmental_noise_issues
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_environmental_noise_issues"
                          />
                        }
                        label="Problemas com as condições ambientais (ruído)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_environmental_temperature_issues
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_environmental_temperature_issues"
                          />
                        }
                        label="Problemas com as condições ambientais (temperatura)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_workspace_lighting
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_workspace_lighting"
                          />
                        }
                        label="Problemas com a iluminação do posto de trabalho"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_no_opportunity_for_improvement
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_no_opportunity_for_improvement"
                          />
                        }
                        label="Não há oportunidades de melhorias."
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_constant_displacements
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_constant_displacements"
                          />
                        }
                        label="Deslocamentos constantes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_inadequate_it_systems
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_inadequate_it_systems"
                          />
                        }
                        label="Os sistemas informatizados são inadequados para executar suas atividades"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              answers.workEnvironmentIssues
                                .issue_pulling_and_pushing_heavy_loads
                            }
                            onChange={handleCheckboxChangeWorkEnvironmentIssues}
                            name="issue_pulling_and_pushing_heavy_loads"
                          />
                        }
                        label="Atividades realizada puxando e empurrando cargas pesadas"
                      />
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        12 - Como você classifica a força utilizada no uso de
                        equipamentos e/ou ferramentas de trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.equipment_force}
                        onChange={(e) =>
                          handleChange("equipment_force", e.target.value)
                        }
                      >
                        {[...Array(5).keys()].map((index) => (
                          <FormControlLabel
                            key={index}
                            value={String(index + 1)}
                            control={<Radio />}
                            label={
                              index === 0
                                ? "Sem esforço"
                                : index === 1
                                ? "Leve"
                                : index === 2
                                ? "Moderado"
                                : index === 3
                                ? "Alto"
                                : index === 4
                                ? "Muito alto"
                                : ""
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        13 - Como você trabalha a maior parte da sua jornada?
                      </FormLabel>
                      <RadioGroup
                        value={answers.work_posture}
                        onChange={(e) =>
                          handleChange("work_posture", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Em pé"
                          control={<Radio />}
                          label="Em pé"
                        />
                        <FormControlLabel
                          value="Sentado"
                          control={<Radio />}
                          label="Sentado"
                        />
                        <FormControlLabel
                          value="Ambos"
                          control={<Radio />}
                          label="Ambos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        14 - Há pessoal em número suficiente para o trabalho a
                        ser feito?
                      </FormLabel>
                      <RadioGroup
                        value={answers.adequate_staff}
                        onChange={(e) =>
                          handleChange("adequate_staff", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        15 - Você tem liberdade ou autonomia para ir ao banheiro
                        ou beber água?
                      </FormLabel>
                      <RadioGroup
                        value={answers.bathroom_freedom}
                        onChange={(e) =>
                          handleChange("bathroom_freedom", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        16 - Existe uma exigência de produtividade/meta mínima
                        determinada para sua atividade?
                      </FormLabel>
                      <RadioGroup
                        value={answers.productivity_demand}
                        onChange={(e) =>
                          handleChange("productivity_demand", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        17 - Você participa da elaboração ou das modificações
                        que ocorrem em sua área de trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.participate_in_changes}
                        onChange={(e) =>
                          handleChange("participate_in_changes", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                        <FormControlLabel
                          value="Frequentemente"
                          control={<Radio />}
                          label="Frequentemente"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        18 - Além dos intervalos para refeições existem outras
                        pausas (pausas descanso/ pequenos intervalos das
                        atividades) no seu trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.extra_breaks}
                        onChange={(e) =>
                          handleChange("extra_breaks", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        19 - Se sim, quantas?
                      </FormLabel>
                      <RadioGroup
                        value={answers.break_count}
                        onChange={(e) =>
                          handleChange("break_count", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Não existem outras pausas"
                          control={<Radio />}
                          label="Não existem outras pausas"
                        />
                        <FormControlLabel
                          value="1-2"
                          control={<Radio />}
                          label="1 a 2 vezes"
                        />
                        <FormControlLabel
                          value="3-4"
                          control={<Radio />}
                          label="3 a 4 vezes"
                        />
                        <FormControlLabel
                          value="Mais de 5 vezes"
                          control={<Radio />}
                          label="Mais de 5 vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        20 - Você realiza rodízio de atividades no seu trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.activity_rotation}
                        onChange={(e) =>
                          handleChange("activity_rotation", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        21 - Você sente cansaço mental que, segundo seu
                        entendimento, possa estar relacionado ao trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.mental_fatigue}
                        onChange={(e) =>
                          handleChange("mental_fatigue", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        22 - Você sente cansaço físico que, segundo seu
                        entendimento, possa estar relacionado ao trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.physical_fatigue}
                        onChange={(e) =>
                          handleChange("physical_fatigue", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        23 - Você tem tempo suficiente para cumprir todas as
                        tarefas de seu trabalho?
                      </FormLabel>
                      <RadioGroup
                        value={answers.sufficient_time}
                        onChange={(e) =>
                          handleChange("sufficient_time", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        24 - Seu trabalho exige atenção constante?
                      </FormLabel>
                      <RadioGroup
                        value={answers.constant_attention}
                        onChange={(e) =>
                          handleChange("constant_attention", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="Não"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          value="Às vezes"
                          control={<Radio />}
                          label="Às vezes"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    padding: "20px",
                    marginBottom: "-10px",
                  }}
                >
                  <GridItem item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        25 - Você está satisfeito com a sua situação de trabalho
                        em geral?
                      </FormLabel>
                      <RadioGroup
                        value={answers.job_satisfaction}
                        onChange={(e) =>
                          handleChange("job_satisfaction", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Muito satisfeito"
                          control={<Radio />}
                          label="Muito satisfeito"
                        />
                        <FormControlLabel
                          value="Satisfeito"
                          control={<Radio />}
                          label="Satisfeito"
                        />
                        <FormControlLabel
                          value="Indiferente"
                          control={<Radio />}
                          label="Indiferente"
                        />
                        <FormControlLabel
                          value="Insatisfeito"
                          control={<Radio />}
                          label="Insatisfeito"
                        />
                        <FormControlLabel
                          value="Muito insatisfeito"
                          control={<Radio />}
                          label="Muito insatisfeito"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                </Card>
                <Card style={{ width: "100%", padding: "20px" }}>
                  <GridItem item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        26 - Você tem alguma sugestão de melhoria em relação ao
                        seu ambiente de trabalho, máquinas, equipamentos,
                        condições ergonômicas e de segurança do seu posto de
                        trabalho?
                      </FormLabel>
                      <DividerNoLineTextField />

                      <TextField
                        value={suggestion}
                        onChange={(e) => setSuggestion(e.target.value)}
                        placeholder="Não tenho sugestão"
                        multiline
                      />
                    </FormControl>
                  </GridItem>
                  <DividerNoLine />
                </Card>

                <Grid item xs={12}>
                  <div
                    style={{
                      width: "80px",
                      padding: "20px",
                      marginTop: "-30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmmit}
                      fullWidth
                    >
                      Enviar
                    </Button>
                  </div>
                </Grid>
              </>
            )}
          </GridContainer>
        )}
      </div>
    );
  }
}
const options = {
  0: { label: "0.  😀 Sem desconforto", color: "#FFFFCC" },
  1: { label: "1.  🙂 Muito leve", color: "#FFEDA3" },
  2: { label: "2.  😐 Leve", color: "#FFD180" },
  3: { label: "3.  😕", color: "#FFB74D" },
  4: { label: "4.  😟", color: "#FFA726" },
  5: { label: "5.  🙁", color: "#FF9800" },
  6: { label: "6.  ☹️", color: "#FB8C00" },
  7: { label: "7.  😖", color: "#F57C00" },
  8: { label: "8.  😞", color: "#EF6C00" },
  9: { label: "9.  😢 Forte", color: "#E65100" },
  10: { label: "10. 😭 Muito forte", color: "#E64A19" },
};

function transformDiscomfort(discomfort) {
  let result = {};

  for (let part in discomfort) {
    let prefix = "discomfort_" + part;

    result[`${prefix}`] = discomfort[part].checked;
    result[`${prefix}_level`] = discomfort[part].level;
    result[`${prefix}_duration`] = discomfort[part].duration;
    result[`${prefix}_improvement`] = discomfort[part].improvement;
  }

  return result;
}
