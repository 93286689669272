import React, { useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllJobPositionsByGheId } from "api/jobPositionApi";
import JobPositionTreeComponent from "./JobPositionTreeComponent";
import StyledTreeItem from "./StyledTreeItem";

export default function GheTreeComponent({
  gheName,
  gheId,
  companyId,
  evaluationList,
  setOpenBackDrop,
}) {
  const [jobPositionList, setJobPositionList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (gheId !== "" && gheId !== undefined)
        try {
          const data = await getAllJobPositionsByGheId(gheId);
          setJobPositionList(data);
        } catch (err) {}
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gheId]);

  return (
    <StyledTreeItem
      selected="4"
      tooltipTitle="GHE"
      nodeId={gheId}
      label={gheName}
    >
      {jobPositionList &&
        jobPositionList.map(({ name, id }) => {
          return (
            <TreeView
              key={id}
              defaultExpanded={[`${id}`]}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              multiSelect
            >
              <JobPositionTreeComponent
                setOpenBackDrop={setOpenBackDrop}
                evaluationList={evaluationList}
                companyId={companyId}
                key={id}
                jobPositionName={name}
                jobPositionId={id}
              />
            </TreeView>
          );
        })}
    </StyledTreeItem>
  );
}
