import React, { useState } from "react";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useEffect } from "react";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  grid: {
    flex: "1 !important",
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBest({
  name,
  picture,
  title,
  functionPosture,
  counterPosture,
  subtitle,
  functionCheck,
  check,
  line = 3,
  lineMid = 4,
}) {
  const [checkedPosture, setChecked] = useState(false);

  useEffect(() => {
    if (check) {
      setChecked(true);
      functionPosture(counterPosture + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  const handleChange = () => {
    if (!checkedPosture) {
      setChecked(true);
      functionCheck(true);
      functionPosture(counterPosture + 1);
    } else {
      setChecked(false);
      functionCheck(false);
      functionPosture(counterPosture - 1);
    }
  };

  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <GridItem
      className={classes2.grid}
      xs={12}
      sm={lineMid}
      md={line}
      onClick={handleChange}
    >
      <Card
        style={{
          backgroundColor: checkedPosture ? "rgba(0, 211, 238, 0.1)" : "white",
          boxShadow: checkedPosture
            ? "0px 0px 10px #00acc1"
            : "0px 0px 10px rgba(0, 0, 0, 0.3)",
        }}
        className={classes2.grid}
      >
        <CardHeader>
          <h6
            style={{
              margin: "0px",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "9px",
              fontWeight: "bold",
            }}
            className={classes.cardTitle}
          >
            {name}
          </h6>
        </CardHeader>
        <CardBody>
          <div
            style={{
              margin: "0px",
              textAlign: "center",
              justifyContent: "center",
              position: "relative", // Adicione isso
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                display: "block", // Adicione isso
              }}
              src={picture}
              alt={title}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: checkedPosture
                  ? "rgba(0, 211, 238, 0.1)"
                  : "transparent",
              }}
            />
          </div>
        </CardBody>
        <h6
          style={{
            textAlign: "center",
            justifyContent: "center",
            fontSize: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
          className={classes.cardTitle}
        >
          {subtitle}
        </h6>
      </Card>
    </GridItem>
  );
}
