// material-ui components
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

export default function DropDownMenuFilled({
  marginBot,
  filterName,
  data,
  color,
  value,
}) {
  const classes = useStyles();
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormControl
      value={value}
      variant="filled"
      style={{
        backgroundColor: "#02aec357",
        color: `${color}`,
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "10px",
        borderRadius: "3px",
      }}
      className={classes.formControl}
    >
      <InputLabel
        style={{ color: `${color}` }}
        id="demo-simple-select-filled-label"
      >
        {filterName}
      </InputLabel>
      <Select
        style={{ color: `${color}` }}
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={age}
        onChange={handleChange}
      >
        <MenuItem value={data}>{data}</MenuItem>
      </Select>
    </FormControl>
  );
}
