import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createEquipment(
  name,
  description,
  observation,
  is_standard,
  workstation_id
) {
  const res = await axios.post(
    `${API_URL}/equipment`,
    {
      name,
      description,
      observation,
      is_standard,
      workstation_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editEquipment(name, description, observation, is_standard, id) {
  const res = await axios.put(
    `${API_URL}/equipment/${id}`,
    {
      name,
      description,
      observation,
      is_standard,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEquipmentByWorkstationId(workstation_id) {
  const res = await axios.get(
    `${API_URL}/equipment?workstation_id=${workstation_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEquipments() {
  const res = await axios.get(
    `${API_URL}/equipment`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteEquipment(id) {
  const res = await axios.delete(
    `${API_URL}/equipment/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadEquipmentImage(file, equipment_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/equipmentimage/${equipment_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllEquipmentImages(equipment_id) {
  const res = await axios.get(
    `${API_URL}/equipmentimage?equipment_id=${equipment_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllEquipmentImage() {
  const res = await axios.get(
    `${API_URL}/equipmentimage`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function deleteEquipmentImage(id) {
  const res = await axios.delete(
    `${API_URL}/equipmentimage/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createEquipment,
  getAllEquipmentImages,
  getAllEquipmentImage,
  getAllEquipmentByWorkstationId,
  uploadEquipmentImage,
  editEquipment,
  deleteEquipment,
  deleteEquipmentImage,
  getAllEquipments,
};
