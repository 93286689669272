import axios from "axios";

import { API_URL } from "./urlApi.js";

async function downloadData({
  company_id,
  area_id,
  sector_id,
  ghe_id,
  start_date,
  end_date,
}) {
  const res = await axios.post(
    `${API_URL}/exportdata`,
    {
      company_id,
      work_area_id: area_id,
      sector_id,
      ghe_id,
      start_date,
      end_date,
    },
    {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res;
}

async function getRiskChart({
  company_id,
  area_id,
  sector_id,
  ghe_id,
  start_date,
  end_date,
}) {
  const res = await axios.post(
    `${API_URL}/exportdata/risk`,
    {
      company_id,
      work_area_id: area_id,
      sector_id,
      ghe_id,
      start_date,
      end_date,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res;
}

export { downloadData, getRiskChart };
