import React, { useEffect, useState } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import { getAllEvaluationsByFilter } from "../../api/evaluationApi";
import { getAllStepsByCompanyId, getAllStepsByFilter } from "../../api/stepApi";
import { getAllJobPositionsByFilter } from "../../api/jobPositionApi";
import { getAllActivitiesByFilter } from "../../api/activityApi";
import { getAllWorkstationsByFilter } from "../../api/workstationApi";
import { getAllImprovements } from "../../api/improvementApi";
import { getAllCauses } from "../../api/causeApi";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import PieChar from "components/PieChart/PieChart";
import RiskReduce from "./RiskReduce";
import ImprovementsDashboard from "./ImprovementsDashboard";
import { getRiskChart } from "api/exportDataApi";

const transformOursEnd = (endDate) => {
  return new Date(new Date(endDate).setHours(23, 59)).setDate(
    new Date(endDate).getDate() + 1
  );
};
const transformOursStart = (start) => {
  return new Date(new Date(start).setHours(0, 0)).setDate(
    new Date(start).getDate() + 1
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      marginTop: "-15px",
      marginBottom: "15px",
    },
  })
);
export default function Dashboard({
  areaId,
  sectorId,
  gheId,
  companyId,
  setOpenBackDrop,
  openBackDrop,
  refresh,
  setRefresh,
  endDate,
  startDate,
}) {
  const classes = useStyles();

  const [improvementArray, setImprovementArray] = useState([]);
  const [causeArray, setCauseArray] = useState([]);
  const [stepArray, setStepArray] = useState([]);
  const [jobPositionArray, setJobPositionArray] = useState([]);
  const [workstationArray, setWorkstationArray] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);

  const [jobPositionEmpty, setJobPositionEmpty] = useState(0);
  const [jobPositionLow, setJobPositionLow] = useState(0);
  const [jobPositionModerate, setJobPositionModerate] = useState(0);
  const [jobPositionHigh, setJobPositionHigh] = useState(0);
  const [jobPositionVeryHigh, setJobPositionVeryHigh] = useState(0);
  const [workstationEmpty, setWorkstationEmpty] = useState(0);
  const [workstationLow, setWorkstationLow] = useState(0);
  const [workstationModerate, setWorkstationModerate] = useState(0);
  const [workstationHigh, setWorkstationHigh] = useState(0);
  const [workstationVeryHigh, setWorkstationVeryHigh] = useState(0);
  const [activityEmpty, setActivityEmpty] = useState(0);
  const [activityLow, setActivityLow] = useState(0);
  const [activityModerate, setActivityModerate] = useState(0);
  const [activityHigh, setActivityHigh] = useState(0);
  const [activityVeryHigh, setActivityVeryHigh] = useState(0);
  const [stepEmpty, setStepEmpty] = useState(0);
  const [stepLow, setStepLow] = useState(0);
  const [stepModerate, setStepModerate] = useState(0);
  const [stepHigh, setStepHigh] = useState(0);
  const [stepVeryHigh, setStepVeryHigh] = useState(0);
  const [allSteps, setAllSteps] = useState([]);
  const [improvementLoading, setImprovementLoading] = useState(false);
  const [riskLoading, setRiskLoading] = useState(false);

  const [riskChart, setRiskChart] = useState({});

  useEffect(() => {
    if (companyId !== undefined && companyId !== "") {
      const fetch = async () => {
        let allStepsInside = await getAllStepsByCompanyId(companyId);

        if (startDate) {
          allStepsInside = allStepsInside.filter((step) => {
            return (
              new Date(step.updated_at).getTime() >=
              transformOursStart(startDate)
            );
          });
        }
        if (endDate) {
          allStepsInside = allStepsInside.filter((step) => {
            return (
              new Date(step.updated_at).getTime() <= transformOursEnd(endDate)
            );
          });
        }

        setAllSteps(allStepsInside);
      };
      fetch();
    }
  }, [companyId, startDate, endDate]);

  useEffect(() => {
    setJobPositionEmpty(0);
    setJobPositionLow(0);
    setJobPositionModerate(0);
    setJobPositionHigh(0);
    setJobPositionVeryHigh(0);
    setWorkstationEmpty(0);
    setWorkstationLow(0);
    setWorkstationModerate(0);
    setWorkstationHigh(0);
    setWorkstationVeryHigh(0);
    setActivityEmpty(0);
    setActivityLow(0);
    setActivityModerate(0);
    setActivityHigh(0);
    setActivityVeryHigh(0);
    setStepEmpty(0);
    setStepLow(0);
    setStepModerate(0);
    setStepHigh(0);
    setStepVeryHigh(0);
  }, [refresh]);

  useEffect(() => {
    var empty = 0;
    var low = 0;
    var moderate = 0;
    var high = 0;
    var veryHigh = 0;
    jobPositionArray.forEach(({ id }) => {
      let colorToSetInside = "";
      allSteps
        .filter(({ job_position_id }) => job_position_id === id)
        .forEach(({ id }) => {
          const initial = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Inicial"
          );
          const current = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Acompanhamento"
          );

          let nprInitialInside = "";
          let nprCurrentInside = "";
          initial !== undefined && initial.length !== 0
            ? (nprInitialInside = initial[0].npr)
            : (nprInitialInside = "--");
          current !== undefined && current.length !== 0
            ? (nprCurrentInside = current[0].npr)
            : (nprCurrentInside = "--");
          if (initial.length === 0 && current.length === 0)
            nprCurrentInside = "--";
          if (
            (nprCurrentInside !== "--" &&
              colorToSetInside <= parseFloat(nprCurrentInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprCurrentInside;
          }
          if (
            (nprCurrentInside === "--" &&
              colorToSetInside <= parseFloat(nprInitialInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprInitialInside;
          }
          if (
            (nprCurrentInside === "--" &&
              nprInitialInside === "--" &&
              colorToSetInside === "--") ||
            colorToSetInside === ""
          )
            colorToSetInside = nprInitialInside;
        });
      if (colorToSetInside === "--") return (empty = empty + 1);
      let nprFloat = parseFloat(colorToSetInside);
      if (nprFloat === 0) return (empty = empty + 1);
      if (nprFloat >= 0 && nprFloat < 10) return (low = low + 1);
      if (nprFloat >= 10 && nprFloat < 30) return (moderate = moderate + 1);
      if (nprFloat >= 30 && nprFloat < 50) return (high = high + 1);
      if (nprFloat >= 50) return (veryHigh = veryHigh + 1);
    });
    setJobPositionEmpty(empty);
    setJobPositionLow(low);
    setJobPositionModerate(moderate);
    setJobPositionHigh(high);
    setJobPositionVeryHigh(veryHigh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionArray, allSteps]);
  useEffect(() => {
    var empty = 0;
    var low = 0;
    var moderate = 0;
    var high = 0;
    var veryHigh = 0;
    activityArray.forEach(({ id }) => {
      let colorToSetInside = "";
      allSteps
        .filter(({ activity_id }) => activity_id === id)
        .forEach(({ id }) => {
          const initial = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Inicial"
          );
          const current = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Acompanhamento"
          );

          let nprInitialInside = "";
          let nprCurrentInside = "";
          initial !== undefined && initial.length !== 0
            ? (nprInitialInside = initial[0].npr)
            : (nprInitialInside = "--");
          current !== undefined && current.length !== 0
            ? (nprCurrentInside = current[0].npr)
            : (nprCurrentInside = "--");
          if (initial.length === 0 && current.length === 0)
            nprCurrentInside = "--";
          if (
            (nprCurrentInside !== "--" &&
              colorToSetInside <= parseFloat(nprCurrentInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprCurrentInside;
          }
          if (
            (nprCurrentInside === "--" &&
              colorToSetInside <= parseFloat(nprInitialInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprInitialInside;
          }
          if (
            (nprCurrentInside === "--" &&
              nprInitialInside === "--" &&
              colorToSetInside === "--") ||
            colorToSetInside === ""
          )
            colorToSetInside = nprInitialInside;
        });
      if (colorToSetInside === "--") return (empty = empty + 1);
      let nprFloat = parseFloat(colorToSetInside);
      if (nprFloat === 0) return (empty = empty + 1);
      if (nprFloat >= 0 && nprFloat <= 10) return (low = low + 1);
      if (nprFloat > 10 && nprFloat <= 30) return (moderate = moderate + 1);
      if (nprFloat > 30 && nprFloat <= 50) return (high = high + 1);
      if (nprFloat > 50) return (veryHigh = veryHigh + 1);
    });
    setActivityEmpty(empty);
    setActivityLow(low);
    setActivityModerate(moderate);
    setActivityHigh(high);
    setActivityVeryHigh(veryHigh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityArray, allSteps]);
  useEffect(() => {
    var empty = 0;
    var low = 0;
    var moderate = 0;
    var high = 0;
    var veryHigh = 0;
    workstationArray.forEach(({ id }) => {
      let colorToSetInside = "";
      allSteps
        .filter(({ workstation_id }) => workstation_id === id)
        .forEach(({ id }) => {
          const initial = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Inicial"
          );
          const current = evaluationList.filter(
            ({ type, step_id }) => step_id === id && type === "Acompanhamento"
          );

          let nprInitialInside = "";
          let nprCurrentInside = "";
          initial !== undefined && initial.length !== 0
            ? (nprInitialInside = initial[0].npr)
            : (nprInitialInside = "--");
          current !== undefined && current.length !== 0
            ? (nprCurrentInside = current[0].npr)
            : (nprCurrentInside = "--");
          if (initial.length === 0 && current.length === 0)
            nprCurrentInside = "--";
          if (
            (nprCurrentInside !== "--" &&
              colorToSetInside <= parseFloat(nprCurrentInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprCurrentInside;
          }
          if (
            (nprCurrentInside === "--" &&
              colorToSetInside <= parseFloat(nprInitialInside)) ||
            colorToSetInside === "--"
          ) {
            colorToSetInside = nprInitialInside;
          }
          if (
            (nprCurrentInside === "--" &&
              nprInitialInside === "--" &&
              colorToSetInside === "--") ||
            colorToSetInside === ""
          )
            colorToSetInside = nprInitialInside;
        });
      if (colorToSetInside === "--") return (empty = empty + 1);
      let nprFloat = parseFloat(colorToSetInside);
      if (nprFloat === 0) return (empty = empty + 1);
      if (nprFloat >= 0 && nprFloat <= 10) return (low = low + 1);
      if (nprFloat > 10 && nprFloat <= 30) return (moderate = moderate + 1);
      if (nprFloat > 30 && nprFloat <= 50) return (high = high + 1);
      if (nprFloat > 50) return (veryHigh = veryHigh + 1);
    });

    setWorkstationEmpty(empty);
    setWorkstationLow(low);
    setWorkstationModerate(moderate);
    setWorkstationHigh(high);
    setWorkstationVeryHigh(veryHigh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workstationArray, allSteps]);

  useEffect(() => {
    let empty = 0;
    let low = 0;
    let moderate = 0;
    let high = 0;
    let veryHigh = 0;

    let steps = stepArray;

    if (startDate) {
      steps = steps.filter((step) => {
        return (
          new Date(step.updated_at).getTime() >= transformOursStart(startDate)
        );
      });
    }
    if (endDate) {
      steps = steps.filter((step) => {
        return new Date(step.updated_at).getTime() <= transformOursEnd(endDate);
      });
    }

    steps.forEach(({ id }) => {
      const initial = evaluationList.filter(
        ({ type, step_id }) => step_id === id && type === "Inicial"
      );
      const current = evaluationList.filter(
        ({ type, step_id }) => step_id === id && type === "Acompanhamento"
      );

      let nprInitialInside = "";
      let nprCurrentInside = "";
      initial !== undefined && initial.length !== 0
        ? (nprInitialInside = initial[0].npr)
        : (nprInitialInside = "--");
      current !== undefined && current.length !== 0
        ? (nprCurrentInside = current[0].npr)
        : (nprCurrentInside = "--");
      if (initial.length === 0 && current.length === 0) nprCurrentInside = "--";

      let colorToSetInside = "";
      if (nprCurrentInside !== "--") {
        colorToSetInside = nprCurrentInside;
      } else {
        colorToSetInside = nprInitialInside;
      }
      if (colorToSetInside === "--") return (empty = empty + 1);
      let nprFloat = parseFloat(colorToSetInside);
      if (nprFloat === 0) return (empty = empty + 1);
      if (nprFloat >= 0 && nprFloat <= 10) return (low = low + 1);
      if (nprFloat > 10 && nprFloat <= 30) return (moderate = moderate + 1);
      if (nprFloat > 30 && nprFloat <= 50) return (high = high + 1);
      if (nprFloat > 50) return (veryHigh = veryHigh + 1);
    });
    setStepEmpty(empty);
    setStepLow(low);
    setStepModerate(moderate);
    setStepHigh(high);
    setStepVeryHigh(veryHigh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepArray, startDate, endDate]);

  useEffect(() => {
    if (refresh) {
      const fetch = async () => {
        setOpenBackDrop(true);
        setEvaluationList(
          await getAllEvaluationsByFilter(
            gheId,
            companyId,
            areaId,
            sectorId,
            refresh
          )
        );

        setJobPositionArray(
          await getAllJobPositionsByFilter(gheId, companyId, areaId, sectorId)
        );
        setWorkstationArray(
          await getAllWorkstationsByFilter(gheId, companyId, areaId, sectorId)
        );
        setActivityArray(
          await getAllActivitiesByFilter(gheId, companyId, areaId, sectorId)
        );
        setStepArray(
          await getAllStepsByFilter(gheId, companyId, areaId, sectorId)
        );
        setOpenBackDrop(false);
      };
      fetch();
    }
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, companyId]);

  useEffect(() => {
    setOpenBackDrop(true);
    setImprovementLoading(true);
    const fetch = async () => {
      if (companyId !== undefined && companyId !== "") {
        let steps = await getAllStepsByCompanyId(companyId);

        steps = steps.filter((step) => {
          if (gheId) {
            return step.ghe_id === gheId;
          }
          if (sectorId) {
            return step.sector_id === sectorId;
          }
          if (areaId) {
            return step.work_area_id === areaId;
          } else {
            return step;
          }
        });

        if (startDate) {
          steps = steps.filter((step) => {
            return (
              new Date(step.updated_at).getTime() >=
              transformOursStart(startDate)
            );
          });
        }

        if (endDate) {
          steps = steps.filter((step) => {
            return (
              new Date(step.updated_at).getTime() <= transformOursEnd(endDate)
            );
          });
        }

        const allCauses = await getAllCauses();
        const allImprovements = await getAllImprovements();

        let cause = [];
        let improvement = [];

        for (const { id } of steps) {
          var resCause = allCauses.filter((item) => item.step_id === id);
          for (const item of resCause) {
            cause.push(item);
          }
          var resImprovement = allImprovements.filter(
            (item) => item.step_id === id
          );
          for (const item of resImprovement) {
            improvement.push(item);
          }
        }

        setImprovementArray(improvement);
        setCauseArray(cause);
        setImprovementLoading(false);
      }
    };
    fetch();
    setOpenBackDrop(false);
  }, [companyId, areaId, sectorId, gheId, endDate, startDate, setOpenBackDrop]);

  useEffect(() => {
    const fetch = async () => {
      setRiskLoading(true);
      const data = await getRiskChart({
        company_id: companyId,
        area_id: areaId,
        sector_id: sectorId,
        ghe_id: gheId,
        start_date: startDate,
        end_date: endDate,
      });

      setRiskChart(data.data);
      setRiskLoading(false);
    };

    fetch();
  }, [companyId, areaId, sectorId, gheId, endDate, startDate]);

  return (
    <>
      {companyId && (
        <>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <PieChar
                loading={openBackDrop}
                title={"Cargo"}
                empty={jobPositionEmpty}
                low={jobPositionLow}
                moderate={jobPositionModerate}
                high={jobPositionHigh}
                veryHigh={jobPositionVeryHigh}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <PieChar
                loading={openBackDrop}
                title={"Posto de trabalho"}
                empty={workstationEmpty}
                low={workstationLow}
                moderate={workstationModerate}
                high={workstationHigh}
                veryHigh={workstationVeryHigh}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <PieChar
                loading={openBackDrop}
                title={"Atividade"}
                empty={activityEmpty}
                low={activityLow}
                moderate={activityModerate}
                high={activityHigh}
                veryHigh={activityVeryHigh}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3}>
              <PieChar
                loading={openBackDrop}
                title={"Passo"}
                empty={stepEmpty}
                low={stepLow}
                moderate={stepModerate}
                high={stepHigh}
                veryHigh={stepVeryHigh}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}></GridItem>
          </GridContainer>
          <Card className={classes.grid}>
            <GridContainer>
              <GridItem xs={12} sm={1} md={1}></GridItem>
              <GridItem xs={6} sm={2} md={2}>
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#57c9d5",
                  }}
                >
                  Trivial
                </h4>
              </GridItem>

              <GridItem xs={6} sm={2} md={2}>
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#5cb860",
                  }}
                >
                  Baixo
                </h4>
              </GridItem>

              <GridItem xs={6} sm={2} md={2}>
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#ffa21a",
                  }}
                >
                  Moderado
                </h4>
              </GridItem>
              <GridItem xs={6} sm={2} md={2}>
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#ef5350",
                  }}
                >
                  Alto
                </h4>
              </GridItem>
              <GridItem xs={6} sm={2} md={2}>
                <h4
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#af2cc5",
                  }}
                >
                  Altíssimo
                </h4>
              </GridItem>
              <GridItem xs={12} sm={1} md={1}></GridItem>
            </GridContainer>
          </Card>
          <RiskReduce
            loading={riskLoading}
            veryHighToHigh={riskChart.veryHighToHigh}
            veryHighToModerate={riskChart.veryHighToModerate}
            veryHighToLow={riskChart.veryHighToLow}
            veryHighToNoRisk={riskChart.veryHighToNoRisk}
            highToModerate={riskChart.highToModerate}
            highToLow={riskChart.highToLow}
            highToNoRisk={riskChart.highToNoRisk}
            moderateToLow={riskChart.moderateToLow}
            moderateToNoRisk={riskChart.moderateToNoRisk}
            lowToNoRisk={riskChart.lowToNoRisk}
            sameRisk={riskChart.sameRisk}
          />
          <ImprovementsDashboard
            loading={improvementLoading}
            improvements={improvementArray}
            causes={causeArray}
            improvementArray={improvementArray}
            setOpenBackDrop={setOpenBackDrop}
          />
        </>
      )}
    </>
  );
}
