import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import {
  getAllImprovementsByStepId,
  deleteImprovement,
} from "../../api/improvementApi";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { useLoginContext } from "context/LoginContext";

export default function ImprovementList({
  stepId,
  setImprovementId,
  setNumber,
  setTitle,
  setCost,
  setControl,
  setPriority,
  setFinalDate,
  setTargetDate,
  setStatusField,
  setResponsibleName,
  setEdit,
  refresh,
  setRefresh,
}) {
  const [improvements, setImprovements] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  const dateHelper = (dateToFormat) => {
    const date = new Date(dateToFormat);
    return `${
      date.getDate() < 9 ? `0${date.getDate() + 1}` : date.getDate() + 1
    }/${
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()}`;
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllImprovementsByStepId(stepId);
      setImprovements(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);
  useEffect(() => {
    const fetch = async () => {
      const data = await getAllImprovementsByStepId(stepId);
      setImprovements(data);
    };
    fetch();
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      {improvements.length > 0 &&
        improvements.map(
          ({
            number,
            id,
            title,
            cost,
            control,
            priority,
            final_date,
            target_date,
            status,
            responsible_name,
          }) => {
            const handleOpenDialog = () => {
              setIdToDelete(id);
              setOpenDialog(true);
            };
            const handleEdit = () => {
              setImprovementId(id);
              setNumber(number);
              setTitle(title);
              setCost(cost);
              setControl(control);
              setPriority(priority);
              setFinalDate(final_date);
              setTargetDate(target_date);
              setStatusField(status);
              setResponsibleName(responsible_name);
              setEdit(true);
            };

            return (
              <div key={id} style={{ marginTop: "10px" }}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={2} md={2}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Número"
                                variant="outlined"
                                disabled
                                value={number}
                              ></TextField>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Título"
                                variant="outlined"
                                disabled
                                value={title}
                                fullWidth
                                multiline
                              ></TextField>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Data Objetivo"
                                variant="outlined"
                                disabled
                                value={dateHelper(target_date)}
                              ></TextField>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                color="primary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleEdit}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                disabled={isReader}
                                color="secondary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleOpenDialog}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                              <DeleteDialogImprovement
                                open={openDialog}
                                setOpen={setOpenDialog}
                                deleteFunction={deleteImprovement}
                                idToDelete={idToDelete}
                                setRefresh={setRefresh}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            );
          }
        )}
    </>
  );
}
