import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import {
  createCompany,
  editCompany,
  getAllCompanies,
  uploadCompanyImage,
} from "../../api/companyApi.js";
import CompanyComponentList from "./CompanyComponentList.js";
import { useLoginContext } from "context/LoginContext.js";
import ImageCard from "components/ImageCard/ImageCard.js";
import CompanyImageRegister from "./CompanyImageRegister.js";

export default function CompanyRegister({
  setOpen,
  setMessage,
  setSeverity,
  setChecked,
  setRefreshCompanyId,
}) {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [address, setAddress] = useState("");
  const [riskRate, setRiskRate] = useState("");
  const [cnae, setCnae] = useState("");
  const [technic_name, setTechnicName] = useState("");
  const [technic_class, setTechnicClass] = useState("");
  const [technic_specialty, setTechnicSpecialty] = useState("");
  const [paragraph_1, setParagraph1] = useState("");
  const [paragraph_2, setParagraph2] = useState("");
  const [edit, setEdit] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreviews] = useState([]);

  useEffect(() => {
    let blob = file ? URL.createObjectURL(file) : null;
    let pos = 0;
    setPreviews({ blob, pos });
  }, [file]);

  const { isReader } = useLoginContext();

  const handleSubmit = async () => {
    if (
      name === "" ||
      cnpj === "" ||
      address === "" ||
      riskRate === "" ||
      cnae === "" ||
      technic_name === "" ||
      technic_class === "" ||
      technic_specialty === ""
    ) {
      setMessage("Favor preencher todos os campos");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await createCompany(
          name,
          cnpj,
          address,
          riskRate,
          cnae,
          technic_name ? technic_name : " ",
          paragraph_1 ? paragraph_1 : " ",
          paragraph_2 ? paragraph_2 : " ",
          technic_specialty ? technic_specialty : " ",
          technic_class ? technic_class : " "
        );

        if (res && file) {
          await uploadCompanyImage(file, res.id);
        }
        setMessage("Empresa criada com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
        setRefreshCompanyId(true);
      } catch (err) {
        setMessage("Objeto já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  const handleEdit = async () => {
    try {
      const res = await editCompany(
        name,
        cnpj,
        address,
        riskRate,
        cnae,
        companyId,
        technic_name ? technic_name : " ",
        paragraph_1 ? paragraph_1 : " ",
        paragraph_2 ? paragraph_2 : " ",
        technic_specialty ? technic_specialty : " ",
        technic_class ? technic_class : " "
      );

      if (res && file) {
        await uploadCompanyImage(file, res.id);
      }

      setMessage("Empresa editada com sucesso");
      setSeverity("success");
      setOpen(true);
      setChecked(false);
      setRefreshCompanyId(true);
    } catch (err) {
      setMessage("Objeto já cadastrado com esse nome");
      setSeverity("error");
      setOpen(true);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllCompanies();
      setCompanies(data);
      setRefresh(false);
    };
    fetch();
  }, [refresh]);

  useEffect(() => {
    refresh ? setRefreshCompanyId(true) : setRefreshCompanyId(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleUploadFile = (e) => {
    setFile(e.target.files[0]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFile(null);
  }

  return (
    <CardBody>
      <CompanyComponentList
        companies={companies}
        setEdit={setEdit}
        setName={setName}
        setCompanyId={setCompanyId}
        setRefresh={setRefresh}
        setCnpj={setCnpj}
        setCnae={setCnae}
        setAddress={setAddress}
        setRiskRate={setRiskRate}
        setTechnicName={setTechnicName}
        setTechnicSpecialty={setTechnicSpecialty}
        setTechnicClass={setTechnicClass}
        setParagraph1={setParagraph1}
        setParagraph2={setParagraph2}
      />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setName(event.target.value)}
            value={name}
            variant="outlined"
            id="name"
            label="Nome da empresa"
            name="name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setCnpj(event.target.value)}
            value={cnpj}
            variant="outlined"
            id="cnpj"
            label="CNPJ"
            name="cnpj"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setAddress(event.target.value)}
            value={address}
            variant="outlined"
            id="address"
            label="Endereço"
            multiline
            name="address"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setRiskRate(event.target.value)}
            value={riskRate}
            variant="outlined"
            id="risk_rate"
            label="Grau de risco"
            name="risk_rate"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setCnae(event.target.value)}
            value={cnae}
            variant="outlined"
            id="cnae"
            label="CNAE"
            name="cnae"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <h5>Informações do relatório:</h5>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setTechnicName(event.target.value)}
            value={technic_name}
            variant="outlined"
            id="technic_name"
            label="Responsável Técnico"
            name="technic_name"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setTechnicSpecialty(event.target.value)}
            value={technic_specialty}
            variant="outlined"
            id="technic_specialty"
            label="Especialidade"
            name="technic_specialty"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <TextField
            onChange={(event) => setTechnicClass(event.target.value)}
            value={technic_class}
            variant="outlined"
            id="technic_class"
            label="Ordem de classe"
            name="technic_class"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <TextField
            onChange={(event) => setParagraph1(event.target.value)}
            value={paragraph_1}
            variant="outlined"
            id="paragraph_1"
            label="Item 7 Parágrafo 1"
            name="paragraph_1"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            onChange={(event) => setParagraph2(event.target.value)}
            value={paragraph_2}
            variant="outlined"
            id="paragraph_2"
            label="Item 7 Parágrafo 2"
            name="paragraph_2"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
          />
        </GridItem>

        {preview?.blob && (
          <ImageCard
            key={preview.pos}
            preview={preview}
            removeFile={removeFile}
          />
        )}
        {!edit && (
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <RegularButton
                variant="contained"
                color="primary"
                component="label"
              >
                + Adicionar logo
                <input
                  onChange={handleUploadFile}
                  type="file"
                  className="form-control"
                  hidden
                />
              </RegularButton>
            </div>
          </GridItem>
        )}

        {edit && (
          <CompanyImageRegister
            companyId={companyId}
            setEdit={setEdit}
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            handleSubmitCompany={handleEdit}
            setChecked={setChecked}
          />
        )}

        {!edit && (
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <RegularButton
                disabled={isReader}
                color={edit ? "warning" : "success"}
                onClick={edit ? handleEdit : handleSubmit}
                variant="contained"
              >
                {edit ? "Editar" : "Criar Empresa"}
              </RegularButton>
            </div>
          </GridItem>
        )}
      </GridContainer>
    </CardBody>
  );
}
