import React, { useState } from "react";
// @material-ui/core components
// core components

import { InputAdornment, TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { useForm } from "react-hook-form";
import ImprovementList from "./ImprovementList.js";
import CauseImprovementLists from "./CauseImprovementLists.js";
import { createImprovement, editImprovement } from "api/improvementApi.js";
import DropDownMenuControl from "components/DropDownMenuControl/DropDownMenuControl.js";
import DropDownMenuStatus from "components/DropDownMenuStatus/DropDownMenuStatus.js";
import DropDownMenuPriority from "components/DropDownMenuPriority/DropDownMenuPriority.js";
import { useLoginContext } from "context/LoginContext.js";

export default function ImprovementEvaluation({
  stepId,
  setOpen,
  setMessage,
  setSeverity,
  companyId,
}) {
  const { handleSubmit, register, reset } = useForm();
  const [improvementId, setImprovementId] = useState("");
  const [number, setNumber] = useState("");
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState("");
  const [control, setControl] = useState("");
  const [priority, setPriority] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [targetDate, setTargetDate] = useState("");
  const [statusField, setStatusField] = useState("");
  const [responsibleName, setResponsibleName] = useState("");
  const [refresh, setRefresh] = useState("");
  const [edit, setEdit] = useState(false);

  const { isReader } = useLoginContext();

  const onSubmit = handleSubmit(async (data) => {
    if (
      stepId === "" ||
      control === "" ||
      statusField === "" ||
      priority === ""
    ) {
      setMessage("Favor selecionar um controle ou status ou prioridade");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = !edit
          ? await createImprovement(
              data.number,
              data.title,
              data.cost,
              control,
              priority,
              data.final_date,
              data.target_date,
              statusField,
              data.responsible_name,
              companyId,
              stepId
            )
          : await editImprovement(
              data.number,
              data.title,
              data.cost,
              control,
              priority,
              data.final_date,
              data.target_date,
              statusField,
              data.responsible_name,
              improvementId
            );
        setNumber("");
        setTitle("");
        setCost("");
        setControl("");
        setPriority("");
        setFinalDate("");
        setTargetDate("");
        setStatusField("");
        setResponsibleName("");
        setEdit(false);
        edit ? setImprovementId("") : setImprovementId(res.id);
        edit
          ? setMessage("Melhoria editada com sucesso")
          : setMessage("Melhoria criada com sucesso");
        setSeverity("success");
        setOpen(true);
        setRefresh(true);
        reset();
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  });

  return (
    <>
      <ImprovementList
        setNumber={setNumber}
        setTitle={setTitle}
        setCost={setCost}
        setControl={setControl}
        setPriority={setPriority}
        setFinalDate={setFinalDate}
        setTargetDate={setTargetDate}
        setStatusField={setStatusField}
        setResponsibleName={setResponsibleName}
        companyId={companyId}
        stepId={stepId}
        setImprovementId={setImprovementId}
        setEdit={setEdit}
        refresh={refresh}
        setRefresh={setRefresh}
        improvementId={improvementId}
      />
      <CardBody>
        <form onSubmit={onSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                variant="outlined"
                id="number"
                label="Número"
                inputRef={register}
                name="number"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                id="title"
                label="Título"
                inputRef={register}
                name="title"
                fullWidth
                margin="normal"
                multiline
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                id="cost"
                label="Custo"
                inputRef={register}
                name="cost"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <DropDownMenuControl control={control} setControl={setControl} />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <DropDownMenuPriority
                priority={priority}
                setPriority={setPriority}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <DropDownMenuStatus
                statusField={statusField}
                setStatusField={setStatusField}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={targetDate}
                onChange={(e) => setTargetDate(e.target.value)}
                variant="outlined"
                id="target_date"
                label="Data objetivo"
                inputRef={register}
                name="target_date"
                type="date"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                variant="outlined"
                id="final_date"
                label="Data final"
                inputRef={register}
                name="final_date"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4} md={4}>
              <TextField
                value={responsibleName}
                onChange={(e) => setResponsibleName(e.target.value)}
                variant="outlined"
                id="responsible_name"
                label="Responsável"
                inputRef={register}
                name="responsible_name"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            {edit && (
              <CauseImprovementLists
                setMessage={setMessage}
                setSeverity={setSeverity}
                setOpen={setOpen}
                stepId={stepId}
                improvementId={improvementId}
              />
            )}

            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                <RegularButton
                  disabled={isReader}
                  color={edit ? "warning" : "success"}
                  type="submit"
                  variant="contained"
                >
                  {edit ? "Editar Melhoria" : "Criar Nova Melhoria"}
                </RegularButton>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      {improvementId !== "" && !edit && (
        <CauseImprovementLists
          setMessage={setMessage}
          setSeverity={setSeverity}
          setOpen={setOpen}
          stepId={stepId}
          improvementId={improvementId}
          title={"Associar causas a nova melhoria"}
        />
      )}
    </>
  );
}
