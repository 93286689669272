import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createObjectLift(
  name,
  type,
  weight,
  lift_frequency,
  distance,
  benchmark_value,
  found_value,
  risk_level,
  step_id
) {
  const res = await axios.post(
    `${API_URL}/objectlift`,
    {
      name,
      type,
      weight,
      lift_frequency,
      distance,
      benchmark_value,
      found_value,
      risk_level,
      step_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editObjectLift(
  name,
  type,
  weight,
  lift_frequency,
  distance,
  benchmark_value,
  found_value,
  risk_level,
  id
) {
  const res = await axios.put(
    `${API_URL}/objectlift/${id}`,
    {
      name,
      type,
      weight,
      lift_frequency,
      distance,
      benchmark_value,
      found_value,
      risk_level,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteObjectLift(id) {
  const res = await axios.delete(
    `${API_URL}/objectlift/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteObjectImage(id) {
  const res = await axios.delete(
    `${API_URL}/objectliftimage/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllObjectImages(id) {
  const res = await axios.get(
    `${API_URL}/objectliftimage?object_lift_id=${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllObjectImage() {
  const res = await axios.get(
    `${API_URL}/objectliftimage`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadObjectImage(file, object_lift_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/objectliftimage/${object_lift_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllObjectsByStepId(step_id) {
  const res = await axios.get(
    `${API_URL}/objectlift?step_id=${step_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );

  return res.data;
}
async function getAllObjects() {
  const res = await axios.get(
    `${API_URL}/objectlift`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createObjectLift,
  uploadObjectImage,
  getAllObjectsByStepId,
  editObjectLift,
  deleteObjectLift,
  getAllObjectImages,
  getAllObjectImage,
  deleteObjectImage,
  getAllObjects,
};
