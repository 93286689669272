import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createWorkstation(
  name,
  working_ours,
  workers_quantity,
  is_overtime,
  overtime_shift,
  is_short_brake,
  short_brake_shift,
  is_rotational_system,
  rotational_system_frequency,
  is_rotational_efficient,
  why_rotational_efficient,
  communication_frequency,
  is_occupational_gymnastics,
  is_activities_procedures,
  is_productions_goals,
  is_goals_achieved,
  organizational_observation,
  cognitive_observation,
  air_speed_benchmark,
  air_speed_achieved,
  is_air_speed_attend,
  temperature_benchmark,
  temperature_achieved,
  is_temperature_attend,
  noise_benchmark,
  noise_achieved,
  is_noise_attend,
  air_humidity_benchmark,
  air_humidity_achieved,
  is_air_humidity_attend,
  illuminance_benchmark,
  illuminance_achieved,
  is_illuminance_attend,
  description,
  jobPositionId,
  gheId,
  companyId,
  sectorId,
  workAreaId,
  conclusion,
  observation_verbalization
) {
  const res = await axios.post(
    `${API_URL}/workstation`,
    {
      name,
      working_ours,
      workers_quantity,
      is_overtime,
      overtime_shift,
      is_short_brake,
      short_brake_shift,
      is_rotational_system,
      rotational_system_frequency,
      is_rotational_efficient,
      why_rotational_efficient,
      communication_frequency,
      is_occupational_gymnastics,
      is_activities_procedures,
      is_productions_goals,
      is_goals_achieved,
      organizational_observation,
      cognitive_observation,
      air_speed_benchmark,
      air_speed_achieved,
      is_air_speed_attend,
      temperature_benchmark,
      temperature_achieved,
      is_temperature_attend,
      noise_benchmark,
      noise_achieved,
      is_noise_attend,
      air_humidity_benchmark,
      air_humidity_achieved,
      is_air_humidity_attend,
      illuminance_benchmark,
      illuminance_achieved,
      is_illuminance_attend,
      description,
      job_position_id: jobPositionId,
      ghe_id: gheId,
      company_id: companyId,
      sector_id: sectorId,
      work_area_id: workAreaId,
      conclusion,
      observation_verbalization,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editWorkstation(
  name,
  working_ours,
  workers_quantity,
  is_overtime,
  overtime_shift,
  is_short_brake,
  short_brake_shift,
  is_rotational_system,
  rotational_system_frequency,
  is_rotational_efficient,
  why_rotational_efficient,
  communication_frequency,
  is_occupational_gymnastics,
  is_activities_procedures,
  is_productions_goals,
  is_goals_achieved,
  organizational_observation,
  cognitive_observation,
  air_speed_benchmark,
  air_speed_achieved,
  is_air_speed_attend,
  temperature_benchmark,
  temperature_achieved,
  is_temperature_attend,
  noise_benchmark,
  noise_achieved,
  is_noise_attend,
  air_humidity_benchmark,
  air_humidity_achieved,
  is_air_humidity_attend,
  illuminance_benchmark,
  illuminance_achieved,
  is_illuminance_attend,
  description,
  workstationId,
  conclusion,
  observation_verbalization
) {
  const res = await axios.put(
    `${API_URL}/workstation/${workstationId}`,
    {
      name,
      working_ours,
      workers_quantity,
      is_overtime,
      overtime_shift,
      is_short_brake,
      short_brake_shift,
      is_rotational_system,
      rotational_system_frequency,
      is_rotational_efficient,
      why_rotational_efficient,
      communication_frequency,
      is_occupational_gymnastics,
      is_activities_procedures,
      is_productions_goals,
      is_goals_achieved,
      organizational_observation,
      cognitive_observation,
      air_speed_benchmark,
      air_speed_achieved,
      is_air_speed_attend,
      temperature_benchmark,
      temperature_achieved,
      is_temperature_attend,
      noise_benchmark,
      noise_achieved,
      is_noise_attend,
      air_humidity_benchmark,
      air_humidity_achieved,
      is_air_humidity_attend,
      illuminance_benchmark,
      illuminance_achieved,
      is_illuminance_attend,
      description,
      conclusion,
      observation_verbalization,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllWorkstationsByJobPositionId(job_position_id) {
  const res = await axios.get(
    `${API_URL}/workstation?job_position_id=${job_position_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllWorkstationsByFilter(
  ghe_id,
  company_id,
  work_area_id,
  sector_id
) {
  if (company_id) {
    const res = await axios.get(
      // prettier-ignore
      `${API_URL}/workstation?${ghe_id && `ghe_id=${ghe_id}`}${company_id && `&company_id=${company_id}`}${work_area_id && `&work_area_id=${work_area_id}`}${sector_id && `&sector_id=${sector_id}`}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
      }
    );
    return res.data;
  }
  return [];
}
async function getAllWorkstations() {
  const res = await axios.get(
    // prettier-ignore
    `${API_URL}/workstation`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getWorkstation(id) {
  const res = await axios.get(
    `${API_URL}/workstation/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteWorkstation(id) {
  const res = await axios.delete(
    `${API_URL}/workstation/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllWorkstationImages(id) {
  const res = await axios.get(
    `${API_URL}/workstationimage?workstation_id=${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function getAllWorkstationImage() {
  const res = await axios.get(
    `${API_URL}/workstationimage`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteWorkstationImage(id) {
  const res = await axios.delete(
    `${API_URL}/workstationimage/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

async function uploadWorkstationImage(file, workstation_id) {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(
    `${API_URL}/workstationimage/${workstation_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export {
  createWorkstation,
  getAllWorkstations,
  getAllWorkstationsByJobPositionId,
  uploadWorkstationImage,
  getWorkstation,
  getAllWorkstationImages,
  editWorkstation,
  deleteWorkstationImage,
  deleteWorkstation,
  getAllWorkstationsByFilter,
  getAllWorkstationImage,
};
