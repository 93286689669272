import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    fontSize: "40px",
  },
});

export default function WorkstationCheckbox({ title, setCheck, checked }) {
  const classes = useStyles();
  const handleCheck = () => {
    checked ? setCheck(false) : setCheck(true);
  };

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheck}
          name={title}
          color="primary"
        />
      }
      label={title}
    />
  );
}
