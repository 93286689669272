import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function PieCharStatus({
  finished,
  current,
  delayed,
  waitingDecision,
  requiresEvaluation,
  impracticable,
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(-1);

  const [data, setData] = useState([
    { title: "Concluído", value: finished ? finished : 0, color: "#57c9d5" },
    { title: "Em andamento", value: current ? current : 0, color: "#5cb860" },
    { title: "Atrasado", value: delayed ? delayed : 0, color: "#ffa21a" },
    {
      title: "Esperar decisão",
      value: waitingDecision ? waitingDecision : 0,
      color: "#00695c",
    },
    {
      title: "Requer avaliação",
      value: requiresEvaluation ? requiresEvaluation : 0,
      color: "#795548",
    },
    {
      title: "Inviável",
      value: impracticable ? impracticable : 0,
      color: "#ef5350",
    },
  ]);

  useEffect(() => {
    setData([
      { title: "Concluído", value: finished ? finished : 0, color: "#57c9d5" },
      { title: "Em andamento", value: current ? current : 0, color: "#5cb860" },
      { title: "Atrasado", value: delayed ? delayed : 0, color: "#ffa21a" },
      {
        title: "Esperar decisão",
        value: waitingDecision ? waitingDecision : 0,
        color: "#00695c",
      },
      {
        title: "Requer avaliação",
        value: requiresEvaluation ? requiresEvaluation : 0,
        color: "#795548",
      },
      {
        title: "Inviável",
        value: impracticable ? impracticable : 0,
        color: "#ef5350",
      },
    ]);
  }, [
    finished,
    current,
    delayed,
    waitingDecision,
    requiresEvaluation,
    impracticable,
  ]);

  return (
    <div style={{ marginBottom: "30px", padding: "10px" }}>
      <h5
        className={classes.pieTitle}
        style={{ justifyContent: "center", display: "flex" }}
      >
        Status
      </h5>
      <PieChart
        radius={PieChart.defaultProps.radius - 6}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        segmentsShift={(index) => (index === selected ? 6 : 1)}
        onClick={(event, index) => {
          setSelected(index === selected ? undefined : index);
        }}
        labelStyle={{
          fontSize: "6px",
          fontWeight: "bold",
          fontColor: "white",
        }}
        label={({ dataEntry }) =>
          dataEntry.value !== 0 ? dataEntry.value : ""
        }
        data={data}
      />

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#57c9d5" }}>
        Concluído
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#5cb860" }}>
        Em andamento
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ffa21a" }}>
        Atrasado
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#00695c" }}>
        Esperar decisão
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#795548" }}>
        Requer avaliação
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ef5350" }}>
        Inviável
      </p>
    </div>
  );
}
