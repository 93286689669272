import Card from "components/Card/Card";
import useWindowDimensions from "hooks/UseWindowDimensions";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export default function GenericChart({ title, data, chartType, colors }) {
  const [chartData, setChartData] = useState([]);
  const [total, setTotal] = useState(0);

  const { width } = useWindowDimensions();

  useEffect(() => {
    const formattedData = data.map(({ label, value }) => [label, value]);
    setChartData([[title, "Valor"], ...formattedData]);
  }, [data, title]);

  useEffect(() => {
    const totalInside = data.reduce((acc, { value }) => acc + value, 0);
    setTotal(totalInside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  if (chartData.length === 0) {
    return <></>;
  }

  if (total === 0) {
    return <></>;
  }

  return (
    <Card
      style={{
        overflowX: "auto",
        overflowY: "hidden",
        margin: "auto",
        padding: "20px",
        marginBottom: "30px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>{title}</h2>

      <table
        style={{
          margin: "auto",
          borderCollapse: "collapse",
          border: "1px solid black",
          wordWrap: "break-word",
          display: width < 1000 ? "none" : "table",
          marginBottom: "30px",
        }}
      >
        <tbody>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td
                key={index}
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid black",
                }}
              >
                {row[0]}
              </td>
            ))}
          </tr>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td
                key={index}
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "1px solid black",
                }}
              >
                {row[1]}
              </td>
            ))}
          </tr>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td
                key={index}
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "1px solid black",
                }}
              >
                {((row[1] / total) * 100).toFixed(2)}%{" "}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <div style={{ margin: "auto" }}>
        <Chart
          id={`${chartData.length * 140}px`}
          chartType={chartType}
          height="400px"
          width={`${chartData.length * 120}px`}
          data={chartData}
          options={{
            legend: { position: "none" },
            colors: colors,
          }}
        />
      </div>
    </Card>
  );
}
