import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import { getAllCompanies, createUserCompany } from "../../api/companyApi.js";
import { creatUser } from "../../api/userApi.js";
import { useForm } from "react-hook-form";
import DropDownMenu from "components/DropDownMenu/DropDownMenu.js";

const roles = [
  {
    id: "reader",
    name: "leitor",
  },
  {
    id: "editor",
    name: "editor",
  },
];

export default function CreateUser({
  setOpen,
  setMessage,
  setSeverity,
  setCheckedCreateUser,
}) {
  const { handleSubmit, register, reset } = useForm();
  const [createCompany, setCreateCompany] = useState("");
  const [createRole, setCreateRole] = useState("");
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllCompanies();
        return setCompanyList(data);
      } catch (err) {
        setOpen(true);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    if (
      createRole === "" ||
      createCompany === "" ||
      data.password !== data.passwordConfirmation
    ) {
      data.password !== data.passwordConfirmation
        ? setMessage("Senha e confirmação de senha não confere")
        : setMessage("Favor Inserir o tipo ou a empresa do usuário");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await creatUser(
          data.name,
          data.email,
          data.password,
          createRole
        );
        await createUserCompany(res.id, createCompany);
        setMessage("Usuário criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setCreateCompany("");
        setCreateRole("");
        reset();
        setCheckedCreateUser(false);
      } catch (err) {
        setMessage("Email já cadastrado");
        setSeverity("error");
        setOpen(true);
      }
    }
  });

  return (
    <CardBody>
      <form onSubmit={onSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              variant="outlined"
              id="name"
              label="Nome do usuário"
              inputRef={register}
              name="name"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              inputRef={register}
              name="email"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <DropDownMenu
              filterName={"Tipo de usuário"}
              itemSelected={createRole}
              data={roles}
              itemSelector={setCreateRole}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              variant="outlined"
              id="password"
              label="Senha do usuário"
              inputRef={register}
              name="password"
              fullWidth
              margin="normal"
              type="password"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              variant="outlined"
              id="passwordConfirmation"
              label="Confirmar senha"
              inputRef={register}
              name="passwordConfirmation"
              fullWidth
              margin="normal"
              required
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <DropDownMenu
              itemSelected={createCompany}
              itemSelector={setCreateCompany}
              data={companyList}
              filterName={"Empresa"}
            />
          </GridItem>
          <div
            style={{
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <RegularButton color="success" type="submit" variant="contained">
              Criar Usuário
            </RegularButton>
          </div>
        </GridContainer>
      </form>
    </CardBody>
  );
}
