import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { getAllCausesByStepId } from "../../api/causeApi.js";
import { getAllCausesByImprovementId } from "../../api/causeApi.js";
import _ from "lodash";
import CauseImprovementList from "../CauseImprovementList/CauseImprovementList";
import CauseList from "../CauseImprovementList/CauseList";

const styles = {
  title: {
    color: "black",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default function CauseImprovementLists({
  setOpen,
  setMessage,
  setSeverity,
  improvementId,
  stepId,
  title,
}) {
  const [causeImprovementEdit, setCauseImprovementEdit] = useState([]);
  const [causeImprovementList, setCauseImprovementList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchCompanyData() {
      if (improvementId !== "")
        try {
          const causes = await getAllCausesByStepId(stepId);
          const causeImprovement = await getAllCausesByImprovementId(
            improvementId
          );
          setCauseImprovementList(causeImprovement);
          causeImprovement.length === 0
            ? setCauseImprovementEdit(causes)
            : setCauseImprovementEdit(
                _.differenceBy(causes, causeImprovement, "description")
              );
        } catch (err) {
          setOpen(true);
        }
    }
    fetchCompanyData();
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [improvementId, refresh]);

  return (
    <CardBody>
      {improvementId !== "" && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <h4 style={styles.title}>{title}</h4>
            </div>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <CauseImprovementList
                setRefresh={setRefresh}
                improvementId={improvementId}
                data={causeImprovementList}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <CauseList
                setRefresh={setRefresh}
                improvementId={improvementId}
                data={causeImprovementEdit}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
              />
            </div>
          </GridItem>
        </GridContainer>
      )}
    </CardBody>
  );
}
