import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import CardBody from "components/Card/CardBody.js";
import { getAllCompanies, getCompaniesByUser } from "../../api/companyApi.js";
import { getAllUsers } from "../../api/userApi.js";
import _ from "lodash";
import UserCompanyList from "../UserCompanyList/UserCompanyList";
import CompanyList from "../UserCompanyList/CompanyList";

export default function EditUser({ setOpen, setMessage, setSeverity }) {
  const [createUser, setCreateUser] = useState("");
  const [userList, setUserList] = useState("");
  const [companyListEdit, setCompanyListEdit] = useState([]);
  const [userCompanyList, setUserCompanyList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllUsers();
        return setUserList(data);
      } catch (err) {
        setUserList([]);
        setOpen(true);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchCompanyData() {
      if (createUser !== "")
        try {
          const companies = await getAllCompanies();
          const userCompanies = await getCompaniesByUser(createUser);
          setUserCompanyList(userCompanies);
          userCompanies.length === 0
            ? setCompanyListEdit(companies)
            : setCompanyListEdit(
                _.differenceBy(companies, userCompanies, "name")
              );
        } catch (err) {
          setOpen(true);
        }
    }
    fetchCompanyData();
    return () => {
      setRefresh(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUser, refresh]);

  return (
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <DropDownMenu
            itemSelected={createUser}
            itemSelector={setCreateUser}
            data={userList}
            filterName={"Usuário"}
          />
        </GridItem>
      </GridContainer>
      {createUser !== "" && (
        <GridContainer>
          <GridItem xs={12} sm={4} md={6}>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <UserCompanyList
                setRefresh={setRefresh}
                user={createUser}
                data={userCompanyList}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={6}>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <CompanyList
                setRefresh={setRefresh}
                user={createUser}
                data={companyListEdit}
                setMessage={setMessage}
                setOpen={setOpen}
                setSeverity={setSeverity}
              />
            </div>
          </GridItem>
        </GridContainer>
      )}
    </CardBody>
  );
}
