import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isAuth } from "./api/authApi";

export default function RouteWrapper({
  element: Element,
  isPrivate = false,
  ...rest
}) {
  const [logged, setLogged] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function isAdmin() {
      try {
        await isAuth();
        setLogged(true);
      } catch (err) {
        console.warn(err);
        history.push("/login");
      }
    }
    isAdmin();
    return () => {
      setLogged(false);
    };
  }, [history]);

  return logged ? <Route {...rest} element={Element} /> : null;
}
