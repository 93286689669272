import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import { useForm } from "react-hook-form";
import { createQuestionnaire } from "api/questionnaireApi";
import { getAllCompanies } from "api/companyApi";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import InputMask from "react-input-mask";

export default function CreateCenso({
  setOpen,
  setMessage,
  setSeverity,
  setCheckedCreateCenso,
  setRefresh,
  isReader,
}) {
  const { handleSubmit, register, reset } = useForm();
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [ano, setAno] = useState("");

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await getAllCompanies();
      setCompanies(data);
    };
    fetchCompanies();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createQuestionnaire({
        name: data.name,
        reference_year: ano,
        company_id: companyId,
      });
      setMessage("Questionário criado com sucesso");
      setSeverity("success");
      setOpen(true);
      reset();
      setCheckedCreateCenso(false);
      setRefresh(true);
    } catch (err) {
      setMessage("Erro ao criar o questionário");
      setSeverity("error");
      setOpen(true);
    }
  });

  return (
    <CardBody>
      <form onSubmit={onSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              variant="outlined"
              id="name"
              label="Nome"
              inputRef={register}
              name="name"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InputMask
              mask="9999"
              value={ano}
              onChange={(e) => setAno(e.target.value)}
            >
              {() => (
                <TextField
                  variant="outlined"
                  id="reference_year"
                  label="Ano de referência"
                  name="reference_year"
                  type="text"
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </InputMask>
          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <div style={{ marginTop: "-4px" }}>
              <DropDownMenu
                itemSelected={companyId}
                itemSelector={setCompanyId}
                data={companies}
                filterName={"Empresa"}
              />
            </div>
          </GridItem>

          <div
            style={{
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <RegularButton
              disabled={isReader}
              color="success"
              type="submit"
              variant="contained"
            >
              Criar Questionário
            </RegularButton>
          </div>
        </GridContainer>
      </form>
    </CardBody>
  );
}
