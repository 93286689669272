import React, { useEffect, useState } from "react";

import Card from "components/Card/Card.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CardBody from "components/Card/CardBody.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";

import DropDownMenuMultiplies from "components/DropDownMenu/DropDownMenuMultiplies.js";

import DropDownMenuEditor from "components/DropDownMenu/DropDownMenuEditor";
import CensoCharts from "components/Censo/CensoCharts";
import { getAllQuestionaires } from "api/questionnaireApi";
import { Button } from "@material-ui/core";
import { generateCharts } from "api/questionnaireApi";

export default function CensoGrafico() {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [gheId, setGheId] = useState("");
  const [sectorList, setSectorList] = useState([]);
  const [gheList, setGheList] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [questionnaireIds, setQuestionnaireIds] = useState([]);
  const [filters, SetFilters] = useState();
  const [chartData, setChartData] = useState([]);
  const [restrict, setRestrict] = useState("");

  useEffect(() => {
    setChartData([]);
    SetFilters([]);
    setAreaList([]);
    setSectorList([]);
    setGheList([]);
    setAreaId("");
    setSectorId("");
    setGheId("");
  }, [questionnaireIds]);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      const data = await getAllQuestionaires();
      setQuestionnaires(
        data?.map((q) => ({
          id: q.id,
          name: q.name + " - " + q.reference_year + " (" + q.company.name + ")",
        }))
      );
    };
    fetchQuestionnaires();
  }, []);

  const handleGerarGráficos = async () => {
    setOpenBackDrop(true);
    const data = await generateCharts(
      questionnaireIds,
      areaId,
      sectorId,
      gheId,
      restrict
    );
    setChartData(data);
    SetFilters(data.filtros);
    setAreaList(data.filtros.areas);
    // setGheList(data.filtros.ghes);
    setOpenBackDrop(false);
  };

  useEffect(() => {
    setGheList([]);
    if (sectorId !== "") {
      setGheList(filters.ghes.filter((s) => s.sector === sectorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorId]);

  useEffect(() => {
    setGheList([]);
    setSectorList([]);

    if (areaId !== "") {
      setSectorList(filters.setores.filter((s) => s.area === areaId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  return (
    <>
      <Card>
        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <DropDownMenuMultiplies
                itemSelected={questionnaireIds}
                itemSelector={setQuestionnaireIds}
                data={questionnaires}
                filterName={"Selecionar questionário(s)"}
              />
            </GridItem>
            {areaList.length > 0 && (
              <>
                <GridItem xs={12} sm={4} md={3}>
                  <DropDownMenuEditor
                    itemSelected={areaId}
                    itemSelector={setAreaId}
                    data={areaList}
                    filterName={"Área"}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                  <DropDownMenuEditor
                    itemSelected={sectorId}
                    itemSelector={setSectorId}
                    data={sectorList}
                    filterName={"Setor"}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                  <DropDownMenuEditor
                    itemSelected={gheId}
                    itemSelector={setGheId}
                    data={gheList}
                    filterName={"Ghe"}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                  <DropDownMenuEditor
                    itemSelected={restrict}
                    itemSelector={setRestrict}
                    data={[
                      {
                        id: "true",
                        name: "Sim",
                      },
                      {
                        id: "false",
                        name: "Não",
                      },
                    ]}
                    filterName={"Restrito"}
                  />
                </GridItem>
              </>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  marginTop: "12px",
                  width: "200px",
                }}
              >
                <Button
                  disabled={questionnaireIds.length === 0}
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleGerarGráficos}
                >
                  {areaList.length > 0 ? "Aplicar filtros" : "Gerar Gráficos"}
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <CensoCharts chartData={chartData} />
    </>
  );
}
