// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function RegularButton({
  filterName,
  data,
  itemSelected,
  itemSelector,
}) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    itemSelector(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "30px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">{filterName}</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        onChange={handleChange}
        value={itemSelected}
        className={classes2.selectEmpty}
      >
        {data &&
          data.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
