// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "../../assets/jss/material-dashboard-react/components/dropDownButtonStyle";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function DropDownPosition({ stepPosition, setStepPosition }) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    setStepPosition(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "30px" }}
      className={classes.formControl}
    >
      <InputLabel id="demo-simple-select-filled-label">Posição</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        onChange={handleChange}
        value={stepPosition}
        className={classes2.selectEmpty}
      >
        <MenuItem value={"Sentado"}>{"Sentado"}</MenuItem>
        <MenuItem value={"Em pé"}>{"Em pé"}</MenuItem>
        <MenuItem value={"Agachado"}>{"Agachado"}</MenuItem>
        <MenuItem value={"Andando"}>{"Andando"}</MenuItem>
        <MenuItem value={"Deitado"}>{"Deitado"}</MenuItem>
      </Select>
    </FormControl>
  );
}
