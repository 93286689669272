import React, { useEffect, useState } from "react";
import { getAllSectorsByAreaId } from "api/sectorApi";
import SectorTreeComponent from "./SectorTreeComponent";
import StyledTreeItem from "./StyledTreeItem";

export default function AreaTreeComponent({
  areaName,
  areaId,
  companyId,
  evaluationList,
  setOpenBackDrop,
}) {
  const [sectorList, setSectorList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (areaId !== "" && areaId !== undefined)
        try {
          const data = await getAllSectorsByAreaId(areaId);
          setSectorList(data);
        } catch (err) {}
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  return (
    <StyledTreeItem
      tooltipTitle="Área"
      selected="2"
      nodeId={areaId}
      label={areaName}
    >
      {sectorList &&
        sectorList.map(({ name, id }) => {
          return (
            <SectorTreeComponent
              setOpenBackDrop={setOpenBackDrop}
              evaluationList={evaluationList}
              companyId={companyId}
              key={id}
              sectorName={name}
              sectorId={id}
            />
          );
        })}
    </StyledTreeItem>
  );
}
