import React from "react";
// @material-ui/core components
// core components
import { TextField } from "@material-ui/core";

import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { createCause, editCause } from "../../api/causeApi.js";
import { useForm } from "react-hook-form";
import DropDownMenuCategory from "components/DropDownMenuCategory/DropDownMenuCategory.js";
import { useLoginContext } from "context/LoginContext.js";
import DropDownMenuDanger from "components/DropDownMenuDanger/DropDownMenuDanger.js";

export default function CauseEvaluation({
  stepId,
  setOpen,
  setMessage,
  setSeverity,
  setRefresh,
  setCategory,
  setDescription,
  category,
  description,
  causeId,
  edit,
  setEdit,
  recommendation,
  setRecommendation,
  danger,
  setDanger,
}) {
  const { handleSubmit, register, reset } = useForm();

  const { isReader } = useLoginContext();

  const onSubmit = handleSubmit(async (data) => {
    if (stepId === "" || category === "") {
      setMessage("Favor selecionar uma categoria");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        !edit
          ? await createCause(
              data.description,
              category,
              data.recommendation,
              stepId,
              danger
            )
          : await editCause(
              description,
              category,
              recommendation,
              causeId,
              danger
            );
        !edit
          ? setMessage("Causa criado com sucesso")
          : setMessage("Causa editada com sucesso");
        setEdit(false);
        setDescription("");
        setCategory("");
        setRecommendation("");
        setDanger("");
        setSeverity("success");
        setOpen(true);
        setRefresh(true);
        reset();
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  });

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <DropDownMenuCategory
                  category={category}
                  setCategory={setCategory}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <div style={{ marginTop: "5px" }}>
                  <TextField
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    id="description"
                    label="Descrição"
                    inputRef={register}
                    name="description"
                    fullWidth
                    margin="normal"
                    multiline
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  value={recommendation}
                  onChange={(e) => setRecommendation(e.target.value)}
                  variant="outlined"
                  id="recommendation"
                  label="Recomendação"
                  inputRef={register}
                  name="recommendation"
                  fullWidth
                  margin="normal"
                  required
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <DropDownMenuDanger control={danger} setControl={setDanger} />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  <RegularButton
                    disabled={isReader}
                    color={edit ? "warning" : "success"}
                    type="submit"
                    variant="contained"
                  >
                    {edit ? "Editar Causa" : "Criar Causa"}
                  </RegularButton>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </>
  );
}
