// material-ui components
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/components/dropDownButtonStyle.js";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) =>
  createStyles({
    selectEmpty: {
      zIndex: 1000000000000000000000000000000,
      marginTop: theme.spacing(2),
    },
  })
);

export default function RegularButton({
  filterName,
  data,
  itemSelected = [],
  itemSelector,
}) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.includes("")) {
      itemSelector([]);
    } else {
      itemSelector(value);
    }
  };

  const handleDelete = (itemId) => () => {
    const updatedSelected = itemSelected.filter((id) => id !== itemId);
    itemSelector(updatedSelected);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-filled-label">
          {filterName}
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          multiple
          onChange={handleChange}
          value={itemSelected}
          className={classes2.selectEmpty}
          renderValue={(selected) => null} // Do not render inline, we use chips below
        >
          {data &&
            data.map(({ id, name }) => {
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          <MenuItem value={""}>Nenhum</MenuItem>
        </Select>
      </FormControl>
      <div style={{ marginTop: "8px" }}>
        {itemSelected.map((id) => {
          const item = data.find((dataItem) => dataItem.id === id);
          return (
            <Chip
              key={id}
              label={item ? item.name : null}
              onDelete={handleDelete(id)}
              style={{
                margin: "4px",
                fontWeight: "bold",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
