import React, { useEffect, useState } from "react";

import { Chart } from "react-google-charts";

export default function BarCharPGR({
  trivial = 0,
  tolerable = 0,
  moderate = 0,
  high = 0,
  Intolerable = 0,
  responsible,
  company,
}) {
  const [data, setData] = useState([
    ["", "Trivial", "Tolerável", "Moderado", "Alto", "Intolerável"],
    [responsible, trivial, tolerable, moderate, high, Intolerable],
  ]);

  useEffect(() => {
    const newData = [
      ["", "Trivial", "Tolerável", "Moderado", "Alto", "Intolerável"],
      [responsible, trivial, tolerable, moderate, high, Intolerable],
    ];

    setData(newData);
  }, [trivial, tolerable, moderate, high, Intolerable, responsible]);

  const colors = ["#57c9d5", "#57b05c", "#fc930a", "#eb4945", "#9c27b0"];
  const labels = ["Trivial", "Tolerável", "Moderado", "Alto", "Intolerável"];

  return (
    <div style={{ marginBottom: "30px", padding: "30px", cursor: "pointer" }}>
      <h4>Criticidade</h4>
      <Chart
        chartType="Bar"
        width="400px"
        height="400px"
        data={data}
        options={{
          legend: { position: "none" },
          chart: {
            title: responsible,
            subtitle: company,
          },
          colors: colors,
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        {colors.map((color, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: color,
                marginRight: "10px",
              }}
            ></div>
            {labels[index]}
          </div>
        ))}
      </div>
    </div>
  );
}
