import Card from "components/Card/Card";
import useWindowDimensions from "hooks/UseWindowDimensions";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export default function PeopleChart({
  title,
  data,
  chartType,
  colors,
  title1,
  title2,
}) {
  const [chartData, setChartData] = useState([]);

  const { width } = useWindowDimensions();

  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    setChartWidth(chartData.length);
  }, [chartData]);

  useEffect(() => {
    const formattedData = data.map(({ label, total, sim, nao }) => [
      label,
      sim,
      nao,
    ]);
    setChartData([[title, title1, title2], ...formattedData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, title]);

  if (chartData.length < 2) {
    return <></>;
  }

  return (
    <Card
      style={{
        overflowX: "auto",
        overflowY: "hidden",
        margin: "auto",
        padding: "20px",
        marginBottom: "30px",
      }}
    >
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <table
        style={{
          margin: "auto",
          borderCollapse: "collapse",
          border: "1px solid black",
          wordWrap: "break-word",
          display: width < 1000 ? "none" : "table",
          marginBottom: "30px",
        }}
      >
        <tbody>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td key={index} style={styleForCells}>
                {row[0]}
              </td>
            ))}
          </tr>
          <tr>
            {data.map((row, index) => (
              <td key={index} style={styleForCells}>
                Total: {row.total}
              </td>
            ))}
          </tr>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td key={index} style={styleForCells}>
                {title1}: {row[1]} - (
                {Math.round((row[1] / data[index]?.total ?? 0) * 100)}%)
              </td>
            ))}
          </tr>
          <tr>
            {chartData.slice(1).map((row, index) => (
              <td key={index} style={styleForCells}>
                {title2}: {row[2]} - (
                {Math.round((row[2] / data[index]?.total ?? 0) * 100)}%)
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <div style={{ margin: "auto" }}>
        <Chart
          key={chartWidth}
          chartType={chartType}
          height="400px"
          width={`${chartWidth * 190}px`}
          data={chartData}
          options={{
            legend: { position: "none" },
            colors: colors,
          }}
        />
      </div>
    </Card>
  );
}

const styleForCells = {
  padding: "10px",
  textAlign: "center",
  border: "1px solid black",
  minWidth: "120px",
  maxWidth: "1fr",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
