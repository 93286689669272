import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: "100%",
  },
});

export default function ImageCard({ url, name }) {
  const classes = useStyles();
  return (
    <GridItem className={classes.root} xs={12} sm={12} md={12}>
      <img style={{ width: "100%", height: "120px" }} src={url} alt={name} />
    </GridItem>
  );
}
