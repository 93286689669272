import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components

import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
  createObjectLift,
  editObjectLift,
  getAllObjectsByStepId,
} from "../../api/objectApi.js";
import CardBody from "components/Card/CardBody.js";
import { uploadObjectImage } from "../../api/objectApi";
import ImageCard from "components/ImageCard/ImageCard";
import ObjectComponentList from "./ObjectComponentList.js";
import ObjectImagesRegister from "./ObjectImageRegister.js";
import { useLoginContext } from "context/LoginContext.js";

export default function StepEditorRegister({
  stepId,
  setChecked,
  setOpen,
  setMessage,
  setSeverity,
}) {
  const [files, setFiles] = useState([]);
  const [preview, setPreviews] = useState([]);
  const [objectId, setObjectId] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [weight, setWeight] = useState("");
  const [liftFrequency, setLiftFrequency] = useState("");
  const [distance, setDistance] = useState("");
  const [benchmarkValue, setBenchmarkValue] = useState("");
  const [foundValue, setFoundValue] = useState("");
  const [riskLevel, setRiskLevel] = useState("");
  const [objectArray, setObjectArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const prevs = [];
    files.forEach((file) => {
      let prev = [];
      prev.blob = file ? URL.createObjectURL(file) : null;
      prev.pos = files.indexOf(file);

      if (prev) prevs.push(prev);
    });
    setPreviews(prevs);
  }, [files]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFiles(files.filter((file) => file !== files[pos]));
  }

  const handleSubmit = async () => {
    if (stepId === "") {
      setMessage("Favor selecionar um passo");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await createObjectLift(
          name,
          type,
          weight,
          liftFrequency,
          distance,
          benchmarkValue,
          foundValue,
          riskLevel,
          stepId
        );
        if (res) {
          for (const file of files) {
            await uploadObjectImage(file, res.id);
          }
        }

        setMessage("Objeto criado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  const handleEdit = async () => {
    if (stepId === "") {
      setMessage("Favor selecionar um passo");
      setSeverity("error");
      setOpen(true);
    } else {
      try {
        const res = await editObjectLift(
          name,
          type,
          weight,
          liftFrequency,
          distance,
          benchmarkValue,
          foundValue,
          riskLevel,
          objectId
        );
        if (res) {
          for (const file of files) {
            await uploadObjectImage(file, res.id);
          }
        }

        setMessage("Objeto editado com sucesso");
        setSeverity("success");
        setOpen(true);
        setChecked(false);
      } catch (err) {
        setMessage("Passo já cadastrado com esse nome");
        setSeverity("error");
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllObjectsByStepId(stepId);
      setObjectArray(data);
      setRefresh(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <ObjectComponentList
        objects={objectArray}
        setName={setName}
        setType={setType}
        setWeight={setWeight}
        setLiftFrequency={setLiftFrequency}
        setDistance={setDistance}
        setBenchmarkValue={setBenchmarkValue}
        setFoundValue={setFoundValue}
        setRiskLevel={setRiskLevel}
        setObjectId={setObjectId}
        setRefresh={setRefresh}
        setEdit={setEdit}
      />
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={name}
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              id="name"
              label="Nome do Objeto"
              name="name"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={type}
              onChange={(event) => setType(event.target.value)}
              variant="outlined"
              id="type"
              label="Tipo"
              name="type"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={weight}
              onChange={(event) => setWeight(event.target.value)}
              variant="outlined"
              id="weight"
              label="Peso"
              name="weight"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={liftFrequency}
              onChange={(event) => setLiftFrequency(event.target.value)}
              variant="outlined"
              id="lift_frequency"
              label="Frequência"
              name="lift_frequency"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={distance}
              onChange={(event) => setDistance(event.target.value)}
              variant="outlined"
              id="distance"
              label="Distância"
              name="distance"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={benchmarkValue}
              onChange={(event) => setBenchmarkValue(event.target.value)}
              variant="outlined"
              id="benchmark_value"
              label="Valor referência"
              name="benchmark_value"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={foundValue}
              onChange={(event) => setFoundValue(event.target.value)}
              variant="outlined"
              id="found_value"
              label="Valor encontrado"
              name="found_value"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <TextField
              value={riskLevel}
              onChange={(event) => setRiskLevel(event.target.value)}
              variant="outlined"
              id="risk_level"
              label="Nível de risco"
              name="risk_level"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {preview.length > 0 &&
            preview
              .filter((preview) => preview.blob !== null)
              .map((preview) => {
                return (
                  <ImageCard
                    key={preview.pos}
                    preview={preview}
                    removeFile={removeFile}
                  />
                );
              })}
          {!edit && (
            <>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  <RegularButton
                    disabled={isReader}
                    variant="contained"
                    color="primary"
                    component="label"
                  >
                    + Adicionar imagens
                    <input
                      onChange={handleUploadMultipleFiles}
                      type="file"
                      className="form-control"
                      hidden
                    />
                  </RegularButton>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  <RegularButton
                    disabled={isReader}
                    color="success"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Criar Objeto
                  </RegularButton>
                </div>
              </GridItem>
            </>
          )}
          {edit && (
            <ObjectImagesRegister
              objectId={objectId}
              setEdit={setEdit}
              setOpen={setOpen}
              setMessage={setMessage}
              setSeverity={setSeverity}
              handleSubmitObject={handleEdit}
              setChecked={setChecked}
            />
          )}
        </GridContainer>
      </CardBody>
    </>
  );
}
