import axios from "axios";

import { API_URL } from "./urlApi.js";
async function userLogin(email, password) {
  const res = await axios.post(`${API_URL}/session`, {
    email: email,
    password: password,
  });
  sessionStorage.setItem(
    "QUESTIONNAIRE",
    res.data?.user?.role === "administer"
      ? true
      : res.data?.user?.is_questionnaire
  );
  sessionStorage.setItem("ROLE", res.data?.user?.role ?? "editor");
  sessionStorage.setItem("TOKEN_KEY", res.data.token);
  return res.data;
}

async function creatUser(username, email, password, role) {
  const res = await axios.post(
    `${API_URL}/user`,
    {
      name: username,
      email: email,
      password: password,
      role: role,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteUser(user_id) {
  const res = await axios.delete(
    `${API_URL}/user/${user_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllUsers() {
  const res = await axios.get(
    `${API_URL}/user/all`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}

export { creatUser, userLogin, getAllUsers, deleteUser };
