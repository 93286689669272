import axios from "axios";
import { API_URL } from "./urlApi.js";
async function createSector(name, sector_activity, work_area_id) {
  const res = await axios.post(
    `${API_URL}/sector`,
    {
      name,
      sector_activity,
      work_area_id,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function editSector(name, sector_activity, sectorId) {
  const res = await axios.put(
    `${API_URL}/sector/${sectorId}`,
    {
      name,
      sector_activity,
    },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllSectorsByAreaId(area_id) {
  const res = await axios.get(
    `${API_URL}/sector?work_area_id=${area_id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getAllSectors() {
  const res = await axios.get(
    `${API_URL}/sector`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function getSector(id) {
  const res = await axios.get(
    `${API_URL}/sector/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
async function deleteSector(id) {
  const res = await axios.delete(
    `${API_URL}/sector/${id}`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
      },
    }
  );
  return res.data;
}
export {
  createSector,
  getAllSectorsByAreaId,
  getSector,
  deleteSector,
  editSector,
  getAllSectors,
};
