import React, { useState } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { deleteCause } from "../../api/causeApi";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { useLoginContext } from "context/LoginContext";

export default function CauseComponentList({
  causes,
  setEdit,
  setCategory,
  setDescription,
  setRecommendation,
  setCauseId,
  setRefresh,
  setDanger,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  return (
    <>
      {causes.length > 0 &&
        causes.map(({ description, category, id, recommendation, danger }) => {
          const handleOpenDialog = () => {
            setIdToDelete(id);
            setOpenDialog(true);
          };

          const handleEdit = () => {
            setCauseId(id);
            setDescription(description);
            setRecommendation(recommendation);
            setCategory(category);
            setDanger(danger);
            setEdit(true);
          };

          return (
            <div key={id} style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={2} md={2}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Categoria"
                              variant="outlined"
                              disabled
                              value={category}
                              fullWidth
                            ></TextField>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Diagnóstico"
                              variant="outlined"
                              disabled
                              value={description}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              multiline
                            ></TextField>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Recomendação"
                              variant="outlined"
                              disabled
                              value={recommendation}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              multiline
                            ></TextField>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2}>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label="Perigo ou fator de risco"
                              variant="outlined"
                              disabled
                              value={danger}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              multiline
                            ></TextField>
                          </div>
                        </GridItem>

                        <GridItem xs={12} sm={1} md={1}>
                          <div style={{ padding: "10px", textAlign: "center" }}>
                            <IconButton
                              color="primary"
                              edge="end"
                              aria-label="delete"
                              onClick={handleEdit}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={1} md={1}>
                          <div style={{ padding: "10px", textAlign: "center" }}>
                            <IconButton
                              disabled={isReader}
                              color="secondary"
                              edge="end"
                              aria-label="delete"
                              onClick={handleOpenDialog}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                            <DeleteDialogImprovement
                              open={openDialog}
                              setOpen={setOpenDialog}
                              deleteFunction={deleteCause}
                              idToDelete={idToDelete}
                              setRefresh={setRefresh}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          );
        })}
    </>
  );
}
