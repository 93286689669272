import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function PieCharControl({
  riskEliminate,
  localChanges,
  timeReduce,
  practiceImplementation,
  implementProgram,
}) {
  const classes = useStyles();

  const [data, setData] = useState([
    {
      title: "Eliminar risco",
      value: riskEliminate ? riskEliminate : 0,
      color: "#57c9d5",
    },
    {
      title: "Mudança no local",
      value: localChanges ? localChanges : 0,
      color: "#5cb860",
    },
    {
      title: "Redução tempo ao risco",
      value: timeReduce ? timeReduce : 0,
      color: "#ffa21a",
    },
    {
      title: "Implementação de práticas",
      value: practiceImplementation ? practiceImplementation : 0,
      color: "#ef5350",
    },
    {
      title: "Programas Epi, ginástica ou outros",
      value: implementProgram ? implementProgram : 0,
      color: "#af2cc5",
    },
  ]);

  const [selected, setSelected] = useState(-1);
  useEffect(() => {
    setData([
      {
        title: "Eliminar risco",
        value: riskEliminate ? riskEliminate : 0,
        color: "#57c9d5",
      },
      {
        title: "Mudança no local",
        value: localChanges ? localChanges : 0,
        color: "#5cb860",
      },
      {
        title: "Redução tempo ao risco",
        value: timeReduce ? timeReduce : 0,
        color: "#ffa21a",
      },
      {
        title: "Implementação de práticas",
        value: practiceImplementation ? practiceImplementation : 0,
        color: "#ef5350",
      },
      {
        title: "Programas Epi, ginástica ou outros",
        value: implementProgram ? implementProgram : 0,
        color: "#af2cc5",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localChanges, practiceImplementation, implementProgram, timeReduce]);

  return (
    <div style={{ marginBottom: "30px", padding: "10px" }}>
      <h5
        className={classes.pieTitle}
        style={{ justifyContent: "center", display: "flex" }}
      >
        Controle
      </h5>
      <PieChart
        radius={PieChart.defaultProps.radius - 6}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        segmentsShift={(index) => (index === selected ? 6 : 1)}
        onClick={(event, index) => {
          setSelected(index === selected ? undefined : index);
        }}
        labelStyle={{
          fontSize: "6px",
          fontWeight: "bold",
          fontColor: "white",
        }}
        label={({ dataEntry }) =>
          dataEntry.value !== 0 ? dataEntry.value : ""
        }
        data={data}
      />
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#57c9d5" }}>
        Eliminar Risco
      </p>
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#5cb860" }}>
        Mudança no local para reduzir risco
      </p>{" "}
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ffa21a" }}>
        Reduzir o tempo de exposição ao risco
      </p>{" "}
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ef5350" }}>
        {" "}
        Implementar práticas para reduzir o risco
      </p>
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#af2cc5" }}>
        {" "}
        Programas de Epi, ginástica ou outros
      </p>
    </div>
  );
}
