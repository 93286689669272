import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import StepEvaluationView from "./StepEvaluationView";
import CausesView from "./CausesView";
import ImprovementEvaluation from "./ImprovementEvaluation";
import BriefAndBestProvider from "context/BriefAndBestContext";
import AlertCustom from "components/Alerts/AlertCustom";
import StepToolViewn from "./StepToolView";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#f9ffffff",
  },
}));

export default function TabsView({
  stepId,
  areaId,
  sectorId,
  gheId,
  companyId,
  jobPositionId,
  activityId,
  workstationId,
  setOpenBackDrop,
  openBackDrop,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AlertCustom
        setOpen={setOpen}
        open={open}
        message={message}
        severity={severity}
      />

      <AppBar position="static" color="default">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Avaliação" {...a11yProps(0)} />
          <Tab label="Fatores Ergonômicos" {...a11yProps(1)} />
          <Tab label="Melhorias" {...a11yProps(2)} />
          <Tab label="Avaliação pós melhoria" {...a11yProps(3)} />
          <Tab label="Concepção" {...a11yProps(4)} />
          <Tab label="Ferramenta Auxiliar" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BriefAndBestProvider>
          <StepEvaluationView
            setOpenBackDrop={setOpenBackDrop}
            openBackDrop={openBackDrop}
            areaId={areaId}
            sectorId={sectorId}
            gheId={gheId}
            companyId={companyId}
            jobPositionId={jobPositionId}
            activityId={activityId}
            workstationId={workstationId}
            setMessage={setMessage}
            setOpen={setOpen}
            setSeverity={setSeverity}
            stepId={stepId}
            setValue={setValue}
            evaluationType={"Inicial"}
          />
        </BriefAndBestProvider>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CausesView
          setOpenBackDrop={setOpenBackDrop}
          setMessage={setMessage}
          setOpen={setOpen}
          setSeverity={setSeverity}
          stepId={stepId}
          setValue={setValue}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ImprovementEvaluation
          setMessage={setMessage}
          setOpen={setOpen}
          setSeverity={setSeverity}
          stepId={stepId}
          companyId={companyId}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BriefAndBestProvider>
          <StepEvaluationView
            setOpenBackDrop={setOpenBackDrop}
            openBackDrop={openBackDrop}
            activityId={activityId}
            areaId={areaId}
            sectorId={sectorId}
            gheId={gheId}
            companyId={companyId}
            jobPositionId={jobPositionId}
            workstationId={workstationId}
            setMessage={setMessage}
            setOpen={setOpen}
            setSeverity={setSeverity}
            stepId={stepId}
            setValue={setValue}
            evaluationType={"Acompanhamento"}
          />
        </BriefAndBestProvider>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BriefAndBestProvider>
          <StepEvaluationView
            setOpenBackDrop={setOpenBackDrop}
            openBackDrop={openBackDrop}
            activityId={activityId}
            areaId={areaId}
            sectorId={sectorId}
            gheId={gheId}
            companyId={companyId}
            jobPositionId={jobPositionId}
            workstationId={workstationId}
            setMessage={setMessage}
            setOpen={setOpen}
            setSeverity={setSeverity}
            stepId={stepId}
            setValue={setValue}
            evaluationType={"Projetado"}
          />
        </BriefAndBestProvider>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <StepToolViewn
          setOpenBackDrop={setOpenBackDrop}
          setOpen={setOpen}
          setSeverity={setSeverity}
          stepId={stepId}
          setMessage={setMessage}
        />
      </TabPanel>
    </div>
  );
}
