import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import RegularButton from "components/CustomButtons/Button";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
  },
});

export default function FileCard({ preview, removeFile }) {
  const classes = useStyles();

  return (
    <GridItem className={classes.root} key={preview.pos} xs={12} sm={4} md={3}>
      <img
        style={{ width: "247,5px", height: "421px" }}
        src={preview.blob}
        alt={preview.blob}
      />
      <RegularButton
        onClick={(event) => removeFile(event, preview.pos)}
        id="delete-button"
        color="rose"
        type="submit"
        variant="contained"
      >
        Excluir imagem
      </RegularButton>
    </GridItem>
  );
}
