import React, { useState } from "react";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useEffect } from "react";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles({
  grid: {
    flex: "1 !important",
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function BriefAndBest({
  name,
  picture,
  title,
  functionPhysicalStressor,
  counter,
  subtitle,
  functionCheck,
  check,
}) {
  const [checkedPosture, setChecked] = useState(false);

  const classes2 = useStyles2();

  useEffect(() => {
    if (check) {
      setChecked(true);
      functionPhysicalStressor(counter + 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  const handleChange = () => {
    if (!checkedPosture) {
      setChecked(true);
      functionCheck(true);
    } else {
      setChecked(false);
      functionCheck(false);
      functionPhysicalStressor(counter - 2);
    }
  };

  const classes = useStyles();
  return (
    <GridItem
      onClick={handleChange}
      className={classes2.grid}
      xs={12}
      sm={3}
      md={2}
    >
      <Card
        style={{
          cursor: "pointer",
          backgroundColor: checkedPosture ? "rgba(0, 211, 238, 0.1)" : "white",
          boxShadow: checkedPosture
            ? "0px 0px 10px #00acc1"
            : "0px 0px 10px rgba(0, 0, 0, 0.3)",
          height: "180px",
        }}
        className={classes2.grid}
      >
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h5
            style={{
              display: "flex",
              fontSize: "14px",
              fontWeight: "bold",
              minHeight: "100%",
              margin: "auto",
              alignItems: "center",
              textAlign: "center",
            }}
            className={classes.cardTitle}
          >
            {title}
          </h5>
        </CardBody>
      </Card>
    </GridItem>
  );
}
