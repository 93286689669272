import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Card } from "@material-ui/core";
import ChartLoader from "./ChartLoader";

export default function RiskReduce({
  veryHighToHigh,
  veryHighToModerate,
  veryHighToLow,
  veryHighToNoRisk,
  highToModerate,
  highToLow,
  highToNoRisk,
  moderateToLow,
  moderateToNoRisk,
  lowToNoRisk,
  sameRisk,
  loading,
}) {
  const chartOptions = {
    bars: "vertical",
    legend: { position: "none" },
    bar: { groupWidth: "90%" },
    annotations: {
      alwaysOutside: true,
    },
  };

  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total =
      veryHighToHigh +
      veryHighToModerate +
      veryHighToLow +
      veryHighToNoRisk +
      highToModerate +
      highToLow +
      highToNoRisk +
      moderateToLow +
      moderateToNoRisk +
      lowToNoRisk +
      sameRisk;

    setTotal(total);
  }, [
    veryHighToHigh,
    veryHighToModerate,
    veryHighToLow,
    veryHighToNoRisk,
    highToModerate,
    highToLow,
    highToNoRisk,
    moderateToLow,
    moderateToNoRisk,
    lowToNoRisk,
    sameRisk,
  ]);

  useEffect(() => {
    const newData = [
      [
        "Melhoria",
        "Valor",
        { role: "style" },
        {
          sourceColumn: 1,
          role: "annotation",
          type: "string",
        },
      ],
      ["Altíssimo - Alto", veryHighToHigh, "#00acc1", String(veryHighToHigh)],
      [
        "Altíssimo - Moderado",
        veryHighToModerate,
        "#00acc1",
        String(veryHighToModerate),
      ],
      ["Altíssimo - Baixo", veryHighToLow, "#00acc1", String(veryHighToLow)],
      [
        "Altíssimo - Sem risco",
        veryHighToNoRisk,
        "#00acc1",
        String(veryHighToNoRisk),
      ],
      ["Alto - Moderado", highToModerate, "#00acc1", String(highToModerate)],
      ["Alto - Baixo", highToLow, "#00acc1", String(highToLow)],
      ["Alto - Sem risco", highToNoRisk, "#00acc1", String(highToNoRisk)],
      ["Moderado - Baixo", moderateToLow, "#00acc1", String(moderateToLow)],
      [
        "Moderado - Sem risco",
        moderateToNoRisk,
        "#00acc1",
        String(moderateToNoRisk),
      ],
      ["Baixo - Sem risco", lowToNoRisk, "#00acc1", String(lowToNoRisk)],
      ["Mesmo risco", sameRisk, "#00acc1", String(sameRisk)],
    ];

    setData(newData);
  }, [
    veryHighToHigh,
    veryHighToModerate,
    veryHighToLow,
    veryHighToNoRisk,
    highToModerate,
    highToLow,
    highToNoRisk,
    moderateToLow,
    moderateToNoRisk,
    lowToNoRisk,
    sameRisk,
  ]);

  if (loading) {
    return <ChartLoader />;
  }

  if (!data) {
    return <ChartLoader />;
  }

  return (
    <Card>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h5>Mudança de Risco</h5>
      </div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={chartOptions}
      />
      <div style={{ width: "100%", textAlign: "center" }}>
        <h6 style={{ marginTop: "+10px" }}>
          Total <strong>{total}</strong>
        </h6>
      </div>
    </Card>
  );
}
