import GridContainer from "components/Grid/GridContainer.js";
import React, { useEffect, useState } from "react";
import RegularButton from "components/CustomButtons/Button";
import FileCard from "components/FileCard/FileCard";

import GridItem from "components/Grid/GridItem.js";

import { useLoginContext } from "context/LoginContext.js";

export default function StepToolFile({ file, setFile }) {
  const [preview, setPreviews] = useState([]);

  const { isReader } = useLoginContext();

  useEffect(() => {
    let blob = file ? URL?.createObjectURL(file) : null;
    let name = file ? file.name : null;
    let pos = 0;
    setPreviews({ blob, pos, name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleUploadMultipleFiles = (e) => {
    if (e.target.files[0]) setFile(e.target.files[0]);
  };

  function removeFile(event, pos) {
    event.preventDefault();
    setFile(null);
  }

  return (
    <>
      <GridContainer>
        {preview?.blob && (
          <FileCard
            key={preview.pos}
            preview={preview}
            removeFile={removeFile}
          />
        )}
        {!file && (
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <RegularButton
                disabled={isReader}
                variant="contained"
                color="primary"
                component="label"
              >
                + Adicionar foto
                <input
                  onChange={handleUploadMultipleFiles}
                  type="file"
                  className="form-control"
                  hidden
                />
              </RegularButton>
            </div>
          </GridItem>
        )}
      </GridContainer>
    </>
  );
}
