import React, { useState } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { deleteObjectLift } from "api/objectApi";
import { useLoginContext } from "context/LoginContext";

export default function ObjectComponentList({
  objects,
  setName,
  setType,
  setWeight,
  setLiftFrequency,
  setDistance,
  setBenchmarkValue,
  setFoundValue,
  setRiskLevel,
  setObjectId,
  setRefresh,
  setEdit,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  return (
    <>
      {objects.length > 0 &&
        objects.map(
          ({
            name,
            id,
            type,
            weight,
            lift_frequency,
            distance,
            benchmark_value,
            found_value,
            risk_level,
          }) => {
            const handleOpenDialog = () => {
              setIdToDelete(id);
              setOpenDialog(true);
            };

            const handleEdit = () => {
              setType(type);
              setWeight(weight);
              setLiftFrequency(lift_frequency);
              setDistance(distance);
              setBenchmarkValue(benchmark_value);
              setFoundValue(found_value);
              setRiskLevel(risk_level);
              setObjectId(id);
              setName(name);
              setEdit(true);
            };

            return (
              <div key={id} style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={10} md={10}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Nome"
                                variant="outlined"
                                disabled
                                value={name}
                                fullWidth
                              ></TextField>
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                color="primary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleEdit}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                disabled={isReader}
                                color="secondary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleOpenDialog}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                              <DeleteDialogImprovement
                                open={openDialog}
                                setOpen={setOpenDialog}
                                deleteFunction={deleteObjectLift}
                                idToDelete={idToDelete}
                                setRefresh={setRefresh}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            );
          }
        )}
    </>
  );
}
