import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function PieChartCategory({
  posture,
  strength,
  repetition,
  layout,
  tool,
}) {
  const classes = useStyles();

  const [data, setData] = useState([
    { title: "Postura", value: posture ? posture : 0, color: "#57c9d5" },
    { title: "Força", value: strength ? strength : 0, color: "#5cb860" },
    {
      title: "Repetição",
      value: repetition ? repetition : 0,
      color: "#ffa21a",
    },
    { title: "Layout", value: layout ? layout : 0, color: "#ef5350" },
    { title: "Ferramenta", value: tool ? tool : 0, color: "#af2cc5" },
  ]);

  useEffect(() => {
    setData([
      { title: "Postura", value: posture ? posture : 0, color: "#57c9d5" },
      { title: "Força", value: strength ? strength : 0, color: "#5cb860" },
      {
        title: "Repetição",
        value: repetition ? repetition : 0,
        color: "#ffa21a",
      },
      { title: "Layout", value: layout ? layout : 0, color: "#ef5350" },
      { title: "Ferramenta", value: tool ? tool : 0, color: "#af2cc5" },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strength, layout, tool, repetition]);
  const [selected, setSelected] = useState(-1);

  return (
    <div style={{ marginBottom: "30px", padding: "10px" }}>
      <h5
        className={classes.pieTitle}
        style={{ justifyContent: "center", display: "flex" }}
      >
        Categoria da causa
      </h5>
      <PieChart
        radius={PieChart.defaultProps.radius - 6}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        segmentsShift={(index) => (index === selected ? 6 : 1)}
        onClick={(event, index) => {
          setSelected(index === selected ? undefined : index);
        }}
        labelStyle={{
          fontSize: "6px",
          fontWeight: "bold",
          fontColor: "white",
        }}
        label={({ dataEntry }) =>
          dataEntry.value !== 0 ? dataEntry.value : ""
        }
        data={data}
      />
      <p style={{ textAlign: "center", fontWeight: "bold", color: "#57c9d5" }}>
        Postura
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#5cb860" }}>
        Força
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ffa21a" }}>
        Repetição
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#ef5350" }}>
        Layout
      </p>

      <p style={{ textAlign: "center", fontWeight: "bold", color: "#af2cc5" }}>
        Ferramenta
      </p>
    </div>
  );
}
