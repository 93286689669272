import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import GuardRoute from "./GuardRoute";

// import Admin from "layouts/Admin.js";
import Editor from "layouts/Editor.js";
// import Reader from "layouts/Reader.js";
import Login from "layouts/Login/Login.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import HeaderProvider from "context/HeaderContext";
import LoginProvider from "context/LoginContext";
import Quizz from "layouts/Quizz/Quizz";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <LoginProvider>
      <HeaderProvider>
        <Switch>
          {/* <Route path="/admin" component={Admin} /> */}
          <GuardRoute path="/editor" component={Editor} isPrivate />
          {/* <Route path="/reader" component={Reader} /> */}
          <Route
            path="/webmail"
            component={() => (window.location = "http://162.214.197.176:2095/")}
          />
          <Route path="/login" component={Login} />
          <Route path="/questionario/:id" component={Quizz} />
          <Redirect from="/" to="/login" />
        </Switch>
      </HeaderProvider>
    </LoginProvider>
  </Router>,
  document.getElementById("root")
);
