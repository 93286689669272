import React, { useState } from "react";
// Modules
// MUI Core
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import imagine3 from "assets/img/sidebar-3.jpg";
import logo from "../../assets/img/logo.png";
import RegularButton from "components/CustomButtons/Button";
import AlertCustom from "components/Alerts/AlertCustom";
import { userLogin } from "../../api/userApi";
import { useHistory } from "react-router-dom";
import { useHeaderContext } from "context/HeaderContext.js";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#fefeffb4",
    height: "100vh",
    top: "50%",
  },
}));

export default function Login() {
  const classes = useStyles();
  const { handleSubmit, register, reset } = useForm();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { setRefreshCompanyId } = useHeaderContext();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await userLogin(data.email, data.password);
      setRefreshCompanyId(true);
      history.push("/editor");
    } catch (err) {
      setOpen(true);
      reset();
    }
  });

  return (
    <div style={style.div}>
      <AlertCustom
        setOpen={setOpen}
        open={open}
        message={"Senha ou usuário inválido"}
        severity={"error"}
      />
      <Container className={classes.container} maxWidth="xs">
        <div style={{ marginTop: "20%" }}>
          <img style={style.img} src={logo} alt="logo" />
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      inputRef={register}
                      label="Email"
                      name="email"
                      size="small"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      inputRef={register}
                      label="Password"
                      name="password"
                      size="small"
                      type="password"
                      variant="outlined"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RegularButton
                  color="info"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Log in
                </RegularButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}

const style = {
  div: {
    height: "100vh",
    position: "relative",
    backgroundImage: "url(" + imagine3 + ")",
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    width: "300px",
  },
};
